import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import { PaperContainer } from '../../../../shared/components/PaperContainer';

/**
 * @returns {JSX.Element} Experience loading.
 */
export const ExperienceLoading: React.FC = () => (
  <PaperContainer>
    <Box pt={7} pr={5}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Box display="flex" width="100%" justifyContent="center">
            <Skeleton variant="circle" width="140px" height="140px" />
          </Box>
        </Grid>
        <Grid item xs={9}>
          <Box mb="10px">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Skeleton variant="rect" width="100%" height={40} />
              </Grid>
              <Grid item xs={6}>
                <Skeleton variant="rect" width="100%" height={40} />
              </Grid>
            </Grid>
          </Box>
          <Box mb="20px">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Skeleton variant="rect" width="100%" height={40} />
              </Grid>
              <Grid item xs={6}>
                <Skeleton variant="rect" width="100%" height={40} />
              </Grid>
            </Grid>
          </Box>
          <Box mb="20px">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Skeleton variant="rect" width="100%" height={40} />
              </Grid>
              <Grid item xs={6}>
                <Skeleton variant="rect" width="100%" height={40} />
              </Grid>
            </Grid>
          </Box>
          <Box mb="10px">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Skeleton variant="rect" width="100%" height={160} />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  </PaperContainer>
);
