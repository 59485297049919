import { createAction } from '@cobuildlab/react-simple-state';
import { apolloClient } from '../../shared/apollo';
import { SortType, UserType } from '../../shared/types';
import {
  OnFetchUsers,
  OnErrorFetchUsers,
  OnFetchUser,
  OnErrorFetchUser,
  OnUpdateUser,
  OnUpdateUserError,
  OnFetchGenders,
  OnErrorFetchGenders,
  OnArchiveUser,
  OnArchiveUserError,
  OnUnarchiveUser,
  OnUnarchiveUserError,
} from './people-events';
import { PeopleListDataType } from './people-model';
import {
  FETCH_USER,
  FETCH_USERS,
  UPDATE_USER,
  UPDATE_AUTH0_USER,
  FETCH_GENDERS,
} from './people-queries';
import { createFilterPeopleList } from './people-utils';

/**
 * @description - Fetch users.
 */
export const fetchUsersAction = createAction(
  OnFetchUsers,
  OnErrorFetchUsers,
  async (
    data: PeopleListDataType,
    page: number,
    sortData: SortType,
    first = 10,
  ) => {
    const filter = createFilterPeopleList(data);
    const skip = (page - 1) * first;
    let sort;

    if (sortData && Object.keys(sortData).length) {
      sort = sortData;
    }

    const response = await apolloClient.query({
      query: FETCH_USERS,
      fetchPolicy: 'network-only',
      variables: { filter, skip, first, sort },
    });

    return response.data.usersList;
  },
);

/**
 * @description - Fetch user.
 */
export const fetchUser = createAction(
  OnFetchUser,
  OnErrorFetchUser,
  async (id: string): Promise<UserType> => {
    const response = await apolloClient.query({
      query: FETCH_USER,
      fetchPolicy: 'network-only',
      variables: { id },
    });
    return response.data.user;
  },
);

/**
 * @description - Update user.
 */
export const updateUserAction = createAction(
  OnUpdateUser,
  OnUpdateUserError,
  async (data) => {
    const response = await apolloClient.mutate({
      mutation: UPDATE_USER,
      fetchPolicy: 'network-only',
      variables: {
        data,
      },
    });

    return response.data.userUpdate;
  },
);

/**
 * @description - Fetch genders.
 */
export const fetchGendersAction = createAction(
  OnFetchGenders,
  OnErrorFetchGenders,
  async () => {
    const response = await apolloClient.query({
      query: FETCH_GENDERS,
    });

    return response.data.genderTypesList;
  },
);

/**
 * @description - Archive user.
 */
export const archiveUserAction = createAction(
  OnArchiveUser,
  OnArchiveUserError,
  async (id, email) => {
    const response = await apolloClient.mutate({
      mutation: UPDATE_USER,
      fetchPolicy: 'network-only',
      variables: {
        data: {
          id,
          archivedAt: new Date(),
        },
      },
    });

    await apolloClient.mutate({
      mutation: UPDATE_AUTH0_USER,
      fetchPolicy: 'network-only',
      variables: {
        email,
        data: {
          blocked: true,
        },
      },
    });

    return response.data.userUpdate;
  },
);

/**
 * @description - Unarchive user.
 */
export const unarchiveUserAction = createAction(
  OnUnarchiveUser,
  OnUnarchiveUserError,
  async (id, email) => {
    const response = await apolloClient.mutate({
      mutation: UPDATE_USER,
      fetchPolicy: 'network-only',
      variables: {
        data: {
          id,
          archivedAt: null,
        },
      },
    });

    await apolloClient.mutate({
      mutation: UPDATE_AUTH0_USER,
      fetchPolicy: 'network-only',
      variables: {
        email,
        data: {
          blocked: false,
        },
      },
    });

    return response.data.userUpdate;
  },
);
