import { createAction } from '@cobuildlab/react-simple-state';
import Geocode from 'react-geocode';
import { OnFetchGeoData, OnFetchGeoDataError } from './geo-events';
import { GOOGLE_MAP_API_KEY } from '../../shared/constants';
import { GeoDataType } from '../../shared/types';

Geocode.setApiKey(GOOGLE_MAP_API_KEY as string);

export const fetchGeoDataAction = createAction(
  OnFetchGeoData,
  OnFetchGeoDataError,
  async (lat, lng) => {
    const { results }: GeoDataType = await Geocode.fromLatLng(lat, lng);

    return results;
  },
);
