import React, { useState, useEffect, useContext } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useFormContext, Controller } from 'react-hook-form';
import { useCallAction } from '@cobuildlab/react-simple-state';
import {
  fetchCitiesByNameAction,
  createCityAction,
} from '../../../city/city-actions';
import { CityType } from '../../../../shared/types';
import { ExperienceFormContext } from './ExperienceFormProvider';
import * as toast from '../../../../shared/components/ui/toasts/Toast';

/**
 * @returns {JSX.Element} City list.
 */
export const ExperienceCitySelect: React.FC = () => {
  const { watch, control, setValue, clearErrors } = useFormContext();
  const stateId = watch('state');
  const [cities, setCities] = useState<CityType[]>([]);
  const { experience, cityName, setLoadingUpload } = useContext(
    ExperienceFormContext,
  );

  const [createCity, loadingCreate] = useCallAction(createCityAction, {
    /**
     * @param {CityType} cityCreate - List of cities.
     */
    onCompleted: (cityCreate) => {
      setCities([cityCreate]);
      setValue('city', cityCreate.id);
      setLoadingUpload(false);
      clearErrors('city');
    },

    /**
     * @param {TypeError} e - Error event.
     */
    onError: (e) => {
      toast.error(e.message);
      setValue('city', '');
      setLoadingUpload(false);
      clearErrors('city');
    },
  });

  const [fetchCitiesByName, loading] = useCallAction(fetchCitiesByNameAction, {
    /**
     * @param {CityType[]} cityData - List of cities.
     */
    onCompleted: (cityData) => {
      if (cityData.length) {
        setCities(cityData);
        setValue('city', cityData[0].id);
        setLoadingUpload(false);
        clearErrors('city');
      } else {
        createCity({
          state: {
            connect: {
              id: stateId,
            },
          },
          cityName,
        });
      }
    },

    /**
     * @param {TypeError} e - Error event.
     */
    onError: (e) => {
      toast.error(e.message);
      setValue('city', '');
      setLoadingUpload(false);
      clearErrors('city');
    },
  });

  useEffect(() => {
    if (stateId) {
      setLoadingUpload(true);
      fetchCitiesByName(cityName);
    }
  }, [fetchCitiesByName, setLoadingUpload, stateId, cityName]);

  const list = cities.map((city) => (
    <MenuItem value={city.id} key={city.id}>
      {city.cityName}
    </MenuItem>
  ));

  let content: JSX.Element = (
    <Controller
      name="city"
      control={control}
      defaultValue={experience?.city?.id || ''}
      render={({ field }) => (
        <Select {...field} disabled labelId="city-label" id="city" label="City">
          {list}
        </Select>
      )}
    />
  );

  if (loading || loadingCreate) {
    content = (
      <Select value="0" disabled labelId="city-label" id="city" label="City">
        <MenuItem value="0" disabled>
          Loading...
        </MenuItem>
      </Select>
    );
  }

  return content;
};
