import { apolloClient } from '../../shared/apollo';
import {
  FETCH_BOOKING_COUNT_DAYS,
  FETCH_BOOKING_COUNT,
  FETCH_BOOKING_TIME_ELAPSED_TOTAL,
  FETCH_BOOKING_CANCELLED_COUNT,
} from './booking-queries';

/**
 * @param {string} startDateTime - Itinerary start date.
 * @param {string} endDateTime - Itinerary end date.
 * @param {string} status - Booking status.
 *
 * @returns {Record} - Filter .
 */
const createFilterBookingByStatus = (
  startDateTime: string,
  endDateTime: string,
  status: string,
): Record<string, unknown> => {
  const filter = {
    AND: [
      {
        itinerary: {
          startDateTime: {
            gte: startDateTime,
          },
        },
      },
      {
        itinerary: {
          endDateTime: {
            lte: endDateTime,
          },
        },
      },
      {
        bookingStatus: {
          statusName: {
            equals: status,
          },
        },
      },
    ],
  };

  return filter;
};

/**
 * @param {string} startDateTime  - Start date booking.
 * @param {string} endDateTime - End date booking.
 * @param {string} status - Status booking.
 *
 * @returns {Promise<number>} - Days per booking.
 */
export const fetchBookingDaysCountService = async (
  startDateTime: string,
  endDateTime: string,
  status: string,
): Promise<number> => {
  const response = await apolloClient.query<{
    bookingsList: { groups: Array<{ dayIdentifier: string }> };
  }>({
    query: FETCH_BOOKING_COUNT_DAYS,
    fetchPolicy: 'network-only',
    variables: {
      startDateTime,
      endDateTime,
      status,
    },
  });

  return response.data?.bookingsList?.groups?.length;
};

/**
 * @param {string} startDateTime  - Start date booking.
 * @param {string} endDateTime - End date booking.
 * @param {string} status - Status booking.
 *
 * @returns {Promise<number>} - Days per booking.
 */
export const fetchBookingCompletedCountService = async (
  startDateTime: string,
  endDateTime: string,
  status: string,
): Promise<number> => {
  const filter = createFilterBookingByStatus(
    startDateTime,
    endDateTime,
    status,
  );

  const response = await apolloClient.query<{
    bookingsList: { count: number };
  }>({
    query: FETCH_BOOKING_COUNT,
    fetchPolicy: 'network-only',
    variables: {
      filter,
    },
  });

  return response.data?.bookingsList?.count;
};

/**
 * @param {string} startDateTime  - Start  approved date.
 * @param {string} endDateTime - End approved date.
 * @param {string} status - Booking status.
 *
 * @returns {Promise<number| null>} - Sum time elapsed.
 */
export const fetchTimeElapsedAverageService = async (
  startDateTime: string,
  endDateTime: string,
  status: string,
): Promise<{
  timeElapsedTotal: number | null;
  bookingApprovedCount: number;
}> => {
  const response = await apolloClient.query<{
    bookingsList: {
      groups: Array<{ timeElapsedTotal: number | null }>;
      count: number;
    };
  }>({
    query: FETCH_BOOKING_TIME_ELAPSED_TOTAL,
    variables: {
      startDateTime,
      endDateTime,
      status,
    },
  });

  return {
    bookingApprovedCount: response.data?.bookingsList?.count,
    timeElapsedTotal: response.data?.bookingsList?.groups[0]?.timeElapsedTotal,
  };
};

/**
 * @param {string} startDateTime  - Start date booking.
 * @param {string} endDateTime - End date booking.
 *
 * @returns {Promise<number>} - Booking cancelled.
 */
export const fetchBookingCancelledCountService = async (
  startDateTime: string,
  endDateTime: string,
): Promise<number> => {
  const response = await apolloClient.query<{
    bookingsList: { count: number };
  }>({
    query: FETCH_BOOKING_CANCELLED_COUNT,
    fetchPolicy: 'network-only',
    variables: {
      startDateTime,
      endDateTime,
    },
  });

  return response.data?.bookingsList?.count;
};
