import React, { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallAction } from '@cobuildlab/react-simple-state';
import { useHistory } from 'react-router-dom';
import { ExperienceAddHeader } from './components/ExperienceAddHeader';
import { PaperContainer } from '../../shared/components/PaperContainer';
import { ExperienceGeneralFields } from './components/experience-form/ExperienceGeneralFields';
import { ExperienceOpeningTime } from './components/experience-form/ExperienceOpeningTime';
import {
  ExperiencePricing,
  PriceItemType,
} from './components/experience-form/ExperiencePricing';
import { ExperienceRestrictions } from './components/experience-form/ExperienceRestrictions';
import { Media } from './components/experience-form/Media';
import { Waiver } from './components/experience-form/Waiver';
import { ExperienceFormButtons } from './components/experience-form/ExperienceFormButtons';
import { ExperienceAvatar } from './components/experience-form/ExperienceAvatar';
import { ExperienceFormProvider } from './components/experience-form/ExperienceFormProvider';
import { omit } from '../../shared/utils';
import { experienceValidatorSchema } from './experience-validator';
import { createExperienceAction } from './experience-actions';
import * as toast from '../../shared/components/ui/toasts/Toast';
import {
  ExperienceScheduleType,
  FileConnectType,
  ExperienceKeywordType,
} from '../../shared/types';
import { ExperienceDataSubmitType, MediaType } from './experience-model';
import { useWebpageTitle } from '../../shared/hooks/useWebpageTitle';

export type ExperienceInputType = {
  experienceName: string;
  contactEmailAddress: string;
  zipcode: string;
  address: string;
  phoneNumber: string;
  experienceDescription: string;
  state: string;
  categories: string[];
  subCategories: string[];
  pricings: PriceItemType[];
  openingTimes: ExperienceScheduleType[];
  additionalInformation: string;
  city: string;
  operatorName: string;
  media: MediaType[];
  waiver: MediaType[];
  requireReservation?: boolean;
  file?: FileConnectType;
  keywords?: ExperienceKeywordType[];
  lat?: string;
  lng?: string;
};

/**
 * Experience create view.
 *
 * @returns {JSX.Element} Experience add form.
 */
export const ExperienceAdd: React.FC = () => {
  const method = useForm<ExperienceInputType>({
    resolver: yupResolver(experienceValidatorSchema),
  });

  const history = useHistory();

  useEffect(() => {
    /**
     * Manually registers some fields whose value cannot
     * be taken by the inputRef attribute.
     */
    method.register('categories');
    method.register('subCategories');
    method.register('openingTimes');
    method.register('pricings');
    method.register('media');
    method.register('waiver');
    method.register('requireReservation');
    method.register('keywords');
    method.register('lat');
    method.register('lng');
  }, [method]);

  const [createExperience, loading] = useCallAction(createExperienceAction, {
    /**
     * Success callback.
     */
    onCompleted: () => {
      toast.success('Success', 'Experience saved successfully');
      history.push('/experiences');
    },

    /**
     * @param {TypeError} e - Error event.
     */
    onError: (e) => {
      toast.error(e.message);
    },
  });

  /**
   * Submit data.
   *
   * @param inputs - Data of inputs.
   */
  const onSubmit: SubmitHandler<ExperienceInputType> = (inputs): void => {
    const pricings = inputs.pricings || [];
    const openingTimes = inputs.openingTimes || [];
    const waiver = inputs.waiver || [];
    const media = inputs.media || [];

    const city = {
      connect: {
        id: inputs.city,
      },
    };

    const state = {
      connect: {
        id: inputs.state,
      },
    };

    const experienceExperienceCategoryRelation = {
      create: inputs.categories.map((categoryId) => ({
        experienceCategory: {
          connect: {
            id: categoryId,
          },
        },
      })),
    };

    const experienceExperienceMediaRelation = {
      create: media.map((mediaItem) => ({
        file: {
          create: {
            fileId: mediaItem.id,
            filename: mediaItem.filename,
          },
        },
      })),
    };

    const experienceExperienceWaiverRelation = {
      create: waiver.map((waiverItem) => ({
        file: {
          create: {
            fileId: waiverItem.id,
            filename: waiverItem.filename,
          },
        },
      })),
    };

    const experienceExperienceScheduleRelation = {
      create: openingTimes.map((openingTime) => ({
        day: openingTime.day,
        startHour: openingTime.startHour,
        endHour: openingTime.endHour,
      })),
    };

    const experienceExperiencePricingRelation = {
      create: pricings.map((pricing) => {
        let travelerType;

        if (pricing.travelerTypeId) {
          travelerType = {
            connect: {
              id: pricing.travelerTypeId,
            },
          };
        }

        return {
          startTime: pricing.startDate,
          endTime: pricing.endDate,
          pricingName: pricing.name,
          feeAmount: parseFloat(pricing.value as string),
          travelerType,
        };
      }),
    };

    const location = [];

    if (inputs.lng && inputs.lat) {
      location.push(inputs.lng);
      location.push(inputs.lat);
    }

    const data = {
      ...omit<ExperienceDataSubmitType>(
        [
          'openingTimes',
          'pricings',
          'media',
          'waiver',
          'categories',
          'subCategories',
          'keywords',
          'lat',
          'lng',
        ],
        inputs,
      ),
      city,
      state,
      experienceExperienceCategoryRelation,
      experienceExperienceMediaRelation,
      experienceExperienceWaiverRelation,
      experienceExperienceScheduleRelation,
      experienceExperiencePricingRelation,
      requireReservation: !!inputs.requireReservation,
      location,
    };

    if (inputs.subCategories?.length) {
      const experienceExperienceSubCategoryRelation = {
        create: inputs.subCategories.map((subCategoryId) => ({
          experienceSubCategory: {
            connect: {
              id: subCategoryId,
            },
          },
        })),
      };

      data.experienceExperienceSubCategoryRelation =
        experienceExperienceSubCategoryRelation;
    }

    if (inputs.keywords?.length) {
      const experienceExperienceKeywordRelation = {
        create: inputs.keywords.map(({ text }) => ({
          text,
        })),
      };

      data.experienceExperienceKeywordRelation =
        experienceExperienceKeywordRelation;
    }

    createExperience(data);
  };

  useWebpageTitle('Candid Travel Ventures - Add Experiences');

  return (
    <div>
      <ExperienceAddHeader />
      <Box mt={4}>
        <ExperienceFormProvider loadingSubmit={loading}>
          <FormProvider {...method}>
            <PaperContainer>
              <Box pt={7} pr={5}>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <ExperienceAvatar />
                  </Grid>
                  <Grid item xs={9}>
                    <form onSubmit={method.handleSubmit(onSubmit)}>
                      <ExperienceGeneralFields />
                      <ExperienceOpeningTime />
                      <ExperiencePricing />
                      <ExperienceRestrictions />
                      <Media />
                      <Waiver />
                      <ExperienceFormButtons />
                    </form>
                  </Grid>
                </Grid>
              </Box>
            </PaperContainer>
          </FormProvider>
        </ExperienceFormProvider>
      </Box>
    </div>
  );
};
