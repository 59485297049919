import { createAction } from '@cobuildlab/react-simple-state';
import {
  OnStateList,
  OnStateListError,
  OnStateListByName,
  OnStateListByNameError,
  OnStateCreate,
  OnStateCreateError,
  OnStateById,
  OnStateByIdError,
} from './state-events';
import {
  FETCH_STATES,
  FETCH_STATES_BY_NAME,
  FETCH_STATE_BY_ID,
  CREATE_STATE,
} from './state-queries';
import { apolloClient } from '../../shared/apollo';

export const fetchStatesAction = createAction(
  OnStateList,
  OnStateListError,
  async () => {
    const response = await apolloClient.query({
      query: FETCH_STATES,
      fetchPolicy: 'cache-first',
    });

    return response?.data?.statesList?.items;
  },
);

export const fetchStateByNameAction = createAction(
  OnStateListByName,
  OnStateListByNameError,
  async (stateName: string) => {
    const response = await apolloClient.query({
      query: FETCH_STATES_BY_NAME,
      fetchPolicy: 'network-only',
      variables: {
        stateName,
      },
    });

    return response?.data?.statesList?.items;
  },
);

export const fetchStateByIdAction = createAction(
  OnStateById,
  OnStateByIdError,
  async (id: string) => {
    const response = await apolloClient.query({
      query: FETCH_STATE_BY_ID,
      fetchPolicy: 'network-only',
      variables: {
        id,
      },
    });

    return response?.data?.state;
  },
);

export const createStateAction = createAction(
  OnStateCreate,
  OnStateCreateError,
  async (data) => {
    const response = await apolloClient.mutate({
      mutation: CREATE_STATE,
      fetchPolicy: 'network-only',
      variables: {
        data,
      },
    });

    return response?.data?.stateCreate;
  },
);
