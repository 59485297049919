import gql from 'graphql-tag';

export const FETCH_USERS_COUNT = gql`
  query FetchUsersCount($date: DateTime) {
    usersList(
      filter: {
        createdAt: { lte: $date }
        roles: { every: { name: { equals: "User" } } }
      }
    ) {
      count
    }
  }
`;

export const UPDATE_USER = gql`
  mutation ($data: UserUpdateInput!) {
    userUpdate(data: $data) {
      id
      email
      firstName
      lastName
      timezone
      phoneNumber
      birthdate
      language {
        id
      }
      gender {
        id
      }
      avatar {
        shareUrl
        downloadUrl
      }
    }
  }
`;

export const UPDATE_USER_AVATAR = gql`
  mutation avatarUpdate($data: UserUpdateInput!) {
    userUpdate(data: $data) {
      id
      email
      firstName
      lastName
      timezone
      phoneNumber
      birthdate
      language {
        id
      }
      gender {
        id
      }
      avatar {
        shareUrl
        downloadUrl
      }
    }
  }
`;
