import moment from 'moment';
import prettyMilliseconds from 'pretty-ms';
import parseMilliseconds from 'parse-ms';
import { ItineraryKpiType } from '../../shared/types';

type DefaultDatesType = {
  startDateTime: string;
  endDateTime: string;
  startYear: string;
  endYear: string;
};

export type DashboarKpiType = {
  title: string;
  message: string;
  hasMenu?: boolean;
};

export type DashboardDataType = [ItineraryKpiType, number, number];

/**
 * @returns {DefaultDatesType} Default dates for query.
 */
export const getDefaultDays = (): DefaultDatesType => {
  const currentDate = moment();
  const initDate = moment().subtract(90, 'd');

  return {
    startDateTime: initDate.startOf('day').format(),
    endDateTime: currentDate.endOf('day').format(),
    startYear: moment().startOf('year').format(),
    endYear: moment().endOf('year').format(),
  };
};

/**
 * @param {number | null} timeElapsedMs - Sum of elapsed time in seconds.
 * @returns {DashboarKpiType} - Time elapsed kpi.
 */
const getTimeElapsedKpi = (timeElapsedMs: number | null): DashboarKpiType => {
  let title = '0';
  const message = 'Average booking request response';

  if (timeElapsedMs) {
    const parseMs = parseMilliseconds(timeElapsedMs);
    const compact = !!parseMs.days;
    const prettyMs = prettyMilliseconds(timeElapsedMs, { compact });
    title = prettyMs;

    if (prettyMs.indexOf('m') > -1) {
      const timeWithoutSecond = prettyMs.split('m');
      title = `${timeWithoutSecond[0]}m`;
    }
  }

  return {
    title,
    message,
  };
};

/**
 * @param {DashboardDataType} data - Average data.
 *
 * @returns {Array<DashboarKpiType>} - Kpis.
 */
export const extractKpis = (
  data: DashboardDataType,
): Array<DashboarKpiType> => {
  const [itineraryKpi, bookinCancelledCount, itinerariesReservedCount] = data;

  const daysKpi = {
    title: itineraryKpi ? `${itineraryKpi.averageDays} days` : '0',
    message: 'Average trip duration',
  };

  const spendKpi = {
    title: itineraryKpi ? `$${itineraryKpi.averageCost.toFixed(2)}` : '0',
    message: 'Average trip cost',
  };

  const peopleKpi = {
    title: itineraryKpi ? itineraryKpi.averagePeople.toString() : '0',
    message: 'Average trip group size',
  };

  const bookingCancelledKpi = {
    title: bookinCancelledCount.toString(),
    message: 'Activities cancelled in the past 3 months',
  };

  const bookingKpi = {
    title: itinerariesReservedCount.toString(),
    message: 'Total bookings in the past',
    hasMenu: true,
  };

  const timeElapsedKpi = getTimeElapsedKpi(
    itineraryKpi?.averageRequestResponse || 0,
  );

  return [
    daysKpi,
    spendKpi,
    peopleKpi,
    bookingCancelledKpi,
    bookingKpi,
    timeElapsedKpi,
  ];
};
