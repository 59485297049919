import React from 'react';
import { styled } from '@material-ui/core/styles';
import { useFormContext } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';

const commonStyles = {
  alignItems: 'center',
  borderRadius: 5,
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  height: 100,
  justifyContent: 'center',
  userSelect: 'none',
  width: 120,
} as React.CSSProperties;

const InterestContainer = styled('div')({
  ...commonStyles,
  border: '1px solid',
  borderColor: '#00000040',
});

const InterestAltContaier = styled('div')(({ theme }) => ({
  ...commonStyles,
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.background.default,
}));

const InterestDescription = styled(Typography)({
  lineHeight: 1.2,
});

type InterestsProps = {
  title: string;
  description: JSX.Element;
  icon: JSX.Element;
  altIcon: JSX.Element;
  loading: boolean;
};

/**
 * @param props - Props.
 * @param props.title - Interest title.
 * @param props.description - Interest description.
 * @param props.icon - Interest icon.
 * @param props.altIcon - Interest altIcon.
 * @param props.loading - Loading state.
 * @returns {JSX.Element} - Interest component.
 */
export function Interest({
  title,
  description,
  icon,
  altIcon,
  loading,
}: InterestsProps): JSX.Element {
  const { setValue, watch } = useFormContext();

  if (loading) {
    return <Skeleton variant="rect" width={120} height={100} />;
  }

  const interests = watch('interests');
  const interestKey = title.toLowerCase();
  const selected = interests[interestKey];
  const interestCount = Object.values(interests).reduce(
    (previousValue: number, currentValue) =>
      currentValue ? previousValue + 1 : previousValue,
    0,
  );
  const disableClick = selected && interestCount <= 3;

  /**
   * @description Toggle selected state.
   */
  const toggleSelected = (): void => {
    if (disableClick) {
      return;
    }

    setValue('interests', {
      ...interests,
      [interestKey]: !selected,
    });
  };

  const Container = selected ? InterestAltContaier : InterestContainer;

  return (
    <Grid item onClick={toggleSelected}>
      <Container>
        {selected ? altIcon : icon}
        <Typography variant="caption" align="center">
          <b>{title}</b>
        </Typography>
        <InterestDescription variant="caption" align="center">
          {description}
        </InterestDescription>
      </Container>
    </Grid>
  );
}
