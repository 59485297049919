import React, { ReactNode } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useRouteMatch } from 'react-router-dom';
import {
  COLOR_PRIMARY,
  BG_MENU,
} from '../../../shared/components/ui/theme/contants';

type AccountMenuItemProps = {
  icon: ReactNode;
  selectedIcon: ReactNode;
  text: string;
  path: string;
};

const useStyles = makeStyles({
  item: {
    height: '40px',
    color: '#000000',
    borderLeft: '4px solid transparent',
    '&:hover': {
      borderLeft: `4px solid ${COLOR_PRIMARY}`,
      backgroundColor: BG_MENU,
      cursor: 'pointer',
    },

    '&:hover  > div > .account-icon': {
      display: 'none',
    },

    '&:hover > div >.account-selected-icon': {
      display: 'block',
    },
  },
  itemActive: {
    height: '40px',
    borderLeft: `4px solid ${COLOR_PRIMARY}`,
    color: '#000000',
    backgroundColor: BG_MENU,
    cursor: 'pointer',
  },
});

/**
 * @param props - Props received.
 * @param {ReactNode} props.icon - Icon.
 * @param {ReactNode} props.selectedIcon - Selected icon.
 * @param {string} props.text - Text item.
 * @param {string} props.path - Path item.
 * @returns {JSX.Element} Account menu item.
 */
export const AccountMenuItem: React.FC<AccountMenuItemProps> = ({
  icon,
  text,
  path,
  selectedIcon,
}) => {
  const isActive = useRouteMatch(path);
  const classes = useStyles({ isActive });

  let itemClass = classes.item;

  /**
   * This to select icon with hover class event.
   */
  let contentIcon = (
    <>
      <Box className="account-icon">{icon}</Box>
      <Box display="none" className="account-selected-icon">
        {selectedIcon}
      </Box>
    </>
  );

  if (isActive) {
    itemClass = classes.itemActive;

    contentIcon = <Box>{selectedIcon}</Box>;
  }

  return (
    <Box
      display="flex"
      paddingLeft="40px"
      alignItems="center"
      mb="18px"
      boxSizing="border-box"
      className={itemClass}>
      <Box pt="6px" width="20px" mr="12px">
        {contentIcon}
      </Box>
      <Box>
        <Typography>{text}</Typography>
      </Box>
    </Box>
  );
};
