import React from 'react';
import { TableList } from '../../../shared/components/ui/tables/TableList';
import { ColumnType, BookingType } from '../../../shared/types';
import { renderTableFiltersFactory } from '../utils/renderTableFiltersFactory';
import {
  BookingFilterState,
  BookingListMenuFilter,
} from './BookingListMenuFilter';
import { BookingListRow } from './BookingListRow';

type BookingListTableProps = {
  page: number;
  totalCount: number;
  filterState: BookingFilterState;
  bookings: BookingType[];
  loading: boolean;
  onResetFilter: (filterState: BookingFilterState) => void;
  onChangeSearch: (searchText: string) => void;
  onApplyFilters: (filterState: BookingFilterState) => void;
  onChangePage: (page: number) => void;
  onChangeSort: (field: string, direction?: string) => void;
  onViewDetails: (booking: BookingType) => void;
  onConfirmBooking: (booking: BookingType) => void;
  onRejectBooking: (booking: BookingType) => void;
  onViewItineraryDetails: (booking: BookingType) => void;
};

const COLUMNS: ColumnType[] = [
  {
    field: 'user.fullName',
    headerName: 'CLIENT NAME',
    sorteable: true,
  },
  {
    field: 'createdAt',
    headerName: 'REQUESTED DATE',
    sorteable: true,
  },
  {
    field: 'experience.experienceName',
    headerName: 'EXPERIENCE',
    sorteable: true,
  },
  {
    field: 'startDateTime',
    headerName: 'START DATE',
    sorteable: true,
  },
  {
    field: 'endDateTime',
    headerName: 'END DATE',
    sorteable: true,
  },
  {
    field: 'timeElapsed',
    headerName: 'TIME ELAPSED',
    sorteable: false,
  },
  {
    field: 'bookingStatus.statusName',
    headerName: 'STATUS',
    sorteable: true,
  },
  {
    field: 'price',
    headerName: 'PRICE',
    sorteable: false,
  },
];

/**
 * @param filterState - Filter state.
 * @param onApplyFilters - OnApplyFilters function.
 * @param onResetFilter - OnResetFilter function.
 * @returns - RenderMenuFilter function.
 */
function renderMenuFilterFactory(
  filterState: BookingFilterState,
  onApplyFilters: (filterState: BookingFilterState) => void,
  onResetFilter: (filterState: BookingFilterState) => void,
) {
  return function renderMenuFilter(
    anchorEl: HTMLElement | null,
    onClose: () => void,
  ) {
    return (
      <BookingListMenuFilter
        anchorEl={anchorEl}
        onClose={onClose}
        onApplyFilters={onApplyFilters}
        filterState={filterState}
        onResetFilter={onResetFilter}
      />
    );
  };
}

/**
 * Table list.
 *
 * @param props - Props received.
 * @param {number} props.page - Number page.
 * @param {BookingFilterState} props.filterState - Filter state.
 * @param {number} props.totalCount - Total number o register.
 * @param {Function} props.onApplyFilters - Function to apply filters.
 * @param {Function} props.onChangeSearch - Function to change search text.
 * @param {Function} props.onChangePage - Function to change page.
 * @param {BookingType[]} props.bookings - Bookings data.
 * @param {boolean} props.loading - Loading status.
 * @param {Function} props.onChangeSort - Function to change sort.
 * @param {Function} props.onResetFilter - Function to reset filter.
 * @param {Function} props.onViewDetails - Function to view details.
 * @param {Function} props.onConfirmBooking - Function to confirm booking.
 * @param {Function} props.onRejectBooking - Function to reject booking.
 * @param {Function} props.onViewItineraryDetails - Function to view details.
 * @returns {JSX.Element} Bookings table.
 */
export function BookingListTable({
  page,
  filterState,
  totalCount,
  bookings,
  loading,
  onApplyFilters,
  onChangeSearch,
  onChangePage,
  onChangeSort,
  onResetFilter,
  onViewDetails,
  onConfirmBooking,
  onRejectBooking,
  onViewItineraryDetails,
}: BookingListTableProps): JSX.Element {
  let content: JSX.Element | JSX.Element[] = bookings.map((booking) => (
    <BookingListRow
      key={booking.id}
      booking={booking}
      onViewDetails={onViewDetails}
      onConfirmBooking={onConfirmBooking}
      onRejectBooking={onRejectBooking}
      onViewItineraryDetails={onViewItineraryDetails}
    />
  ));

  if (!bookings.length) {
    content = (
      <tr>
        <td colSpan={7} style={{ textAlign: 'center', paddingTop: '8px' }}>
          No records to display
        </td>
      </tr>
    );
  }

  return (
    <TableList
      columns={COLUMNS}
      renderMenuFilter={renderMenuFilterFactory(
        filterState,
        onApplyFilters,
        onResetFilter,
      )}
      renderOptionalComponent={renderTableFiltersFactory(
        filterState,
        onApplyFilters,
        onResetFilter,
      )}
      page={page}
      totalCount={totalCount}
      onChangeSearch={onChangeSearch}
      onChangePage={onChangePage}
      onChangeSort={onChangeSort}
      loading={loading}
    >
      {content}
    </TableList>
  );
}
