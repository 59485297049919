import { createAction } from '@cobuildlab/react-simple-state';
import {
  OnItineraryReservedCount,
  OnItineraryReservedCountError,
} from './itinerary-events';
import { fetchItineraryCountByPaymentDateService as fetchItineraryPaymentByDate } from './itinerary-services';

export const fetchItineraryReservedCountAction = createAction(
  OnItineraryReservedCount,
  OnItineraryReservedCountError,
  async (startDate: string, endDate: string) => {
    const itineraryReservedCount = await fetchItineraryPaymentByDate(
      startDate,
      endDate,
    );

    return itineraryReservedCount;
  },
);
