import React, { useContext, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { useFormContext, Controller } from 'react-hook-form';
import FormHelperText from '@material-ui/core/FormHelperText';
import { FormStepTitle } from '../../../../shared/components/ui/texts/Texts';
import { ExperienceStateSelect } from './ExperienceStateSelect';
import { ExperienceCitySelect } from './ExperienceCitySelect';
import { ExperienceCategorySelect } from './ExperienceCategorySelect';
import { ExperienceSubCategorySelect } from './ExperienceSubCategorySelect';
import { ExperienceFormContext } from './ExperienceFormProvider';
import { ExperienceReservationCheck } from './ExperienceReservationCheck';
import { ExperienceKeyWords } from './ExperienceKeyWords';
import { ExperienceAddress } from './ExperienceAddress';
import { ExperienceTimeZoneSelect } from './ExperienceTimeZoneSelect';
import { formatPhoneNumber } from '../../../people/people-utils';

/**
 * @returns {JSX.Element} General fields.
 */
export const ExperienceGeneralFields: React.FC = () => {
  const {
    setValue,
    control,
    formState: { errors },
  } = useFormContext();
  const [phoneMatcher, setPhoneMatcher] = useState(true);
  const { loadingSubmit, experience } = useContext(ExperienceFormContext);

  const handlePhoneFormat = (value: string | number): void => {
    formatPhoneNumber({
      numberValue: value,
      onMatcher: (isMatched) => setPhoneMatcher(isMatched),
      onMatchValue: (formattedValue: string) => setValue('phoneNumber', formattedValue)
    });
  };

  return (
    <>
      <Box mb="15px">
        <FormStepTitle>I. General Information</FormStepTitle>
      </Box>
      <Box mb="20px">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Controller
              name="experienceName"
              control={control}
              defaultValue={experience?.experienceName || ''}
              render={({ field }) => (
                <TextField
                  {...field}
                  disabled={loadingSubmit}
                  error={!!errors?.experienceName}
                  helperText={
                    errors?.experienceName && errors?.experienceName.message
                  }
                  fullWidth
                  variant="outlined"
                  label="Experience name"
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <ExperienceCategorySelect />
          </Grid>
        </Grid>
      </Box>
      <Box mb="20px">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <ExperienceSubCategorySelect />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="operatorsName"
              control={control}
              defaultValue={experience?.operatorsName || ''}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors.operatorsName}
                  disabled={loadingSubmit}
                  fullWidth
                  variant="outlined"
                  label="Operator name"
                  helperText={errors?.operatorsName?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mb="20px">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <ExperienceAddress />
          </Grid>
          <Grid item xs={6}>
            <FormControl variant="outlined" fullWidth error={!!errors.city}>
              <InputLabel id="city-label">City</InputLabel>
              <ExperienceCitySelect />
              <FormHelperText>{errors?.city?.message}</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <Box mb="20px">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  error={!!errors?.state}
                >
                  <InputLabel id="state-label">State</InputLabel>
                  <ExperienceStateSelect />
                  <FormHelperText>{errors?.state?.message}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="zipcode"
                  control={control}
                  defaultValue={experience?.zipcode || ''}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors?.zipcode}
                      disabled
                      fullWidth
                      variant="outlined"
                      label="Zip Code"
                      helperText={errors?.zipcode?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="websiteLink"
              control={control}
              defaultValue={experience?.websiteLink || ''}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors?.websiteLink}
                  disabled={loadingSubmit}
                  fullWidth
                  variant="outlined"
                  label="Website"
                  helperText={errors?.websiteLink?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mb="20px">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Controller
              name="contactEmailAddress"
              control={control}
              defaultValue={experience?.contactEmailAddress || ''}
              render={({ field }) => (
                <TextField
                  error={!!errors?.contactEmailAddress}
                  {...field}
                  disabled={loadingSubmit}
                  helperText={errors?.contactEmailAddress?.message}
                  fullWidth
                  variant="outlined"
                  label="Email"
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="phoneNumber"
              control={control}
              defaultValue={experience?.phoneNumber || ''}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors.phoneNumber || !phoneMatcher}
                  disabled={loadingSubmit}
                  fullWidth
                  variant="outlined"
                  label="Phone number"
                  helperText={errors?.phoneNumber?.message}
                  onKeyUp={() => handlePhoneFormat(field.value)}
                  inputProps={{
                    pattern: '.([0-9]{3}). ([0-9]{3}) ([0-9]{4})',
                    minlength: 14,
                    maxlength: 14,
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mb="20px">
        <Grid item xs={12}>
          <FormControl variant="outlined" fullWidth error={!!errors?.timezone}>
            <InputLabel id="experience-time-zone-label">Time zone</InputLabel>
            <ExperienceTimeZoneSelect />
            <FormHelperText>{errors?.timezone?.message}</FormHelperText>
          </FormControl>
        </Grid>
      </Box>
      <Box mb="20px">
        <Grid item xs={12}>
          <ExperienceKeyWords />
        </Grid>
      </Box>
      <Box mb="25px">
        <Grid item xs={12}>
          <Controller
            name="experienceDescription"
            control={control}
            defaultValue={experience?.experienceDescription || 'Type here'}
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.experienceDescription}
                disabled={loadingSubmit}
                fullWidth
                label="DESCRIPTION"
                multiline
                rows={4}
                variant="outlined"
                helperText={errors?.experienceDescription?.message}
              />
            )}
          />
        </Grid>
      </Box>
      <ExperienceReservationCheck />
    </>
  );
};
