import gql from 'graphql-tag';

export const MESSAGES_LIST = gql`
  query MESSAGES_LIST($filterMsg: MessageFilter) {
    messagesList(filter: $filterMsg) {
      count
      items {
        id
        message
        createdAt
        createdBy {
          id
          email
          firstName
          lastName
          fullName
          roles {
            items {
              name
            }
          }
        }
        messageMediaRelation {
          items {
            id
            createdAt
            file {
              id
              fileId
              filename
              downloadUrl
              previewUrl
            }
          }
        }
      }
    }
  }
`;

export const CHAT_HEADER = gql`
  query FETCH_ITINERARIES($filter: ItineraryFilter) {
    itinerariesList(filter: $filter) {
      items {
        id
        itineraryName
        startDateTime
        endDateTime
        itineraryCityName
        user {
          id
          avatar {
            id
            fileId
            filename
            downloadUrl
          }
          email
          fullName
          firstName
          lastName
        }
        itineraryBookingRelation(
          first: 1
          filter: {
            experience: {
              experienceExperienceMediaRelation: { some: { file: {} } }
            }
          }
        ) {
          items {
            experience {
              experienceExperienceMediaRelation(
                first: 1
                filter: { file: {} }
              ) {
                items {
                  file {
                    downloadUrl
                    shareUrl
                  }
                }
              }
            }
          }
        }
        inboxRelation {
          count
          items {
            id
            messageRelation(sort: { createdAt: ASC }, last: 1) {
              count
              items {
                id
                message
                createdAt
                createdBy {
                  id
                  email
                  firstName
                  lastName
                  fullName
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const MSG_CREATE = gql`
  mutation MSG_CREATE($data: MessageCreateInput!) {
    messageCreate(data: $data) {
      id
      message
      messageMediaRelation {
        items {
          id
          createdAt
          file {
            id
            fileId
            filename
            downloadUrl
          }
        }
      }
    }
  }
`;

export const FETCH_ALL_BOOKINGS = gql`
  query FETCH_ALL_BOOKINGS($filter: BookingFilter) {
    bookingsList(filter: $filter) {
      count
      items {
        id
        user {
          avatar {
            id
            fileId
            filename
            downloadUrl
          }
          email
          fullName
          firstName
          lastName
        }
        createdAt
        startDateTime
        endDateTime
        experience {
          id
          experienceName
          operatorsName
          phoneNumber
          contactEmailAddress
        }
        itinerary {
          startDateTime
        }
        itineraryDay
        timeElapsed
        bookingStatus {
          statusName
        }
        bookingBookingPricingRelation {
          items {
            price
            peopleAmount
            travelerType {
              travelerTypeName
            }
          }
        }
        rejectionReason
        inboxRelation {
          count
          items {
            id
            messageRelation(sort: { createdAt: ASC }) {
              count
              items {
                id
                message
                createdAt
                createdBy {
                  id
                  email
                  firstName
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const SUBS_NEW_MSG = gql`
  subscription SUBS_NEW_MSG($filter: MessageSubscriptionFilter) {
    Message(filter: $filter) {
      mutation
      node {
        id
        message
        createdAt
        createdBy {
          id
          email
          firstName
          lastName
          fullName
        }
        messageMediaRelation {
          items {
            id
            file {
              id
              filename
              fileId
              downloadUrl
            }
          }
        }
      }
    }
  }
`;

export const CURRENT_USER = gql`
  query CURRENT_USER {
    user {
      id
      email
      fullName
    }
  }
`;

export const ITINERARIES_LIST = gql`
  query FETCH_ITINERARIES($filter: ItineraryFilter) {
    itinerariesList(filter: $filter) {
      items {
        id
        itineraryName
        startDateTime
        endDateTime
        itineraryCityName
        user {
          id
          avatar {
            id
            fileId
            filename
            downloadUrl
          }
          email
          fullName
          firstName
          lastName
        }
        inboxRelation {
          count
          items {
            id
            createdBy {
              id
              email
              fullName
            }
            messageRelation(sort: { createdAt: ASC }, last: 1) {
              count
              items {
                id
                message
                createdAt
                createdBy {
                  id
                  email
                  firstName
                  lastName
                  fullName
                }
              }
            }
          }
        }
      }
    }
  }
`;
