import React from 'react';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import Skeleton from '@material-ui/lab/Skeleton';
import { Chart } from 'chart.js';
import millify from 'millify';
import {
  DashboardChartDropdown,
  ChartOptionsType,
  ChartOptionValueType,
} from './DashboardChartDropdown';
import {
  COLOR_PRIMARY,
  GRAY_176,
} from '../../../shared/components/ui/theme/contants';
import {
  BasicGrayText,
  CardTitleText,
} from '../../../shared/components/ui/texts/Texts';

type DashboardChartProps = {
  title: string;
  count?: number;
  xValues: string[];
  yValues: number[];
  menuOptions: Array<ChartOptionsType>;
  loading: boolean;
  symbol?: string;
  onSelectedOption: (value: ChartOptionValueType) => void;
};

const useStyles = makeStyles({
  cardContainer: {
    borderRadius: '5px',
    paddingBottom: '8px',
  },
  buttonIcon: {
    minWidth: 0,
  },
});

/**
 *
 * @param props - Props received.
 * @param {string} props.title - Title of card.
 * @param {number|undefined} props.count - Count of entitiy.
 * @param {boolean} props.loading - Loading state.
 * @param {string[]} props.xValues - X axis values.
 * @param {number[]} props.yValues - Y axis values.
 * @param {ChartOptionsType[]} props.menuOptions - Options days.
 * @param {Function} props.onSelectedOption - Function to select option.
 * @param {number|string} props.symbol - Graph symbol.
 * @returns Dashboard chart.
 */
export const DashboardChart: React.FC<DashboardChartProps> = ({
  title,
  count,
  menuOptions,
  onSelectedOption,
  loading,
  xValues,
  yValues,
  symbol,
}) => {
  const classes = useStyles();
  const currentMonth = moment().startOf('month').format('MMMM');
  const currentDate = `Data as of  ${currentMonth} ${moment().date()}`;
  const notValues = yValues.every((yValue) => yValue === 0);

  const data = {
    type: 'line',
    fill: false,
    labels: xValues,
    datasets: [
      {
        data: yValues,
        backgroundColor: COLOR_PRIMARY,
        borderColor: COLOR_PRIMARY,
      },
    ],
  };

  const plugins = {
    /**
     * This function paints the value at the end of the line.
     *
     * @param {Chart} chart - Chart parameter.
     */
    afterDraw: (chart: Chart) => {
      const { ctx } = chart;
      const xAxis = chart.scales.x;
      const yAxis = chart.scales.y;

      if (chart?.data?.labels?.length) {
        const iLastValue = chart.data.labels.length - 1;
        const lastValue = chart.data.datasets[0].data[iLastValue] as number;
        const x = xAxis.getPixelForValue(
          chart.data.labels[iLastValue] as number,
        );
        const y = yAxis.getPixelForValue(lastValue as number);
        const spacesY = 4;
        let spacesX = 18;
        let lastAmount = millify(lastValue);

        if (lastValue >= 100) {
          spacesX = 24;
        }

        if (lastValue >= 1000) {
          spacesX = 25;
        }

        if (symbol) {
          lastAmount = `${symbol}${lastAmount}`;
        }

        ctx.save();
        ctx.textAlign = 'center';
        ctx.font = '16px';
        ctx.fillStyle = COLOR_PRIMARY;
        ctx.fillText(lastAmount, x + spacesX, y + spacesY);
        ctx.restore();
      }
    },
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    scales: {
      y: {
        grid: {
          display: false,
        },
        ticks: {
          /**
           * This function is used as a workaround to
           * generate spaces in the lateral border.
           *
           * @returns {string} Generic value.
           */
          callback: () => '1000',
          color: '#fff',
          font: {
            size: '14px',
            weight: 600,
          },
        },
        position: 'right',
      },
      x: {
        ticks: {
          color: GRAY_176,
          font: {
            size: '14px',
          },
        },
      },
    },
  };

  let countData: JSX.Element | null = null;
  let headerContent = <Skeleton variant="text" />;
  let dropdownContent: JSX.Element | null = (
    <Skeleton variant="rect" width="40%" />
  );
  let dateContent = null;

  let graphContent = (
    <Box px={3} py={2}>
      <Skeleton variant="rect" width="100%" height={260} />
    </Box>
  );

  if (count !== undefined && !loading) {
    countData = (
      <Box display="flex" mr="15px" alignItems="center">
        <Box mr="5px">
          <Typography variant="body2">Total users</Typography>
        </Box>
        <Box lineHeight={0}>
          <CardTitleText>{count.toString()}</CardTitleText>
        </Box>
      </Box>
    );
  }
  if (!loading) {
    headerContent = <CardTitleText>{title}</CardTitleText>;
    dateContent = <BasicGrayText>{currentDate} </BasicGrayText>;
    graphContent = <Line data={data} options={options} plugins={[plugins]} />;

    dropdownContent = (
      <DashboardChartDropdown
        menuOptions={menuOptions}
        onSelectedOption={onSelectedOption}
      />
    );
  }

  if (notValues && !loading) {
    dateContent = null;
    dropdownContent = null;

    graphContent = (
      <>
        <Box height={40} fontSize="15px" paddingLeft="25px">
          <span>Not enough data is available to display the chart</span>
        </Box>
      </>
    );
  }

  return (
    <>
      <Paper className={classes.cardContainer} elevation={24}>
        <Box paddingX={4} paddingTop="20px" paddingBottom="12px">
          {headerContent}
        </Box>
        <Divider />

        <Box paddingTop={3} paddingX={4} display="flex" alignItems="center">
          {countData}
          {dropdownContent}
          <Box flexGrow={1} />
          <Box>{dateContent}</Box>
        </Box>
        <Box paddingX={1} mt="5px">
          {graphContent}
        </Box>
      </Paper>
    </>
  );
};
