import React, {useCallback, useState} from 'react';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { useFormContext, Controller } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import { AccountGenderSelect } from './AccountGenderSelect';
import { AccountLanguageSelect } from './AccountLanguageSelect';
import { AccountTimeZoneSelect } from './AccountTimeZoneSelect';
import {
  ButtonPrimary,
  ButtonOutlinedPrimary,
} from '../../../../shared/components/ui/buttons/Buttons';
import {
  DatePicker,
  DatePickerType,
} from '../../../../shared/components/ui/form/DatePicker';
import { COLOR_PRIMARY } from '../../../../shared/components/ui/theme/contants';
import { UserType } from '../../../../shared/types';
import { formatPhoneNumber } from '../../../people/people-utils';

type AccountFieldsProps = {
  user?: UserType;
  loading: boolean;
};

const useStyles = makeStyles({
  link: {
    color: COLOR_PRIMARY,
    textDecoration: 'none',
  },
});

/**
 * @param props - Props received.
 * @param {UserType} props.user - User data.
 * @param {boolean} props.loading - Loading state.
 *
 * @returns {JSX.Element} Account information fields.
 */
export const AccountFields: React.FC<AccountFieldsProps> = ({
  user,
  loading,
}) => {
  const classes = useStyles();
  const [phoneMatcher, setPhoneMatcher] = useState(true);
  const {
    control,
    setValue,
    formState: { errors },
    clearErrors,
  } = useFormContext();

  const handlePhoneFormat = (value: string | number): void => {
    formatPhoneNumber({
      numberValue: value,
      onMatcher: (isMatched) => setPhoneMatcher(isMatched),
      onMatchValue: (formattedValue: string) => setValue('phoneNumber', formattedValue)
    });
  };

  const handleChangeBirthDay = useCallback(
    (date: DatePickerType | string) => {
      clearErrors('birthdate');
      setValue('birthdate', date);
    },
    [setValue, clearErrors],
  );

  return (
    <>
      <Box px="40px" pt="35px" mb="40px">
        <Box mb="15px">
          <Grid item xs={12}>
            <Controller
              name="fullName"
              control={control}
              defaultValue={user?.fullName || ''}
              render={({ field }) => (
                <TextField
                  {...field}
                  disabled={loading}
                  error={!!errors?.fullName}
                  helperText={errors?.fullName && errors.fullName.message}
                  fullWidth
                  label="Full name"
                  variant="outlined"
                />
              )}
            />
          </Grid>
        </Box>
        <Box mb="15px">
          <Grid item xs={12}>
            <Controller
              name="phoneNumber"
              control={control}
              defaultValue={user?.phoneNumber || ''}
              render={({ field }) => (
                <TextField
                  {...field}
                  disabled={loading}
                  error={!!errors?.phoneNumber || !phoneMatcher}
                  helperText={errors?.phoneNumber && errors.phoneNumber.message}
                  fullWidth
                  label="Phone Number"
                  variant="outlined"
                  onKeyUp={() => handlePhoneFormat(field.value)}
                  inputProps={{
                    pattern: '.([0-9]{3}). ([0-9]{3}) ([0-9]{4})',
                    minlength: 14,
                    maxlength: 14,
                  }}
                />
              )}
            />
          </Grid>
        </Box>
        <Box mb="15px">
          <Grid item xs={12}>
            <FormControl
              variant="outlined"
              fullWidth
              error={!!errors.gender}
              disabled={loading}
            >
              <InputLabel>Gender</InputLabel>
              <AccountGenderSelect user={user} />
              <FormHelperText>{errors?.gender?.message}</FormHelperText>
            </FormControl>
          </Grid>
        </Box>
        <Box mb="15px">
          <DatePicker
            error={!!errors?.birthdate}
            defaultValue={user?.birthdate}
            label="Date of birth"
            onChange={handleChangeBirthDay}
            isDisabled={loading}
            disableFuture
          />
        </Box>
        <Box mb="15px">
          <Grid item xs={12}>
            <FormControl
              variant="outlined"
              fullWidth
              error={!!errors.language}
              disabled={loading}
            >
              <InputLabel>Language</InputLabel>
              <AccountLanguageSelect user={user} />
              <FormHelperText>{errors?.language?.message}</FormHelperText>
            </FormControl>
          </Grid>
        </Box>
        <Box mb="15px">
          <Grid item xs={12}>
            <FormControl
              variant="outlined"
              fullWidth
              error={!!errors.timezone}
              disabled={loading}
            >
              <InputLabel>Time Zone</InputLabel>
              <AccountTimeZoneSelect user={user} />
              <FormHelperText>{errors?.timezone?.message}</FormHelperText>
            </FormControl>
          </Grid>
        </Box>
      </Box>
      <Box
        display="flex"
        px="40px"
        paddingBottom="60px"
        justifyContent="flex-end"
      >
        <Box mr="20px">
          <ButtonOutlinedPrimary type="button" disabled={loading}>
            <Link to="/" className={classes.link}>
              CANCEL
            </Link>
          </ButtonOutlinedPrimary>
        </Box>
        <Box>
          <ButtonPrimary type="submit" isLoading={loading}>
            SAVE CHANGES
          </ButtonPrimary>
        </Box>
      </Box>
    </>
  );
};
