import React, { useMemo } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Link } from 'react-router-dom';
import { RejectIcon, DetailGrayIcon, ApproveGreyIcon } from '../../../shared/icons/icons';
import { PillStatus } from '../../../shared/components/PillStatus';
import { TableAction } from '../../../shared/components/ui/tables/components/TableAction';
import { ExperienceType } from '../../../shared/types';
import {
  MenuIconContainer,
  BasicCardTitle,
} from '../../../shared/components/ui/texts/Texts';

type ExperienceListRowProps = {
  experience: ExperienceType;
  checked: boolean;
  hasSelecteds: boolean;
  onSelectedExperience: (checked: boolean, experienceId: string) => void;
  onUpdateExperience: (data: Record<string, unknown>) => void;
  onDeactiveExperiences: () => void;
};

const useStyles = makeStyles({
  paper: {
    marginRight: '60px',
    width: '200px',
    '& > ul': {
      paddingTop: '2px',
      paddingBottom: '2px',
    },
  },
  menuItemContainer: {
    height: '45px',
    paddingLeft: '20px',
    '&:hover > div': {
      color: '#fff',
    },
  },
  approvedIcon: {
    paddingLeft: '16px',
  },
  link: {
    color: 'inherit',
    textDecoration: 'none',
  },
  title: {
    fontWeight: 500,
    fontSize: '16px',
  },
  checkboxItem: {
    marginLeft: '4px',
  },
});

/**
 *
 * @param props - Props received.
 * @param {ExperienceType} props.experience - Experience data.
 * @param {Function} props.onSelectedExperience - Function to select experience.
 * @param {boolean} props.checked - Checked state.
 * @param {boolean} props.hasSelecteds - If there are selected experiences.
 * @param {Function} props.onUpdateExperience - Function to update experience.
 * @param {Function} props.onDeactiveExperiences - Function to deactive experiences.
 * @returns {JSX.Element}  Row data.
 */
export const ExperienceListRow: React.FC<ExperienceListRowProps> = ({
  experience,
  checked,
  hasSelecteds,
  onSelectedExperience,
  onUpdateExperience,
  onDeactiveExperiences
}) => {
  const classes = useStyles();
  const statusStr = experience?.isActive ? 'Active' : 'Inactive';
  const linkEdit = `experiences/${experience.id}/detail?name=${experience.experienceName}`;

  const pricing = useMemo(() => {
    const {
      experienceExperiencePricingRelation: { items },
    } = experience;

    const adultPricing = items?.find((price) => price.travelerType.travelerTypeName === 'Adults');

    return adultPricing?.feeAmount || 0;
  }, [experience]);

  /**
   * @param {null | HTMLElement} anchorEl - Anchor.
   * @param {Function} onClose - Function to close menu.
   *
   * @returns {JSX.Element} - Menu action.
   */
  const renderAction = (
    anchorEl: null | HTMLElement,
    onClose: () => void,
  ): JSX.Element => {
    const open = !!anchorEl;
    let approvedClass = '';

    let stateContent = (
      <>
        <MenuIconContainer>
          <RejectIcon />
        </MenuIconContainer>
        <Box ml="10px">
          <Typography>Deactivate</Typography>
        </Box>
      </>
    );

    if (!experience.isActive) {
      approvedClass = classes.approvedIcon;

      stateContent = (
        <>
          <MenuIconContainer>
            <ApproveGreyIcon />
          </MenuIconContainer>
          <Box ml="10px">
            <Typography>Active</Typography>
          </Box>
        </>
      );
    }

    return (
      <Menu
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Link to={linkEdit} className={classes.link}>
          <MenuItem className={classes.menuItemContainer}>
            <MenuIconContainer>
              <DetailGrayIcon />
            </MenuIconContainer>
            <Box ml="8px">
              <Typography>View Details</Typography>
            </Box>
          </MenuItem>
        </Link>
        <MenuItem
          className={`${classes.menuItemContainer} ${approvedClass}`}
          onClick={() => {
            onClose();
            onUpdateExperience({
              id: experience.id,
              isActive: !experience.isActive
            });
          }}
        >
          {stateContent}
        </MenuItem>
        <MenuItem
          className={classes.menuItemContainer}
          disabled={hasSelecteds}
          onClick={() => {
            onClose();
            onDeactiveExperiences();
          }}
        >
          <MenuIconContainer>
            <RejectIcon />
          </MenuIconContainer>
          <Box ml="10px">
            <Typography>Deactivate Selected</Typography>
          </Box>
        </MenuItem>
      </Menu>
    );
  };

  /**
   * @param {React.ChangeEvent<HTMLInputElement>} e - Event.
   */
  const handleChangState = (e: React.ChangeEvent<HTMLInputElement>): void => {
    onSelectedExperience(e.target.checked, experience.id);
  };

  return (
    <TableRow>
      <TableCell padding="checkbox">
        <Checkbox
          className={classes.checkboxItem}
          color="primary"
          onChange={handleChangState}
          checked={checked}
        />
      </TableCell>
      <TableCell>
        <Link to={linkEdit} className={classes.link}>
          <BasicCardTitle color="primary">
            {experience.experienceName.toUpperCase()}
          </BasicCardTitle>
        </Link>
      </TableCell>
      <TableCell>{experience.operatorsName}</TableCell>
      <TableCell>{experience.address}</TableCell>
      <TableCell>
        <PillStatus text={statusStr} isActive={experience.isActive} />
      </TableCell>
      <TableCell>${pricing.toFixed(2)}</TableCell>
      <TableCell>
        <TableAction renderAction={renderAction} />
      </TableCell>
    </TableRow>
  );
};
