import * as yup from 'yup';

export const accountSchemaValidation = yup.object().shape({
  fullName: yup.string().max(100).label('full name').required(),
  phoneNumber: yup
    .string()
    .label('phone number')
    .required()
    .test(
      'format-phone',
      'phone number cannot contain letters',
      (value) => !value?.match(/[a-zA-Z]/),
    ),
  gender: yup.string().required(),
  timezone: yup.string().required(),
  language: yup.string().required(),
  birthdate: yup.string().required(),
});
