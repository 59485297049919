import React, { useCallback, useContext, useMemo } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useFormContext } from 'react-hook-form';
import { FormStepTitle } from '../../../../shared/components/ui/texts/Texts';
import { FileType, DragDropItemType } from './DragDropItem';
import { ExperienceFormContext } from './ExperienceFormProvider';
import { DragDrop } from './DragDrop';

const DESCRIPTION =
  'SUPPORTED FORMATS: JPG, PNG, EPS, AI, PDF, TIFF, SVG, JP2 (JPEG2000)';
const ACCEPTED_FILES = 'image/*';

/**
 * @returns {JSX.Element} Media files.
 */
export const Media: React.FC = () => {
  const { setValue, getValues } = useFormContext();
  const { setCountUploadingFile, experience } = useContext(
    ExperienceFormContext,
  );

  const preSavedFiles: DragDropItemType[] = useMemo(() => {
    if (!experience) return [];

    const {
      experienceExperienceMediaRelation: { items },
    } = experience;

    if (!items?.length) return [];

    const savedFiles = items.map((mediaItem) => ({
      id: mediaItem.id,
      source: mediaItem.file.downloadUrl,
      size: mediaItem.file.meta.size,
      name: mediaItem.file.filename,
    }));

    return savedFiles;
  }, [experience]);

  /**
   * @param {FileType[]} uploadedFiles - Uploaded files.
   */
  const handleAddFile = useCallback(
    (uploadedFiles: FileType[]): void => {
      setValue('media', uploadedFiles);
    },
    [setValue],
  );

  /**
   * @param {string} id - Id of file to delete.
   */
  const handleDeleteUploadedFile = (id: string): void => {
    if (!experience) return;

    const {
      experienceExperienceMediaRelation: { items },
    } = experience;

    if (!items?.length) return;

    const isUploaded = items.some((mediaItem) => mediaItem.id === id);

    if (isUploaded) {
      const mediaToDelete = getValues('mediaToDelete') || [];

      setValue('mediaToDelete', [...mediaToDelete, id]);
    }
  };

  /**
   * @param uploadingCount - Amount files uploading count.
   */
  const handleCountUploadingFiles = useCallback(
    (uploadingCount: number): void => {
      setCountUploadingFile(uploadingCount);
    },
    [setCountUploadingFile],
  );

  return (
    <Box mt={6}>
      <Box mb="15px">
        <FormStepTitle>V. Media</FormStepTitle>
      </Box>
      <Grid item xs={12}>
        <DragDrop
          onAddFiles={handleAddFile}
          description={DESCRIPTION}
          accept={ACCEPTED_FILES}
          onCountUploadingFiles={handleCountUploadingFiles}
          onDeleteUploadedFile={handleDeleteUploadedFile}
          preSavedFiles={preSavedFiles}
        />
      </Grid>
    </Box>
  );
};
