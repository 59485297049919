import React, { useEffect } from 'react';
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';
import { useFetchAction, useCallAction } from '@cobuildlab/react-simple-state';
import { yupResolver } from '@hookform/resolvers/yup';
import { AccountHeader } from './components/AccountHeader';
import { AccountLayout } from './components/AccountLayout';
import { AccountFields } from './components/account-form/AccountFields';
import { AccountLoading } from './components/account-form/AccountLoading';
import { fetchSession } from '../session/session-actions';
import { updateUserAction } from './user-actions';
import * as toast from '../../shared/components/ui/toasts/Toast';
import { accountSchemaValidation } from './user-validators';
import { useWebpageTitle } from '../../shared/hooks/useWebpageTitle';

type AccountInputs = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  gender: string;
  birthdate: string;
  language: string;
  timezone: string;
};

/**
 * @returns {JSX.Element} Account form.
 */
export const Account: React.FC = () => {
  const method = useForm<AccountInputs>({
    resolver: yupResolver(accountSchemaValidation),
  });
  const [user, loadingUser, { refetch }] = useFetchAction(fetchSession, []);

  const [upadteUser, loadingUpdate] = useCallAction(updateUserAction, {
    /**
     * Callback to completed loaded user.
     */
    onCompleted: () => {
      toast.success('Success', 'User updated successfully');
      refetch();
    },

    /**
     * @param error - Error event.
     */
    onError: (error: TypeError) => {
      toast.error(error.message);
    },
  });

  useEffect(() => {
    /**
     * Manually registers some fields whose value cannot
     * be taken by the inputRef attribute.
     */
    method.register('birthdate');
  }, [method]);

  useEffect(() => {
    /**
     * Manually registers some  value cannot
     * be taken by the inputRef attribute.
     */

    if (user?.birthdate) {
      method.setValue('birthdate', user.birthdate);
    }
  }, [method, user]);

  /**
   * @param {AccountInputs} inputs - Account input data.
   */
  const onSubmit: SubmitHandler<AccountInputs> = (inputs): void => {
    const language = {
      reconnect: {
        id: inputs.language,
      },
    };

    const gender = {
      reconnect: {
        id: inputs.gender,
      },
    };

    const data = { ...inputs, gender, language, id: user.id };
    upadteUser(data);
  };

  let content = (
    <form onSubmit={method.handleSubmit(onSubmit)}>
      <AccountFields user={user} loading={loadingUpdate} />
    </form>
  );

  if (loadingUser && !user) {
    content = <AccountLoading />;
  }

  useWebpageTitle('Candid Travel Ventures - Settings');

  return (
    <>
      <AccountHeader />
      <AccountLayout title="Account Information">
        <FormProvider {...method}>{content}</FormProvider>
      </AccountLayout>
    </>
  );
};
