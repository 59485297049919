import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import EditIcon from '@material-ui/icons/Edit';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { GRAY_178 } from '../../../shared/components/ui/theme/contants';
import { TableAction } from '../../../shared/components/ui/tables/components/TableAction';
import { BookingType } from '../../../shared/types';
import { Link as GreenLink } from '../../../shared/components/ui/link/Link';
import { formatDate, formatMoney } from '../../../shared/utils';
import { calculateTimeElapsed, calculateTotalPrice } from '../booking-utils';
import { SwitchChip } from './SwitchChip';

type BookingListRowProps = {
  booking: BookingType;
  onViewDetails: (booking: BookingType) => void;
  onConfirmBooking: (booking: BookingType) => void;
  onRejectBooking: (booking: BookingType) => void;
  onViewItineraryDetails: (booking: BookingType) => void;
};

const useStyles = makeStyles({
  paper: {
    width: '190px',
    '& > ul': {
      paddingTop: '2px',
      paddingBottom: '2px'
    }
  },
  menuItemContainer: {
    height: '45px',
    paddingLeft: '20px',
    '&:hover > div': {
      color: '#fff'
    }
  },
  menuIconContainer: {
    marginRight: '10px',
    color: GRAY_178,
    fontSize: '20px',
    lineHeight: '12px'
  },
  link: {
    textDecoration: 'none'
  }
});

/**
 * @param {BookingType} booking - Booking data.
 * @param {Function} onViewDetails - Function to view details.
 * @param {Function} onConfirmBooking - Function to confirm booking.
 * @param {Function} onRejectBooking - Function to reject booking.
 * @param {Function} onViewItineraryDetails - Function to view details.
 * @returns A renderAction component.
 */
function renderActionFactory(
  booking: BookingType,
  onViewDetails: (booking: BookingType) => void,
  onConfirmBooking: (booking: BookingType) => void,
  onRejectBooking: (booking: BookingType) => void,
  onViewItineraryDetails: (booking: BookingType) => void
) {
  return function RenderAction(
    anchorEl: null | HTMLElement,
    onClose: () => void
  ): JSX.Element {
    const classes = useStyles();
    const open = !!anchorEl;

    const approveOption = (
      <MenuItem
        className={classes.menuItemContainer}
        onClick={() => {
          onConfirmBooking(booking);
          onClose();
        }}
      >
        <Box className={classes.menuIconContainer}>
          <CheckCircleIcon fontSize="inherit" />
        </Box>
        <Typography variant="inherit">Confirm Booking</Typography>
      </MenuItem>
    );

    const rejectOption = (
      <MenuItem
        className={classes.menuItemContainer}
        onClick={() => {
          onRejectBooking(booking);
          onClose();
        }}
      >
        <Box className={classes.menuIconContainer}>
          <DeleteForeverIcon fontSize="inherit" />
        </Box>
        <Typography variant="inherit">Reject</Typography>
      </MenuItem>
    );

    return (
      <Menu
        classes={{
          paper: classes.paper
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        {booking.bookingStatus.statusName === 'PAID' && approveOption}
        <MenuItem
          className={classes.menuItemContainer}
          onClick={() => {
            onViewDetails(booking);
            onClose();
          }}
        >
          <Box className={classes.menuIconContainer}>
            <EditIcon fontSize="inherit" />
          </Box>
          <Typography variant="inherit">View Details</Typography>
        </MenuItem>
        <MenuItem
          className={classes.menuItemContainer}
          onClick={() => {
            onViewItineraryDetails(booking);
            onClose();
          }}
        >
          <Box className={classes.menuIconContainer}>
            <AssignmentIcon fontSize="inherit" />
          </Box>
          <Typography variant="inherit">View Itinerary</Typography>
        </MenuItem>
        {(booking.bookingStatus.statusName === 'PAID' ||
          booking.bookingStatus.statusName === 'DRAFT' ||
          booking.bookingStatus.statusName === 'CONFIRMED') &&
          rejectOption}
      </Menu>
    );
  };
}

/**
 * Booking data row.
 *
 * @param props - Props received.
 * @param {BookingType} props.booking - Booking data.
 * @param {Function} props.onViewDetails - Function to view details.
 * @param {Function} props.onConfirmBooking - Function to confirm booking.
 * @param {Function} props.onRejectBooking - Function to reject booking.
 * @param {Function} props.onViewItineraryDetails - Function to view details.
 * @returns {JSX.Element}  Row data.
 */
export const BookingListRow: React.FC<BookingListRowProps> = ({
  booking,
  onViewDetails,
  onConfirmBooking,
  onRejectBooking,
  onViewItineraryDetails
}) => (
  <TableRow key={booking.id}>
    <TableCell>
      {booking.user.fullName}
      <Typography variant="caption" display="block" gutterBottom>
        {booking.user.email.toUpperCase()}
      </Typography>
    </TableCell>
    <TableCell>{formatDate(booking.createdAt, 'M/D/YYYY, hh:mm A')}</TableCell>
    <TableCell>
      <GreenLink
        to={`/experiences/${booking.experience.id}/detail?name=${booking.experience.experienceName}`}
      >
        {booking.experience.experienceName}
      </GreenLink>
    </TableCell>
    <TableCell>
      {formatDate(booking.startDateTime, 'M/D/YYYY, hh:mm A')}
    </TableCell>
    <TableCell>
      {formatDate(booking.endDateTime, 'M/D/YYYY, hh:mm A')}
    </TableCell>
    <TableCell>{calculateTimeElapsed(booking)}</TableCell>
    <TableCell>
      <SwitchChip status={booking.bookingStatus.statusName} />
    </TableCell>
    <TableCell>{formatMoney(calculateTotalPrice(booking))}</TableCell>
    <TableCell>
      <TableAction
        renderAction={renderActionFactory(
          booking,
          onViewDetails,
          onConfirmBooking,
          onRejectBooking,
          onViewItineraryDetails
        )}
      />
    </TableCell>
  </TableRow>
);
