import React from 'react';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';

/**
 * @returns {JSX.Element} Account loading.
 */
export const AccountLoading: React.FC = () => (
  <Box px="40px" pt="35px" mb="40px">
    <Box mb="15px">
      <Skeleton variant="rect" width="100%" height={50} />
    </Box>
    <Box mb="15px">
      <Skeleton variant="rect" width="100%" height={50} />
    </Box>
    <Box mb="15px">
      <Skeleton variant="rect" width="100%" height={50} />
    </Box>
    <Box mb="15px">
      <Skeleton variant="rect" width="100%" height={50} />
    </Box>
    <Box mb="15px">
      <Skeleton variant="rect" width="100%" height={50} />
    </Box>
  </Box>
);
