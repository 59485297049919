import React from 'react';
import {
  AppBar,
  createStyles,
  IconButton,
  makeStyles,
  Theme,
  Toolbar,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import { Breadcrumb } from './Breadcrumb';
import { AvatarMenu } from './AvatarMenu';
import { MenuPusherIcon } from '../icons/icons';
import { NotificationList } from '../../modules/notification/NotificationList';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      backgroundColor: '#fff',
      zIndex: theme.zIndex.drawer + 1,
    },
    toolbar: {
      minHeight: '73px',
    },
    iconButton: {
      marginRight: '15px',
      padding: 0,
    },
  }),
);

/**
 * @returns Component.
 */
export const Topbar: React.FC = () => {
  const classes = useStyles();

  /**
   * @param event - Event.
   */
  const handleMenu = (event: React.MouseEvent<HTMLElement>): void => {
    console.log('*** event ***', event);
  };

  return (
    <AppBar position="relative" className={classes.appBar} elevation={24}>
      <Toolbar className={classes.toolbar}>
        <Box
          display="flex"
          alignItems="center"
          flexGrow={1}
          paddingRight="20px"
          paddingLeft="32px"
        >
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
            className={classes.iconButton}
          >
            <MenuPusherIcon />
          </IconButton>
          <Breadcrumb />
          <Box flexGrow={1} />
          <NotificationList />
        </Box>
        <Divider orientation="vertical" flexItem />
        <AvatarMenu />
      </Toolbar>
    </AppBar>
  );
};
