import { createAction } from '@cobuildlab/react-simple-state';
import { apolloClient } from '../../shared/apollo';
import { OnLanguageTypes, OnLanguageTypesError } from './language-type-events';
import { FETCH_LANGUAGE_TYPES } from './language-type-queries';

export const fetchLanguageTypesAction = createAction(
  OnLanguageTypes,
  OnLanguageTypesError,
  async (isActive = true) => {
    const response = await apolloClient.query({
      query: FETCH_LANGUAGE_TYPES,
      fetchPolicy: 'cache-first',
      variables: {
        isActive,
      },
    });

    return response?.data?.languageTypesList?.items;
  },
);
