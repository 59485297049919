import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';
import { PageTitle } from '../../shared/components/ui/texts/Texts';
import { Container } from './components/Container';

/**
 * @returns UserinfoLoading component.
 */
export function UserInfoLoading(): JSX.Element {
  return (
    <>
      <PageTitle text="People" />
      <Container>
        <Typography variant="h5">
          <Box fontWeight="fontWeightMedium">User Information</Box>
        </Typography>
        <Box mt="20px" mb="50px">
          <Divider />
        </Box>
        <div>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Skeleton variant="rect" width={403} height={56} />
            </Grid>
            <Grid item xs={6}>
              <Skeleton variant="rect" width={403} height={56} />
            </Grid>
            <Grid item xs={6}>
              <Skeleton variant="rect" width={403} height={56} />
            </Grid>
            <Grid item xs={6}>
              <Skeleton variant="rect" width={403} height={56} />
            </Grid>
            <Grid item xs={6}>
              <Skeleton variant="rect" width={403} height={56} />
            </Grid>
            <Grid item xs={6}>
              <Skeleton variant="rect" width={403} height={56} />
            </Grid>
            <Grid item xs={6}>
              <Skeleton variant="rect" width={403} height={56} />
            </Grid>
          </Grid>
          <Box mt="40px" mb="40px">
            <Skeleton variant="rect" width={800} height={305} />
          </Box>
          <Grid container>
            <Grid item xs={6}>
              <Skeleton variant="rect" width={115} height={45} />
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={2} justifyContent="flex-end">
                <Grid item>
                  <Skeleton variant="rect" width={102} height={45} />
                </Grid>
                <Grid item>
                  <Skeleton variant="rect" width={129} height={45} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Container>
    </>
  );
}
