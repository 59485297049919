import React, { useCallback, useContext, useMemo } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useFormContext } from 'react-hook-form';
import { FormStepTitle } from '../../../../shared/components/ui/texts/Texts';
import { DragDrop } from './DragDrop';
import { FileType, DragDropItemType } from './DragDropItem';
import { ExperienceFormContext } from './ExperienceFormProvider';

const DESCRIPTION = 'SUPPORTED FORMATS: JPG, PNG, PDF';
const ACCEPTED_FILES = 'image/jpeg, image/png, .pdf';
/**
 * @returns {JSX.Element} Media files.
 */
export const Waiver: React.FC = () => {
  const { setValue, getValues } = useFormContext();
  const { setCountUploadingWaiver, experience } = useContext(
    ExperienceFormContext,
  );

  const preSavedFiles: DragDropItemType[] = useMemo(() => {
    if (!experience) return [];

    const {
      experienceExperienceWaiverRelation: { items },
    } = experience;

    if (!items?.length) return [];

    const savedFiles = items.map((mediaItem) => ({
      id: mediaItem.id,
      source: mediaItem.file.downloadUrl,
      size: mediaItem.file.meta.size,
      name: mediaItem.file.filename,
    }));

    return savedFiles;
  }, [experience]);

  /**
   * @param {FileType[]} uploadedFiles - Uploaded files.
   */
  const handleAddFile = useCallback(
    (uploadedFiles: FileType[]): void => {
      setValue('waiver', uploadedFiles);
    },
    [setValue],
  );

  /**
   * @param {string} id - Id of file to delete.
   */
  const handleDeleteUploadedFile = (id: string): void => {
    if (!experience) return;

    const {
      experienceExperienceWaiverRelation: { items },
    } = experience;

    if (!items?.length) return;

    const isUploaded = items.some((waiverItem) => waiverItem.id === id);

    if (isUploaded) {
      const waiverToDelete = getValues('waiverToDelete') || [];

      setValue('waiverToDelete', [...waiverToDelete, id]);
    }
  };

  /**
   * @param {number} uploadingCount - Amount file uploading.
   */
  const handleCountUploadingFiles = useCallback(
    (uploadingCount: number): void => {
      setCountUploadingWaiver(uploadingCount);
    },
    [setCountUploadingWaiver],
  );

  return (
    <Box mt={6}>
      <Box mb="15px">
        <FormStepTitle>VI. Waiver</FormStepTitle>
      </Box>
      <Grid item xs={12}>
        <DragDrop
          onAddFiles={handleAddFile}
          description={DESCRIPTION}
          accept={ACCEPTED_FILES}
          onCountUploadingFiles={handleCountUploadingFiles}
          preSavedFiles={preSavedFiles}
          onDeleteUploadedFile={handleDeleteUploadedFile}
        />
      </Grid>
    </Box>
  );
};
