import React from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import { CardTitlePage } from '../../../shared/components/ui/texts/Texts';

type InboxContainerProps = {
  title: string;
  children: JSX.Element;
};

const useStyles = makeStyles({
  paper: {
    borderRadius: '8px',
  },
});

/**
 * @param props - Props received.
 * @param {string} props.title - Title of card.
 * @param {JSX.Element} props.children - Content of card.
 *
 * @returns {JSX.Element} - Container of inbox.
 */
export const InboxContainer: React.FC<InboxContainerProps> = ({
  title,
  children,
}) => {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item xs={12}>
        <Paper className={classes.paper} elevation={24}>
          <Box paddingX={4} paddingTop="20px" paddingBottom="12px">
            <CardTitlePage>{title}</CardTitlePage>
          </Box>
          <Divider />
          {children}
        </Paper>
      </Grid>
    </Grid>
  );
};
