import React from 'react';
import { BreadcrumbItem } from './BreadcrumbItem';

const routes = [
  {
    pathToMatch: '/',
    breadcrumb: [
      {
        label: 'Dashboard',
      },
    ],
  },
  {
    pathToMatch: '/inbox',
    breadcrumb: [
      {
        label: 'Home',
        path: '/',
      },
      {
        label: 'Inbox',
      },
    ],
  },
  {
    pathToMatch: '/people',
    breadcrumb: [
      {
        label: 'Home',
        path: '/',
      },
      {
        label: 'People',
      },
    ],
  },
  {
    pathToMatch: '/people/:id/detail',
    breadcrumb: [
      {
        label: 'Home',
        path: '/',
      },
      {
        label: 'People',
        path: '/people',
      },
      {
        label: 'Detail People',
        paramValue: 'name',
      },
    ],
  },
  {
    pathToMatch: '/bookings',
    breadcrumb: [
      {
        label: 'Home',
        path: '/',
      },
      {
        label: 'Bookings',
      },
    ],
  },
  {
    pathToMatch: '/experiences',
    breadcrumb: [
      {
        label: 'Home',
        path: '/',
      },
      {
        label: 'Experiences',
      },
    ],
  },
  {
    pathToMatch: '/experiences/add-experience',
    breadcrumb: [
      {
        label: 'Home',
        path: '/',
      },
      {
        label: 'Experiences',
        path: '/experiences',
      },
      {
        label: 'Add Experience',
      },
    ],
  },
  {
    pathToMatch: '/experiences/:id/detail',
    breadcrumb: [
      {
        label: 'Home',
        path: '/',
      },
      {
        label: 'Experiences',
        path: '/experiences',
      },
      {
        label: 'Detail Experience',
        paramValue: 'name',
      },
    ],
  },
  {
    pathToMatch: '/settings',
    breadcrumb: [
      {
        label: 'Home',
        path: '/',
      },
      {
        label: 'Settings',
      },
    ],
  },
  {
    pathToMatch: '/my-account/information',
    breadcrumb: [
      {
        label: 'Home',
        path: '/',
      },
      {
        label: 'My Account',
        path: '/my-account/information',
      },
      {
        label: 'Account Information',
      },
    ],
  },
];

/**
 *
 * @returns {JSX.Element} - The breadcrumb item.
 */
export const Breadcrumb: React.FC = () => {
  const breadcrumbList = routes.map((route) => (
    <BreadcrumbItem
      key={route.pathToMatch}
      pathToMatch={route.pathToMatch}
      breadcrumb={route.breadcrumb}
    />
  ));

  return <>{breadcrumbList}</>;
};
