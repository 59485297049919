import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useFetchAction } from '@cobuildlab/react-simple-state';
import * as filestack from 'filestack-js';
import { useFormContext } from 'react-hook-form';
import { COLOR_PRIMARY } from '../../../../shared/components/ui/theme/contants';
import { fetchFileUploadInfoAction } from '../../../file-upload/file-upload-actions';
import { ExperienceFormContext } from './ExperienceFormProvider';

const useStyles = makeStyles({
  avatarContainer: {
    position: 'relative',
    overflow: 'hidden',
    width: '140px',
    height: '140px',
    borderRadius: '50%',
    backgroundColor: COLOR_PRIMARY,
    color: '#fff',
    cursor: 'pointer',
  },
  iconContainer: {
    marginTop: '20px',
    height: '60px',
    fontSize: '60px',
    fontWeight: 300,
  },
  iconText: {
    paddingTop: '8px',
    color: '#fff',
    fontSize: '12px',
  },
  img: {
    width: '100%',
    height: '100%',
    borderRadius: '50%',
    objectFit: 'cover',
  },
  footerAvatar: {
    position: 'absolute',
    bottom: '0',
    width: '100%',
    height: '30px',
    backgroundColor: COLOR_PRIMARY,
    textAlign: 'center',
    zIndex: 999,
  },
  overlay: {
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    zIndex: 1000,
  },
});

/**
 * @returns {JSX.Element} Experience Avatar.
 */
export const ExperienceAvatar: React.FC = () => {
  const classes = useStyles();
  const [source, setSource] = useState('');
  const [fileUploadInfo, loadingUploadInfo] = useFetchAction(
    fetchFileUploadInfoAction,
    [],
  );
  const [path, setPath] = useState<string>('');
  const [clientFileStack, setClientFileStack] = useState<filestack.Client>();
  const { loadingSubmit, experience } = useContext(ExperienceFormContext);
  const { setValue } = useFormContext();

  const isLoading = loadingSubmit || loadingUploadInfo;

  useEffect(() => {
    if (fileUploadInfo) {
      setClientFileStack(
        filestack.init(fileUploadInfo.apiKey, {
          security: {
            signature: fileUploadInfo.signature,
            policy: fileUploadInfo.policy,
          },
        }),
      );

      setPath(fileUploadInfo.path);
    }
  }, [fileUploadInfo]);

  useEffect(() => {
    if (experience?.file?.downloadUrl) {
      setSource(experience.file.downloadUrl);
    }
  }, [experience]);

  /**
   * @param {filestack.PickerResponse} files - Uploaded files.
   */
  const onUploadDone = (files: filestack.PickerResponse): void => {
    if (files.filesUploaded.length > 0) {
      const { policy, signature } = fileUploadInfo;
      const { filename, handle, url } = files.filesUploaded[0];
      const data = { create: { fileId: handle, filename } };
      const readUrl = `${url}?policy=${policy}&signature=${signature}`;

      setSource(readUrl);
      setValue('file', data);
    }
  };

  /**
   *
   */
  const handleAddPhoto = (): void => {
    if (isLoading || !clientFileStack) return;

    const options = {
      fromSources: ['local_file_system'],
      accept: 'image/*',
      maxFiles: 1,
      onUploadDone,
      storeTo: { path },
    };

    clientFileStack.picker(options).open();
  };

  let content = (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box className={classes.iconContainer}>+</Box>
      <span className={classes.iconText}>ADD IMAGE</span>
    </Box>
  );

  let overlay: JSX.Element | null = null;

  if (isLoading) {
    overlay = <div className={classes.overlay} />;
  }

  if (source) {
    content = (
      <>
        <img className={classes.img} src={source} alt="avatar" />
        <Box className={classes.footerAvatar}>
          <span className={classes.iconText}>Change</span>
        </Box>
      </>
    );
  }

  return (
    <Grid container justifyContent="center">
      <div
        className={classes.avatarContainer}
        onClick={handleAddPhoto}
        role="button"
        tabIndex={0}
      >
        {content}
        {overlay}
      </div>
    </Grid>
  );
};
