import { Typography } from '@material-ui/core';
import React from 'react';

export interface TimestampProps {
  text: string;
}

/**
 * @param {string} data - Value.
 * @returns {JSX.Element} - Messages group header.
 */
export const Timestamp: React.FC<TimestampProps> = ({ text }) => (
  <>
    <Typography
      align="center"
      style={{
        color: 'grey',
        fontSize: '14px',
        margin: '10px 0px',
      }}
    >
      {text}
    </Typography>
  </>
);
