import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { styled } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { BookingType, TravelerToItineraryMapping } from '../../../shared/types';
import { DialogTitle } from '../../../shared/components/ui/dialogs/DialogTitle';
import { SwitchChip } from './SwitchChip';

const OperatorDetails = styled('div')({
  padding: 20,
  borderRadius: 4,
  boxShadow: '0 3px 20px 0 rgba(171,171,171,0.35)',
});

type ItineraryDetailsProps = {
  booking: BookingType | null;
  isOpen: boolean;
  onClose: () => void;
};

const ItineraryThumbnail = styled('img')(() => ({
  position: 'absolute',
  borderRadius: 5,
  width: '100%',
  objectFit: 'fill',
  height: '100%',
}));

const GridImg = styled(Grid)({
  position: 'relative',
  height: 110,
  width: 110,
});

/**
 * @param props - Props received.
 * @param {BookingType | null} props.booking - Booking data.
 * @param {boolean} props.isOpen - Check if is open.
 * @param {Function} props.onClose - Function to close.
 *
 * @returns {JSX.Element} Booking details.
 */
export const ItineraryDetails: React.FC<ItineraryDetailsProps> = ({
  booking,
  isOpen,
  onClose,
}) => {
  if (!booking) {
    return null;
  }

  const days = _.groupBy(
    booking.itinerary.itineraryBookingRelation.items,
    (x) => x.itineraryDay,
  );

  /**
   * @param {BookingType} bookingData - Booking data.
   * @returns {string} - Travelers string.
   */
  const renderTravelers = (bookingData: BookingType): string =>
    [...bookingData.itinerary.itineraryTravelToItineraryMappingRelation.items]
      .sort((first, second) => {
        const a = first.travelerType.travelerTypeName;
        const b = second.travelerType.travelerTypeName;

        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        return 0;
      })
      .map(
        (traveler: TravelerToItineraryMapping) =>
          `${traveler.amount} ${traveler.travelerType.travelerTypeName}`,
      )
      .join(', ');

  const experienceImage =
    booking?.experience?.experienceExperienceMediaRelation?.items[0]?.file
      .downloadUrl;

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle onClose={onClose}>
        {booking.user.fullName ?? booking.user.email} - Itinerary
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Box mt="20px" mb="20px">
          <Grid container spacing={3} />
        </Box>
        <Box mt="20px" mb="20px">
          <OperatorDetails>
            <Grid container>
              <Grid item xs={8}>
                <Typography variant="button" display="block" gutterBottom>
                  {/* <Box fontSize={18} fontWeight={500}>
                    Trip to {booking.experience.city.cityName},{' '}
                    {booking.experience.state.stateName}
                  </Box> */}
                  <Box fontSize={18} fontWeight={500}>
                    Trip to {booking?.itinerary?.itineraryCityName}
                  </Box> 
                </Typography>
                <Box color="#8d8d8d">
                  {moment
                    .tz(booking.itinerary.startDateTime, 'UTC')
                    .format('MMM D YYYY')}
                  &ndash;
                  {moment
                    .tz(booking.itinerary.endDateTime, 'UTC')
                    .format('MMM D YYYY')}
                  <br />
                  <Box mt="8px" style={{ textTransform: 'lowercase' }}>
                    {renderTravelers(booking)}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Grid container justifyContent="flex-end">
                  <GridImg item>
                    <ItineraryThumbnail src={experienceImage} alt="city" />
                  </GridImg>
                </Grid>
              </Grid>
            </Grid>
          </OperatorDetails>
        </Box>

        {Object.keys(days).map((day, index) => (
          <React.Fragment key={day}>
            <Box mt="20px" mb="20px" key={day}>
              <Typography variant="button" display="block" gutterBottom>
                <Box fontWeight={600} color="#9e9e9e" width={552}>
                  DAY #{day}
                </Box>
              </Typography>
              <Grid container spacing={1}>
                {days[day].map((b) => (
                  <Grid item xs={12} key={b.id}>
                    <Grid container justifyContent="space-between">
                      <Grid item>
                        <Grid container spacing={1} alignItems="center">
                          <Grid item>
                            <SwitchChip status={b.bookingStatus.statusName} />
                          </Grid>
                          <Grid item>
                            <Box
                              fontWeight={500}
                              textOverflow="ellipsis"
                              whiteSpace="nowrap"
                              overflow="hidden"
                              width={200}
                            >
                              {b.experience.experienceName}
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Box
                          display="flex"
                          alignItems="center"
                          height="100%"
                          fontWeight={500}
                        >
                          {moment.tz(b.startDateTime, 'UTC').format('h:mm a')}
                          &ndash;
                          {moment.tz(b.endDateTime, 'UTC').format('h:mm a')}
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Box>
            {index !== Object.keys(days).length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </DialogContent>
    </Dialog>
  );
};
