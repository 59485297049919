import {
  isValidString,
  isValidNumber,
  isValidDate,
} from '@cobuildlab/validation-utils';
import * as yup from 'yup';
import moment from 'moment';
import {
  ExperienceScheduleType,
  KeyFilterType,
  ExperienceMediaGalleryType,
} from '../../shared/types';
import {
  ExperiencePricingConnectType,
  ExperienceMediaCreateType,
} from './experience-model';

type ExperienceValidationData = {
  pricings: ExperiencePricingConnectType[];
  openingTimes: ExperienceScheduleType[];
  location: string[];
};

const days = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

const hours = [
  '12:00 am',
  '12:30 am',
  '1:00 am',
  '1:30 am',
  '2:00 am',
  '2:30 am',
  '3:00 am',
  '3:30 am',
  '4:00 am',
  '4:30 am',
  '5:00 am',
  '5:30 am',
  '6:00 am',
  '6:30 am',
  '7:00 am',
  '7:30 am',
  '8:00 am',
  '8:30 am',
  '9:00 am',
  '9:30 am',
  '10:00 am',
  '10:30 am',
  '11:00 am',
  '11:30 am',
  '12:00 pm',
  '12:30 pm',
  '1:00 pm',
  '1:30 pm',
  '2:00 pm',
  '2:30 pm',
  '3:00 pm',
  '3:30 pm',
  '4:00 pm',
  '4:30 pm',
  '5:00 pm',
  '5:30 pm',
  '6:00 pm',
  '6:30 pm',
  '7:00 pm',
  '7:30 pm',
  '8:00 pm',
  '8:30 pm',
  '9:00 pm',
  '9:30 pm',
  '10:00 pm',
  '10:30 pm',
  '11:00 pm',
  '11:30 pm',
];

export const experienceValidatorSchema = yup.object().shape({
  experienceName: yup.string().max(50).label('experience name').required(),
  contactEmailAddress: yup.string().label('email').email().required(),
  // zipcode: yup.string().label('zip').required(),
  address: yup.string().required(),
  phoneNumber: yup.string().label('phone number').required(),
  experienceDescription: yup.string().label('description').required(),
  state: yup.string().required(),
  city: yup.string().required(),
  categories: yup.array().defined(),
  subCategories: yup.array().label('subcategories'),
  operatorsName: yup.string().label('operator name').required(),
  websiteLink: yup.string().label('website').url(),
  timezone: yup.string().label('time zone').required(),
});

/**
 * @param {ExperiencePricingConnectType[]} pricings - Pricings data.
 *
 * @returns {boolean} State o validation.
 */
const invalidPricingName = (
  pricings: ExperiencePricingConnectType[],
): boolean => pricings.some(({ pricingName }) => !isValidString(pricingName));

/**
 * @param {ExperiencePricingConnectType[]} pricings - Pricings data.
 *
 * @returns {boolean} State of validation.
 */
const invalidPricingValue = (
  pricings: ExperiencePricingConnectType[],
): boolean =>
  pricings.some(({ feeAmount }) => !isValidNumber(feeAmount.toString(), true));

/**
 * @param {ExperiencePricingConnectType[]} pricings - Pricings data.
 *
 * @returns {boolean} State of validation.
 */
const invalidPricingDate = (
  pricings: ExperiencePricingConnectType[],
): boolean =>
  pricings.some(
    ({ startTime, endTime }) =>
      !isValidDate(startTime) || !isValidDate(endTime),
  );

/**
 * @param {ExperiencePricingConnectType[]} pricings - Pricings data.
 *
 * @returns {boolean} State of validation.
 */
const invalidPricingMajorDate = (
  pricings: ExperiencePricingConnectType[],
): boolean =>
  pricings.some(({ startTime, endTime }) => moment(startTime).isAfter(endTime));

/**
 * @param {ExperiencePricingConnectType[]} pricings - Pricings data.
 *
 * @returns {boolean} State of validation.
 */
const invalidPricingTravelerType = (
  pricings: ExperiencePricingConnectType[],
): boolean => pricings.some(({ travelerType }) => !travelerType);

/**
 * @param {ExperienceScheduleType []} openigTimes - Opening Times data.
 * @returns {boolean} State of validation.
 */
const invalidOpeningTimeDay = (
  openigTimes: ExperienceScheduleType[],
): boolean =>
  openigTimes.some(({ day }) => !days.find((dayName) => dayName === day));

/**
 * @param {ExperienceScheduleType []} openigTimes - Opening Times data.
 * @returns {boolean} State of validation.
 */
const invalidOpeningTimeHour = (
  openigTimes: ExperienceScheduleType[],
): boolean =>
  openigTimes.some(
    ({ startHour }) => !hours.find((time) => time === startHour),
  );

/**
 * @param {ExperienceScheduleType []} openigTimes - Opening Times data.
 * @returns {boolean} State of validation.
 */
const invalidOpeningTimeEndingHour = (
  openigTimes: ExperienceScheduleType[],
): boolean =>
  openigTimes.some(({ endHour }) => !hours.find((time) => time === endHour));

/**
 * @param {ExperienceScheduleType} openingTimes - Opening times data.
 */
export const openingTimesValidator = (
  openingTimes: ExperienceScheduleType[],
): void => {
  if (invalidOpeningTimeDay(openingTimes)) {
    throw new Error('All opening times must have a valid day');
  }

  if (invalidOpeningTimeHour(openingTimes)) {
    throw new Error('All opening times must have a valid start hour');
  }

  if (invalidOpeningTimeEndingHour(openingTimes)) {
    throw new Error('All opening times must have a valid ending hour');
  }
};

/**
 * @param {ExperiencePricingConnectType} pricings - Pricings data.
 */
export const pricingValidator = (
  pricings: ExperiencePricingConnectType[],
): void => {
  if (invalidPricingName(pricings)) {
    throw new Error('All pricings must have a valid name');
  }

  if (invalidPricingValue(pricings)) {
    throw new Error('All pricings must have valid values');
  }

  if (invalidPricingDate(pricings)) {
    throw new Error('All pricings must have correct dates');
  }

  if (invalidPricingMajorDate(pricings)) {
    throw new Error(
      'All pricings must have its start date lower or equal than the end date',
    );
  }

  if (invalidPricingTravelerType(pricings)) {
    throw new Error('All pricings must select a traveler type');
  }
};

/**
 * @param {ExperienceMediaCreateType} media - Media array.
 */
export const mediaRequiredValidator = (
  media: ExperienceMediaCreateType[],
): void => {
  if (!media.length) {
    throw new Error('At least one media file is required');
  }
};

/**
 * @param {ExperienceMediaCreateType[]} mediaCreate - Media to create.
 * @param {KeyFilterType[]} mediaDisconnect - Media to disconnect.
 * @param {ExperienceMediaGalleryType} media - Previously saved media.
 */
export const mediaUpdateRequireValidator = (
  mediaCreate: ExperienceMediaCreateType[],
  mediaDisconnect: KeyFilterType[],
  media: ExperienceMediaGalleryType[],
): void => {
  if (mediaDisconnect.length) {
    const allDelete = media.every(({ id }) => {
      const isDisconnect = mediaDisconnect.find(
        ({ id: disconnectId }) => disconnectId === id,
      );

      return !!isDisconnect;
    });

    if (allDelete && !mediaCreate.length) {
      throw new Error('At least one media file is required');
    }
  }
};

/**
 * @param {ExperienceMediaCreateType []} waiver - Waiver array.
 */
export const maxWaiverValidator = (
  waiver: ExperienceMediaCreateType[],
): void => {
  if (waiver.length) {
    if (waiver.length > 1) {
      throw new Error('Maximum one waiver per experience');
    }
  }
};

/**
 * @param {ExperienceMediaCreateType[]} waiverCreate - Waiver to create.
 * @param {KeyFilterType[]} waiverDisconnect - Media to disconnect.
 * @param {ExperienceMediaGalleryType} waiver - Previously saved media.
 */
export const maxWaiverUpdateValidator = (
  waiverCreate: ExperienceMediaCreateType[],
  waiverDisconnect: KeyFilterType[],
  waiver: ExperienceMediaGalleryType[],
): void => {
  if (waiverDisconnect.length && waiverCreate.length) {
    maxWaiverValidator(waiverCreate);
  } else if (waiverCreate.length && waiver.length) {
    throw new Error('Maximum one waiver per experience');
  } else if (waiverCreate.length) {
    maxWaiverValidator(waiverCreate);
  }
};

/**
 * Function to validate fields that are beyond yup's scope.
 *
 * @param {ExperienceValidationData} data - Data to validate.
 */
export const createExperienceValidator = (
  data: ExperienceValidationData,
): void => {
  const { pricings, openingTimes, location } = data;

  if (!openingTimes?.length) {
    throw new Error('At least one opening time is mandatory');
  }

  if (!pricings?.length) {
    throw new Error('At least one pricing is mandatory');
  }

  if (!location.length) {
    throw new Error('Enter valid address');
  }

  openingTimesValidator(openingTimes);
  pricingValidator(pricings);
};

/**
 * @param {ExperiencePricingConnectType[]} princingsToCreate - Pricings to create.
 * @param {ExperiencePricingConnectType[]} pricingsToUpdate - Pricings to update.
 */
export const createPriceValidationTraveler = (
  princingsToCreate: ExperiencePricingConnectType[],
  pricingsToUpdate: ExperiencePricingConnectType[] = [],
): void => {
  princingsToCreate.forEach((pricing) => {
    const interferedPrices = princingsToCreate.filter(
      (pricingToCreate) =>
        moment(pricingToCreate.startTime).isSameOrBefore(pricing.endTime) &&
        moment(pricingToCreate.endTime).isSameOrAfter(pricing.startTime) &&
        pricingToCreate.travelerType?.connect.id ===
          pricing.travelerType?.connect.id,
    );

    if (interferedPrices.length > 1) {
      const { pricingName } = interferedPrices[0];
      const selectedPrice = interferedPrices[1];

      throw new Error(
        `${pricingName} dates interfere with ${selectedPrice?.pricingName} dates`,
      );
    }
  });

  if (!pricingsToUpdate.length) return;

  princingsToCreate.forEach((pricing) => {
    const interferedPrices = pricingsToUpdate.filter(
      (pricingToUpdate) =>
        moment(pricingToUpdate.startTime).isSameOrBefore(pricing.endTime) &&
        moment(pricingToUpdate.endTime).isSameOrAfter(pricing.startTime) &&
        pricingToUpdate.travelerType?.connect.id ===
          pricing.travelerType?.connect.id,
    );

    if (interferedPrices.length) {
      const { pricingName } = pricing;
      const selectedPrice = interferedPrices[0];

      throw new Error(
        `${pricingName} dates interfere with ${selectedPrice?.pricingName} dates`,
      );
    }
  });

  pricingsToUpdate.forEach((pricing) => {
    const interferedPrices = pricingsToUpdate.filter(
      (pricingToUpdate) =>
        moment(pricingToUpdate.startTime).isSameOrBefore(pricing.endTime) &&
        moment(pricingToUpdate.endTime).isSameOrAfter(pricing.startTime) &&
        pricingToUpdate.travelerType?.connect.id ===
          pricing.travelerType?.connect.id,
    );

    if (interferedPrices.length > 1) {
      const { pricingName } = pricing;
      const selectedPrice = interferedPrices.find(
        ({ id }) => id !== pricing.id,
      );

      throw new Error(
        `${pricingName} dates interfere with ${selectedPrice?.pricingName} dates`,
      );
    }
  });
};
