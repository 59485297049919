import React, { useState, useMemo } from 'react';
import Menu from '@material-ui/core/Menu';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormHelperText from '@material-ui/core/FormHelperText';
import Chip from '@material-ui/core/Chip';
import { CardTitlePage } from '../../../../shared/components/ui/texts/Texts';
import {
  ButtonLink,
  ButtonLinkDefault,
} from '../../../../shared/components/ui/buttons/Buttons';
import { COLOR_DANGER } from '../../../../shared/components/ui/theme/contants';

export type SelectCheckboxItemType = {
  id: string;
  name: string;
  checked: boolean;
};

type SelectCheckboxProps = {
  label: string;
  items: SelectCheckboxItemType[];
  loading?: boolean;
  hasError?: boolean;
  errorMessage?: string;
  onFetchData: () => void;
  onChangeState: (id: string) => void;
  onApply: () => void;
  onRemoveNoApplied: () => void;
  onRemove: (id: string) => void;
};

const useStyles = makeStyles({
  selectedContainer: {
    position: 'relative',
    minHeight: '56px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '11.5px',
    paddingRight: '14px',
    borderRadius: '4px',
    border: '1px solid #D2D2D2',
    color: '#ADADAD',
    fontSize: '16px',
    lineHeight: '26px',
    cursor: 'pointer',
    '&:hover': {
      border: '1px solid rgba(0, 0, 0, 0.87)',
    },
  },
  selectedLabel: {
    position: 'absolute',
    top: '0',
    left: '-11px',
    paddingLeft: '4.5px',
    paddingRight: '5px',
    background: 'white',
    transformOrigin: '100px 20px',
    transform: 'scale(0.8) translateY(-19px)',
  },
  selectedError: {
    border: `1px solid ${COLOR_DANGER}`,
    color: COLOR_DANGER,
    '&:hover': {
      border: `1px solid ${COLOR_DANGER}`,
    },
  },
  helperError: {
    marginLeft: '14px',
    color: COLOR_DANGER,
  },
  paperMenu: {
    borderRadius: '8px',
    paddingTop: '25px',
    marginTop: '8px',
    width: '390px',
  },
  cancelLink: {
    color: '#B2B2B2',
  },
  chipContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingTop: '10px',
  },
});

/**
 * @param props - Props received.
 * @param {string} props.label - Label input.
 * @param {SelectCheckboxItemType[]} props.items - Items list.
 * @param {boolean} props.loading - State of loading.
 * @param {Function} props.onFetchData - Function to fetch data.
 * @param {Function} props.onChangeState - Function to change checkbox.
 * @param {Function} props.onApply - Function to apply change.
 * @param {Function} props.onRemoveNoApplied - Function to remove categories not applied.
 * @param {Function} props.onRemove - Function to remove category from pill.
 * @param {boolean} props.hasError - Has a error.
 * @param {string} props.errorMessage - Error message.
 * @returns {JSX.Element} Select input.
 */
export const SelectCheckbox: React.FC<SelectCheckboxProps> = ({
  label,
  items,
  loading,
  hasError,
  errorMessage,
  onFetchData,
  onChangeState,
  onApply,
  onRemoveNoApplied,
  onRemove,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = !!anchorEl;
  const classes = useStyles();
  const errorClass = hasError ? classes.selectedError : '';
  const selectedItems = useMemo(
    () => items.filter((item) => item.checked),
    [items],
  );

  /**
   * Close Menu.
   */
  const handleClose = (): void => {
    onRemoveNoApplied();
    setAnchorEl(null);
  };

  /**
   * @param {HTMLElement} e - Event.
   */
  const handleOpenMenu = (e: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(e.currentTarget);
    onFetchData();
  };

  /**
   * Function to approve changes.
   */
  const handleSubmitData = (): void => {
    setAnchorEl(null);
    onApply();
  };

  let selectedLabel = null;
  let content: JSX.Element | JSX.Element[] = <span>{label}</span>;
  let options: JSX.Element | JSX.Element[] = items.map((item) => (
    <Box key={item.id} mt="10px" ml="22px" display="flex" alignItems="center">
      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            checked={item.checked}
            onChange={() => onChangeState(item.id)}
          />
        }
        label={item.name}
      />
    </Box>
  ));

  if (selectedItems.length) {
    const pills = selectedItems.map((selectedItem) => (
      <Box mb="5px" ml="2px" key={selectedItem.id}>
        <Chip
          disabled={loading}
          label={selectedItem.name}
          color="primary"
          onDelete={() => onRemove(selectedItem.id)}
        />
      </Box>
    ));

    selectedLabel = <div className={classes.selectedLabel}>{label}</div>;

    content = <div className={classes.chipContainer}>{pills}</div>;
  }

  if (loading) {
    options = (
      <Box textAlign="center">
        <CircularProgress size={20} />
      </Box>
    );
  }

  return (
    <>
      <div
        className={`${classes.selectedContainer} ${errorClass}`}
        onClick={handleOpenMenu}
        role="button"
        tabIndex={0}
      >
        {selectedLabel}
        {content}
        <Box pt="4px">
          <ArrowDropDownIcon />
        </Box>
      </div>
      <FormHelperText className={classes.helperError}>
        {errorMessage || ''}
      </FormHelperText>
      <Menu
        classes={{
          paper: classes.paperMenu,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <Box ml="22px">
          <CardTitlePage>{label}</CardTitlePage>
        </Box>
        <Box mb="10px">{options}</Box>
        <Divider />
        <Box pt="10px" display="flex" justifyContent="flex-end">
          <Box pr="25px" pb="2px">
            <ButtonLinkDefault
              className={classes.cancelLink}
              onClick={handleClose}
            >
              CANCEL
            </ButtonLinkDefault>
            <ButtonLink onClick={handleSubmitData}>APPLY</ButtonLink>
          </Box>
        </Box>
      </Menu>
    </>
  );
};
