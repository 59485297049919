import moment from 'moment';

// eslint-disable-next-line
type FunctionType = (...args: any[]) => void;

/**
 * @param {string []} keysToOmit - Keys to omit.
 * @param {Record<string, any>} originalObj - Object to remove keys.
 *
 * @returns {Record} Object without keys.
 */
export const omit = <T>(keysToOmit: string[], originalObj = {}): T => {
  const record: unknown = Object.fromEntries(
    Object.entries(originalObj).filter(([key]) => !keysToOmit.includes(key)),
  );

  return record as T;
};

/**
 * @param {FunctionType} callback - Function to call.
 * @param {number} wait - Time of wait.
 * @returns {FunctionType} - Function to call.
 */
export const debounce = (
  callback: FunctionType,
  wait: number,
): FunctionType => {
  let timerId: NodeJS.Timeout;

  return (...args: unknown[]) => {
    clearTimeout(timerId);
    timerId = setTimeout(() => {
      callback(...args);
    }, wait);
  };
};

/**
 *
 * @param amount - Amount to format.
 * @returns - Formatted string.
 */
export const formatMoney = (amount: number): string => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return formatter.format(amount);
};

/**
 * @param date - Date to format.
 * @param format - Moment format to use.
 * @returns {string} Formatted date.
 */
export function formatDate(date: Date | null, format: string): string {
  if (!date) {
    return 'N/A';
  }

  return moment.tz(date, 'UTC').format(format);
}
