import React, { useState, useEffect } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useCallAction } from '@cobuildlab/react-simple-state';
import { useFormContext, Controller } from 'react-hook-form';
import { LanguageType, UserType } from '../../../../shared/types';
import { fetchLanguageTypesAction } from '../../../language-type/language-type-actions';

type AccountLanguageProps = {
  user?: UserType;
};

/**
 * @param props - Props received.
 * @param {UserType} props.user - User data.
 * @returns {JSX.Element} Select genders.
 */
export const AccountLanguageSelect: React.FC<AccountLanguageProps> = ({
  user,
}) => {
  const { control } = useFormContext();
  const [languageTypes, setLanguageTypes] = useState<LanguageType[]>([]);

  const [fetchLanguage, loading] = useCallAction(fetchLanguageTypesAction, {
    /**
     * @param {LanguageType[]} languages - Genders to select.
     */
    onCompleted: (languages: LanguageType[]) => {
      if (!languageTypes.length) {
        setLanguageTypes(languages.map((language) => ({ ...language })));
      }
    },
  });

  useEffect(() => {
    if (user?.language?.id && !languageTypes.length) {
      fetchLanguage();
    }
  }, [user, languageTypes.length, fetchLanguage]);

  let content: JSX.Element | JSX.Element[] = languageTypes.map(
    (languageType) => (
      <MenuItem key={languageType.id} value={languageType.id}>
        {languageType.languageName}
      </MenuItem>
    ),
  );

  if (loading) {
    content = (
      <MenuItem value="" disabled>
        Loading...
      </MenuItem>
    );
  }

  return (
    <Controller
      name="language"
      control={control}
      defaultValue={user?.language?.id || ''}
      render={({ field, fieldState: { error } }) => (
        <Select
          {...field}
          error={!!error}
          label="Language"
          onFocus={() => fetchLanguage()}
        >
          {content}
        </Select>
      )}
    />
  );
};
