import React from 'react';
import Box from '@material-ui/core/Box';
import { PageTitle } from '../../../shared/components/ui/texts/Texts';

/**
 * Dashboard top info.
 *
 * @returns {JSX.Element} Dashoard header.
 */
export const BookingListHeader: React.FC = () => (
  <Box display="flex" justifyContent="space-between" alignItems="center">
    <PageTitle>Bookings</PageTitle>
  </Box>
);
