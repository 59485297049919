import React, { useState, useEffect } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useCallAction } from '@cobuildlab/react-simple-state';
import { useFormContext, Controller } from 'react-hook-form';
import { fetchGenderTypesAction } from '../../../gender-type/gender-type-actions';
import { GenderType, UserType } from '../../../../shared/types';

type AccountGenderProps = {
  user?: UserType;
};

/**
 * @param props - Props recived.
 * @param {UserType} props.user - User data.
 * @returns {JSX.Element} Select genders.
 */
export const AccountGenderSelect: React.FC<AccountGenderProps> = ({ user }) => {
  const { control } = useFormContext();
  const [genderTypes, setGenderTypes] = useState<GenderType[]>([]);

  const [fetchGenders, loading] = useCallAction(fetchGenderTypesAction, {
    /**
     * @param {GenderType[]} genders - Genders to select.
     */
    onCompleted: (genders: GenderType[]) => {
      if (!genderTypes.length) {
        setGenderTypes(genders.map((gender) => ({ ...gender })));
      }
    },
  });

  useEffect(() => {
    if (user?.gender?.id && !genderTypes.length) {
      fetchGenders();
    }
  }, [user, genderTypes.length, fetchGenders]);

  let content: JSX.Element | JSX.Element[] = genderTypes.map((genderType) => (
    <MenuItem key={genderType.id} value={genderType.id}>
      {genderType.genderName}
    </MenuItem>
  ));

  if (loading) {
    content = (
      <MenuItem value="" disabled>
        Loading...
      </MenuItem>
    );
  }

  return (
    <Controller
      name="gender"
      control={control}
      defaultValue={user?.gender?.id || ''}
      render={({ field, fieldState: { error } }) => (
        <Select
          {...field}
          error={!!error}
          label="Genders"
          onFocus={() => fetchGenders()}
        >
          {content}
        </Select>
      )}
    />
  );
};
