import { createAction } from '@cobuildlab/react-simple-state';
import {
  OnUserCount,
  OnUserError,
  OnUpdateUser,
  OnUpdateUserError,
  OnUpdateUserAvatar,
  OnUpdateUserAvatarError,
} from './user-events';
import { fetchUserCountService as fetchUserCount } from './user-services';
import { UPDATE_USER, UPDATE_USER_AVATAR } from './user-queries';
import { apolloClient } from '../../shared/apollo';

export const fetchUserCountActions = createAction(
  OnUserCount,
  OnUserError,
  async (dates: string[]) => {
    const results = await Promise.all(
      dates.map((date) => fetchUserCount(date)),
    );

    return results;
  },
);

export const updateUserAction = createAction(
  OnUpdateUser,
  OnUpdateUserError,
  async (data) => {
    const response = await apolloClient.mutate({
      mutation: UPDATE_USER,
      fetchPolicy: 'network-only',
      variables: {
        data,
      },
    });

    return response?.data?.userUpdate;
  },
);

export const updateUserAvatarAction = createAction(
  OnUpdateUserAvatar,
  OnUpdateUserAvatarError,
  async (data) => {
    const response = await apolloClient.mutate({
      mutation: UPDATE_USER_AVATAR,
      fetchPolicy: 'network-only',
      variables: {
        data,
      },
    });

    return response?.data?.userUpdate;
  },
);
