import React, {
  useState,
  useEffect,
  createContext,
  Dispatch,
  SetStateAction,
} from 'react';

import { ExperienceType } from '../../../../shared/types';

type ExperienceFormType = {
  loadingSubmit: boolean;
  loadingUpload: boolean;
  experience?: ExperienceType;
  stateName: string;
  cityName: string;
  setCountUploadingFile: Dispatch<SetStateAction<number>>;
  setCountUploadingWaiver: Dispatch<SetStateAction<number>>;
  setStateName: Dispatch<SetStateAction<string>>;
  setCityName: Dispatch<SetStateAction<string>>;
  setLoadingUpload: Dispatch<SetStateAction<boolean>>;
};

type ExperienceFormProviderProps = {
  loadingSubmit: boolean;
  experience?: ExperienceType;
  children: JSX.Element;
};

export const ExperienceFormContext = createContext<ExperienceFormType>({
  loadingUpload: false,
  loadingSubmit: false,
  stateName: '',
  cityName: '',

  /**
   *
   * @returns {boolean} Loading state.
   */
  setLoadingUpload: () => false,

  /**
   * Function to add city name.
   *
   * @returns {string} City name.
   */
  setCityName: () => '',

  /**
   * Function to add state name.
   *
   * @returns {string} State name.
   */
  setStateName: () => '',

  /**
   * Function to change  amount uploading file.
   *
   * @returns {number} Amount uploading file.
   */
  setCountUploadingFile: () => 0,

  /**
   * Function to change  amount uploading waiver.
   *
   * @returns {number} Amount uploading waiver.
   */
  setCountUploadingWaiver: () => 0,
});

/**
 * @param props - Props Received.
 * @param {JSX.Element} props.children - Children node.
 * @param {boolean} props.loadingSubmit - Loading submit.
 * @param {ExperienceType} props.experience - Experience to edit.
 * @returns {JSX.Element} Experience from provider.
 */
export const ExperienceFormProvider: React.FC<ExperienceFormProviderProps> = ({
  children,
  loadingSubmit,
  experience,
}) => {
  const [loadingUpload, setLoadingUpload] = useState<boolean>(false);
  const [stateName, setStateName] = useState<string>(
    experience?.state?.stateName || '',
  );
  const [cityName, setCityName] = useState<string>(
    experience?.city?.cityName || '',
  );
  const [countUploadingFile, setCountUploadingFile] = useState<number>(0);
  const [countUpladingWave, setCountUploadingWaiver] = useState<number>(0);

  useEffect(() => {
    if (countUploadingFile > 0 || countUpladingWave > 0) {
      setLoadingUpload(true);
    } else {
      setLoadingUpload(false);
    }
  }, [countUploadingFile, countUpladingWave]);

  return (
    <ExperienceFormContext.Provider
      value={{
        loadingSubmit,
        loadingUpload,
        experience,
        stateName,
        cityName,
        setCityName,
        setCountUploadingFile,
        setCountUploadingWaiver,
        setLoadingUpload,
        setStateName,
      }}
    >
      {children}
    </ExperienceFormContext.Provider>
  );
};
