import React, { useState, useEffect } from 'react';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import moment from 'moment';
import { useFetchAction } from '@cobuildlab/react-simple-state';
import Skeleton from '@material-ui/lab/Skeleton';
import {
  COLOR_PRIMARY,
  GRAY_176,
} from '../../../shared/components/ui/theme/contants';
import { CardTitleText } from '../../../shared/components/ui/texts/Texts';
import { fetchExperiencesPopularAction as fetchExperiencesPopular } from '../../experience/experience-actions';
import { ExperienceType } from '../../../shared/types';
import { ExperiencePopularType } from '../../experience/experience-events';

type DashboardExperienceListProps = {
  loadingDashboard: boolean;
};

const useStyles = makeStyles({
  cardContainer: {
    borderRadius: '5px',
    paddingBottom: '8px',
  },
  listTitle: {
    color: COLOR_PRIMARY,
    fontWeight: 500,
  },
  avatar: {
    height: '25px',
    width: '25px',
    backgroundColor: COLOR_PRIMARY,
    color: '#fff',
    fontSize: '15px',
  },
  avatarContainer: {
    paddingTop: '10px',
    marginRight: '8px',
    minWidth: 0,
  },
  listSubTitle: {
    color: GRAY_176,
    fontSize: '13px',
  },
  listItem: {
    alignItems: 'normal',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
});

const endDateTime = moment().endOf('day').format();
const startDateTime = moment().subtract(30, 'd').startOf('day').format();

/**
 * @param props - Props received.
 * @param props.loadingDashboard - Loadin dashboard.
 *  @returns {JSX.Element} Experience list.
 */
export const DashboardExperienceList: React.FC<DashboardExperienceListProps> =
  ({ loadingDashboard }) => {
    const classes = useStyles();
    const [experiences, setExperiences] = useState<Array<ExperienceType>>([]);
    const [experiencesGroup, loading] = useFetchAction(
      fetchExperiencesPopular,
      [startDateTime, endDateTime],
      {
        skip: loadingDashboard,
      },
    );

    const isLoading = loading || loadingDashboard;

    useEffect(() => {
      if (experiencesGroup && !experiences.length) {
        const experiencesFormat = (experiencesGroup as ExperiencePopularType[])
          .map(({ experiencesPopular }) => experiencesPopular.items[0])
          .sort((expA, expB) => {
            const countA = expA.experienceBookingRelation.count as number;
            const countB = expB.experienceBookingRelation.count as number;

            if (countA > countB) {
              return -1;
            }

            if (countB > countA) {
              return 1;
            }

            return 0;
          });

        setExperiences(experiencesFormat);
      }
    }, [experiencesGroup, experiences.length]);

    let content: JSX.Element | JSX.Element[] = experiences.map(
      (experience, index) => (
        <ListItem className={classes.listItem} key={experience.id}>
          <ListItemAvatar className={classes.avatarContainer}>
            <Avatar className={classes.avatar}>{index + 1}</Avatar>
          </ListItemAvatar>
          <ListItemText
            classes={{
              primary: classes.listTitle,
              secondary: classes.listSubTitle,
            }}
            primary={experience.experienceName}
            secondary={`${experience.experienceBookingRelation.count} bookings completed`}
          />
        </ListItem>
      ),
    );

    if (isLoading) {
      content = (
        <>
          <Box display="flex" px="12px" mb={1} alignItems="center">
            <Skeleton variant="circle" width={30} height={30} />
            <Box flexGrow={1} ml={1}>
              <Skeleton variant="text" />
              <Skeleton variant="text" />
            </Box>
          </Box>
          <Box display="flex" px="12px" mb={2} alignItems="center">
            <Skeleton variant="circle" width={30} height={30} />
            <Box flexGrow={1} ml={1}>
              <Skeleton variant="text" />
              <Skeleton variant="text" />
            </Box>
          </Box>
          <Box display="flex" px="12px" mb={1} alignItems="center">
            <Skeleton variant="circle" width={30} height={30} />
            <Box flexGrow={1} ml={1}>
              <Skeleton variant="text" />
              <Skeleton variant="text" />
            </Box>
          </Box>
        </>
      );
    }

    if (experiencesGroup !== null && !experiencesGroup.length) {
      content = (
        <Box pl="20px" py="2px" fontSize="13.5px">
          <span>No Records</span>
        </Box>
      );
    }

    return (
      <Paper className={classes.cardContainer} elevation={24}>
        <Box paddingLeft="26px" paddingTop="20px" paddingBottom="12px">
          <CardTitleText>Top 5 Experiences</CardTitleText>
        </Box>
        <Divider />
        <Box paddingLeft="10px">
          <List>{content}</List>
        </Box>
      </Paper>
    );
  };
