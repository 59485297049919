import gql from 'graphql-tag';

export const FETCH_TRAVELER_TYPES = gql`
  query {
    travelerTypesList {
      items {
        id
        travelerTypeName
      }
    }
  }
`;
