import React from 'react';
import { TableList } from '../../../shared/components/ui/tables/TableList';
import { ColumnType, ExperienceType } from '../../../shared/types';
import {
  ExperienceListMenuFilter,
  ExperienceFilterState,
} from './ExperienceListMenuFilter';
import { ExperienceListRow } from './ExperienceListRow';
import { TableFilters } from '../../../shared/components/ui/tables/components/TableFilters';

type ExperienceListTableProps = {
  page: number;
  totalCount: number;
  filterState: ExperienceFilterState;
  experiences: ExperienceType[];
  loading: boolean;
  selectedExperiences: string[];
  onResetFilter: (filterState: ExperienceFilterState) => void;
  onSelectedExperience: (checked: boolean, experienceId: string) => void;
  onChangeSearch: (searchText: string) => void;
  onApplyFilters: (filterState: ExperienceFilterState) => void;
  onChangePage: (page: number) => void;
  onChangeSort: (field: string, direction?: string) => void;
  onSelectionAll: (status: boolean) => void;
  onUpdateExperience: (data: Record<string, unknown>) => void;
  onDeactiveExperiences: () => void;
};

const COLUMNS: ColumnType[] = [
  {
    field: 'experienceName',
    headerName: 'EXPERIENCE NAME',
    sorteable: true,
  },
  {
    field: 'operatorsName',
    headerName: 'OPERATOR',
    sorteable: true,
  },
  {
    field: 'address',
    headerName: 'LOCATION',
    sorteable: true,
  },
  {
    field: 'isActive',
    headerName: 'STATUS',
    sorteable: true,
  },
  {
    field: 'price',
    headerName: 'PRICE',
    sorteable: false,
  },
];

/**
 * Table list.
 *
 * @param props - Props recieved.
 * @param {number} props.page - Number page.
 * @param {ExperienceFilterState} props.filterState - Filter state.
 * @param {number} props.totalCount - Total number o register.
 * @param {Function} props.onApplyFilters - Function to apply filters.
 * @param {Function} props.onChangeSearch - Function to change search text.
 * @param {Function} props.onChangePage - Function to change page.
 * @param {ExperienceType[]} props.experiences - Experience data.
 * @param {boolean} props.loading - Loading status.
 * @param {Function} props.onChangeSort - Function to change sort.
 * @param {string[]} props.selectedExperiences - Selected experiences id.
 * @param {Function} props.onSelectedExperience - Function to selected experience.
 * @param {Function} props.onSelectionAll - Function selection all elemenets.
 * @param {Function} props.onResetFilter - Function to reset filter.
 * @param {Function} props.onUpdateExperience - Function to update experience.
 * @param {Function} props.onDeactiveExperiences - Function to deactive multiple experiences.
 * @returns {JSX.Element} Experience table.
 */
export const ExperienceListTable: React.FC<ExperienceListTableProps> = ({
  page,
  filterState,
  totalCount,
  experiences,
  loading,
  selectedExperiences,
  onSelectionAll,
  onSelectedExperience,
  onApplyFilters,
  onChangeSearch,
  onChangePage,
  onChangeSort,
  onResetFilter,
  onUpdateExperience,
  onDeactiveExperiences
}) => {
  /**
   * @param {string} experienceId - Id of experience.
   * @returns {boolean} Check if experience is selected.
   */
  const isChecked = (experienceId: string): boolean => {
    const isSelected = selectedExperiences.some(
      (selectedExperiecenId) => selectedExperiecenId === experienceId,
    );

    return isSelected;
  };

  /**
   * @param {null | HTMLElement} anchorEl - Anchored element.
   * @param {Function} onClose - Function to close filter.
   *
   * @returns {JSX.Element} - Menu filter.
   */
  const renderMenuFilter = (
    anchorEl: HTMLElement | null,
    onClose: () => void,
  ): JSX.Element => (
    <ExperienceListMenuFilter
      anchorEl={anchorEl}
      filterState={filterState}
      onApplyFilters={onApplyFilters}
      onClose={onClose}
      onResetFilter={onResetFilter}
    />
  );

  /**
   * @returns {JSX.Element} Table filters.
   */
  const renderTableFilters = (): JSX.Element => (
    <TableFilters<ExperienceFilterState> filterState={filterState} onResetFilter={onResetFilter} />
  );

  let content: JSX.Element | JSX.Element[] = experiences.map((experience) => (
    <ExperienceListRow
      key={experience.id}
      experience={experience}
      checked={isChecked(experience.id)}
      onSelectedExperience={onSelectedExperience}
      hasSelecteds={!selectedExperiences.length}
      onUpdateExperience={onUpdateExperience}
      onDeactiveExperiences={onDeactiveExperiences}
    />
  ));

  if (!experiences.length) {
    content = (
      <tr>
        <td colSpan={7} style={{ textAlign: 'center', paddingTop: '8px' }}>
          No records to display
        </td>
      </tr>
    );
  }

  return (
    <>
      <TableList
        columns={COLUMNS}
        renderMenuFilter={renderMenuFilter}
        renderOptionalComponent={renderTableFilters}
        page={page}
        totalCount={totalCount}
        onChangeSearch={onChangeSearch}
        onChangePage={onChangePage}
        onChangeSort={onChangeSort}
        loading={loading}
        selection
        onSelectionAll={onSelectionAll}
        selectionCount={selectedExperiences.length}
      >
        {content}
      </TableList>
    </>
  );
};
