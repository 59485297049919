import { PeopleListDataType } from './people-model';

/**
 *
 * @param {PeopleListDataType} data - Data to filter.
 * @returns {Record<string, any>} - Filter data.
 */
export const createFilterPeopleList = (
  data: PeopleListDataType,
): Record<string, unknown> => {
  const archivedFilter = {
    archivedAt: { is_empty: !data.showArchivedUsers },
  };

  const statusFilter = {
    tripStatus: {
      equals: data.status,
    },
  };

  const textFilter = {
    OR: [
      {
        fullName: {
          contains: data.searchText,
        },
      },
      {
        email: {
          contains: data.searchText,
        },
      },
    ],
  };

  const AND = [];

  AND.push(archivedFilter);

  if (data.status) {
    AND.push(statusFilter);
  }

  if (data.searchText) {
    AND.push(textFilter);
  }

  return { AND };
};

interface PhoneNumberFormatter {
  numberValue: string | number,
  onMatcher: (isMatched: boolean) => void;
  onMatchValue: (formattedPhone: string) => void;
}

export const formatPhoneNumber = ({ numberValue, onMatcher, onMatchValue }: PhoneNumberFormatter): void => {
  const cleaned = `${numberValue}`.replace(/\D/g, '');
  const match = cleaned.match(/^(\d?)(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    const result = `(${match[2]}) ${match[3]} ${match[4]}`;
    onMatchValue(result);
    onMatcher(true);
  } else {
    onMatcher(false);
  }
};
