import { FETCH_USERS_COUNT } from './user-queries';
import { apolloClient } from '../../shared/apollo';

/**
 * @param {string} date - Date query param.
 * @returns {Promise<number>} - User count.
 */
export const fetchUserCountService = async (
  date: string
): Promise<number> => {
  const response = await apolloClient.query<{
    usersList: {
      count: number;
    }
  }>({
    query: FETCH_USERS_COUNT,
    variables: {
      date
    }
  });

  return response.data?.usersList.count;
};