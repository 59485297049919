import React, { useCallback, useState, useEffect, useContext } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles, styled } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { useDropzone } from 'react-dropzone';
import { useFetchAction } from '@cobuildlab/react-simple-state';
import uniqueString from 'unique-string';
import { UploadIcon } from '../../../../shared/icons/icons';
import { BLACK_TITLE } from '../../../../shared/components/ui/theme/contants';
import { DragDropItem, DragDropItemType, FileType } from './DragDropItem';
import { fetchFileUploadInfoAction } from '../../../file-upload/file-upload-actions';
import * as toast from '../../../../shared/components/ui/toasts/Toast';
import { ExperienceFormContext } from './ExperienceFormProvider';
import { InfoTypes } from '../../../../shared/components/ui/texts/Texts';

type DragDropProps = {
  onAddFiles: (uploadedFiles: FileType[]) => void;
  onDeleteUploadedFile: (id: string) => void;
  onCountUploadingFiles: (uploadingFilesCount: number) => void;
  description: string;
  accept: string;
  preSavedFiles: DragDropItemType[];
};

type RejectedFileType = {
  file: File;
};

const ListTitle = styled(Typography)({
  color: BLACK_TITLE,
});

const useStyles = makeStyles({
  dragDropContainer: {
    position: 'relative',
    height: '170px',
    border: '1px dashed #BBBBBB',
    backgroundColor: '#FAFAFA',
    cursor: 'pointer',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
  },
});

/**
 * @param props - Props received.
 * @param {Function} props.onAddFiles - Function to save file in state form.
 * @param {string} props.description - Description formats.
 * @param {string} props.accept - Accepted file.
 * @param {Function} props.onCountUploadingFiles - To know amount uploading files.
 * @param {DragDropItemType[]} props.preSavedFiles - Pre saved files.
 * @param {Function} props.onDeleteUploadedFile - Function to delete uploaded files.
 * @returns {JSX.Element} Drag and drop.
 */
export const DragDrop: React.FC<DragDropProps> = ({
  onAddFiles,
  onCountUploadingFiles,
  onDeleteUploadedFile,
  description,
  accept,
  preSavedFiles,
}) => {
  const classes = useStyles();
  const { loadingSubmit } = useContext(ExperienceFormContext);
  const [dragDropItems, setDragDropItems] =
    useState<DragDropItemType[]>(preSavedFiles);
  const [countUploading, setCountUploading] = useState<number>(0);
  const [fileUploadInfo, loading] = useFetchAction(
    fetchFileUploadInfoAction,
    [],
  );

  let imageList = null;
  let overlay = null;

  /**
   * @param {string} id - Id of dragDropItem.
   */
  const handleDelete = (id: string): void => {
    if (loadingSubmit) return;

    const selectedDragDrops = dragDropItems.filter(
      (dragDropItem) => dragDropItem.id !== id,
    );

    setDragDropItems(selectedDragDrops);
    onDeleteUploadedFile(id);
  };

  /**
   * @param {string} id - Id of drag drop.
   * @param {FileType} uploadedFile - File uploaded.
   */
  const handleAddUploadedFile = useCallback(
    (id: string, uploadedFile: FileType): void => {
      setDragDropItems((preveDragDrops) => {
        const selectedDragDrops = preveDragDrops.map((dragDropItem) => {
          if (dragDropItem.id === id) {
            const dragDropUploaded = { ...dragDropItem, uploadedFile };
            delete dragDropUploaded.fileToUpload;

            return dragDropUploaded;
          }

          return dragDropItem;
        });

        return selectedDragDrops;
      });
    },
    [],
  );

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    rejectedFiles.forEach((rejectedFile: RejectedFileType) => {
      toast.error(`Invalid file format ${rejectedFile.file.name}`);
    });

    acceptedFiles.forEach((file: File) => {
      const reader = new FileReader();
      /**
       * Event load filter.
       */
      reader.onload = () => {
        const dragDropItem = {
          id: uniqueString(),
          name: file.name,
          size: file.size,
          source: reader.result as string,
          fileToUpload: file,
        };

        setDragDropItems((prevDragDropItem) => [
          ...prevDragDropItem,
          dragDropItem,
        ]);
      };

      reader.readAsDataURL(file);
    });
  }, []);

  useEffect(() => {
    const dragDropUploads = dragDropItems.filter(
      ({ uploadedFile }) => uploadedFile,
    );
    const uploadedFiles = dragDropUploads.map(
      ({ uploadedFile }) => ({ ...uploadedFile } as FileType),
    );

    onAddFiles(uploadedFiles);
  }, [dragDropItems, onAddFiles]);

  useEffect(() => {
    const dragDropsUploading = dragDropItems.filter(
      (draDropItem) => draDropItem.fileToUpload,
    );

    setCountUploading(dragDropsUploading.length);
  }, [dragDropItems]);

  useEffect(() => {
    onCountUploadingFiles(countUploading);
  }, [countUploading, onCountUploadingFiles]);

  if (dragDropItems.length) {
    const imageItems = dragDropItems.map((draDropItem) => (
      <DragDropItem
        onAddUploadedFile={handleAddUploadedFile}
        onDelete={handleDelete}
        key={draDropItem.id}
        dragDropItem={draDropItem}
        fileUploadInfo={fileUploadInfo}
      />
    ));

    imageList = (
      <>
        <Box mt="30px" mb="20px">
          <ListTitle>Uploading {countUploading} files</ListTitle>
        </Box>
        {imageItems}
      </>
    );
  }

  if (loading) {
    overlay = <div className={classes.overlay} />;
  }

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept });

  return (
    <>
      <div {...getRootProps({ className: classes.dragDropContainer })}>
        <input {...getInputProps()} disabled={loadingSubmit} />
        <Box textAlign="center" mt="35px">
          <UploadIcon />
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          mt="13px"
          fontSize="16px"
          lineHeight="26px"
        >
          <Typography>Drag and drop images here, or</Typography>
          <Box ml="4px">
            <Typography color="primary">browse files</Typography>
          </Box>
        </Box>
        <Box textAlign="center" mt="6px">
          <InfoTypes>{description}</InfoTypes>
        </Box>
        {overlay}
      </div>
      {imageList}
    </>
  );
};
