import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import Pagination from '@material-ui/lab/Pagination';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import { TableHeadItem } from './components/TableHeadItem';
import { GRAY_250 } from '../theme/contants';
import { ColumnType, OrderType } from '../../../types';
import { Toolbar } from './components/Toolbar';

type TableListProps = {
  columns: ColumnType[];
  children: JSX.Element | JSX.Element[];
  selection?: boolean;
  selectionCount?: number;
  onSelectionAll?: (state: boolean) => void;
  disabledActions?: boolean;
  page: number;
  totalCount: number;
  loading: boolean;
  onChangeSearch: (searchText: string) => void;
  onChangePage: (page: number) => void;
  onChangeSort: (field: string, direction?: string) => void;
  renderOptionalComponent?: () => JSX.Element | null;
  renderMenuFilter?: (
    anchorEl: null | HTMLElement,
    handleCloseFilter: () => void,
  ) => JSX.Element;
};

const useStyles = makeStyles({
  tHead: {
    backgroundColor: GRAY_250,
  },
  paper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '8px',
    flexGrow: 1,
  },
  overlay: {
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
  },
});

/**
 * @param props - Received props.
 * @param {ColumnType[]} props.columns - Column data.
 * @param {boolean} props.selection - Show checkbox to select items.
 * @param {JSX.Element} props.children - Table.
 * @param {Function} props.renderMenuFilter - Function to render menu filter.
 * @param {boolean} props.disabledActions - Disabled cell actions.
 * @param {number} props.page - Current page.
 * @param {number} props.totalCount - Total count.
 * @param {Function} props.onChangePage - Function to change page.
 * @param {Function} props.onChangeSearch - Function to change search.
 * @param {boolean} props.loading - State loading.
 * @param {Function} props.onChangeSort - Function to change sort.
 * @param {Function} props.onSelectionAll - Function to select all elements.
 * @param {number} props.selectionCount - Amount of selected items.
 * @param {Function} props.renderOptionalComponent - Function to render optional component.
 * @returns {JSX.Element} - The table list.
 */
export const TableList: React.FC<TableListProps> = ({
  columns,
  children,
  selection,
  selectionCount,
  onSelectionAll,
  renderMenuFilter,
  disabledActions,
  page,
  totalCount,
  loading,
  onChangePage,
  onChangeSearch,
  onChangeSort,
  renderOptionalComponent,
}) => {
  const [orderBy, setOrderBy] = useState<null | string>(null);
  const [order, setOrder] = useState<OrderType>('asc');
  const classes = useStyles();

  /**
   * @param {string} field - Column for Ordering.
   */
  const handleSort = (field: string): void => {
    const isSelected = field === orderBy;
    const direction = isSelected && order === 'asc' ? 'desc' : 'asc';
    setOrder(direction as OrderType);

    /**
     * If it was previously selected and the sort order changed to asc, it is deselected.
     */
    if (isSelected && direction === 'asc') {
      setOrderBy(null);
      onChangeSort(field);
    } else {
      setOrderBy(field);
      onChangeSort(field, direction);
    }
  };

  /**
   * @param {React.ChangeEvent<any>} event - Event.
   * @param {number} value - Value of page.
   */
  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ): void => {
    onChangePage(value);
  };

  const headerColumns = columns.map((column) => (
    <TableHeadItem
      key={column.field}
      order={order}
      orderBy={orderBy}
      column={column}
      onSort={handleSort}
    />
  ));

  let columnCheckbox: JSX.Element | null = null;
  let columnAction: JSX.Element | null = null;
  let overlay: JSX.Element | null = null;

  if (loading) {
    overlay = (
      <div className={classes.overlay}>
        <CircularProgress />
      </div>
    );
  }

  if (selection) {
    columnCheckbox = <TableCell padding="checkbox" />;
  }

  if (!disabledActions) {
    columnAction = <TableCell>ACTIONS</TableCell>;
  }

  return (
    <Paper className={classes.paper} elevation={24}>
      <TableContainer>
        <Toolbar
          renderMenuFilter={renderMenuFilter}
          selection={selection}
          selectionCount={selectionCount}
          onSelectionAll={onSelectionAll}
          onChangeSearch={onChangeSearch}
          renderOptionalComponent={renderOptionalComponent}
        />
        <Table>
          <TableHead className={classes.tHead}>
            <TableRow>
              {columnCheckbox}
              {headerColumns}
              {columnAction}
            </TableRow>
          </TableHead>
          <TableBody>{children}</TableBody>
        </Table>
      </TableContainer>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="flex-end"
        flexGrow={1}
        pb={3}
        mt={2}
      >
        <Pagination
          color="primary"
          count={totalCount}
          page={page}
          onChange={handleChangePage}
          showFirstButton
          showLastButton
        />
      </Box>
      {overlay}
    </Paper>
  );
};
