import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/Close';
import { isValidDate } from '@cobuildlab/validation-utils';
import moment from 'moment';

type TableFiltersTypes<T> = {
  filterState: T;
  onResetFilter: (filterState: T) => void;
};

const useStyles = makeStyles({
  pillFilter: {
    display: 'flex',
    alignItems: 'center',
    height: '30px',
    marginRight: '10px',
    marginBottom: '10px',
    paddingLeft: '6px',
    paddingRight: '6px',
    borderRadius: '4px',
    width: 'fit-content',
    background: '#BDBDBD',
    color: '#FFFFFF',
    fontSize: '12px',
    fontWeight: 500,
  },
  buttonIcon: {
    marginLeft: '2px',
    color: '#FFFFFF',
    cursor: 'pointer',
  },
});

/**
 * @param props - Props received.
 * @param {Record} props.filterState - Filter state.
 * @param {Function} props.onResetFilter - Function to reset filter.
 * @returns {JSX.Element | null} Table filters.
 */
export const TableFilters = <T extends Record<string, unknown>>({
  filterState,
  onResetFilter,
}: TableFiltersTypes<T>): JSX.Element | null => {
  const classes = useStyles();
  const keys = Object.keys(filterState as T).filter(
    (key) => !!filterState[key],
  );
  let pills: null | JSX.Element = null;

  /**
   * @param {string} value - Value of filter.
   * @returns {string} Format of value.
   */
  const getDate = (value: string | boolean): string => {
    if (typeof value === 'boolean') {
      return value.toString();
    }

    if (isValidDate(value)) {
      return moment(value, 'YYYY/MM/DD').format('MM/DD/YYYY');
    }

    return value;
  };

  /**
   * @param {string} key - Key of property.
   */
  const handleRemoveFilter = (key: string): void => {
    const filters: T = { ...filterState, [key]: '' };
    onResetFilter(filters);
  };

  if (keys.length) {
    const list = keys.map((key) => (
      <div className={classes.pillFilter} key={key}>
        <span>
          {key}: {getDate(filterState[key] as string)}
        </span>
        <Box
          fontSize="19px"
          pt="4px"
          className={classes.buttonIcon}
          onClick={() => handleRemoveFilter(key)}
        >
          <CloseIcon fontSize="inherit" />
        </Box>
      </div>
    ));

    pills = (
      <Box display="flex" ml="19px" mb="20px" color="#FFF" flexWrap="wrap">
        {list}
      </Box>
    );
  }

  return pills;
};
