import { FETCH_ITINERARY_KPI } from './itinerary-kpi-queries';
import { apolloClient } from '../../shared/apollo';
import { ItineraryKpiType } from '../../shared/types';

/**
 *
 * Itineraries kpi.
 *
 * @param {string} startDateTime  - Start of year.
 * @param {string} endDateTime - End of year.
 *
 * @returns {Promise<ItineraryKpiType>} - Itinerary kpi.
 */
export const fetchItineraryKpiService = async (
  startDateTime: string,
  endDateTime: string,
): Promise<ItineraryKpiType> => {
  const response = await apolloClient.query<{
    itineraryKpisList: {
      items: Array<ItineraryKpiType>;
    };
  }>({
    query: FETCH_ITINERARY_KPI,
    fetchPolicy: 'network-only',
    variables: {
      startDateTime,
      endDateTime,
    },
  });

  return response.data?.itineraryKpisList?.items[0];
};
