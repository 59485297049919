import React, { ReactNode } from 'react';
import Button, { ButtonProps } from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, styled } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { GRAY_214 } from '../theme/contants';

interface CustomButtonsPros extends ButtonProps {
  isLoading?: boolean | undefined;
  to?: string;
}

const useStyles = makeStyles({
  linkCancel: {
    color: GRAY_214,
  },
  button: {
    height: '45px',
    fontSize: '14px',
  },
  buttonLink: {
    padding: 0,
    fontSize: '14px',
  },
});

const BtnLink = styled(Link)({
  padding: '14px 35px',
  color: '#fff',
  textDecoration: 'none',
});

/**
 *
 * @returns {JSX.Element} Loading component.
 */
const Loading: React.FC = () => (
  <Box marginRight={2} display="flex" alignItems="center">
    <CircularProgress size={18} />
  </Box>
);

/**
 * @param props - Props received.
 * @param {ReactNode} props.children - React Node.
 * @param {string} props.size - Button size.
 * @param {boolean} props.isLoading - Loading status.
 * @param {boolean} props.disabled - If disabled.
 *
 * @returns {JSX.Element} - Button component.
 */
export const ButtonDefault: React.FC<CustomButtonsPros> = ({
  children,
  size = 'large',
  isLoading,
  disabled,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Button
      {...rest}
      disabled={isLoading || disabled}
      disableElevation
      variant="contained"
      size={size}
      className={classes.button}
    >
      {isLoading && <Loading />}
      {children}
    </Button>
  );
};

/**
 * @param props - Props received.
 * @param {ReactNode} props.children - React Node.
 * @param {string} props.size - Button size.
 * @param {boolean} props.isLoading - Loading status.
 * @param {boolean} props.disabled - If disabled.
 * @param {boolean} props.variant - Button variant.
 * @param {boolean} props.color - Button color.
 * @returns {JSX.Element} - Button component.
 */
export const ButtonPrimary: React.FC<CustomButtonsPros> = ({
  children,
  isLoading,
  disabled,
  size = 'large',
  variant = 'contained',
  color = 'primary',
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Button
      {...rest}
      disabled={isLoading || disabled}
      disableElevation
      className={classes.button}
      variant={variant}
      color={color}
      size={size}
    >
      {isLoading && <Loading />}
      {children}
    </Button>
  );
};

/**
 * @param props - Props received.
 * @param {ReactNode} props.children - React Node.
 * @param {string} props.size - Button size.
 * @param {boolean} props.disabled - If disabled.
 * @param {string} props.to - Route to go.
 * @returns {JSX.Element} - Button component.
 */
export const ButtonPrimaryLink: React.FC<CustomButtonsPros> = ({
  children,
  size = 'large',
  to,
  disabled,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Button
      {...rest}
      disableElevation
      variant="contained"
      color="primary"
      size={size}
      className={classes.buttonLink}
    >
      <BtnLink to={to as string}>{children}</BtnLink>
    </Button>
  );
};

/**
 * @param props - Props received.
 * @param {ReactNode} props.children - React Node.
 * @param {string} props.size - Button size.
 * @param {boolean} props.isLoading - Loading status.
 * @param {boolean} props.disabled - If disabled.
 *
 * @returns {JSX.Element} - Button component.
 */
export const ButtonOutlinedPrimary: React.FC<CustomButtonsPros> = ({
  children,
  size = 'large',
  isLoading,
  disabled,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Button
      {...rest}
      disabled={isLoading || disabled}
      disableElevation
      variant="outlined"
      color="primary"
      size={size}
      className={classes.button}
    >
      {isLoading && <Loading />}
      {children}
    </Button>
  );
};

/**
 * @param props - Props received.
 * @param {ReactNode} props.children - React Node.
 * @param {string} props.size - Button size.
 * @param {boolean} props.isLoading - Loading status.
 * @param {boolean} props.disabled - If disabled.
 *
 * @returns {JSX.Element} - Button component.
 */
export const ButtonLink: React.FC<CustomButtonsPros> = ({
  children,
  size = 'large',
  isLoading,
  disabled,
  ...rest
}) => (
  <Button
    {...rest}
    disabled={isLoading || disabled}
    color="primary"
    size={size}
  >
    {isLoading && <Loading />}
    {children}
  </Button>
);

/**
 * @param props - Props received.
 * @param {ReactNode} props.children - React Node.
 * @param {string} props.size - Button size.
 * @param {boolean} props.isLoading - Loading status.
 * @param {boolean} props.disabled - If disabled.
 *
 * @returns {JSX.Element} - Button component.
 */
export const ButtonLinkDefault: React.FC<CustomButtonsPros> = ({
  children,
  size = 'large',
  isLoading,
  disabled,
  ...rest
}) => (
  <Button {...rest} disabled={isLoading || disabled} size={size}>
    {isLoading && <Loading />}
    {children}
  </Button>
);

/**
 * @param props - Props received.
 * @param {ReactNode} props.children - React Node.
 * @param {string} props.size - Button size.
 * @param {boolean} props.isLoading - Loading status.
 * @param {boolean} props.disabled - If disabled.
 *
 * @returns {JSX.Element} - Button component.
 */
export const ButtonLinkCancel: React.FC<CustomButtonsPros> = ({
  children,
  size = 'large',
  isLoading,
  disabled,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Button
      className={classes.linkCancel}
      {...rest}
      disabled={isLoading || disabled}
      size={size}
    >
      {isLoading && <Loading />}
      {children}
    </Button>
  );
};

/**
 * @param props - Props received.
 * @param {ReactNode} props.children - React Node.
 * @param {string} props.size - Button size.
 * @param {boolean} props.isLoading - Loading status.
 * @param {boolean} props.disabled - If disabled.
 *
 * @returns {JSX.Element} - Button component.
 */
export const ButtonDanger: React.FC<CustomButtonsPros> = ({
  children,
  size = 'large',
  isLoading,
  disabled,
  ...rest
}) => (
  <Button
    {...rest}
    disabled={isLoading || disabled}
    color="secondary"
    variant="contained"
    size={size}
  >
    {isLoading && <Loading />}
    {children}
  </Button>
);

/**
 * @param props - Props received.
 * @param {ReactNode} props.children - React Node.
 * @param {string} props.size - Button size.
 * @param {boolean} props.isLoading - Loading status.
 * @param {boolean} props.disabled - If disabled.
 *
 * @returns {JSX.Element} - Button component.
 */
export const ButtonLinkDanger: React.FC<CustomButtonsPros> = ({
  children,
  size = 'large',
  isLoading,
  disabled,
  ...rest
}) => (
  <Button
    {...rest}
    disabled={isLoading || disabled}
    size={size}
    color="secondary"
  >
    {isLoading && <Loading />}
    {children}
  </Button>
);
