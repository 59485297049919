import React, { useState, useCallback } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { debounce } from '../../../../utils';

type TableSearchProps = {
  onChangeSearch: (searchText: string) => void;
};

const useStyles = makeStyles({
  iconContainer: {
    paddingTop: '8px',
    fontSize: '20px',
    cursor: 'pointer',
  },
});

/**
 *
 * @param props - Props received.
 * @param {Function} props.onChangeSearch - Function to change search text.
 * @returns {JSX.Elmement} Search table.
 */
export const TableSearch: React.FC<TableSearchProps> = ({ onChangeSearch }) => {
  const classes = useStyles();
  const [showSearch, setShowSearch] = useState(false);
  const [searchText, setSearchText] = useState('');

  // eslint-disable-next-line
  const debouncedChangeHandler = useCallback(
    debounce(onChangeSearch, 1000),
    [],
  );

  /**
   *  Function to show search.
   */
  const handleToggleSearch = (): void => {
    if (searchText) return;

    setShowSearch(!showSearch);
  };

  /**
   * @param {React.ChangeEvent<HTMLInputElement>} e - Event.
   */
  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchText(e.target.value);
    debouncedChangeHandler(e.target.value);
  };

  let search = (
    <Box className={classes.iconContainer} onClick={handleToggleSearch}>
      <SearchIcon fontSize="inherit" />
    </Box>
  );

  if (showSearch) {
    search = (
      <OutlinedInput
        autoFocus
        onBlur={handleToggleSearch}
        onChange={handleChangeSearch}
        margin="dense"
        value={searchText}
        placeholder="Search"
        endAdornment={
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        }
      />
    );
  }

  return search;
};
