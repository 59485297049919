import { createAction } from '@cobuildlab/react-simple-state';
import { apolloClient } from '../../shared/apollo';
import { FETCH_FILE_UPLOAD_INFO } from './file-upload-queries';
import { OnFileUploadInfo, OnFileUploadInfoError } from './file-upload-events';

export const fetchFileUploadInfoAction = createAction(
  OnFileUploadInfo,
  OnFileUploadInfoError,
  async () => {
    const response = await apolloClient.query({
      query: FETCH_FILE_UPLOAD_INFO,
      fetchPolicy: 'cache-first',
    });

    return response.data.fileUploadInfo;
  },
);
