import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { ProtectedRoute } from './ProtectedRoute';
import { Auth } from '../modules/auth/Auth';
import { AuthCallback } from '../modules/auth/auth-callback/AuthCallback';
import { Layout } from '../shared/components/Layout';
import { Dashboard } from '../modules/dashboard/DashboradView';
import { Inbox } from '../modules/inbox/InboxView';
import { ExperienceList } from '../modules/experience/ExperienceListView';
import { ExperienceAdd } from '../modules/experience/ExperienceAddView';
import { ExperienceDetail } from '../modules/experience/ExperienceDetailView';
import { Logout } from '../modules/auth/Logout';
import { UserInfo } from '../modules/people/UserInfo';
import { Account } from '../modules/user/AccountView';
import { Session } from '../modules/session/Session';
import { PeopleListView } from '../modules/people/PeopleListView';
import { BookingListView } from '../modules/booking/BookingListView';

/**
 * @returns Routes.
 */
export const Routes: React.FC = () => (
  <>
    <Switch>
      <Route exact path="/auth" component={Auth} />
      <Route exact path="/auth/callback" component={AuthCallback} />
      <Route path="/logout" component={Logout} />
      <ProtectedRoute path="/">
        <Session>
          <Route path="/">
            <Layout>
              <Route exact path="/bookings/:id?" component={BookingListView} />
              <Route exact path="/people" component={PeopleListView} />
              <Route exact path="/people/:id/detail" component={UserInfo} />
              <Route path="/" component={Dashboard} exact />
              <Route path="/inbox" component={Inbox} exact />
              <Route path="/inbox/chat/:id" component={Inbox} exact />
              <Route path="/experiences" component={ExperienceList} exact />
              <Route
                path="/experiences/add-experience"
                component={ExperienceAdd}
                exact
              />
              <Route
                path="/experiences/:id/detail"
                component={ExperienceDetail}
                exact
              />
              <Route path="/my-account/information" component={Account} exact />
            </Layout>
          </Route>
        </Session>
      </ProtectedRoute>
    </Switch>
  </>
);
