import {
  ExperienceType,
  RelationManyType,
  ExperienceScheduleType,
} from '../../shared/types';
import { PriceItemType } from './components/experience-form/ExperiencePricing';
import {
  ExperienceCategoryConnectType,
  ExperienceSubCategoryConnectType,
  ExperiencePricingRelation,
  ExperienceOpeningTimeRelation,
  ExperienceListDataType,
} from './experience-model';

/**
 * @param {ExperienceType} experience - Experience entity.
 * @param {string[]} selectedCategories - Ids of categories selecteds.
 *
 * @returns {RelationManyType} Update category relation data.
 */
export const createExperienceCategoryFormat = (
  experience: ExperienceType,
  selectedCategories: string[],
): RelationManyType<ExperienceCategoryConnectType> => {
  const {
    experienceExperienceCategoryRelation: { items },
  } = experience;

  const categoriesMapping = items?.length ? items : [];

  const categoriesToCreate = selectedCategories.filter((categoryId) => {
    const isNotSaved = categoriesMapping.every(
      ({ experienceCategory }) => categoryId !== experienceCategory.id,
    );

    return isNotSaved;
  });

  const categoriesToDisconnect = categoriesMapping.filter(
    ({ experienceCategory }) => {
      const isDisconnect = selectedCategories.every(
        (categoryId) => experienceCategory.id !== categoryId,
      );

      return isDisconnect;
    },
  );

  const relation = {
    create: categoriesToCreate.map((categoryId) => ({
      experienceCategory: {
        connect: {
          id: categoryId,
        },
      },
    })),
    disconnect: categoriesToDisconnect.map(({ id }) => ({ id })),
  };

  return relation;
};

/**
 * @param {ExperienceType} experience - Experience entity.
 * @param {string[] | undefined} subCategoriesInput - Ids of categories selecteds.
 *
 * @returns {RelationManyType} Update sub categories relation data.
 */
export const createSubCategoryFormat = (
  experience: ExperienceType,
  subCategoriesInput: string[] | undefined,
): RelationManyType<ExperienceSubCategoryConnectType> => {
  const {
    experienceExperienceSubCategoryRelation: { items },
  } = experience;

  const subCategoriesMapping = items?.length ? items : [];
  const selectedSubCategories = subCategoriesInput?.length
    ? subCategoriesInput
    : [];

  const subCategoriesToCreate = selectedSubCategories.filter(
    (subCategoryId) => {
      const isNotSaved = subCategoriesMapping.every(
        ({ experienceSubCategory }) =>
          subCategoryId !== experienceSubCategory.id,
      );

      return isNotSaved;
    },
  );

  const subCategoriesToDisconnect = subCategoriesMapping.filter(
    ({ experienceSubCategory }) => {
      const isDisconnect = selectedSubCategories.every(
        (subCategoryId) => experienceSubCategory.id !== subCategoryId,
      );

      return isDisconnect;
    },
  );

  const relation = {
    create: subCategoriesToCreate.map((subCategoryId) => ({
      experienceSubCategory: {
        connect: {
          id: subCategoryId,
        },
      },
    })),
    disconnect: subCategoriesToDisconnect.map(({ id }) => ({ id })),
  };

  return relation;
};

/**
 * @param {ExperienceType} experience - Experience entity.
 * @param {PriceItemType[]} pricings - Pricings data.
 * @returns {ExperiencePricingRelation} Update pricing relation data.
 */
export const createPricingFormat = (
  experience: ExperienceType,
  pricings: PriceItemType[],
): ExperiencePricingRelation => {
  const {
    experienceExperiencePricingRelation: { items },
  } = experience;

  const experiencePricings = items?.length ? items : [];

  const pricingsToUpdate = pricings.filter((pricing) => {
    const isSelectedPricing = experiencePricings.find(
      (experiencePricing) => pricing.id === experiencePricing.id,
    );

    return !!isSelectedPricing;
  });

  /* const pricingsToDisconnect = experiencePricings.filter(
    (experiencePrincing) => {
      const isDisconnect = pricings.every(
        (pricing) => experiencePrincing.id !== pricing.id,
      );

      return isDisconnect;
    },
  ); */

  const pricingToCreate = pricings.filter((pricing) => {
    const isNotSaved = experiencePricings.every(
      (expericePricing) => pricing.id !== expericePricing.id,
    );

    return isNotSaved;
  });

  const relation = {
    create: pricingToCreate.map((pricing) => {
      let travelerType;

      if (pricing.travelerTypeId) {
        travelerType = {
          connect: {
            id: pricing.travelerTypeId,
          },
        };
      }

      return {
        startTime: pricing.startDate,
        endTime: pricing.endDate,
        pricingName: pricing.name,
        feeAmount: parseFloat(pricing.value as string),
        travelerType,
      };
    }),
    update: pricingsToUpdate.map((pricing) => {
      let travelerType;

      if (pricing.travelerTypeId) {
        travelerType = {
          connect: {
            id: pricing.travelerTypeId,
          },
        };
      }

      const data = {
        id: pricing.id,
        startTime: pricing.startDate,
        endTime: pricing.endDate,
        pricingName: pricing.name,
        feeAmount: parseFloat(pricing.value as string),
        travelerType,
        isActive: pricing.isActive,
      };

      return {
        data,
      };
    }),
    /* disconnect: pricingsToDisconnect.map((pricing) => ({
      id: pricing.id as string,
    })), */
  };

  return relation;
};

/**
 * @param {ExperienceType} experience - Experience entity.
 * @param {ExperienceScheduleType []} openingTimes - Opening times data.
 *
 * @returns {ExperienceOpeningTimeRelation} Opening time relation data.
 */
export const createOpeningTimeFormat = (
  experience: ExperienceType,
  openingTimes: ExperienceScheduleType[],
): ExperienceOpeningTimeRelation => {
  const {
    experienceExperienceScheduleRelation: { items },
  } = experience;

  const schedules = items?.length ? items : [];

  const openingTimesToUpdate = openingTimes.filter((openingTime) => {
    const isSelected = schedules.find(
      (schedule) => openingTime.id === schedule.id,
    );

    return !!isSelected;
  });

  const openingTimesToDisconnect = schedules.filter((schedule) => {
    const isDisconnect = openingTimes.every(
      (openingTime) => schedule.id !== openingTime.id,
    );

    return isDisconnect;
  });

  const openingTimesToCreate = openingTimes.filter((openingTime) => {
    const isNotSaved = schedules.every(
      (schedule) => openingTime.id !== schedule.id,
    );

    return isNotSaved;
  });

  const relation = {
    create: openingTimesToCreate.map((openingTime) => ({
      day: openingTime.day,
      startHour: openingTime.startHour,
      endHour: openingTime.endHour,
    })),
    update: openingTimesToUpdate.map((openingTime) => ({
      data: {
        id: openingTime.id,
        day: openingTime.day,
        startHour: openingTime.startHour,
        endHour: openingTime.endHour,
        isActive: openingTime.isActive,
      },
    })),
    disconnect: openingTimesToDisconnect.map((openingTime) => ({
      id: openingTime.id as string,
    })),
  };

  return relation;
};

/**
 *
 * @param {ExperienceListDataType} data - Data to filter.
 * @returns {Record<string, any>} - Filter data.
 */
export const createFilterExperienceList = (
  data: ExperienceListDataType,
): Record<string, unknown> => {
  let filter: Record<string, unknown> = {};

  if (data?.status) {
    const isActive = data.status === 'active';

    filter = {
      isActive: {
        equals: isActive,
      },
    };
  }

  if (data?.searchText) {
    filter = {
      ...filter,
      experienceName: {
        contains: data.searchText,
      },
    };
  }

  if (data.startDate || data.endDate) {
    const AND = [];

    if (data.startDate) {
      AND.push({
        createdAt: {
          gte: data.startDate,
        },
      });
    }

    if (data.endDate) {
      AND.push({
        createdAt: {
          lte: data.endDate,
        },
      });
    }

    filter = {
      ...filter,
      AND,
    };
  }

  return filter;
};
