import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import { useCallAction } from '@cobuildlab/react-simple-state';
import { useForm, FormProvider } from 'react-hook-form';
import { PeopleListHeader } from './components/PeopleListHeader';
import { PeopleListTable } from './components/PeopleListTable';
import { fetchUsersAction } from './people-actions';
import { UserType, RelationType } from '../../shared/types';
import { usePagination } from '../../shared/hooks/usePagination';
import {
  PeopleFilterState,
  INITIAL_FILTER_STATE,
} from './components/PeopleListMenuFilter';
import { useWebpageTitle } from '../../shared/hooks/useWebpageTitle';

/**
 *  People List.
 *
 * @returns {JSX.Element} People table.
 */
export const PeopleListView: React.FC = () => {
  const [totalCount, setTotalCount] = useState<number>(0);
  const [people, setPeople] = useState<UserType[]>([]);
  const [paginationData, setPagination] = usePagination<PeopleFilterState>({
    ...INITIAL_FILTER_STATE,
  });
  const methods = useForm();

  const [fetchPeople, loading] = useCallAction(fetchUsersAction, {
    /**
     * @param {RelationType<UserType>} data  - People data.
     */
    onCompleted: (data: RelationType<UserType>) => {
      const { count, items } = data;
      /**
       * Elements per page.
       */
      const first = 10;
      const totalPages = Math.ceil((count as number) / first);

      setTotalCount(totalPages);
      setPeople(items as UserType[]);
    },
  });

  useEffect(() => {
    const { searchText, filterState, page, sort } = paginationData;
    const queryParams = { searchText, ...filterState };

    fetchPeople(queryParams, page, sort);
  }, [paginationData, fetchPeople]);

  /**
   * @param {PeopleFilterState} defaultValues - Default state.
   */
  const handleResetFilter = (defaultValues: PeopleFilterState): void => {
    setPagination({
      page: 1,
      filterState: {
        ...defaultValues,
      },
    });

    methods.reset();
  };

  /**
   * @param {PeopleFilterState} filterState - People filter state.
   */
  const handleApplyFilters = (filterState: PeopleFilterState): void => {
    setPagination({
      page: 1,
      filterState: {
        ...filterState,
      },
    });
  };

  /**
   * @param {string} searchText - Search value.
   */
  const handleChangeSearch = (searchText: string): void => {
    setPagination({
      page: 1,
      searchText,
    });
  };

  /**
   * @param {number} newPage - New page pagination.
   */
  const handleChangePage = (newPage: number): void => {
    setPagination({
      page: newPage,
    });
  };

  /**
   * @param {string} field - Field name.
   * @param {string} direction - Order sort.
   */
  const handleChangeSort = (field: string, direction?: string): void => {
    if (direction) {
      setPagination({
        page: 1,
        sort: {
          [field]: direction.toUpperCase(),
        },
      });
    } else {
      setPagination({
        page: 1,
        sort: {},
      });
    }
  };

  useWebpageTitle('Candid Travel Ventures - People');

  return (
    <>
      <PeopleListHeader />
      <Box mt={4} flexGrow={1} display="flex">
        <FormProvider {...methods}>
          <PeopleListTable
            page={paginationData.page as number}
            filterState={paginationData.filterState as PeopleFilterState}
            onApplyFilters={handleApplyFilters}
            onChangeSearch={handleChangeSearch}
            onChangePage={handleChangePage}
            totalCount={totalCount}
            people={people}
            onChangeSort={handleChangeSort}
            loading={loading}
            onResetFilter={handleResetFilter}
          />
        </FormProvider>
      </Box>
    </>
  );
};
