import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { useFetchAction } from '@cobuildlab/react-simple-state';
import { DashboardChart } from './DashboardChart';
import { fetchUserCountActions as fetchUserCount } from '../../user/user-actions';
import { ChartOptionValueType } from './DashboardChartDropdown';

type DashboardUserChartProps = {
  loadingDashboard: boolean;
};

const dates = [
  moment().subtract(365, 'd').format(),
  moment().subtract(240, 'd').format(),
  moment().subtract(120, 'd').format(),
  moment().subtract(90, 'd').format(),
  moment().subtract(60, 'd').format(),
  moment().subtract(45, 'd').format(),
  moment().subtract(30, 'd').format(),
  moment().format(),
];

/**
 * Position is the position in array of dates above.
 */
const options = [
  {
    label: '30 days',
    value: {
      amountDays: 30,
      position: 6,
    },
  },
  {
    label: '45 days',
    value: {
      amountDays: 45,
      position: 5,
    },
  },
  {
    label: '60 days',
    value: {
      amountDays: 60,
      position: 4,
    },
  },
  {
    label: '90 days',
    value: {
      amountDays: 90,
      position: 3,
    },
  },
  {
    label: '120 days',
    value: {
      amountDays: 120,
      position: 2,
    },
  },
  {
    label: '240 days',
    value: {
      amountDays: 240,
      position: 1,
    },
  },
  {
    label: '365 days',
    value: {
      amountDays: 365,
      position: 0,
    },
  },
];

/**
 * @param props - Props received.
 * @param props.loadingDashboard - Loading Dashboard.
 *
 * @returns {JSX.Element} Dashboard chart.
 */
export const DashboardUserChart: React.FC<DashboardUserChartProps> = ({
  loadingDashboard,
}) => {
  const [totalUsers, setTotalUsers] = useState(0);
  const [xValues, setXValues] = useState<string[]>([]);
  const [yValues, setYValues] = useState<number[]>([]);
  const [usersCount, loading] = useFetchAction(fetchUserCount, [dates], {
    skip: loadingDashboard,
  });

  const handleSaveXValues = useCallback(
    (daysDiff: number, totalValues: number) => {
      const initMonth = moment()
        .subtract(daysDiff, 'd')
        .startOf('month')
        .format('MMM');
      const initDate = moment().subtract(daysDiff, 'd').date();

      /**
       * Only the first and last value of the axis will be shown.
       */
      const labels = Array(totalValues).fill(' ');
      labels[0] = `${initMonth} ${initDate}`;
      labels[totalValues - 1] = 'Today';

      setXValues(labels);
    },
    [],
  );

  /**
   * @param {ChartOptionValueType} value - Selected option value.
   */
  const handleSelectedOption = (value: ChartOptionValueType): void => {
    const selectedCounts = usersCount.slice(value.position, usersCount.length);
    setYValues(selectedCounts.map((userCount) => userCount));
    handleSaveXValues(value.amountDays, selectedCounts.length);
  };

  useEffect(() => {
    if (usersCount && !yValues.length) {
      /**
       * By default selects the quantities up to the last 90 days.
       */
      const selectedCounts = usersCount.slice(3, usersCount.length);
      setYValues(selectedCounts.map((userCount) => userCount));

      /**
       * Select the number of current users.
       */
      const currentUsersCount = usersCount[usersCount.length - 1];
      setTotalUsers(currentUsersCount);
    }
  }, [usersCount, yValues.length]);

  useEffect(() => {
    if (!xValues.length && yValues.length) {
      handleSaveXValues(90, yValues.length);
    }
  }, [xValues.length, yValues.length, handleSaveXValues]);

  let isLoading = loading || loadingDashboard;

  if (!usersCount) {
    isLoading = true;
  }

  return (
    <DashboardChart
      title="User Growth"
      xValues={xValues}
      yValues={yValues}
      count={totalUsers}
      loading={isLoading}
      menuOptions={options}
      onSelectedOption={handleSelectedOption}
    />
  );
};
