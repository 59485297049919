import React from 'react';
import { Avatar, Box, Grid, Typography } from '@material-ui/core';
import { ItineraryType, MessageType } from '../../../shared/types';

export interface InboxChatHeaderProps {
  chatHeader: ItineraryType[];
  messages: MessageType[];
  formatDate: (value: string) => string;
}

/**
 * @param {*} data - All data.
 * @returns {JSX.Element} - Returns chat header.
 */
export const InboxChatHeader: React.FC<InboxChatHeaderProps> = ({
  chatHeader,
  messages,
  formatDate,
}) => (
  <Box
    mt={3}
    borderRadius={5}
    style={{ boxShadow: '0px 0px 15px #e0e0e0' }}
    paddingY={2}
    paddingX={2}
  >
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid item xs={5} md={6}>
        {chatHeader[0]?.user?.fullName ? (
          <Typography style={{ fontWeight: 'bold' }}>
            Chat with{' '}
            <span style={{ color: '#00CC99' }}>
              {chatHeader[0]?.user?.fullName}
            </span>
          </Typography>
        ) : (
          'No customer name'
        )}
        <Typography
          style={{ fontSize: '12px', color: 'grey', fontWeight: 500 }}
        >
          {messages ? formatDate('createdAt') : 'No date'}
        </Typography>
      </Grid>
      <Grid item xs={5} md={5}>
        <Grid container>
          <Grid item xs={12} md={9}>
            <Typography style={{ fontWeight: 500 }} noWrap>
              {chatHeader[0]?.itineraryName || 'No trip name'}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography
              style={{
                fontSize: '12px',
                color: 'grey',
                fontWeight: 500,
              }}
            >
              {chatHeader ? formatDate('startDateTime') : 'No date'}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography
              style={{
                fontSize: '11px',
                color: 'grey',
                fontWeight: 500,
              }}
            >
              {chatHeader[0]?.itineraryCityName || 'No city name'}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={1}>
        <Grid container direction="row" justifyContent="flex-end">
          <Avatar
            alt="Travel Place"
            variant="rounded"
            src={
              chatHeader[0]?.itineraryBookingRelation?.items[0]?.experience
                ?.experienceExperienceMediaRelation?.items[0]?.file?.downloadUrl
            }
            style={{ width: '60px', height: '60px' }}
          />
        </Grid>
      </Grid>
    </Grid>
  </Box>
);
