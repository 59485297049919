import React, { useState, useContext, useEffect } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useCallAction } from '@cobuildlab/react-simple-state';
import { useFormContext, Controller } from 'react-hook-form';
import {
  fetchStateByNameAction,
  createStateAction,
} from '../../../state/state-actions';
import { StateType } from '../../../../shared/types';
import { ExperienceFormContext } from './ExperienceFormProvider';
import * as toast from '../../../../shared/components/ui/toasts/Toast';

/**
 * @returns {JSX.Element} States list.
 */
export const ExperienceStateSelect: React.FC = () => {
  const { control, setValue, clearErrors } = useFormContext();
  const { experience, stateName, setLoadingUpload } = useContext(
    ExperienceFormContext,
  );
  const [loading, setLoading] = useState(false);
  const [states, setStates] = useState<StateType[]>([]);

  const [createState] = useCallAction(createStateAction, {
    /**
     * @param {StateType} stateCreate - State create.
     */
    onCompleted: (stateCreate: StateType) => {
      setStates([stateCreate]);
      setValue('state', stateCreate.id);
      setLoading(false);
      clearErrors('state');
    },

    /**
     * @param {TypeError} e - Error event.
     */
    onError: (e) => {
      toast.error(e.message);
      setValue('state', '');
      setLoadingUpload(false);
      setLoading(false);
      clearErrors('state');
    },
  });

  const [fetchStatesByName] = useCallAction(fetchStateByNameAction, {
    /**
     * @param {StateType[]} stateData - List of states.
     */
    onCompleted: (stateData: StateType[]) => {
      if (stateData.length) {
        setStates(stateData);
        setValue('state', stateData[0].id);
        setLoadingUpload(false);
        setLoading(false);
        clearErrors('state');
      } else {
        createState({
          stateName,
        });
      }
    },

    /**
     * @param {TypeError} e - Error event.
     */
    onError: (e) => {
      toast.error(e.message);
      setValue('state', '');
      setLoadingUpload(false);
      setLoading(false);
      clearErrors('state');
    },
  });

  useEffect(() => {
    if (stateName) {
      fetchStatesByName(stateName);
      setLoading(true);
      setLoadingUpload(true);
    }
  }, [fetchStatesByName, setLoadingUpload, stateName]);

  const list: JSX.Element | JSX.Element[] = states.map((state) => (
    <MenuItem value={state.id} key={state.id}>
      {state.stateName}
    </MenuItem>
  ));

  let content = (
    <Controller
      name="state"
      control={control}
      defaultValue={experience?.state?.id || ''}
      render={({ field }) => (
        <Select
          {...field}
          disabled
          labelId="state-label"
          id="state"
          label="State"
        >
          {list}
        </Select>
      )}
    />
  );

  if (loading) {
    content = (
      <Controller
        name="state"
        control={control}
        render={({ field }) => (
          <Select
            {...field}
            value="0"
            disabled
            labelId="state-label"
            id="state"
            label="State"
          >
            <MenuItem value="0" key="0">
              Loading...
            </MenuItem>
          </Select>
        )}
      />
    );
  }

  return content;
};
