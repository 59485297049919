import React from 'react';
import moment from 'moment';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import EditIcon from '@material-ui/icons/Edit';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Link } from 'react-router-dom';
import { GRAY_178 } from '../../../shared/components/ui/theme/contants';
import { TableAction } from '../../../shared/components/ui/tables/components/TableAction';
import { UserType } from '../../../shared/types';
import { formatMoney } from '../../../shared/utils';
import { Anchor } from '../../../shared/components/ui/anchor/Anchor';
import {
  PrimaryChip,
  SecondaryChip,
} from '../../../shared/components/ui/chips/Chips';

type PeopleListRowProps = {
  person: UserType;
};

/**
 * @param status - Row status.
 * @returns {JSX.Element} - Chip component.
 */
function switchChip(status: string): JSX.Element | null {
  switch (status) {
  case null:
    return null;
  case 'RETURNING':
    return <PrimaryChip label="Returning" />;
  case 'ONE_TIME':
    return <PrimaryChip label="One Time" />;
  case 'NEW':
    return <SecondaryChip label="New" />;
  default:
    return <PrimaryChip label={status} />;
  }
}

const useStyles = makeStyles({
  paper: {
    width: '190px',
    '& > ul': {
      paddingTop: '2px',
      paddingBottom: '2px',
    },
  },
  menuItemContainer: {
    height: '45px',
    paddingLeft: '20px',
    '&:hover > div': {
      color: '#fff',
    },
  },
  menuIconContainer: {
    marginRight: '10px',
    color: GRAY_178,
    fontSize: '20px',
    lineHeight: '12px',
  },
  link: {
    textDecoration: 'none',
  },
});

/**
 * @param userId - Person id.
 * @param fullName - Person fullName.
 * @param person - Person.
 * @returns A renderAction component.
 */
function renderActionFactory(
  userId: string,
  fullName: string,
  person: UserType,
) {
  return function RenderAction(
    anchorEl: null | HTMLElement,
    onClose: () => void,
  ): JSX.Element {
    const classes = useStyles();
    const open = !!anchorEl;

    const personInbox = person?.userItineraryRelation?.items?.some((itnry) => {
      const {
        inboxRelation: { items: inboxes },
      } = itnry;

      const {
        itineraryStatus: { statusName },
      } = itnry;

      const inbox = inboxes[0];

      return inbox?.messageRelation.count && statusName !== 'ARCHIVED';
    });

    return (
      <Menu
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem
          className={classes.menuItemContainer}
          component={Link}
          to={`/people/${userId}/detail?name=${fullName}`}
        >
          <Box className={classes.menuIconContainer}>
            <EditIcon fontSize="inherit" />
          </Box>
          <Typography variant="inherit">View Details</Typography>
        </MenuItem>
        {personInbox && (
          <MenuItem
            className={classes.menuItemContainer}
            component={Link}
            to={`/inbox/chat/${userId}`}
          >
            <Box className={classes.menuIconContainer}>
              <QuestionAnswerIcon fontSize="inherit" />
            </Box>
            <Typography variant="inherit">Open Chat</Typography>
          </MenuItem>
        )}
      </Menu>
    );
  };
}

/**
 * @param amount - Amount to format.
 * @returns - Formatted string.
 */
function formatTotalRevenue(amount: number): string {
  if (amount === 0) {
    return 'N/A';
  }

  return formatMoney(amount);
}
/**
 * @param date - Date to format.
 * @returns - Formatted date.
 */
function formatLastTripDate(date: Date | null): string | null {
  if (date === null) {
    return null;
  }
  return moment.tz(date, 'UTC').format('M/D/YYYY');
}

/**
 * Person data row.
 *
 * @param props - Props received.
 * @param {UserType} props.person - Person data.
 * @returns {JSX.Element}  Row data.
 */
export const PeopleListRow: React.FC<PeopleListRowProps> = ({ person }) => (
  <TableRow key={person.id}>
    <TableCell>{person.fullName}</TableCell>
    <TableCell>
      <Anchor href="#">{person.email}</Anchor>
    </TableCell>
    <TableCell>{person.phoneNumber}</TableCell>
    <TableCell>{formatLastTripDate(person.lastTripDate)}</TableCell>
    <TableCell>{person.totalTrips}</TableCell>
    <TableCell>{switchChip(person.tripStatus)}</TableCell>
    <TableCell>{formatTotalRevenue(person.totalRevenue)}</TableCell>
    <TableCell>
      <TableAction
        renderAction={renderActionFactory(person.id, person.fullName, person)}
      />
    </TableCell>
  </TableRow>
);
