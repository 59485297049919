import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { AccountAvatar } from './AccountAvatar';
import {
  COLOR_BORDER,
  BLACK_TITLE,
} from '../../../shared/components/ui/theme/contants';
import { AccountMenu } from './AccountMenu';

type AccountLayoutProps = {
  title: string;
  children: JSX.Element;
};

const useStyles = makeStyles({
  papper: {
    borderRadius: '8px',
  },
  leftContainer: {
    height: '100%',
    borderRight: `1px solid ${COLOR_BORDER}`,
  },
  header: {
    borderBottom: `1px solid ${COLOR_BORDER}`,
  },
  title: {
    color: BLACK_TITLE,
    fontSize: '21px',
    fontWeight: 500,
    letterSpacing: '-0.2px',
  },
});

/**
 * @param props - Props received.
 * @param {string} props.title - Title of screen.
 * @param {JSX.Element} props.children - Component children.
 * @returns {JSX.Element} Account menu.
 */
export const AccountLayout: React.FC<AccountLayoutProps> = ({
  title,
  children,
}) => {
  const classes = useStyles();

  return (
    <Paper elevation={24} className={classes.papper}>
      <Grid container>
        <Grid item xs={3}>
          <Box className={classes.leftContainer}>
            <AccountAvatar />
            <AccountMenu />
          </Box>
        </Grid>
        <Grid item xs={9}>
          <Box pl="40px" py="17px" className={classes.header}>
            <Typography className={classes.title}>{title}</Typography>
          </Box>
          {children}
        </Grid>
      </Grid>
    </Paper>
  );
};
