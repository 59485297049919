import React, { ReactNode } from 'react';
import { makeStyles, styled } from '@material-ui/core/styles';
import Typography, { TypographyProps } from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {
  GRAY_176,
  GRAY_69,
  BLACK_TITLE,
  GRAY_178,
  COLOR_INFO_TYPES,
} from '../theme/contants';

interface TextProps extends TypographyProps {
  text?: string;
  children?: ReactNode;
}

const useStyles = makeStyles({
  smallGray: {
    color: GRAY_176,
    fontSize: '13px',
  },
  basicGray: {
    color: GRAY_176,
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: '0.8px',
  },
  cardTitle: {
    color: BLACK_TITLE,
    letterSpacing: '-0.15px',
    fontWeight: 500,
    fontSize: '16px',
  },
  cardTitlePage: {
    fontSize: '18px',
  },
  pageTitle: {
    padding: 0,
    margin: 0,
    color: BLACK_TITLE,
    fontSize: '36px',
  },
  basicText: {
    fontSize: '16px',
  },
  tableSelected: {
    fontSize: '15px',
  },
  formTitle: {
    color: BLACK_TITLE,
    fontSize: '21px',
  },
  breadcrumText: {
    color: GRAY_69,
    fontSize: '16px',
  },
  menuIconContainer: {
    paddingTop: '2px',
    color: GRAY_178,
    fontSize: '20px',
    lineHeight: '12px',
  },
});

export const BasicCardTitle = styled(Typography)({
  letterSpacing: '-0.15px',
  fontWeight: 500,
  fontSize: '16px',
});

export const BasicText = styled(Typography)({
  fontSize: '14px',
});

export const InfoTypes = styled(Typography)({
  color: COLOR_INFO_TYPES,
  fontSize: '12px',
});

/**
 * @param props - Props received.
 * @param {string} props.text - Text to display.
 *
 * @returns {JSX.Element} String with styles.
 */
export const SmallGrayText: React.FC<TextProps> = ({ text, ...rest }) => {
  const classes = useStyles();

  return (
    <Typography {...rest} className={classes.smallGray}>
      {text}
    </Typography>
  );
};

/**
 * @param props - Props received.
 * @param {ReactNode} props.children - Text to display.
 *
 * @returns {JSX.Element} String with styles.
 */
export const BasicGrayText: React.FC<TextProps> = ({ children, ...rest }) => {
  const classes = useStyles();

  return (
    <Typography {...rest} className={classes.basicGray}>
      {children}
    </Typography>
  );
};

/**
 * @param props - Props received.
 * @param {ReactNode} props.children - Text to display.
 *
 * @returns {JSX.Element} String with styles.
 */
export const CardTitleText: React.FC<TextProps> = ({ children, ...rest }) => {
  const classes = useStyles();

  return (
    <Typography {...rest} className={classes.cardTitle}>
      {children}
    </Typography>
  );
};

/**
 * @param props - Props received.
 * @param {ReactNode} props.children - Text to display.
 *
 * @returns {JSX.Element} String with styles.
 */
export const CardTitlePage: React.FC<TextProps> = ({ children, ...rest }) => {
  const classes = useStyles();

  return (
    <Typography {...rest} variant="subtitle2" className={classes.cardTitlePage}>
      {children}
    </Typography>
  );
};

/**
 * @param props - Props received.
 * @param {string} props.children - Text to display.
 *
 * @returns {JSX.Element} String with styles.
 */
export const PageTitle: React.FC<TextProps> = ({ children, ...rest }) => {
  const classes = useStyles();

  return (
    <Typography {...rest} className={classes.pageTitle}>
      {children}
    </Typography>
  );
};

/**
 * @param props - Props received.
 * @param {string} props.text - Text to display.
 *
 * @returns {JSX.Element} String with styles.
 */
export const BreadcrumbText: React.FC<TextProps> = ({ text, ...rest }) => {
  const classes = useStyles();

  return (
    <Typography {...rest} className={classes.breadcrumText}>
      {text}
    </Typography>
  );
};

/**
 * @param props - Props received.
 * @param {string} props.children - Text to display.
 *
 * @returns {JSX.Element} String with styles.
 */
export const TableSelectedText: React.FC<TextProps> = ({
  children,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Typography {...rest} className={classes.tableSelected}>
      {children}
    </Typography>
  );
};

/**
 * @param props - Props received.
 * @param {string} props.children - Text to display.
 *
 * @returns {JSX.Element} String with styles.
 */
export const FormStepTitle: React.FC<TextProps> = ({ children, ...rest }) => {
  const classes = useStyles();

  return (
    <Typography {...rest} className={classes.formTitle}>
      {children}
    </Typography>
  );
};

/**
 * @param props - Props received.
 * @param {string} props.children - Text to display.
 *
 * @returns {JSX.Element} String with styles.
 */
export const MenuIconContainer: React.FC<TextProps> = ({
  children,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Box {...rest} className={classes.menuIconContainer}>
      {children}
    </Box>
  );
};
