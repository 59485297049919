import React, { useState, useEffect, useCallback } from 'react';
import * as filestack from 'filestack-js';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import {
  useFetchAction,
  useCallAction,
  useSubscription,
} from '@cobuildlab/react-simple-state';
import Skeleton from '@material-ui/lab/Skeleton';
import { COLOR_PRIMARY } from '../../../shared/components/ui/theme/contants';
import {
  CardTitleText,
  BasicGrayText,
} from '../../../shared/components/ui/texts/Texts';
import { fetchFileUploadInfoAction } from '../../file-upload/file-upload-actions';
import { fetchSession } from '../../session/session-actions';
import { updateUserAvatarAction } from '../user-actions';
import { UserType } from '../../../shared/types';
import { OnUpdateUser } from '../user-events';
import * as toast from '../../../shared/components/ui/toasts/Toast';

const useStyles = makeStyles({
  avatar: {
    position: 'relative',
    height: '100px',
    width: '100px',
    backgroundColor: COLOR_PRIMARY,
    fontSize: '40px',
    fontWeight: 500,
    cursor: 'pointer',
  },
  avatarFileContainer: {
    position: 'relative',
    height: '100px',
    width: '100px',
    borderRadius: '50%',
    overflow: 'hidden',
    cursor: 'pointer',
  },
  avatarFile: {
    width: '100%',
    height: '100%',
    borderRadius: '50%',
    objectFit: 'cover',
  },
  avatarText: {
    position: 'absolute',
    bottom: '0',
    width: '100%',
    height: '25px',
    paddingTop: '2px',
    backgroundColor: COLOR_PRIMARY,
    color: 'white',
    fontSize: '12px',
    textAlign: 'center',
    zIndex: 1000,
  },
  overlay: {
    position: 'absolute',
    top: '0',
    right: '0',
    left: '0',
    bottom: '0',
    backgroundColor: 'rgba(255, 255 ,255, 0.5)',
    zIndex: 1000,
  },
  fullNameContent: {
    '& > p': {
      maxWidth: '200px',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
});

/**
 * @returns {JSX.Element} Avatar.
 */
export const AccountAvatar: React.FC = () => {
  const classes = useStyles();
  const [clientFileStack, setClientFileStack] = useState<filestack.Client>();
  const [path, setPath] = useState<string>();
  const [user, setUser] = useState<null | UserType>();
  const [fileUploadInfo, loadingFileInfo] = useFetchAction(
    fetchFileUploadInfoAction,
    [],
  );
  const [session, loadingSession, { refetch }] = useFetchAction(
    fetchSession,
    [],
    {
      skip: loadingFileInfo,
    },
  );

  const isLoading = loadingSession || loadingFileInfo;

  const [updateUserAvatar, loadingUploadAvatar] = useCallAction(
    updateUserAvatarAction,
    {
      /**
       * @param {UserType} userData - User data.
       */
      onCompleted: (userData: UserType) => {
        setUser(userData);
      },

      /**
       * @param {TypeError} e - Error event.
       */
      onError: (e: TypeError) => {
        toast.error(e.message);
      },
    },
  );

  useEffect(() => {
    if (fileUploadInfo && !clientFileStack) {
      setPath(fileUploadInfo.path);

      setClientFileStack(
        filestack.init(fileUploadInfo.apiKey, {
          security: {
            policy: fileUploadInfo.policy,
            signature: fileUploadInfo.signature,
          },
        }),
      );
    }
  }, [fileUploadInfo, clientFileStack]);

  useSubscription(
    OnUpdateUser,
    () => {
      refetch();
    },
    [],
  );

  useEffect(() => {
    if (session) {
      setUser(session);
    }
  }, [session]);

  const onUploadDone = useCallback(
    (files: filestack.PickerResponse): void => {
      if (files.filesUploaded.length > 0) {
        const { filename, handle } = files.filesUploaded[0];
        const data = {
          id: user?.id,
          avatar: { create: { fileId: handle, filename } },
        };

        updateUserAvatar(data);
      }
    },
    [user, updateUserAvatar],
  );

  const handleAddPhoto = useCallback((): void => {
    if (clientFileStack !== undefined && !loadingUploadAvatar) {
      const options = {
        fromSources: ['local_file_system'],
        accept: 'image/*',
        maxFiles: 1,
        onUploadDone,
        storeTo: { path },
      };
      clientFileStack.picker(options).open();
    }
  }, [clientFileStack, onUploadDone, path, loadingUploadAvatar]);

  const [firstName = '', lastName = ''] = user?.fullName?.split(' ') || [];
  const firstNameInitial = firstName.charAt(0);
  const lastNameInitial = lastName.charAt(0);

  let loaderAvatar: JSX.Element | null = null;

  if (loadingUploadAvatar) {
    loaderAvatar = <div className={classes.overlay} />;
  }

  let avatar = (
    <Avatar className={classes.avatar} onClick={handleAddPhoto}>
      {firstNameInitial || ''}
      {lastNameInitial || ''}
      {loaderAvatar}
    </Avatar>
  );

  let content = (
    <>
      <Box mt="15px" className={classes.fullNameContent}>
        <CardTitleText>{user?.fullName}</CardTitleText>
      </Box>
      <Box mt="2px">
        <BasicGrayText>ADMINISTRATOR</BasicGrayText>
      </Box>
    </>
  );

  if (user?.avatar?.downloadUrl) {
    avatar = (
      <Box className={classes.avatarFileContainer} onClick={handleAddPhoto}>
        <img
          className={classes.avatarFile}
          src={user.avatar.downloadUrl}
          alt="avatar"
        />
        <Box className={classes.avatarText}>
          <span>Change</span>
        </Box>
        {loaderAvatar}
      </Box>
    );
  }

  if (isLoading && !user) {
    avatar = <Skeleton variant="circle" width={100} height={100} />;

    content = (
      <>
        <Box mt="15px">
          <Skeleton variant="rect" width={200} height={10} />
        </Box>
        <Box mt="8px">
          <Skeleton variant="rect" width={200} height={10} />
        </Box>
      </>
    );
  }

  return (
    <Box pt="50px">
      <Box display="flex" flexDirection="column" alignItems="center" mb="20px">
        {avatar}
        {content}
      </Box>
      <Divider />
    </Box>
  );
};
