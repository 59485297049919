import React from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

type PaperContainerProps = {
  children: JSX.Element;
};

const useStyles = makeStyles({
  paper: {
    borderRadius: '8px',
    flexGrow: 1,
  },
});

/**
 * @param props - Received props.
 * @param {JSX.Element} props.children - Content of paper.
 *
 * @returns {JSX.Element}  Paper container.
 */
export const PaperContainer: React.FC<PaperContainerProps> = ({ children }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper} elevation={24}>
      {children}
    </Paper>
  );
};
