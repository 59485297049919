import React, { useState, useContext, useEffect, useMemo } from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import { makeStyles, styled } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import uniqueString from 'unique-string';
import { useFormContext } from 'react-hook-form';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useCallAction } from '@cobuildlab/react-simple-state';
import moment from 'moment-timezone';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import {
  FormStepTitle,
  BasicText,
} from '../../../../shared/components/ui/texts/Texts';
import { DeleteRedIcon } from '../../../../shared/icons/icons';
import {
  DatePickerTimezone,
  DatePickerType,
} from '../../../../shared/components/ui/form/DatePicker';
import { fetchTravelerTypesAction } from '../../../traveler-type/traveler-type-actions';
import { TravelerTypeType } from '../../../../shared/types';
import { ExperienceFormContext } from './ExperienceFormProvider';
import * as toast from '../../../../shared/components/ui/toasts/Toast';

export type PriceItemType = {
  id: string;
  name: string;
  value: string | number;
  startDate: string;
  endDate: string;
  travelerTypeId: string;
  isActive: boolean;
  bookingCount: number;
};

type PriceItemProps = {
  priceItem: PriceItemType;
  onChangeName: (id: string, name: string) => void;
  onChangeValue: (id: string, value: number) => void;
  onRemovePrice: (id: string) => void;
  onChangeStartDate: (id: string, startDate: string | DatePickerType) => void;
  onChangeEndDate: (id: string, endDate: string | DatePickerType) => void;
  onChangeTravelerType: (id: string, travelerTypeId: string) => void;
  loadingTravelerTypes: boolean;
  loadingSubmit: boolean;
  isSaved: boolean;
  fetchTravelerTypes: () => void;
  travelerTypeItems: TravelerTypeType[];
  index: number;
};

const useStyles = makeStyles({
  link: {
    '&:hover': {
      textDecoration: 'none',
    },
  },
  date: {
    '&  > .MuiFormControl-marginNormal': {
      margin: 0,
    },
  },
  close: {
    cursor: 'pointer',
  },
});

const InfoIcon = styled(InfoOutlinedIcon)({
  cursor: 'pointer',
});

const defaultItem = {
  id: uniqueString(),
  name: '',
  value: '0',
  startDate: '',
  endDate: '',
  travelerTypeId: '',
  isActive: true,
  bookingCount: 0,
};

/**
 * @param props - Prop received.
 * @param {PriceItemType} props.priceItem - Price item.
 * @param {Function} props.onChangeName - Function to change name.
 * @param {Function} props.onChangeValue - Function to change value.
 * @param {Function} props.onChangeStartDate - Function to change start date.
 * @param {Function} props.onChangeEndDate - Function to change end date.
 * @param {Function} props.onRemovePrice - Function to remove item.
 * @param {Function} props.onChangeTravelerType - Function to change travler id.
 * @param {boolean} props.loadingTravelerTypes - State of loading traveler types.
 * @param {Function} props.fetchTravelerTypes - Function to fetch travaler types.
 * @param {boolean} props.loadingSubmit - Loading submit.
 * @param {number} props.index - Position in array.
 * @param {boolean} props.isSaved - Check if pricing is saved.
 * @param {TravelerTypeType[]} props.travelerTypeItems - Traveler type option.
 *
 * @returns {JSX.Element} Price item.
 */
const PricingItem: React.FC<PriceItemProps> = ({
  priceItem,
  onChangeName,
  onChangeValue,
  onRemovePrice,
  onChangeStartDate,
  onChangeEndDate,
  onChangeTravelerType,
  loadingTravelerTypes,
  fetchTravelerTypes,
  travelerTypeItems,
  loadingSubmit,
  isSaved,
  index,
}) => {
  const classes = useStyles();

  let buttonDelete = null;
  let options: JSX.Element[] | JSX.Element = travelerTypeItems.map(
    (travelerType) => (
      <MenuItem value={travelerType.id} key={travelerType.id}>
        {travelerType.travelerTypeName}
      </MenuItem>
    ),
  );

  if (loadingTravelerTypes) {
    options = (
      <MenuItem disabled value="0">
        Loading...
      </MenuItem>
    );
  }

  if (index > 0) {
    buttonDelete = (
      <Box
        className={classes.close}
        position="absolute"
        right="-25px"
        onClick={() => {
          if (priceItem.bookingCount > 0) {
            toast.error('This price has been selected for bookings');
          } else {
            onRemovePrice(priceItem.id);
          }
        }}
      >
        <Grid item xs={4}>
          <Box>
            <DeleteRedIcon />
          </Box>
        </Grid>
      </Box>
    );
  }
  const value = Number.isNaN(priceItem.value) ? '' : priceItem.value;

  return (
    <Box position="relative">
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={2}>
          <TextField
            variant="outlined"
            fullWidth
            label="Price Name"
            disabled={loadingSubmit || isSaved}
            value={priceItem.name}
            onChange={(e) =>
              onChangeName(priceItem.id, e.target.value as string)
            }
          />
        </Grid>
        <Grid item xs={2}>
          <FormControl
            fullWidth
            variant="outlined"
            disabled={loadingSubmit || isSaved}
          >
            <InputLabel htmlFor="pricing">Value</InputLabel>
            <OutlinedInput
              id={priceItem.id}
              label="Value"
              inputProps={{
                type: 'number',
                min: 0,
                step: 0.01,
              }}
              value={value}
              onChange={(e) =>
                onChangeValue(priceItem.id, parseFloat(e.target.value))
              }
              endAdornment={<InputAdornment position="end">$</InputAdornment>}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} className={classes.date}>
          <DatePickerTimezone
            defaultValue={priceItem.startDate}
            label="Start Date"
            isDisabled={loadingSubmit}
            onChange={(date) => onChangeStartDate(priceItem.id, date)}
          />
        </Grid>
        <Grid item xs={3} className={classes.date}>
          <DatePickerTimezone
            defaultValue={priceItem.endDate}
            label="End Date"
            isDisabled={loadingSubmit}
            onChange={(date) => onChangeEndDate(priceItem.id, date)}
          />
        </Grid>
        <Grid item xs={2}>
          <FormControl
            variant="outlined"
            fullWidth
            disabled={loadingSubmit || isSaved}
          >
            <InputLabel>Traveler</InputLabel>
            <Select
              value={priceItem.travelerTypeId}
              label="TravelerType"
              onFocus={fetchTravelerTypes}
              onChange={(e) =>
                onChangeTravelerType(priceItem.id, e.target.value as string)
              }
            >
              {options}
            </Select>
          </FormControl>
        </Grid>
        {buttonDelete}
      </Grid>
    </Box>
  );
};

/**
 * @returns {JSX.Element} Pricing fields.
 */
export const ExperiencePricing: React.FC = () => {
  const classes = useStyles();
  const { setValue } = useFormContext();
  const { loadingSubmit, experience } = useContext(ExperienceFormContext);
  const [prices, setPrices] = useState<PriceItemType[]>([defaultItem]);
  const [travelerTypeItems, setTravelerTypeItems] = useState<
    TravelerTypeType[]
  >([]);

  const pricesActive = useMemo(
    () => prices.filter(({ isActive }) => isActive),
    [prices],
  );

  const [fetchTravelerTypes, loadingTravelerTypes] = useCallAction(
    fetchTravelerTypesAction,
    {
      /**
       * @param {TravelerTypeType} travelerTypes - Traveler types data.
       */
      onCompleted: (travelerTypes: TravelerTypeType[]) => {
        if (!travelerTypeItems.length) {
          setTravelerTypeItems(travelerTypes);
        }
      },
    },
  );

  useEffect(() => {
    if (experience) {
      const {
        experienceExperiencePricingRelation: { items },
      } = experience;
      if (!items?.length) return;

      const pricings = items.map((price) => ({
        id: price.id as string,
        endDate: moment.tz(price.endTime, 'UTC').format(),
        startDate: moment.tz(price.startTime, 'UTC').format(),
        travelerTypeId: price.travelerType.id,
        value: price.feeAmount,
        name: price.pricingName,
        isActive: price.isActive,
        bookingCount: price.experiencePricingBookingPricingRelation.count || 0,
      }));

      fetchTravelerTypes();
      setPrices(pricings);
      setValue('pricings', pricings);
    }
  }, [experience, setValue, fetchTravelerTypes]);

  /**
   * @param {string} id - Id of  price item.
   * @param {name} name - New name.
   */
  const handleChangeName = (id: string, name: string): void => {
    const selectedPrices = prices.map((price) => ({ ...price }));
    const position = selectedPrices.findIndex(
      (priceItem) => priceItem.id === id,
    );
    selectedPrices[position] = { ...selectedPrices[position], name };

    setPrices(selectedPrices);
    setValue('pricings', selectedPrices);
  };

  /**
   * @param {string} id - Id of price item.
   * @param {number} value - Value of price.
   */
  const handleChangeValue = (id: string, value: number): void => {
    const selectedPrices = prices.map((price) => ({ ...price }));
    const position = selectedPrices.findIndex(
      (priceItem) => priceItem.id === id,
    );
    selectedPrices[position] = { ...selectedPrices[position], value };

    setPrices(selectedPrices);
    setValue('pricings', selectedPrices);
  };

  /**
   * @param {string} id - Id of price item.
   * @param {DatePickerType | string } date - Start date.
   */
  const handleChangeStartDate = (
    id: string,
    date: DatePickerType | string,
  ): void => {
    const selectedPrices = prices.map((price) => ({ ...price }));
    const position = selectedPrices.findIndex(
      (priceItem) => priceItem.id === id,
    );

    const startDate = moment
      .tz(date as string, 'YYYY/MM/DD', 'UTC')
      .set({ hour: 0, minute: 0, second: 0 })
      .format();

    selectedPrices[position] = {
      ...selectedPrices[position],
      startDate,
    };

    setPrices(selectedPrices);
    setValue('pricings', selectedPrices);
  };

  /**
   * @param {string} id - Id of price item.
   * @param {DatePickerType | string } date - Start date.
   */
  const handleChangeEndDate = (
    id: string,
    date: DatePickerType | string,
  ): void => {
    const selectedPrices = prices.map((price) => ({ ...price }));
    const position = selectedPrices.findIndex(
      (priceItem) => priceItem.id === id,
    );

    const endDate = moment
      .tz(date as string, 'YYYY/MM/DD', 'UTC')
      .set({ hour: 23, minute: 59, second: 59 })
      .format();

    selectedPrices[position] = {
      ...selectedPrices[position],
      endDate,
    };

    setPrices(selectedPrices);
    setValue('pricings', selectedPrices);
  };

  /**
   * @param {string} id - Id of price item.
   * @param {string} travelerTypeId - Id of traveler type.
   */
  const handleChangeTravelerType = (
    id: string,
    travelerTypeId: string,
  ): void => {
    const selectedPrices = prices.map((price) => ({ ...price }));
    const position = selectedPrices.findIndex(
      (priceItem) => priceItem.id === id,
    );
    selectedPrices[position] = { ...selectedPrices[position], travelerTypeId };

    setPrices(selectedPrices);
    setValue('pricings', selectedPrices);
  };

  /**
   * Function add new price.
   */
  const handleAddPrice = (): void => {
    if (loadingSubmit) return;

    const selectedPrices = prices.map((price) => ({ ...price }));
    const priceItem = { ...defaultItem, id: uniqueString() };
    selectedPrices.push(priceItem);

    setPrices(selectedPrices);
    setValue('pricings', selectedPrices);
  };

  /**
   * @param {string} pricingId - Pricing id.
   * @returns {boolean} -Status of save pricing.
   */
  const isSaved = (pricingId: string): boolean => {
    if (experience) {
      const {
        experienceExperiencePricingRelation: { items: pricings },
      } = experience;

      return !!pricings?.find(({ id }) => id === pricingId);
    }

    return false;
  };

  /**
   * @param {string} id - Id or price item.
   */
  const handleRemovePrice = (id: string): void => {
    if (loadingSubmit) return;

    let selectedPrices = prices.filter((price) => price.id !== id);

    if (isSaved(id)) {
      selectedPrices = prices.map((price) => ({ ...price }));
      const position = selectedPrices.findIndex(
        (priceItem) => priceItem.id === id,
      );

      selectedPrices[position] = {
        ...selectedPrices[position],
        isActive: false,
      };
    }

    setPrices(selectedPrices);
    setValue('pricings', selectedPrices);
  };

  const content = pricesActive.map((priceItem, index) => (
    <Box mb="20px" key={priceItem.id}>
      <PricingItem
        priceItem={priceItem}
        index={index}
        isSaved={isSaved(priceItem.id)}
        onChangeName={handleChangeName}
        onChangeValue={handleChangeValue}
        onChangeStartDate={handleChangeStartDate}
        onChangeEndDate={handleChangeEndDate}
        onRemovePrice={handleRemovePrice}
        onChangeTravelerType={handleChangeTravelerType}
        loadingTravelerTypes={loadingTravelerTypes}
        fetchTravelerTypes={fetchTravelerTypes}
        travelerTypeItems={travelerTypeItems}
        loadingSubmit={loadingSubmit}
      />
    </Box>
  ));

  let info = null;

  if (prices.length > 1) {
    info = (
      <Box mt="15px">
        <Tooltip title="Set dates that do not interfere with other prices">
          <InfoIcon color="primary" />
        </Tooltip>
      </Box>
    );
  }

  return (
    <Box mt={6}>
      <Box mb="15px">
        <FormStepTitle>III. Pricing</FormStepTitle>
      </Box>
      {content}
      {info}
      <Box mt="15px">
        <Link
          className={classes.link}
          component="button"
          onClick={handleAddPrice}
          type="button"
        >
          <BasicText>+ Add another</BasicText>
        </Link>
      </Box>
    </Box>
  );
};
