import React from 'react';
import { TableFilters } from '../../../shared/components/ui/tables/components/TableFilters';
import { TableList } from '../../../shared/components/ui/tables/TableList';
import { ColumnType, UserType } from '../../../shared/types';
import {
  PeopleFilterState,
  PeopleListMenuFilter,
} from './PeopleListMenuFilter';
import { PeopleListRow } from './PeopleListRow';

type PeopleListTableProps = {
  page: number;
  totalCount: number;
  filterState: PeopleFilterState;
  people: UserType[];
  loading: boolean;
  onResetFilter: (filterState: PeopleFilterState) => void;
  onChangeSearch: (searchText: string) => void;
  onApplyFilters: (filterState: PeopleFilterState) => void;
  onChangePage: (page: number) => void;
  onChangeSort: (field: string, direction?: string) => void;
};

const COLUMNS: ColumnType[] = [
  {
    field: 'fullName',
    headerName: 'NAME',
    sorteable: true,
  },
  {
    field: 'email',
    headerName: 'EMAIL',
    sorteable: true,
  },
  {
    field: 'phoneNumber',
    headerName: 'PHONE',
    sorteable: true,
  },
  {
    field: 'lastTripDate',
    headerName: 'LAST TRIP DATE',
    sorteable: true,
  },
  {
    field: 'totalTrips',
    headerName: 'TOTAL TRIPS',
    sorteable: true,
  },
  {
    field: 'tripStatus',
    headerName: 'STATUS',
    sorteable: true,
  },
  {
    field: 'totalRevenue',
    headerName: 'TOTAL REVENUE',
    sorteable: true,
  },
];

/**
 * @param filterState - Filter state.
 * @param onApplyFilters - OnApplyFilters function.
 * @param onResetFilter - OnResetFilter function.
 * @returns - RenderMenuFilter function.
 */
function renderMenuFilterFactory(
  filterState: PeopleFilterState,
  onApplyFilters: (filterState: PeopleFilterState) => void,
  onResetFilter: (filterState: PeopleFilterState) => void,
) {
  return function renderMenuFilter(
    anchorEl: HTMLElement | null,
    onClose: () => void,
  ) {
    return (
      <PeopleListMenuFilter
        anchorEl={anchorEl}
        onClose={onClose}
        onApplyFilters={onApplyFilters}
        filterState={filterState}
        onResetFilter={onResetFilter}
      />
    );
  };
}

/**
 * @param filterState - Filter state.
 * @param onResetFilter - Function to reset filter.
 * @returns - RenderTableFilters function.
 */
function renderTableFiltersFactory(
  filterState: PeopleFilterState,
  onResetFilter: (filterState: PeopleFilterState) => void,
) {
  return function renderTableFilters() {
    return (
      <TableFilters<PeopleFilterState>
        filterState={filterState}
        onResetFilter={onResetFilter}
      />
    );
  };
}

/**
 * Table list.
 *
 * @param props - Props received.
 * @param {number} props.page - Number page.
 * @param {PeopleFilterState} props.filterState - Filter state.
 * @param {number} props.totalCount - Total number o register.
 * @param {Function} props.onApplyFilters - Function to apply filters.
 * @param {Function} props.onChangeSearch - Function to change search text.
 * @param {Function} props.onChangePage - Function to change page.
 * @param {UserType[]} props.people - People data.
 * @param {boolean} props.loading - Loading status.
 * @param {Function} props.onChangeSort - Function to change sort.
 * @param {Function} props.onResetFilter - Function to reset filter.
 * @returns {JSX.Element} People table.
 */
export function PeopleListTable({
  page,
  filterState,
  totalCount,
  people,
  loading,
  onApplyFilters,
  onChangeSearch,
  onChangePage,
  onChangeSort,
  onResetFilter,
}: PeopleListTableProps): JSX.Element {
  let content: JSX.Element | JSX.Element[] = people.map((person) => (
    <PeopleListRow key={person.id} person={person} />
  ));

  if (!people.length) {
    content = (
      <tr>
        <td colSpan={7} style={{ textAlign: 'center', paddingTop: '8px' }}>
          No records to display
        </td>
      </tr>
    );
  }

  return (
    <TableList
      columns={COLUMNS}
      renderMenuFilter={renderMenuFilterFactory(
        filterState,
        onApplyFilters,
        onResetFilter,
      )}
      renderOptionalComponent={renderTableFiltersFactory(
        filterState,
        onResetFilter,
      )}
      page={page}
      totalCount={totalCount}
      onChangeSearch={onChangeSearch}
      onChangePage={onChangePage}
      onChangeSort={onChangeSort}
      loading={loading}
    >
      {content}
    </TableList>
  );
}
