import React, { useState, useEffect, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { useCallAction } from '@cobuildlab/react-simple-state';
import FormHelperText from '@material-ui/core/FormHelperText';
import { SelectCheckbox, SelectCheckboxItemType } from './SelectCheckbox';
import { fetchExperienceSubCategoriesAction as fetchSubCategoriesAction } from '../../../experience-subcategory/experience-subcategory-actions';
import { ExperienceSubCategoryType as SubCategoryType } from '../../../../shared/types';
import { ExperienceFormContext } from './ExperienceFormProvider';

/**
 * @returns {JSX.Element} Categories list.
 */
export const ExperienceSubCategorySelect: React.FC = () => {
  const {
    watch,
    getValues,
    setValue,
    formState: { errors },
    clearErrors,
  } = useFormContext();
  const { experience } = useContext(ExperienceFormContext);
  const categories = watch('categories');
  const [subCategoryItems, setSubCategoryItems] = useState<
    SelectCheckboxItemType[]
  >([]);

  const [fetchSubCategories, loading] = useCallAction(
    fetchSubCategoriesAction,
    {
      /**
       * @param {SubCategoryType []} experienceSubCategories - Sub categories data.
       */
      onCompleted: (experienceSubCategories: SubCategoryType[]) => {
        /**
         *  Subcategories in form state.
         */
        const subCategoryIds: string[] = getValues('subCategories') || [];

        const selectedSubCategoryIds = subCategoryIds.filter(
          (subCategoryId) => {
            const subCategory = experienceSubCategories.find(
              ({ id }) => id === subCategoryId,
            );

            return !!subCategory;
          },
        );

        const items = experienceSubCategories.map((subCategory) => {
          const checked = selectedSubCategoryIds.find(
            (id) => id === subCategory.id,
          );

          return {
            id: subCategory.id,
            name: subCategory.experienceSubCategoryName,
            checked: !!checked,
          };
        });

        setSubCategoryItems(items);

        if (selectedSubCategoryIds.length) {
          setValue('subCategories', selectedSubCategoryIds);
        } else {
          setValue('subCategories', undefined);
        }
      },
    },
  );

  useEffect(() => {
    if (categories?.length) {
      fetchSubCategories(categories);
    } else {
      setSubCategoryItems([]);
      setValue('subCategories', undefined);
    }
  }, [categories, setValue, fetchSubCategories]);

  useEffect(() => {
    if (experience) {
      const {
        experienceExperienceSubCategoryRelation: { items },
      } = experience;

      const subCategories = items?.map(
        ({ experienceSubCategory }) => experienceSubCategory.id,
      );

      setValue('subCategories', subCategories);
    }
  }, [experience, setValue]);

  /**
   * @param {string} id  - Id item.
   */
  const handleChangeState = (id: string): void => {
    const selectedCategories = subCategoryItems.map((subCategory) => {
      if (subCategory.id === id) {
        return {
          ...subCategory,
          checked: !subCategory.checked,
        };
      }

      return subCategory;
    });

    setSubCategoryItems(selectedCategories);
  };

  /**
   * Function to apply change.
   */
  const handleApply = (): void => {
    const selectedSubCategories = subCategoryItems.filter(
      (subCategory) => subCategory.checked,
    );
    const subCategoryIds = selectedSubCategories.map(
      (subCategory) => subCategory.id,
    );

    if (subCategoryIds.length) {
      clearErrors('subCategories');
      setValue('subCategories', subCategoryIds);
    } else {
      setValue('subCategories', undefined);
    }
  };

  /**
   * Function to remove not apply pills.
   */
  const handleRemoveNoApplied = (): void => {
    /**
     *
     * Ids of categories selecteds.
     */
    const subCategoryIds: string[] = getValues('subCategories') || [];
    const items = subCategoryItems.map((subCategory) => {
      const isChecked = subCategoryIds.find((id) => id === subCategory.id);

      return {
        ...subCategory,
        checked: !!isChecked,
      };
    });

    setSubCategoryItems(items);
  };

  /**
   * Function to remove item from pill.
   *
   * @param {string} id - Id of item.
   */
  const handleRemove = (id: string): void => {
    /**
     *
     * Ids of categories in form.
     */
    const selectedSubCategoryIds: string[] = getValues('subCategories') || [];
    const selectedSubCategories = subCategoryItems.filter(
      (subCategory) => subCategory.id !== id,
    );
    const subCategoryIdsToSave = selectedSubCategoryIds.filter(
      (subCategoryId) => subCategoryId !== id,
    );

    setSubCategoryItems(selectedSubCategories);

    if (subCategoryIdsToSave.length) {
      setValue('subCategories', subCategoryIdsToSave);
    } else {
      setValue('subCategories', undefined);
    }
  };

  let content = (
    <FormControl fullWidth variant="outlined" error={!!errors?.subCategories}>
      <InputLabel>Subcategories</InputLabel>
      <Select label="Sub categories">
        <MenuItem disabled value="0">
          You must select at least one category
        </MenuItem>
      </Select>
      <FormHelperText>{errors?.subCategories?.message}</FormHelperText>
    </FormControl>
  );

  if (categories?.length) {
    content = (
      <SelectCheckbox
        label="Subcategories"
        items={subCategoryItems}
        loading={loading}
        hasError={!!errors?.subCategories}
        errorMessage={errors?.subCategories?.message}
        onFetchData={() => fetchSubCategories(categories)}
        onChangeState={handleChangeState}
        onApply={handleApply}
        onRemoveNoApplied={handleRemoveNoApplied}
        onRemove={handleRemove}
      />
    );
  }

  return content;
};
