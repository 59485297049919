import React, { ReactNode } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import { Link } from 'react-router-dom';
import { useActiveLink } from '../hooks/useMatchPath';
import { GRAY_178, BLACK_TITLE, COLOR_PRIMARY } from './ui/theme/contants';

type SidebarItemTextProps = {
  text: string;
  isActive: boolean;
};

type SidebarItemIconProps = {
  children: ReactNode;
  isActive: boolean;
};

type SidebarItemProps = {
  icon: ReactNode;
  text: string;
  pathname: string;
};

type StyleProps = {
  isActive: boolean;
};

const useStyles = makeStyles({
  icon: {
    minWidth: '20px',
    marginRight: '10px',
    /**
     * @param props - Props received.
     *
     * @returns {string} - Color icon.
     */
    color: (props: StyleProps) => (props.isActive ? '#fff' : GRAY_178),
  },
  text: {
    /**
     * @param props - Props received.
     *
     * @returns {string} - Color text.
     */
    color: (props: StyleProps) => (props.isActive ? '#fff' : BLACK_TITLE),
  },
  container: {
    height: '60px',
    marginBottom: '10px',
    borderRadius: '4px',
    /**
     * @param props - Props received.
     *
     * @returns {string} - Background color.
     */
    backgroundColor: (props: StyleProps) =>
      props.isActive ? COLOR_PRIMARY : 'transparent',
    fontSize: '32px',
    cursor: 'pointer',
  },
  link: {
    textDecoration: 'none',
  },
});

/**
 * @param props - Props received.
 * @param {string} props.text - Name used in link.
 * @param {boolean} props.isActive - Check if link is active.
 *
 * @returns {JSX.Element} - Link text.
 */
const SidebarItemText: React.FC<SidebarItemTextProps> = ({
  text,
  isActive,
}) => {
  const classes = useStyles({ isActive });
  return <ListItemText className={classes.text} primary={text} />;
};

/**
 * @param props - Props received.
 * @param {ReactNode} props.children - Icon used in link.
 * @param {boolean} props.isActive - Check if link is active.
 *
 * @returns {JSX.Element} - Link icon.
 */
const SidebarIcon: React.FC<SidebarItemIconProps> = ({
  children,
  isActive,
}) => {
  const classes = useStyles({ isActive });
  return <ListItemIcon className={classes.icon}>{children}</ListItemIcon>;
};

/**
 * @param props - Props recived.
 * @param {ReactNode} props.icon - Icon used in link.
 * @param {string} props.text - Text used in icon.
 * @param {string} props.pathname - Link path.
 *
 * @returns {JSX.Element} - Sidebar link.
 */
export const SidebarItem: React.FC<SidebarItemProps> = ({
  icon,
  text,
  pathname,
}) => {
  const isActive = useActiveLink(pathname);
  const classes = useStyles({ isActive });

  return (
    <Link to={pathname} className={classes.link}>
      <ListItem className={classes.container}>
        <SidebarIcon isActive={isActive}>{icon}</SidebarIcon>
        <SidebarItemText isActive={isActive} text={text} />
      </ListItem>
    </Link>
  );
};
