import React, { useState, useContext, useEffect, useMemo } from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { useFormContext } from 'react-hook-form';
import uniqueString from 'unique-string';
import {
  FormStepTitle,
  BasicText,
} from '../../../../shared/components/ui/texts/Texts';
import { DeleteRedIcon } from '../../../../shared/icons/icons';
import { ExperienceFormContext } from './ExperienceFormProvider';
import { ExperienceScheduleType } from '../../../../shared/types';
import { omit } from '../../../../shared/utils';

type OpeningTimeItemProps = {
  openingItem: ExperienceScheduleType;
  onChangeStartHour: (id: string, startHour: string) => void;
  onChangeEndHour: (id: string, endHour: string) => void;
  onChangeDay: (id: string, day: string) => void;
  onRemoveOpening: (id: string) => void;
  loading: boolean;
  index: number;
};

const useStyles = makeStyles({
  link: {
    '&:hover': {
      textDecoration: 'none',
    },
  },
});

const days = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

const hours = [
  '12:00 am',
  '12:30 am',
  '1:00 am',
  '1:30 am',
  '2:00 am',
  '2:30 am',
  '3:00 am',
  '3:30 am',
  '4:00 am',
  '4:30 am',
  '5:00 am',
  '5:30 am',
  '6:00 am',
  '6:30 am',
  '7:00 am',
  '7:30 am',
  '8:00 am',
  '8:30 am',
  '9:00 am',
  '9:30 am',
  '10:00 am',
  '10:30 am',
  '11:00 am',
  '11:30 am',
  '12:00 pm',
  '12:30 pm',
  '1:00 pm',
  '1:30 pm',
  '2:00 pm',
  '2:30 pm',
  '3:00 pm',
  '3:30 pm',
  '4:00 pm',
  '4:30 pm',
  '5:00 pm',
  '5:30 pm',
  '6:00 pm',
  '6:30 pm',
  '7:00 pm',
  '7:30 pm',
  '8:00 pm',
  '8:30 pm',
  '9:00 pm',
  '9:30 pm',
  '10:00 pm',
  '10:30 pm',
  '11:00 pm',
  '11:30 pm',
];

const defaultItem = {
  id: uniqueString(),
  startHour: '',
  day: '',
  isActive: true,
};

/**
 * @param props - Props received.
 * @param {ExperienceScheduleType} props.openingItem - Opening time item.
 * @param {Function} props.onChangeDay - Function to change day.
 * @param {Function} props.onChangeStartHour - Function to change start hour.
 * @param {Function} props.onChangeEndHour - Function to change end hour.
 * @param {number} props.index - Index number.
 * @param {Function} props.onRemoveOpening - Function to remove opening.
 * @param {boolean} props.loading - Loading submit.
 * @returns {JSX.Element} Opening item.
 */
const OpeningTimeItem: React.FC<OpeningTimeItemProps> = ({
  openingItem,
  onChangeDay,
  onChangeStartHour,
  onChangeEndHour,
  onRemoveOpening,
  loading,
  index,
}) => {
  const optionsDays = days.map((day) => (
    <MenuItem value={day} key={day}>
      {day}
    </MenuItem>
  ));

  const optionHours = hours.map((hour) => (
    <MenuItem value={hour} key={hour}>
      {hour}
    </MenuItem>
  ));

  const labelDayId = `opening-days-${openingItem.id}`;
  const labelHourId = `opening-hours-${openingItem.id}`;
  const selectHourId = `select-hour-${openingItem.id}`;
  let buttonDelete = null;

  if (index > 0) {
    buttonDelete = (
      <Grid item xs={2}>
        <IconButton
          disabled={loading}
          onClick={() => onRemoveOpening(openingItem.id as string)}
        >
          <DeleteRedIcon />
        </IconButton>
      </Grid>
    );
  }

  return (
    <>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={4}>
          <FormControl variant="outlined" fullWidth disabled={loading}>
            <InputLabel id={labelDayId}>Opening Days</InputLabel>
            <Select
              labelId={labelDayId}
              id={openingItem.id}
              value={openingItem.day}
              onChange={(e) =>
                onChangeDay(openingItem.id as string, e.target.value as string)
              }
              label="Opening Days"
            >
              {optionsDays}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl variant="outlined" fullWidth disabled={loading}>
            <InputLabel id={labelHourId}>Opening Hours</InputLabel>
            <Select
              labelId={labelHourId}
              id={selectHourId}
              value={openingItem.startHour}
              onChange={(e) =>
                onChangeStartHour(
                  openingItem.id as string,
                  e.target.value as string,
                )
              }
              label="Opening Hours"
            >
              {optionHours}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl variant="outlined" fullWidth disabled={loading}>
            <InputLabel>Ending Hours</InputLabel>
            <Select
              value={openingItem.endHour}
              onChange={(e) =>
                onChangeEndHour(
                  openingItem.id as string,
                  e.target.value as string,
                )
              }
              label="Ending Hours"
            >
              {optionHours}
            </Select>
          </FormControl>
        </Grid>
        {buttonDelete}
      </Grid>
    </>
  );
};

/**
 * @returns {JSX.Element} Opening time fields.
 */
export const ExperienceOpeningTime: React.FC = () => {
  const classes = useStyles();
  const { setValue } = useFormContext();
  const { loadingSubmit, experience } = useContext(ExperienceFormContext);
  const [openingItems, setOpeningItems] = useState<ExperienceScheduleType[]>([
    defaultItem,
  ]);

  const openingItemsActive = useMemo(
    () => openingItems.filter(({ isActive }) => isActive),
    [openingItems],
  );

  useEffect(() => {
    if (experience) {
      const {
        experienceExperienceScheduleRelation: { items },
      } = experience;

      if (!items?.length) return;

      const openings = items.map((opening) => ({
        id: opening.id as string,
        startHour: opening.startHour as string,
        endHour: opening.endHour as string,
        day: opening.day as string,
        isActive: opening.isActive,
      }));

      setOpeningItems(openings);
      setValue('openingTimes', openings);
    }
  }, [experience, setValue]);

  /**
   * Function to add new opening time.
   *
   */
  const handleAddOpening = (): void => {
    if (loadingSubmit) return;

    const openingTime = { ...defaultItem, id: uniqueString() };
    const selectedItems = openingItems.map((openingItem) => ({
      ...openingItem,
    }));
    selectedItems.push(openingTime);

    setOpeningItems(selectedItems);
    setValue('openingTimes', selectedItems);
  };

  /**
   * @param {string} scheduleId - Schedule id.
   * @returns {ExperienceScheduleType | boolean | undefined}   Schedule.
   */
  const getSaveSchedule = (
    scheduleId: string,
  ): ExperienceScheduleType | boolean | undefined => {
    if (experience) {
      const {
        experienceExperienceScheduleRelation: { items: schedules },
      } = experience;

      return schedules?.find(({ id }) => id === scheduleId);
    }

    return false;
  };

  /**
   * @param {string} id - Id of opening item.
   */
  const handleRemoveOpening = (id: string): void => {
    if (loadingSubmit) return;

    let selectedItems = openingItems.filter(
      (openingItem) => openingItem.id !== id,
    );

    const selectedSchedule = getSaveSchedule(id);

    if (selectedSchedule) {
      selectedItems = openingItems.map((openingItem) => ({ ...openingItem }));

      const position = selectedItems.findIndex(
        (openingItem) => openingItem.id === id,
      );

      selectedItems[position] = {
        ...omit<ExperienceScheduleType>(['__typename'], selectedSchedule),
        isActive: false,
      };
    }

    setOpeningItems(selectedItems);
    setValue('openingTimes', selectedItems);
  };

  /**
   * @param {string} id - Id opening time.
   * @param {string} day - New day.
   */
  const handleChangeDay = (id: string, day: string): void => {
    const selectedItems = openingItems.map((openingItem) => ({
      ...openingItem,
    }));
    const position = selectedItems.findIndex(
      (openingItem) => openingItem.id === id,
    );
    selectedItems[position] = { ...selectedItems[position], day };

    setOpeningItems(selectedItems);
    setValue('openingTimes', selectedItems);
  };

  /**
   * @param {string} id - Id opening time.
   * @param {string} startHour - New hour.
   */
  const handleChangeStartHour = (id: string, startHour: string): void => {
    const selectedItems = openingItems.map((openingItem) => ({
      ...openingItem,
    }));
    const position = selectedItems.findIndex(
      (openingItem) => openingItem.id === id,
    );
    selectedItems[position] = { ...selectedItems[position], startHour };

    setOpeningItems(selectedItems);
    setValue('openingTimes', selectedItems);
  };

  /**
   * @param {string} id - Id opening time.
   * @param {string} endHour - End hour.
   */
  const handleChangeEndHour = (id: string, endHour: string): void => {
    const selectedItems = openingItems.map((openingItem) => ({
      ...openingItem,
    }));
    const position = selectedItems.findIndex(
      (openingItem) => openingItem.id === id,
    );
    selectedItems[position] = { ...selectedItems[position], endHour };

    setOpeningItems(selectedItems);
    setValue('openingTimes', selectedItems);
  };

  const content = openingItemsActive.map((openingItem, index) => (
    <Box mb="20px" key={openingItem.id} position="relative">
      <OpeningTimeItem
        openingItem={openingItem}
        onChangeDay={handleChangeDay}
        onChangeStartHour={handleChangeStartHour}
        onChangeEndHour={handleChangeEndHour}
        onRemoveOpening={handleRemoveOpening}
        loading={loadingSubmit}
        index={index}
      />
    </Box>
  ));

  return (
    <Box mt={6}>
      <Box mb="15px">
        <FormStepTitle>II. Opening times</FormStepTitle>
      </Box>
      {content}
      <Box mt="15px">
        <Link
          className={classes.link}
          component="button"
          onClick={handleAddOpening}
          type="button"
        >
          <BasicText color="primary">+ Add another</BasicText>
        </Link>
      </Box>
    </Box>
  );
};
