import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { makeStyles } from '@material-ui/core/styles';
import { ColumnType, OrderType } from '../../../../types';

type TableHeadItemProps = {
  column: ColumnType;
  order: OrderType;
  orderBy: string | null;
  onSort: (field: string) => void;
};

const useStyles = makeStyles({
  disabledHeader: {
    cursor: 'default',
    '&:hover': {
      color: 'inherit',
    }
  }
});

/**
 * @param props - Props received.
 * @param {ColumnType} props.column - Column data.
 * @param {string} props.order - Sort direciton.
 * @param {string} props.orderBy - Column selected for sort.
 * @param {Function} props.onSort - Method for ordering.
 *
 * @returns {JSX.Element} - Table head.
 */
export const TableHeadItem: React.FC<TableHeadItemProps> = ({
  column,
  order,
  orderBy,
  onSort,
}) => {
  const classes = useStyles();
  let isActive = false;

  if (column.sorteable) {
    isActive = Boolean(orderBy === column.field);
  }

  const direction = isActive ? order : 'asc';

  /**
   * Method to send sort column.
   */
  const handleOnClickSort = (): void => {
    if (!column.sorteable) return;

    onSort(column.field);
  };

  const sortClass = column.sorteable ? '' : classes.disabledHeader;

  return (
    <TableCell sortDirection={isActive ? order : false}>
      <TableSortLabel
        active={isActive}
        direction={direction}
        onClick={handleOnClickSort}
        hideSortIcon={!column.sorteable}
        className={sortClass}
      >
        {column.headerName}
      </TableSortLabel>
    </TableCell>
  );
};
