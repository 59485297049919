import gql from 'graphql-tag';

export const FETCH_ITINERARY_COUNT = gql`
  query FetchItineraryCount($filter: ItineraryFilter!) {
    itinerariesList(filter: $filter) {
      count
    }
  }
`;

export const FETCH_ITINERARY_CREDIT_AMOUNT = gql`
  query FetchItineraryAmount($filter: ItineraryFilter!) {
    itinerariesList(
      filter: $filter
      groupBy: {
        query: {
          itineraryCreditRelation: {
            amount: { as: "creditAmount", fn: { aggregate: SUM } }
          }
        }
      }
    ) {
      groups {
        creditAmount: Int
      }
    }
  }
`;

export const FETCH_ITINERARY_DEBIT_AMOUNT = gql`
  query FetchItineraryAmount($filter: ItineraryFilter!) {
    itinerariesList(
      filter: $filter
      groupBy: {
        query: {
          itineraryDebitRelation: {
            amount: { as: "debitAmount", fn: { aggregate: SUM } }
          }
        }
      }
    ) {
      groups {
        debitAmount: Int
      }
    }
  }
`;

export const FETCH_ITINERARY_ADULTS_SIZE = gql`
  query FetchItineraryPersonSize(
    $startDateTime: DateTime
    $endDateTime: DateTime
  ) {
    itinerariesList(
      filter: {
        startDateTime: { gte: $startDateTime }
        endDateTime: { lte: $endDateTime }
        itineraryBookingRelation: {
          some: {
            bookingStatus: { statusName: { equals: "Booking Confirmed" } }
          }
        }
      }
      groupBy: {
        query: { adultTravelers: { as: "adultsCount", fn: { aggregate: SUM } } }
      }
    ) {
      groups {
        adultsCount: Int
      }
    }
  }
`;

export const FETCH_ITINERARY_INFANTS_SIZE = gql`
  query FetchItineraryPersonSize(
    $startDateTime: DateTime
    $endDateTime: DateTime
  ) {
    itinerariesList(
      filter: {
        startDateTime: { gte: $startDateTime }
        endDateTime: { lte: $endDateTime }
        itineraryBookingRelation: {
          some: {
            bookingStatus: { statusName: { equals: "Booking Confirmed" } }
          }
        }
      }
      groupBy: {
        query: {
          infantTravelers: { as: "infantsCount", fn: { aggregate: SUM } }
        }
      }
    ) {
      groups {
        infantsCount: Int
      }
    }
  }
`;

export const FETCH_ITINERARY_CHILDREN_SIZE = gql`
  query FetchItineraryPersonSize(
    $startDateTime: DateTime
    $endDateTime: DateTime
  ) {
    itinerariesList(
      filter: {
        startDateTime: { gte: $startDateTime }
        endDateTime: { lte: $endDateTime }
        itineraryBookingRelation: {
          some: {
            bookingStatus: { statusName: { equals: "Booking Confirmed" } }
          }
        }
      }
      groupBy: {
        query: {
          childrenTravelers: { as: "childrensCount", fn: { aggregate: SUM } }
        }
      }
    ) {
      groups {
        childrensCount: Int
      }
    }
  }
`;

export const FETCH_PAID_ITINERARIES_COUNT = gql`
  query FetchPaidItinerariesCount(
    $startDateTime: DateTime!
    $endDateTime: DateTime!
  ) {
    itinerariesList(
      filter: {
        AND: [
          { createdAt: { gte: $startDateTime } }
          { createdAt: { lte: $endDateTime } }
        ]
        itineraryStatus: {
          OR: [
            { statusName: { equals: "PAID" } }
            { statusName: { equals: "COMPLETED" } }
          ]
        }
      }
    ) {
      count
    }
  }
`;
