import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import {
  Controller,
  useForm,
  FormProvider,
  SubmitHandler,
} from 'react-hook-form';
import { useCallAction, useFetchAction } from '@cobuildlab/react-simple-state';
import { yupResolver } from '@hookform/resolvers/yup';
import { PageTitle } from '../../shared/components/ui/texts/Texts';
import { Container } from './components/Container';
import { ButtonPrimary } from '../../shared/components/ui/buttons/Buttons';
import {
  fetchUser,
  updateUserAction,
  archiveUserAction,
  unarchiveUserAction,
} from './people-actions';
import { InterestsType } from '../../shared/types';
import * as toast from '../../shared/components/ui/toasts/Toast';
import { AccountGenderSelect } from '../user/components/account-form/AccountGenderSelect';
import { AccountLanguageSelect } from '../user/components/account-form/AccountLanguageSelect';
import { AccountTimeZoneSelect } from '../user/components/account-form/AccountTimeZoneSelect';
import { userSchemaValidation } from './people-validators';
import { InterestGrid } from './components/InterestGrid';
import { UserInfoLoading } from './UserInfoLoading';
import { DecisionDialog } from '../../shared/components/ui/dialogs/DecisionDialog';
import { useWebpageTitle } from '../../shared/hooks/useWebpageTitle';
import { formatPhoneNumber } from './people-utils';

type UserInfoInputs = {
  fullName: string;
  email: string;
  phoneNumber: string;
  gender: string;
  birthdate: Date;
  language: string;
  timezone: string;
  interests: InterestsType;
};

/**
 * @returns Userinfo component.
 */
export function UserInfo(): JSX.Element {
  const { id } = useParams<{ id: string }>();
  const methods = useForm({
    resolver: yupResolver(userSchemaValidation),
  });
  const history = useHistory();
  const [phoneMatcher, setPhoneMatcher] = useState(true);
  const [user, loadingUser] = useFetchAction(fetchUser, [id]);
  const [archiveDialog, setArchiveDialog] = useState(false);
  const [unarchiveDialog, setUnarchiveDialog] = useState(false);
  const [openPicker, setOpenPicker] = useState(false);

  const [updateUser, loadingUpdateUser] = useCallAction(updateUserAction, {
    /**
     * Callback to completed loaded user.
     */
    onCompleted: () => {
      toast.success('Success', 'User updated successfully');
    },

    /**
     * @param error - Error event.
     */
    onError: (error: TypeError) => {
      toast.error(error.message);
    },
  });

  const [archiveUser, loadingArchiveUser] = useCallAction(archiveUserAction, {
    /**
     * Callback to be completed when archiving an user.
     */
    onCompleted: () => {
      toast.success('Success', 'User archived successfully');
      history.push('/people');
    },

    /**
     * @param error - Error event.
     */
    onError: (error: TypeError) => {
      toast.error(error.message);
    },
  });

  const [unarchiveUser, loadingUnarchiveUser] = useCallAction(
    unarchiveUserAction,
    {
      /**
       * Callback to be completed when unarchiving an user.
       */
      onCompleted: () => {
        toast.success('Success', 'User unarchived successfully');
        history.push('/people');
      },

      /**
       * @param error - Error event.
       */
      onError: (error: TypeError) => {
        toast.error(error.message);
      },
    },
  );

  const handlePhoneFormat = (value: string | number): void => {
    formatPhoneNumber({
      numberValue: value,
      onMatcher: (isMatched) => setPhoneMatcher(isMatched),
      onMatchValue: (formattedValue: string) => methods.setValue('phoneNumber', formattedValue)
    });
  };

  /**
   * @param {UserInfoInputs} inputs - Account input data.
   */
  const onSubmit: SubmitHandler<UserInfoInputs> = (
    inputs: UserInfoInputs,
  ): void => {
    const language = {
      reconnect: {
        id: inputs.language,
      },
    };

    const gender = {
      reconnect: {
        id: inputs.gender,
      },
    };

    const data = { ...inputs, gender, language, id };
    updateUser(data);
  };

  /**
   * @description - Confirm user archive.
   */
  const onArchive = (): void => {
    setArchiveDialog(true);
  };

  /**
   * @description - Confirm user unarchive.
   */
  const onUnarchive = (): void => {
    setUnarchiveDialog(true);
  };

  /**
   * @description - Handle cancelation.
   */
  const onCancel = (): void => {
    history.push('/people');
  };

  useWebpageTitle('Candid Travel Ventures - User Information');

  if (loadingUser) {
    return <UserInfoLoading />;
  }

  return (
    <>
      <PageTitle text="People" />
      <Container>
        <Typography variant="h5">
          <Box fontWeight="fontWeightMedium">User Information</Box>
        </Typography>
        <Box mt="20px" mb="50px">
          <Divider />
        </Box>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Controller
                  name="fullName"
                  defaultValue={user.fullName}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      disabled={loadingUser}
                      error={!!error}
                      helperText={error && error.message}
                      fullWidth
                      label="Full name"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="email"
                  defaultValue={user.email}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      disabled={loadingUser}
                      error={!!error}
                      helperText={error && error.message}
                      fullWidth
                      label="Email"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="phoneNumber"
                  defaultValue={user.phoneNumber}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      disabled={loadingUser}
                      error={!!error || !phoneMatcher}
                      helperText={error && error.message}
                      fullWidth
                      label="Phone number"
                      variant="outlined"
                      onKeyUp={() => handlePhoneFormat(field.value)}
                      inputProps={{
                        pattern: '.([0-9]{3}). ([0-9]{3}) ([0-9]{4})',
                        minlength: 14,
                        maxlength: 14,
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  disabled={loadingUser}
                >
                  <InputLabel>Gender</InputLabel>
                  <AccountGenderSelect user={user} />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="birthdate"
                  defaultValue={
                    user.birthdate ? new Date(user.birthdate) : undefined
                  }
                  render={({ field, fieldState: { error } }) => (
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                        disabled={loadingUser}
                        disableFuture
                        error={!!error}
                        format="MM/DD/yyyy"
                        fullWidth
                        helperText={error && error.message}
                        inputVariant="outlined"
                        label="Date of birth"
                        margin="none"
                        onAccept={() => setOpenPicker(false)}
                        onChange={(date) =>
                          date && field.onChange(date.toDate())
                        }
                        onClose={() => setOpenPicker(false)}
                        onOpen={() => setOpenPicker(true)}
                        open={openPicker}
                        value={field.value}
                        variant="inline"
                      />
                    </MuiPickersUtilsProvider>
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  disabled={loadingUser}
                >
                  <InputLabel>Language</InputLabel>
                  <AccountLanguageSelect user={user} />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  disabled={loadingUser}
                >
                  <InputLabel>Time Zone</InputLabel>
                  <AccountTimeZoneSelect user={user} />
                </FormControl>
              </Grid>
            </Grid>
            <Controller
              name="interests"
              defaultValue={user.interests}
              render={() => <InterestGrid loading={loadingUser} />}
            />
            <Grid container>
              <Grid item xs={6}>
                {user.archivedAt ? (
                  <ButtonPrimary
                    onClick={onUnarchive}
                    variant="outlined"
                    color="secondary"
                  >
                    UNARCHIVE USER
                  </ButtonPrimary>
                ) : (
                  <ButtonPrimary
                    onClick={onArchive}
                    variant="outlined"
                    color="secondary"
                  >
                    ARCHIVE USER
                  </ButtonPrimary>
                )}
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={2} justifyContent="flex-end">
                  <Grid item>
                    <ButtonPrimary variant="outlined" onClick={onCancel}>
                      CANCEL
                    </ButtonPrimary>
                  </Grid>
                  <Grid item>
                    <ButtonPrimary
                      type="submit"
                      isLoading={loadingUpdateUser || loadingArchiveUser}
                    >
                      SAVE CHANGES
                    </ButtonPrimary>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </Container>
      <DecisionDialog
        isOpen={archiveDialog}
        title="Archive User"
        loading={loadingArchiveUser}
        onCancel={() => setArchiveDialog(false)}
        onClose={() => setArchiveDialog(false)}
        onConfirm={() => archiveUser(id, user.email)}
        cancelText="Cancel"
        confirmText="Yes, Archive"
      >
        <Box mb="10px">
          <Typography variant="body1">
            Are you sure you want to archive this User?
          </Typography>
        </Box>
      </DecisionDialog>
      <DecisionDialog
        isOpen={unarchiveDialog}
        title="Unarchive User"
        loading={loadingUnarchiveUser}
        onCancel={() => setUnarchiveDialog(false)}
        onClose={() => setUnarchiveDialog(false)}
        onConfirm={() => unarchiveUser(id, user.email)}
        cancelText="Cancel"
        confirmText="Yes, Unarchive"
      >
        <Box mb="10px">
          <Typography variant="body1">
            Are you sure you want to unarchive this User?
          </Typography>
        </Box>
      </DecisionDialog>
    </>
  );
}
