import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { CURRENT_USER_QUERY } from './auth-callback-queries';
// import { AUTH_PROFILE_ID } from '../../../shared/constants';
// import { OnTokenEvent } from '../../events/token-event';
import { useSetupAuth0Token } from '../auth-hooks';
import { UserType } from '../../../shared/types';

// TODO add loading component
/**
 * @param {object} props - Props.
 * @param {object} props.history - History of rect router.
 * @returns {JSX.Element} - Auth callback component.
 */
export function AuthCallback({ history }: RouteComponentProps): JSX.Element {
  const client = useApolloClient();


  const { isAuthenticated } = useAuth0();

  const loadingAuthToken = useSetupAuth0Token();
  useEffect(() => {
    /**
     * Handle auth function.
     */
    const handleAuthentication = async (): Promise<void> => {
      let response;

      try {
        response = await client.query<{user: UserType}>({
          query: CURRENT_USER_QUERY,
          fetchPolicy: 'network-only',
        });
      } catch (error) {
        console.log('error with CURRENT_USER_QUERY', error);
        console.log('error with CURRENT_USER_QUERY', JSON.stringify(error));
        history.push('/logout');
      }
      
      const currentUser = response?.data?.user;
      const isAdmin = currentUser?.roles?.items?.some(({ name }) => name === 'Administrator');
      
      if (!isAdmin) {
        history.push('/logout');
      } else {
        history.push('/');
      }
    };

    if (isAuthenticated && !loadingAuthToken) {
      handleAuthentication();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, loadingAuthToken ]);

  // NOT ADD LOADING COMPONENT HERE this must be a loading auth component
  return <div>authenticated....</div>;
}
