import React from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import HistoryIcon from '@material-ui/icons/History';
import moment from 'moment-timezone';
import { TableFilters } from '../../../shared/components/ui/tables/components/TableFilters';
import { BookingFilterState } from '../components/BookingListMenuFilter';

/**
 * @param filterState - Filter state.
 * @param onApplyFilters - OnApplyFilters function.
 * @param onResetFilter - Function to reset filter.
 * @returns - RenderTableFilters function.
 */
export function renderTableFiltersFactory(
  filterState: BookingFilterState,
  onApplyFilters: (filterState: BookingFilterState) => void,
  onResetFilter: (filterState: BookingFilterState) => void,
): () => JSX.Element {
  const startDate = moment
    .tz(moment().format(), 'YYYY/MM/DD', 'UTC')
    .set({ hour: 0, minute: 0, second: 0 })
    .format();

  const endDate = moment
    .tz(moment().format(), 'YYYY/MM/DD', 'UTC')
    .set({ hour: 23, minute: 59, second: 59 })
    .format();

  return function renderTableFilters(): JSX.Element {
    return (
      <>
        <Box ml="19px" mb="20px">
          <Grid container>
            <Grid item>
              <Button
                onClick={() => {
                  onApplyFilters({
                    startDate,
                  } as BookingFilterState);
                }}
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<MonetizationOnIcon />}
              >
                Current Bookings
              </Button>
            </Grid>
            <Grid item>
              <Box width="10px" />
            </Grid>
            <Grid item>
              <Button
                onClick={() => {
                  onApplyFilters({
                    endDate,
                  } as BookingFilterState);
                }}
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<HistoryIcon />}
              >
                Past Bookings
              </Button>
            </Grid>
          </Grid>
        </Box>
        <TableFilters<BookingFilterState>
          filterState={filterState}
          onResetFilter={onResetFilter}
        />
      </>
    );
  };
}
