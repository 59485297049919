import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { useFetchAction } from '@cobuildlab/react-simple-state';
import { fetchRevenueAction } from '../dashboard-actions';
import { ChartOptionValueType } from './DashboardChartDropdown';
import { DashboardChart } from './DashboardChart';

type DashboarRevenueProps = {
  loadingDashboard: boolean;
};

const dates = [
  moment().subtract(365, 'd').endOf('day').format(),
  moment().subtract(240, 'd').endOf('day').format(),
  moment().subtract(120, 'd').endOf('day').format(),
  moment().subtract(90, 'd').endOf('day').format(),
  moment().subtract(60, 'd').endOf('day').format(),
  moment().subtract(45, 'd').endOf('day').format(),
  moment().subtract(30, 'd').endOf('day').format(),
  moment().endOf('day').format(),
];

/**
 * Position is the position in array of dates above.
 */
const options = [
  {
    label: '30 days',
    value: {
      amountDays: 30,
      position: 6,
    },
  },
  {
    label: '45 days',
    value: {
      amountDays: 45,
      position: 5,
    },
  },
  {
    label: '60 days',
    value: {
      amountDays: 60,
      position: 4,
    },
  },
  {
    label: '90 days',
    value: {
      amountDays: 90,
      position: 3,
    },
  },
  {
    label: '120 days',
    value: {
      amountDays: 120,
      position: 2,
    },
  },
  {
    label: '240 days',
    value: {
      amountDays: 240,
      position: 1,
    },
  },
  {
    label: '365 days',
    value: {
      amountDays: 365,
      position: 0,
    },
  },
];

/**
 *
 * @param props - Props received.
 * @param {boolean} props.loadingDashboard - Loading Dashboard.
 * @returns {JSX.Element} Revenue chart.
 */
export const DashboardRevenueChart: React.FC<DashboarRevenueProps> = ({
  loadingDashboard,
}) => {
  const [revenueAmount, setRevenueAmount] = useState<number[]>([]);
  const [xValues, setXValues] = useState<string[]>([]);
  const [yValues, setYValues] = useState<number[]>([]);

  const [revenueData, loading] = useFetchAction(fetchRevenueAction, [dates], {
    skip: loadingDashboard,
  });

  const handleSaveXValues = useCallback(
    (daysDiff: number, totalValues: number) => {
      const initMonth = moment()
        .subtract(daysDiff, 'd')
        .startOf('month')
        .format('MMM');
      const initDate = moment().subtract(daysDiff, 'd').date();

      /**
       * Only the first and last value of the axis will be shown.
       */
      const labels = Array(totalValues).fill(' ');
      labels[0] = `${initMonth} ${initDate}`;
      labels[totalValues - 1] = 'Today';

      setXValues(labels);
    },
    [],
  );

  /**
   * @param {ChartOptionValueType} value - Selected option value.
   */
  const handleSelectedOption = (value: ChartOptionValueType): void => {
    const selectedAmounts = revenueAmount.slice(
      value.position,
      revenueAmount.length,
    );
    setYValues(selectedAmounts.map((amount) => amount));
    handleSaveXValues(value.amountDays, selectedAmounts.length);
  };

  useEffect(() => {
    if (revenueData && !revenueAmount.length) {
      const revenues: number[] = revenueData.map(
        (amount: number | null) => amount || 0,
      );

      setRevenueAmount(revenues);
    }
  }, [revenueData, revenueAmount]);

  useEffect(() => {
    if (revenueAmount.length && !yValues.length) {
      /**
       * By default selects the amounts up to the last 90 days.
       */
      const selectedAmounts = revenueAmount.slice(3, revenueAmount.length);
      setYValues(selectedAmounts.map((amount) => amount));
    }
  }, [yValues.length, revenueAmount]);

  useEffect(() => {
    if (!xValues.length && yValues.length) {
      handleSaveXValues(90, yValues.length);
    }
  }, [xValues.length, yValues.length, handleSaveXValues]);

  let isLoading = loading || loadingDashboard;

  if (!revenueData) {
    isLoading = true;
  }

  return (
    <DashboardChart
      title="Revenue"
      xValues={xValues}
      yValues={yValues}
      loading={isLoading}
      menuOptions={options}
      onSelectedOption={handleSelectedOption}
      symbol="$"
    />
  );
};
