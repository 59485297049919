import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Divider } from '@material-ui/core';
import { InboxHeader } from './components/InboxHeader';
import { InboxContainer } from './components/InboxContainer';
import { InboxSearch } from './components/InboxSearch';
import { useWebpageTitle } from '../../shared/hooks/useWebpageTitle';
import { InboxList } from './components/InboxList';
import { InboxChat } from './components/InboxChat';

/**
 *
 * @returns {JSX.Element} Inbox view.
 */
export const Inbox: React.FC = () => (
  <div>
    {useWebpageTitle('Candid Travel Ventures - Inbox')}
    <InboxHeader />
    <Box mt={4}>
      <InboxContainer title="Inbox">
        <Grid container>
          <Grid item xs={5} style={{ maxHeight: '800px' }}>
            <Box paddingX={0}>
              <InboxSearch />
              <InboxList />
            </Box>
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid item xs={6} style={{ margin: '0px auto' }}>
            <InboxChat />
          </Grid>
        </Grid>
      </InboxContainer>
    </Box>
  </div>
);
