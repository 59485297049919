import gql from 'graphql-tag';

export const FETCH_STATES = gql`
  query FetchStates {
    statesList {
      items {
        id
        stateName
      }
    }
  }
`;

export const FETCH_STATES_BY_NAME = gql`
  query FetchStatesByName($stateName: String!) {
    statesList(filter: { stateName: { equals: $stateName } }) {
      items {
        id
        stateName
      }
    }
  }
`;

export const CREATE_STATE = gql`
  mutation CreateState($data: StateCreateInput!) {
    stateCreate(data: $data) {
      id
      stateName
    }
  }
`;

export const FETCH_STATE_BY_ID = gql`
  query FetchStateById($id: ID!) {
    state(id: $id) {
      id
      stateName
    }
  }
`;
