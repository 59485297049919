import gql from 'graphql-tag';

export const FETCH_GENDER_TYPES = gql`
  query FetchGenderTypes($isActive: Boolean) {
    genderTypesList(filter: { isActive: { equals: $isActive } }) {
      items {
        id
        genderName
      }
    }
  }
`;
