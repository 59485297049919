import React, { useEffect, useState, useContext } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, styled } from '@material-ui/core/styles';
import * as filestack from 'filestack-js';
import prettyBytes from 'pretty-bytes';
import { DeleteRedIcon } from '../../../../shared/icons/icons';
import { BLACK_TITLE } from '../../../../shared/components/ui/theme/contants';
import { FileUploadInfoType } from '../../../../shared/types';
import { ExperienceFormContext } from './ExperienceFormProvider';
import LogoPdf from '../../../../shared/assets/images/logoPdf.png';

export type FileType = {
  id: string;
  filename: string;
};

export type DragDropItemType = {
  id: string;
  source: string;
  name: string;
  size: number;
  fileToUpload?: File;
  uploadedFile?: FileType;
};

type ProgressEventType = {
  totalPercent: number;
  totalBytes: number;
};

type DragDropItemProps = {
  dragDropItem: DragDropItemType;
  fileUploadInfo: FileUploadInfoType;
  onDelete: (id: string) => void;
  onAddUploadedFile: (id: string, uploadedFile: FileType) => void;
};

const ImgTitle = styled(Typography)({
  maxWidth: '400px',
  color: BLACK_TITLE,
  textOverflow: 'ellipsis',

  overflow: 'hidden',
});

const TextLoading = styled(Typography)({
  color: '#09A7FA',
});

const useStyles = makeStyles({
  container: {
    borderBottom: '1px dashed #BBBBBB',
  },
  img: {
    width: '76px',
    height: '43px',
    objectFit: 'cover',
  },
  button: {
    height: '1px',
  },
});

/**
 * @param props - Props received.
 * @param {DragDropItemType} props.dragDropItem - Data drag drop.
 * @param {FileUploadInfoType} props.fileUploadInfo - File upload info.
 * @param {Function} props.onDelete - To delete drag drop item.
 * @param {Function} props.onAddUploadedFile - Function to save file in form state.
 *
 * @returns {JSX.Element} Drag drop item.
 */
export const DragDropItem: React.FC<DragDropItemProps> = ({
  dragDropItem,
  fileUploadInfo,
  onDelete,
  onAddUploadedFile,
}) => {
  const classes = useStyles();
  const { loadingSubmit } = useContext(ExperienceFormContext);
  const [path, setPath] = useState<string>();
  const [client, setClient] = useState<filestack.Client>();
  const [loadingPercent, setLoadingPercent] = useState<number>(0);

  useEffect(() => {
    if (fileUploadInfo) {
      setPath(fileUploadInfo.path);

      setClient(
        filestack.init(fileUploadInfo.apiKey, {
          security: {
            policy: fileUploadInfo.policy,
            signature: fileUploadInfo.signature,
          },
        }),
      );
    }
  }, [fileUploadInfo]);

  useEffect(() => {
    if (!client || !dragDropItem.fileToUpload) return;

    const storeOptions = { path };

    const uploadOptions = {
      /**
       * @param {ProgressEventType} env - Event.
       */
      onProgress: (env: ProgressEventType) => {
        setLoadingPercent(env.totalPercent);
      },
    };

    /**
     * Function to get data from file.
     * An action was not used since upload returns the result.
     *
     * @returns {Promise<void>} File uploaded.
     */
    const uploadFile = async (): Promise<void> => {
      let response;
      try {
        response = await client.upload(
          dragDropItem.fileToUpload as filestack.InputFile,
          uploadOptions,
          storeOptions,
        );
      } catch (e) {
        console.log('*** error ***', e);
      }

      const uploadedFile = { id: response.handle, filename: response.filename };
      onAddUploadedFile(dragDropItem.id, uploadedFile);
    };

    uploadFile();
  }, [dragDropItem, client, path, onAddUploadedFile]);

  /**
   * @returns {boolean} Check pdf extension.
   */
  const isPdf = (): boolean => {
    const { name: filename } = dragDropItem;
    const ext = filename.substring(filename.lastIndexOf('.') + 1);

    return ext === 'pdf';
  };

  let progress = null;
  let deleteIcon = null;

  if (dragDropItem?.fileToUpload) {
    progress = (
      <Box paddingLeft="15px">
        <TextLoading>{loadingPercent}% completed</TextLoading>
      </Box>
    );
  }

  if (!dragDropItem?.fileToUpload) {
    deleteIcon = (
      <IconButton
        className={classes.button}
        onClick={() => onDelete(dragDropItem.id)}
        disabled={loadingSubmit}
      >
        <DeleteRedIcon />
      </IconButton>
    );
  }

  const source = isPdf() ? LogoPdf : dragDropItem.source;

  return (
    <Box mb="15px" className={classes.container}>
      <Box display="flex" alignItems="center" mb="10px">
        <Box mr="16px">
          <img className={classes.img} src={source} alt={dragDropItem.name} />
        </Box>
        <Box>
          <Box>
            <Box display="flex">
              <ImgTitle>{dragDropItem.name}</ImgTitle>
              {deleteIcon}
            </Box>
            <Box display="flex">
              <Typography>{prettyBytes(dragDropItem.size)}</Typography>
              {progress}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
