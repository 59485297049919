import React, { useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import { useFormContext, Controller } from 'react-hook-form';
import { FormStepTitle } from '../../../../shared/components/ui/texts/Texts';
import { ExperienceFormContext } from './ExperienceFormProvider';

/**
 * @returns {JSX.Element} Additional information field.
 */
export const ExperienceRestrictions: React.FC = () => {
  const { control } = useFormContext();
  const { loadingSubmit, experience } = useContext(ExperienceFormContext);

  return (
    <Box mt={6}>
      <Box mb="15px">
        <FormStepTitle>
          IV. Seasonal Restrictions/Additional Information
        </FormStepTitle>
      </Box>
      <Grid item xs={12}>
        <Controller
          name="experienceAdditionalInformation"
          control={control}
          defaultValue={experience?.experienceAdditionalInformation || ''}
          render={({ field }) => (
            <TextField
              {...field}
              disabled={loadingSubmit}
              fullWidth
              variant="outlined"
              label="Seasonal restrictions"
            />
          )}
        />
      </Grid>
    </Box>
  );
};
