import React from 'react';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import { COLOR_PRIMARY, COLOR_INACTIVE } from './ui/theme/contants';

type StyleProps = {
  isActive: boolean;
};

type PillStatusProps = {
  isActive: boolean;
  text: string;
};

const useStyles = makeStyles({
  pill: {
    borderRadius: '5px',
    height: '25px',
    width: 'fit-content',
    color: '#fff',
    /**
     * @param props - Props received.
     * @param props.isActive - If active .
     *
     * @returns {string} Background color.
     */
    backgroundColor: (props: StyleProps) =>
      props.isActive ? COLOR_PRIMARY : COLOR_INACTIVE,
  },
});

/**
 * @param props - Props received.
 * @param {boolean} props.isActive - If active.
 * @param {string} props.text - Text of pill.
 *
 * @returns {JSX.Element} Pill.
 */
export const PillStatus: React.FC<PillStatusProps> = ({ isActive, text }) => {
  const classes = useStyles({ isActive });

  return <Chip className={classes.pill} label={text} />;
};
