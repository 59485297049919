import React, { useContext } from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import {
  ButtonPrimary,
  ButtonOutlinedPrimary,
} from '../../../../shared/components/ui/buttons/Buttons';
import { ExperienceFormContext } from './ExperienceFormProvider';

const useStyles = makeStyles({
  button: {
    padding: '14px 35px',
  },
});

/**
 * @returns {JSX.Element} Buttons form.
 */
export const ExperienceFormButtons: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { loadingUpload, loadingSubmit } = useContext(ExperienceFormContext);

  /**
   * Function to cancel.
   */
  const handleCancel = (): void => {
    if (history.length) {
      history.goBack();
    } else {
      history.push('/');
    }
  };

  return (
    <Box mt={5} mb={6} display="flex" justifyContent="flex-end">
      <ButtonOutlinedPrimary
        disabled={loadingUpload || loadingSubmit}
        className={classes.button}
        onClick={handleCancel}
        type="button"
      >
        CANCEL
      </ButtonOutlinedPrimary>
      <Box ml="20px">
        <ButtonPrimary
          disabled={loadingUpload}
          isLoading={loadingSubmit}
          className={classes.button}
          type="submit"
        >
          SAVE CHANGES
        </ButtonPrimary>
      </Box>
    </Box>
  );
};
