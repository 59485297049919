import { createAction } from '@cobuildlab/react-simple-state';
import {
  OnCityList,
  OnCityListError,
  OnCityListByName,
  OnCityListByNameError,
  OnCityById,
  OnCityByIdError,
  OnCityCreate,
  OnCityCreateError,
} from './city-events';
import {
  FETCH_CITY_BY_STATE,
  FETCH_CITY_BY_NAME,
  FETCH_CITY_BY_ID,
  CREATE_CITY,
} from './city-queries';
import { apolloClient } from '../../shared/apollo';

export const fetchCitiesAction = createAction(
  OnCityList,
  OnCityListError,
  async (stateId) => {
    const response = await apolloClient.query({
      query: FETCH_CITY_BY_STATE,
      variables: {
        stateId,
      },
    });

    return response?.data?.citiesList?.items;
  },
);

export const fetchCitiesByNameAction = createAction(
  OnCityListByName,
  OnCityListByNameError,
  async (cityName) => {
    const response = await apolloClient.query({
      query: FETCH_CITY_BY_NAME,
      fetchPolicy: 'network-only',
      variables: {
        cityName,
      },
    });

    return response?.data?.citiesList?.items;
  },
);

export const fetchCityByIdAction = createAction(
  OnCityById,
  OnCityByIdError,
  async (id) => {
    const response = await apolloClient.query({
      query: FETCH_CITY_BY_ID,
      fetchPolicy: 'network-only',
      variables: {
        id,
      },
    });

    return response?.data?.city;
  },
);

export const createCityAction = createAction(
  OnCityCreate,
  OnCityCreateError,
  async (data) => {
    const response = await apolloClient.mutate({
      mutation: CREATE_CITY,
      fetchPolicy: 'network-only',
      variables: {
        data,
      },
    });

    return response?.data?.cityCreate;
  },
);
