import moment from 'moment';
import { BookingListDataType } from './booking-model';
import { BookingType, BookingPricingType } from '../../shared/types';

/**
 *
 * @param {BookingListDataType} data - Data to filter.
 * @returns {Record<string, any>} - Filter data.
 */
export const createFilterBookingsList = (
  data: BookingListDataType,
): Record<string, unknown> => {
  const statusFilter = {
    bookingStatus: {
      statusName: {
        equals: data.status,
      },
    },
  };

  const textFilter = {
    OR: [
      {
        user: {
          fullName: {
            contains: data.searchText,
          },
        },
      },
      {
        user: {
          email: {
            contains: data.searchText,
          },
        },
      },
      {
        experience: {
          experienceName: {
            contains: data.searchText,
          },
        },
      },
    ],
  };

  const AND = [];

  if (data.status) {
    AND.push(statusFilter);
  }

  if (data.searchText) {
    AND.push(textFilter);
  }

  if (data.startDate) {
    AND.push({
      startDateTime: {
        gte: data.startDate,
      },
    });
  }

  if (data.endDate) {
    AND.push({
      startDateTime: {
        lte: data.endDate,
      },
    });
  }

  return { AND };
};

/**
 * @param {BookingType} booking - Booking data.
 * @returns {number}  Total price.
 */
export function calculateTotalPrice(booking: BookingType): number {
  return booking.bookingBookingPricingRelation.items.reduce(
    (previousValue: number, currentValue: BookingPricingType) =>
      previousValue + currentValue.price * currentValue.peopleAmount,
    0,
  );
}

/**
 * @param {BookingType} booking - Booking data.
 * @returns {string}  Time elapsed in seconds from creation to confirmation.
 */
export function calculateTimeElapsed(booking: BookingType): string {
  return booking.timeElapsed === null
    ? 'N/A'
    : moment.duration(booking.timeElapsed, 'seconds').humanize();
}

/**
 * @param {BookingType} bookingData - Booking data.
 * @returns {Record<string, number>} - The max number of travelers for each type.
 */
export const calculateTravelersOfBooking = (
  bookingData: BookingType,
): Record<string, number> => {
  const travelers: Record<string, number> = {};

  bookingData.bookingBookingPricingRelation.items.forEach((bookingPricing) => {
    travelers[bookingPricing.travelerType.travelerTypeName] =
      bookingPricing.peopleAmount;
  });

  return travelers;
};
