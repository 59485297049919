import gql from 'graphql-tag';

export const FETCH_CITY_BY_STATE = gql`
  query FetchCitiesByState($stateId: ID) {
    citiesList(filter: { state: { id: { equals: $stateId } } }) {
      items {
        id
        cityName
      }
    }
  }
`;

export const FETCH_CITY_BY_NAME = gql`
  query FetchCityByName($cityName: String!) {
    citiesList(filter: { cityName: { equals: $cityName } }) {
      items {
        id
        cityName
      }
    }
  }
`;

export const FETCH_CITY_BY_ID = gql`
  query FetchCityById($id: ID!) {
    city(id: $id) {
      id
      cityName
    }
  }
`;

export const CREATE_CITY = gql`
  mutation CreateCity($data: CityCreateInput!) {
    cityCreate(data: $data) {
      id
      cityName
    }
  }
`;
