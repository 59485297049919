/* eslint-disable no-restricted-imports */
import { styled } from '@material-ui/core/styles';
import { Chip } from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';
import yellow from '@material-ui/core/colors/yellow';
import orange from '@material-ui/core/colors/orange';
import blue from '@material-ui/core/colors/blue';

const COLOR_GREY = grey[500];
const COLOR_YELLOW = yellow[500];
const COLOR_ORANGE = orange[500];
const COLOR_BLUE = blue[500];

export const PrimaryChip = styled(Chip)({
  borderRadius: 5,
});

PrimaryChip.defaultProps = {
  color: 'primary',
};

export const SecondaryChip = styled(Chip)({
  borderRadius: 5,
});

SecondaryChip.defaultProps = {
  color: 'secondary',
};

export const GreyChip = styled(Chip)({
  borderRadius: 5,
  backgroundColor: COLOR_GREY,
  color: '#fff',
});

export const YellowChip = styled(Chip)({
  borderRadius: 5,
  backgroundColor: COLOR_YELLOW,
});

export const OrangeChip = styled(Chip)({
  borderRadius: 5,
  backgroundColor: COLOR_ORANGE,
  color: '#fff',
});

export const BlueChip = styled(Chip)({
  borderRadius: 5,
  backgroundColor: COLOR_BLUE,
  color: '#fff',
});
