import React from 'react';
import {
  PrimaryChip,
  SecondaryChip,
  GreyChip,
  OrangeChip,
  YellowChip,
  BlueChip,
} from '../../../shared/components/ui/chips/Chips';

type SwitchChipPros = {
  status: string;
};

/**
 * @param props - Props.
 * @param props.status - Booking status.
 * @returns {JSX.Element} - Chip component.
 */
export function SwitchChip({ status }: SwitchChipPros): JSX.Element | null {
  switch (status) {
  case null:
    return null;
  case 'DRAFT':
    return <GreyChip label="Draft" />;
  case 'PAID':
    return <PrimaryChip label="Paid" />;
  case 'CONFIRMED':
    return <YellowChip label="Confirmed" />;
  case 'REJECTED':
    return <SecondaryChip label="Rejected" />;
  case 'COMPLETED':
    return <OrangeChip label="Completed" />;
  case 'CANCELLED_SYSTEM':
    return <SecondaryChip label="Cancelled" />;
  case 'REFUNDED':
    return <BlueChip label="Refunded" />;
  default:
    return <PrimaryChip label={status} />;
  }
}
