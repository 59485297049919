import gql from 'graphql-tag';

export const FETCH_INVOICE_TOTAL = gql`
  query FetchInvoiceTotal($date: DateTime!) {
    invoicesList(
      filter: { createdAt: { lte: $date }, status: { equals: "paid" } }
      groupBy: { query: { total: { as: "amount", fn: { aggregate: SUM } } } }
    ) {
      groups {
        amount: Float
      }
    }
  }
`;
