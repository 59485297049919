import gql from 'graphql-tag';

export const FETCH_ITINERARY_KPI = gql`
  query FetchPaidItinerariesKpi(
    $startDateTime: DateTime!
    $endDateTime: DateTime!
  ) {
    itineraryKpisList(
      filter: {
        AND: [
          { createdAt: { gte: $startDateTime } }
          { createdAt: { lte: $endDateTime } }
        ]
      }
      orderBy: createdAt_DESC
      first: 1
    ) {
      items {
        id
        averageDays
        averageCost
        averagePeople
        averageRequestResponse
      }
    }
  }
`;
