import React from 'react';
import { styled } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import EventIcon from '@material-ui/icons/Event';
import moment from 'moment';
import { ButtonPrimary } from '../../../shared/components/ui/buttons/Buttons';
import { Anchor } from '../../../shared/components/ui/anchor/Anchor';
import { BookingType } from '../../../shared/types';
import { DialogTitle } from '../../../shared/components/ui/dialogs/DialogTitle';
import { calculateTravelersOfBooking } from '../booking-utils';

const OperatorDetails = styled('div')({
  padding: 20,
  borderRradius: 4,
  boxShadow: '0 3px 20px 0 rgba(171,171,171,0.35)',
});

type BookingDetailsProps = {
  booking: BookingType | null;
  isOpen: boolean;
  onClose: () => void;
  onConfirmBooking: (booking: BookingType) => void;
  onRejectBooking: (booking: BookingType) => void;
};

/**
 * @param props - Props received.
 * @param {BookingType | null} props.booking - Booking data.
 * @param {boolean} props.isOpen - Check if is open.
 * @param {Function} props.onClose - Function to close.
 * @param {Function} props.onConfirmBooking - Function to confirm booking.
 * @param {Function} props.onRejectBooking - Function to reject booking.
 *
 * @returns {JSX.Element} Booking details.
 */
export const BookingDetails: React.FC<BookingDetailsProps> = ({
  booking,
  isOpen,
  onClose,
  onConfirmBooking,
  onRejectBooking,
}) => {
  if (!booking) {
    return null;
  }

  const travelers = calculateTravelersOfBooking(booking);

  const rejectionReason = (
    <Box mt="20px" mb="20px">
      <OperatorDetails>
        <Typography variant="button" display="block" gutterBottom>
          REJECTION REASON
        </Typography>
        {booking.rejectionReason}
      </OperatorDetails>
    </Box>
  );

  const approveButton = (
    <Grid item>
      <ButtonPrimary
        onClick={() => {
          onConfirmBooking(booking);
          onClose();
        }}
      >
        CONFIRM BOOKING
      </ButtonPrimary>
    </Grid>
  );

  const rejectButton = (
    <Grid item>
      <ButtonPrimary
        variant="outlined"
        color="secondary"
        onClick={() => {
          onRejectBooking(booking);
          onClose();
        }}
      >
        REJECT
      </ButtonPrimary>
    </Grid>
  );

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle onClose={onClose}>Booking Details</DialogTitle>
      <DialogContent>
        <Box mt="20px" mb="20px">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                label="REQUESTED DATE"
                value={moment.tz(booking.createdAt, 'UTC').format('MM/DD/YYYY')}
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment position="start">
                      <EventIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="EXPERIENCE"
                variant="outlined"
                value={booking.experience.experienceName}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="CLIENT NAME"
                variant="outlined"
                value={booking.user.fullName}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="EMAIL"
                variant="outlined"
                value={booking.user.email}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="outlined"
                label="START DATE"
                value={moment
                  .tz(booking.startDateTime, 'UTC')
                  .format('MM/DD/YYYY')}
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment position="start">
                      <EventIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="outlined"
                label="START TIME"
                value={moment.tz(booking.startDateTime, 'UTC').format('h:mm A')}
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment position="start">
                      <EventIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {Object.entries(travelers)
              .sort(([a], [b]) => {
                if (a < b) {
                  return -1;
                }
                if (a > b) {
                  return 1;
                }
                return 0;
              })
              .map(([key, value]) => (
                <Grid item xs>
                  <TextField
                    fullWidth
                    label={key}
                    variant="outlined"
                    value={value}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
              ))}
          </Grid>
          <Box mt="20px" mb="20px">
            <OperatorDetails>
              <Typography variant="button" display="block" gutterBottom>
                OPERATOR
              </Typography>
              {booking.experience.operatorsName}
              <br />
              {booking.experience.phoneNumber}
              <br />
              <Anchor href="mailto:spacecenterhouston@nasa.gov">
                {booking.experience.contactEmailAddress}
              </Anchor>
            </OperatorDetails>
          </Box>
          {booking.bookingStatus.statusName === 'REJECTED' && rejectionReason}
          <Grid container spacing={2} justifyContent="flex-end">
            {(booking.bookingStatus.statusName === 'PAID' ||
              booking.bookingStatus.statusName === 'DRAFT') &&
              rejectButton}
            {booking.bookingStatus.statusName === 'PAID' && approveButton}
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
