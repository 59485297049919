import { gql } from '@apollo/client';

export const FETCH_SESSION_QUERY = gql`
  query fetchSessionQuery {
    user {
      id
      email
      fullName
      timezone
      phoneNumber
      birthdate
      language {
        id
      }
      gender {
        id
      }
      avatar {
        shareUrl
        downloadUrl
      }
    }
  }
`;
