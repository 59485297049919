import { createAction } from '@cobuildlab/react-simple-state';
import { FETCH_GENDER_TYPES } from './gender-type-queries';
import { apolloClient } from '../../shared/apollo';
import { OnGenderType, OnGenderTypeError } from './gender-type-events';

export const fetchGenderTypesAction = createAction(
  OnGenderType,
  OnGenderTypeError,
  async (isActive = true) => {
    const response = await apolloClient.query({
      query: FETCH_GENDER_TYPES,
      fetchPolicy: 'cache-first',
      variables: {
        isActive,
      },
    });

    return response?.data?.genderTypesList?.items;
  },
);
