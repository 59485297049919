import React from 'react';
import { styled } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Interest } from './Interest';

const InterestContainer = styled('div')({
  backgroundColor: '#FFFFFF',
  borderRadius: 4,
  boxShadow: '0 3px 20px 0 rgba(171,171,171,0.35)',
  marginBottom: 40,
  marginTop: 40,
  padding: 30,
});

type InterestGridProps = {
  loading: boolean;
};

/**
 * @param props - Props.
 * @param props.loading - Loading state.
 * @returns - InterestGrid component.
 */
export function InterestGrid({ loading }: InterestGridProps): JSX.Element {
  return (
    <InterestContainer>
      <Box mb="20px">
        <Typography variant="h5">
          <Box fontWeight="fontWeightMedium">Favorite Experiences</Box>
        </Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid item>
          <Interest
            loading={loading}
            title="Artistry"
            description={
              <>
                Learn a new skill,
                <br /> hands on crafts
              </>
            }
            icon={<img src="/icons/artistry-green.svg" alt="artistry-green" />}
            altIcon={
              <img src="/icons/artistry-white.svg" alt="artistry-white" />
            }
          />
        </Grid>
        <Grid item>
          <Interest
            loading={loading}
            title="Quirky"
            description={
              <>
                Unique sites,
                <br /> unusual events
              </>
            }
            icon={<img src="/icons/quirky-green.svg" alt="quirky-green" />}
            altIcon={<img src="/icons/quirky-white.svg" alt="quirky-white" />}
          />
        </Grid>
        <Grid item>
          <Interest
            loading={loading}
            title="Foodie"
            description={
              <>
                Culinary courses,
                <br /> food exploration...
              </>
            }
            icon={<img src="/icons/foodie-green.svg" alt="foodie-green" />}
            altIcon={<img src="/icons/foodie-white.svg" alt="foodie-white" />}
          />
        </Grid>
        <Grid item>
          <Interest
            loading={loading}
            title="History"
            description={
              <>
                Living history
                <br /> activities and more
              </>
            }
            icon={<img src="/icons/history-green.svg" alt="history-green" />}
            altIcon={<img src="/icons/history-white.svg" alt="history-white" />}
          />
        </Grid>
        <Grid item>
          <Interest
            loading={loading}
            title="Classics"
            description={
              <>
                Popular sites,
                <br /> national parks,...
              </>
            }
            icon={<img src="/icons/classics-green.svg" alt="classics-green" />}
            altIcon={
              <img src="/icons/classics-white.svg" alt="classics-white" />
            }
          />
        </Grid>
        <Grid item>
          <Interest
            loading={loading}
            title="Explorer"
            description={
              <>
                Wildlife &amp; love,
                <br /> of animals...
              </>
            }
            icon={<img src="/icons/explorer-green.svg" alt="explorer-green" />}
            altIcon={
              <img src="/icons/explorer-white.svg" alt="explorer-white" />
            }
          />
        </Grid>
        <Grid item>
          <Interest
            loading={loading}
            title="Relax"
            description={
              <>
                Lodging,
                <br /> Retreats, Refuge
              </>
            }
            icon={<img src="/icons/relax-green.svg" alt="relax-green" />}
            altIcon={<img src="/icons/relax-white.svg" alt="relax-white" />}
          />
        </Grid>
        <Grid item>
          <Interest
            loading={loading}
            title="Coursework"
            description={
              <>
                Trails, Adventures,
                <br /> Hiking, etc
              </>
            }
            icon={
              <img src="/icons/coursework-green.svg" alt="coursework-green" />
            }
            altIcon={
              <img src="/icons/coursework-white.svg" alt="coursework-white" />
            }
          />
        </Grid>
      </Grid>
    </InterestContainer>
  );
}
