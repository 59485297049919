import { createEvent } from '@cobuildlab/react-simple-state';
import { StateType } from '../../shared/types';

export const OnStateList = createEvent<StateType[]>();
export const OnStateListError = createEvent<TypeError>();
export const OnStateListByName = createEvent<StateType[]>();
export const OnStateListByNameError = createEvent<TypeError>();
export const OnStateCreate = createEvent<StateType>();
export const OnStateCreateError = createEvent<TypeError>();
export const OnStateById = createEvent<StateType>();
export const OnStateByIdError = createEvent<TypeError>();
