import React, { useEffect, useState } from 'react';
import { useCallAction } from '@cobuildlab/react-simple-state';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import { GRAY_134 } from '../../../shared/components/ui/theme/contants';
import { searchInMessagesAction } from '../inbox-actions';

const useStyles = makeStyles({
  searchIcon: {
    color: GRAY_134,
  },
  space: {
    margin: '1.5rem 0px 2% 0px',
    padding: '0px 2rem',
  },
});

/**
 * Input to search input.
 *
 * @returns {JSX.Element} Search input.
 */
export const InboxSearch: React.FC = () => {
  const classes = useStyles();

  const [searchVal, setSearchVal] = useState<string>();

  const [searchText] = useCallAction(searchInMessagesAction);

  useEffect(() => {
    const searchTime = setTimeout(() => {
      searchText(searchVal || '');
    }, 1000);
    return () => clearTimeout(searchTime);
  }, [searchVal, searchText]);

  return (
    <FormControl variant="outlined" fullWidth className={classes.space}>
      <OutlinedInput
        onChange={(e) => setSearchVal(e.target.value)}
        placeholder="Search"
        margin="dense"
        startAdornment={
          <InputAdornment
            position="start"
            classes={{ root: classes.searchIcon }}
          >
            <SearchIcon />
          </InputAdornment>
        }
      />
    </FormControl>
  );
};
