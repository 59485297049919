import { createAction } from '@cobuildlab/react-simple-state';
import { OnFetchSession, OnFetchSessionError } from './session-events';
import { FETCH_SESSION_QUERY } from './session-queries';
import { apolloClient as client } from '../../shared/apollo';

/**
 * @description - fetch session.
 * @returns {void}.
 */
export const fetchSession = createAction(
  OnFetchSession,
  OnFetchSessionError,
  async () => {
    const response = await client.query({
      query: FETCH_SESSION_QUERY,
      fetchPolicy: 'network-only',
    });

    return response?.data?.user;
  },
);
