import React, { useContext, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { useFormContext, Controller } from 'react-hook-form';
import { usePlacesWidget } from 'react-google-autocomplete';
import { useCallAction } from '@cobuildlab/react-simple-state';
import { ExperienceFormContext } from './ExperienceFormProvider';
import { GOOGLE_MAP_API_KEY, COUNTRY_CODE } from '../../../../shared/constants';
import {
  AddressComponentType,
  PlaceResponseType,
} from '../../../../shared/types';
import { fetchGeoDataAction } from '../../../geo/geo-actions';
import * as toast from '../../../../shared/components/ui/toasts/Toast';

const STATE_TYPE = 'administrative_area_level_1';
const CITY_TYPE = 'locality';
const POSTAL_TYPE = 'postal_code';

/**
 * @returns {JSX.Element} Experience address field.
 */
export const ExperienceAddress: React.FC = () => {
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();

  const {
    loadingSubmit,
    loadingUpload,
    experience,
    stateName,
    setStateName,
    setCityName,
    setLoadingUpload,
  } = useContext(ExperienceFormContext);

  /**
   * @param {AddressComponentType[]} addressInfo - Address component array.
   * @param {string} selectedType - Selected type.
   *
   * @returns {AddressComponentType} Address component selected info.
   */
  const findAddressData = (
    addressInfo: AddressComponentType[],
    selectedType: string,
  ): AddressComponentType | undefined => {
    const info = addressInfo.find(({ types }) =>
      types.some((typeStr) => typeStr === selectedType),
    );

    return info;
  };

  const [fetchGeoData, loading] = useCallAction(fetchGeoDataAction, {
    /**
     * @param {PlaceResponseType[]} results - Results data.
     */
    onCompleted: (results: PlaceResponseType[]) => {
      const addressComponents = results.flatMap(
        ({ address_components }) => address_components,
      );

      const state = findAddressData(addressComponents, STATE_TYPE);
      const city = findAddressData(addressComponents, CITY_TYPE);
      const zipCode = findAddressData(addressComponents, POSTAL_TYPE);
      setLoadingUpload(false);

      if (state) {
        if (state.long_name !== stateName) {
          setValue('state', '');
        }

        setStateName(state.long_name);
      }

      if (city) {
        setCityName(city.long_name);
      }

      if (zipCode) {
        setValue('zipcode', zipCode.long_name);
      }
    },

    /**
     * Event to handle error.
     */
    onError: () => {
      toast.error('An error has occurred please enter address again');
      setLoadingUpload(false);
      setValue('city', '');
      setValue('state', '');
      setValue('zipcode', '');
    },
  });

  const { ref } = usePlacesWidget({
    apiKey: GOOGLE_MAP_API_KEY,
    /**
     * @param {PlaceResponseType} place - Response of selected place.
     */
    onPlaceSelected: async (place: PlaceResponseType): Promise<void> => {
      setLoadingUpload(true);

      if (place?.formatted_address) {
        setValue('address', place.formatted_address);
      }

      if (place?.geometry?.location) {
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        setValue('lat', lat);
        setValue('lng', lng);
        fetchGeoData(lat, lng);
      }
    },
    options: {
      types: ['address'],
      componentRestrictions: { country: COUNTRY_CODE },
    },
  });

  useEffect(() => {
    if (experience?.location?.coordinates?.length) {
      const {
        location: { coordinates },
      } = experience;

      setValue('lng', coordinates[0]);
      setValue('lat', coordinates[1]);
    }
  }, [experience, setValue]);

  return (
    <Controller
      name="address"
      control={control}
      defaultValue={experience?.address || ''}
      render={({ field }) => (
        <TextField
          {...field}
          inputRef={ref}
          error={!!errors?.address}
          disabled={loadingSubmit || loading || loadingUpload}
          onKeyDown={(e) => {
            if (e.key !== 'Enter') {
              setValue('lat', '');
              setValue('lng', '');
            }
          }}
          fullWidth
          variant="outlined"
          label="Address"
          helperText={errors?.address?.message}
        />
      )}
    />
  );
};
