import gql from 'graphql-tag';

export const FETCH_USERS = gql`
  query FetchUsers(
    $skip: Int
    $first: Int
    $filter: UserFilter!
    $sort: [UserSort!]
  ) {
    usersList(filter: $filter, skip: $skip, first: $first, sort: $sort) {
      count
      items {
        id
        fullName
        email
        phoneNumber
        lastTripDate
        totalTrips
        tripStatus
        totalRevenue
        userItineraryRelation {
          items {
            itineraryStatus {
              statusName
            }
            inboxRelation {
              items {
                id
                messageRelation {
                  count
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const FETCH_USER = gql`
  query FetchUser($id: ID!) {
    user(id: $id) {
      id
      fullName
      email
      phoneNumber
      gender {
        id
      }
      birthdate
      language {
        id
      }
      timezone
      interests
      archivedAt
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($data: UserUpdateInput!) {
    userUpdate(data: $data) {
      id
    }
  }
`;

export const UPDATE_AUTH0_USER = gql`
  mutation updateAuth0User($email: String!, $data: UpdateAuth0UserInput!) {
    updateAuth0User(email: $email, data: $data) {
      result
    }
  }
`;

export const FETCH_GENDERS = gql`
  query FetchGenders {
    genderTypesList {
      items {
        id
        genderName
      }
    }
  }
`;
