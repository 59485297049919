import gql from 'graphql-tag';

export const FETCH_EXPERIENCE_SUBCATEGORIES = gql`
  query FetchExperienceSubCategories($isActive: Boolean, $categoryIds: [ID!]) {
    experienceSubCategoriesList(
      filter: {
        isActive: { equals: $isActive }
        experienceCategory: { id: { in: $categoryIds } }
      }
    ) {
      items {
        id
        experienceSubCategoryName
        experienceCategory {
          id
          experienceCategoryName
        }
      }
    }
  }
`;
