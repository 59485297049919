import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import { useCallAction, useFetchAction } from '@cobuildlab/react-simple-state';
import { useForm, FormProvider } from 'react-hook-form';
import { BookingListHeader } from './components/BookingListHeader';
import { BookingListTable } from './components/BookingListTable';
import {
  BookingFilterState,
  INITIAL_FILTER_STATE,
} from './components/BookingListMenuFilter';
import {
  fetchBookingsAction,
  confirmBookingAction,
  rejectBookingAction,
  fetchBookingAction,
} from './booking-actions';
import { BookingType, RelationType } from '../../shared/types';
import * as toast from '../../shared/components/ui/toasts/Toast';
import { usePagination } from '../../shared/hooks/usePagination';
import { useWebpageTitle } from '../../shared/hooks/useWebpageTitle';
import { BookingDetails } from './components/BookingDetails';
import { ItineraryDetails } from './components/ItineraryDetails';
import { RejectDialog } from './components/RejectDialog';

/**
 *  Booking List.
 *
 * @returns {JSX.Element} Bookings table.
 */
export const BookingListView: React.FC = () => {
  const [totalCount, setTotalCount] = useState<number>(0);
  const [bookings, setBookings] = useState<BookingType[]>([]);
  const [paginationData, setPagination] = usePagination<BookingFilterState>({
    ...INITIAL_FILTER_STATE,
  });
  const methods = useForm();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [detailTarget, setDetailTarget] = useState<BookingType | null>(null);
  const [rejectDialogOpen, setRejectDialogOpen] = useState(false);
  const [rejectTarget, setRejectTarget] = useState<BookingType | null>(null);
  const [itineraryDetailsOpen, setItineraryDetailsOpen] = useState(false);
  const [itineraryDetailsTarget, setItineraryDetailsTarget] =
    useState<BookingType | null>(null);
  const { id } = useParams<{ id?: string }>();
  const history = useHistory();

  const [fetchBookings, loading] = useCallAction(fetchBookingsAction, {
    /**
     * @param {RelationType<BookingType>} data  - People data.
     */
    onCompleted: (data: RelationType<BookingType>) => {
      const { count, items } = data;
      /**
       * Elements per page.
       */
      const first = 10;
      const totalPages = Math.ceil((count as number) / first);

      setTotalCount(totalPages);
      setBookings(items as BookingType[]);
    },
  });

  const [confirmBooking, loadingConfirm] = useCallAction(confirmBookingAction, {
    /**
     * Callback to success update.
     */
    onCompleted: () => {
      setPagination({ page: 1 });
      toast.success('Success', 'Booking confirmed successfully');
    },

    /**
     * @param {TypeError} e - Error Event.
     */
    onError: (e) => {
      toast.error(e.message);
    },
  });

  const [rejectBooking, loadingReject] = useCallAction(rejectBookingAction, {
    /**
     * Callback to success update.
     */
    onCompleted: () => {
      setPagination({ page: 1 });
      toast.success('Success', 'Booking rejected successfully');
    },

    /**
     * @param {TypeError} e - Error Event.
     */
    onError: (e) => {
      setPagination({ page: 1 });
      toast.error(e.message);
    },
  });

  const [, loadingBooking] = useFetchAction(fetchBookingAction, [id || ''], {
    /**
     * @param bookingData - Booking data.
     */
    onCompleted: (bookingData: BookingType) => {
      setDetailTarget(bookingData);
      setDialogOpen(true);
    },
  });

  useEffect(() => {
    const { searchText, filterState, page, sort } = paginationData;
    const queryParams = { searchText, ...filterState };

    fetchBookings(queryParams, page, sort);
  }, [paginationData, fetchBookings]);

  /**
   * @param {BookingFilterState} defaultValues - Default state.
   */
  const handleResetFilter = (defaultValues: BookingFilterState): void => {
    setPagination({
      page: 1,
      filterState: {
        ...defaultValues,
      },
    });

    methods.reset();
  };

  /**
   * @param {BookingFilterState} filterState - People filter state.
   */
  const handleApplyFilters = (filterState: BookingFilterState): void => {
    setPagination({
      page: 1,
      filterState: {
        ...filterState,
      },
    });
  };

  /**
   * @param {string} searchText - Search value.
   */
  const handleChangeSearch = (searchText: string): void => {
    setPagination({
      page: 1,
      searchText,
    });
  };

  /**
   * @param {number} newPage - New page pagination.
   */
  const handleChangePage = (newPage: number): void => {
    setPagination({
      page: newPage,
    });
  };

  /**
   * @param {string} field - Field name.
   * @param {string} direction - Order sort.
   */
  const handleChangeSort = (field: string, direction?: string): void => {
    if (!direction) {
      setPagination({
        page: 1,
        sort: {},
      });

      return;
    }

    if (field === 'user.fullName') {
      setPagination({
        page: 1,
        sort: {
          user: {
            fullName: direction.toUpperCase(),
          },
        },
      });

      return;
    }

    if (field === 'experience.experienceName') {
      setPagination({
        page: 1,
        sort: {
          experience: {
            experienceName: direction.toUpperCase(),
          },
        },
      });

      return;
    }

    if (field === 'bookingStatus.statusName') {
      setPagination({
        page: 1,
        sort: {
          bookingStatus: {
            statusName: direction.toUpperCase(),
          },
        },
      });

      return;
    }

    setPagination({
      page: 1,
      sort: {
        [field]: direction.toUpperCase(),
      },
    });
  };

  /**
   * @param {BookingType} booking - Booking data.
   */
  const handleViewDetails = (booking: BookingType): void => {
    setDetailTarget(booking);
    setDialogOpen(true);
  };

  /**
   * @param {BookingType} booking - Booking data.
   */
  const handleRejectBooking = (booking: BookingType): void => {
    setRejectTarget(booking);
    setRejectDialogOpen(true);
  };

  /**
   * @param {BookingType} booking - Booking data.
   */
  const handleViewItineraryDetails = (booking: BookingType): void => {
    setItineraryDetailsTarget(booking);
    setItineraryDetailsOpen(true);
  };

  useWebpageTitle('Candid Travel Ventures - Bookings');

  return (
    <>
      <BookingListHeader />
      <Box mt={4} flexGrow={1} display="flex">
        <FormProvider {...methods}>
          <BookingListTable
            page={paginationData.page as number}
            filterState={paginationData.filterState as BookingFilterState}
            onApplyFilters={handleApplyFilters}
            onChangeSearch={handleChangeSearch}
            onChangePage={handleChangePage}
            totalCount={totalCount}
            bookings={bookings}
            onChangeSort={handleChangeSort}
            loading={
              loading || loadingConfirm || loadingReject || loadingBooking
            }
            onResetFilter={handleResetFilter}
            onViewDetails={handleViewDetails}
            onConfirmBooking={confirmBooking}
            onRejectBooking={handleRejectBooking}
            onViewItineraryDetails={handleViewItineraryDetails}
          />
        </FormProvider>
      </Box>
      <BookingDetails
        booking={detailTarget}
        isOpen={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
          setDetailTarget(null);
          history.push('./');
        }}
        onConfirmBooking={confirmBooking}
        onRejectBooking={handleRejectBooking}
      />
      <RejectDialog
        booking={rejectTarget}
        isOpen={rejectDialogOpen}
        onClose={() => {
          setRejectDialogOpen(false);
          setRejectTarget(null);
        }}
        rejectBooking={rejectBooking}
      />
      <ItineraryDetails
        booking={itineraryDetailsTarget}
        isOpen={itineraryDetailsOpen}
        onClose={() => {
          setItineraryDetailsOpen(false);
          setItineraryDetailsTarget(null);
        }}
      />
    </>
  );
};
