import * as yup from 'yup';

export const userSchemaValidation = yup.object().shape({
  fullName: yup.string().max(100).label('Full name').required(),
  email: yup.string().label('email').label('Email').required(),
  phoneNumber: yup
    .string()
    .max(100)
    .label('Phone number')
    .test(
      'format-phone',
      'phone number cannot contain letters',
      (value) => !value?.match(/[a-zA-Z]/),
    )
    .required(),
  gender: yup.string().required(),
  birthdate: yup.date().required().label('Date of birth'),
  language: yup.string().required(),
  timezone: yup.string().required(),
  interests: yup
    .object()
    .shape({
      artistry: yup.bool(),
      quirky: yup.bool(),
      foodie: yup.bool(),
      history: yup.bool(),
      classics: yup.bool(),
      explorer: yup.bool(),
      relax: yup.bool(),
      coursework: yup.bool(),
    })
    .required(),
});
