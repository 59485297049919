import gql from 'graphql-tag';

export const FETCH_BOOKING_COUNT_DAYS = gql`
  query FetchCountBookingDays(
    $startDateTime: DateTime!
    $endDateTime: DateTime!
    $status: String!
  ) {
    bookingsList(
      filter: {
        AND: [
          { itinerary: { startDateTime: { gte: $startDateTime } } }
          { itinerary: { endDateTime: { lte: $endDateTime } } }
          { bookingStatus: { statusName: { equals: $status } } }
        ]
      }
      groupBy: {
        query: {
          itinerary: { id: { as: "itineraryIdentifier" } }
          itineraryDay: { as: "dayIdentifier" }
        }
      }
    ) {
      groups {
        dayIdentifier: String
      }
    }
  }
`;

export const FETCH_BOOKING_COUNT = gql`
  query FetchBookingCount($filter: BookingFilter!) {
    bookingsList(filter: $filter) {
      count
    }
  }
`;

export const FETCH_BOOKING_TIME_ELAPSED_TOTAL = gql`
  query FetchTimeElapsedAverage(
    $startDateTime: DateTime!
    $endDateTime: DateTime!
    $status: String!
  ) {
    bookingsList(
      filter: {
        AND: [
          { approvedDate: { gte: $startDateTime } }
          { approvedDate: { lte: $endDateTime } }
          { bookingStatus: { statusName: { equals: $status } } }
        ]
      }
      groupBy: {
        query: {
          timeElapsed: { as: "timeElapsedTotal", fn: { aggregate: SUM } }
        }
      }
    ) {
      groups {
        timeElapsedTotal: Int
      }
      count
    }
  }
`;

export const FETCH_BOOKING_CANCELLED_COUNT = gql`
  query FetchBookingCancelled(
    $startDateTime: DateTime
    $endDateTime: DateTime
  ) {
    bookingsList(
      filter: {
        AND: [
          { createdAt: { gte: $startDateTime } }
          { createdAt: { lte: $endDateTime } }
        ]
        bookingStatus: {
          OR: [
            { statusName: { equals: "CANCELLED_SYSTEM" } }
            { statusName: { equals: "REJECTED" } }
          ]
        }
      }
    ) {
      count
    }
  }
`;

export const FETCH_BOOKINGS = gql`
  query FetchBookings(
    $skip: Int
    $first: Int
    $filter: BookingFilter!
    $sort: [BookingSort!]
  ) {
    bookingsList(filter: $filter, skip: $skip, first: $first, sort: $sort) {
      count
      items {
        id
        user {
          email
          fullName
        }
        createdAt
        startDateTime
        endDateTime
        experience {
          id
          experienceName
          operatorsName
          phoneNumber
          contactEmailAddress
          state {
            stateName
          }
          city {
            cityName
          }
          experienceExperienceMediaRelation(first: 1, filter: { file: {} }) {
            items {
              file {
                downloadUrl
                shareUrl
              }
            }
          }
        }
        itinerary {
          id
          startDateTime
          endDateTime
          itineraryCityName
          itineraryBookingRelation {
            items {
              id
              itineraryDay
              experience {
                experienceName
              }
              startDateTime
              endDateTime
              bookingBookingPricingRelation {
                items {
                  peopleAmount
                  travelerType {
                    travelerTypeName
                  }
                }
              }
              bookingStatus {
                statusName
              }
            }
          }
          itineraryTravelToItineraryMappingRelation {
            items {
              amount
              travelerType {
                travelerTypeName
              }
            }
          }
        }
        itineraryDay
        timeElapsed
        bookingStatus {
          statusName
        }
        bookingBookingPricingRelation {
          items {
            price
            peopleAmount
            travelerType {
              travelerTypeName
            }
          }
        }
        rejectionReason
        approvedDate
      }
    }
  }
`;

export const UPDATE_BOOKING = gql`
  mutation UpdateBooking($data: BookingUpdateInput!) {
    bookingUpdate(data: $data) {
      id
    }
  }
`;

export const REFUND_BOOKING = gql`
  mutation RefundBooking($data: RefundBookingInput!) {
    refundBooking(data: $data) {
      message
      success
    }
  }
`;

export const FETCH_BOOKING = gql`
  query FetchBooking($id: ID!) {
    booking(id: $id) {
      id
      rejectionReason
      createdAt
      startDateTime
      experience {
        experienceName
        operatorsName
        phoneNumber
        contactEmailAddress
      }
      user {
        fullName
        email
      }
      bookingStatus {
        statusName
      }
      bookingBookingPricingRelation {
        items {
          peopleAmount
          travelerType {
            travelerTypeName
          }
        }
      }
    }
  }
`;
