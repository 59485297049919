import { createEvent } from '@cobuildlab/react-simple-state';
import { CityType } from '../../shared/types';

export const OnCityList = createEvent<CityType[]>();
export const OnCityListError = createEvent();
export const OnCityListByName = createEvent<CityType[]>();
export const OnCityListByNameError = createEvent<TypeError>();
export const OnCityCreate = createEvent<CityType>();
export const OnCityCreateError = createEvent<TypeError>();
export const OnCityById = createEvent<CityType>();
export const OnCityByIdError = createEvent<TypeError>();
