import gql from 'graphql-tag';

export const FETCH_LANGUAGE_TYPES = gql`
  query FetchLanguagueTypes($isActive: Boolean) {
    languageTypesList(filter: { isActive: { equals: $isActive } }) {
      items {
        id
        languageName
      }
    }
  }
`;
