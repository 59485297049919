import React, { useState, useContext, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import Switch from '@material-ui/core/Switch';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { GRAY_DARK } from '../../../../shared/components/ui/theme/contants';
import { ExperienceFormContext } from './ExperienceFormProvider';

const useStyles = makeStyles({
  switchLabel: {
    color: GRAY_DARK,
  },
});

/**
 * @returns {JSX.Element} Switch require reservation.
 */
export const ExperienceReservationCheck: React.FC = () => {
  const classes = useStyles();
  const { experience, loadingSubmit } = useContext(ExperienceFormContext);
  const { setValue } = useFormContext();
  const [isChecked, setIsChecked] = useState<boolean>(
    !!experience?.requireReservation,
  );

  /**
   * @param {React.ChangeEvent<HTMLInputElement>} e - Event.
   */
  const handleChangeReservation = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setIsChecked(e.target.checked);
  };

  useEffect(() => {
    setValue('requireReservation', isChecked);
  }, [isChecked, setValue]);

  return (
    <Box display="flex" alignItems="center">
      <Box mr="12px">
        <Typography className={classes.switchLabel}>
          Requires reservation
        </Typography>
      </Box>
      <Switch
        disabled={loadingSubmit}
        color="primary"
        checked={isChecked}
        onChange={handleChangeReservation}
      />
    </Box>
  );
};
