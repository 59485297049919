import { createEvent } from '@cobuildlab/react-simple-state';
import { ItineraryType, MessageType } from '../../shared/types';

export const OnFetchMessages = createEvent<MessageType[]>({ initialValue: [] });
export const OnFetchMessagesError = createEvent<TypeError>();

export const OnSelectedItemId = createEvent<string>({ initialValue: '' });
export const OnSelectedItemIdError = createEvent<TypeError>();

export const OnMessageCreate = createEvent<string>({ initialValue: '' });
export const OnMessageCreateError = createEvent<TypeError>();

export const OnSearchMessages = createEvent<string>({ initialValue: '' });
export const OnSearchMessagesError = createEvent<TypeError>();

export const OnFetchNewMessages = createEvent<{
  inbox: string;
  newMsg: string[];
}>();
export const OnFetchNewMessagesError = createEvent<TypeError>();

// Itinerary Update

export const OnFetchItineraries = createEvent<ItineraryType[]>({
  initialValue: [],
});
export const OnFetchItinerariesError = createEvent<TypeError>();

export const OnFetchChatHeader = createEvent<ItineraryType>();
export const OnFetchChatHeaderError = createEvent<TypeError>();
