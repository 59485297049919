import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { COLOR_PRIMARY } from '../../../shared/components/ui/theme/contants';

export type ChartOptionValueType = {
  amountDays: number;
  position: number;
};

export type ChartOptionsType = {
  label: string;
  value: ChartOptionValueType;
};

type DashboardDropdownProps = {
  menuOptions: ChartOptionsType[];
  onSelectedOption: (value: ChartOptionValueType) => void;
};

const useStyles = makeStyles({
  dropDown: {
    color: COLOR_PRIMARY,
    cursor: 'pointer',
  },
});

/**
 *
 * @param props - Props received.
 * @param {ChartOptionsType []} props.menuOptions - Menu items.
 * @param {Function} props.onSelectedOption - Function to select menu item.
 * @returns {JSX.Element} Dropdown to select days.
 */
export const DashboardChartDropdown: React.FC<DashboardDropdownProps> = ({
  menuOptions,
  onSelectedOption,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [amountDays, setAmountDays] = useState(90);
  const classes = useStyles();

  const open = !!anchorEl;

  /**
   * @param {React.MouseEvent<HTMLElement>} e - Event.
   */
  const handleOpenMenu = (e: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(e.currentTarget);
  };

  /**
   * Close menu.
   */
  const handleCloseMenu = (): void => {
    setAnchorEl(null);
  };

  /**
   * @param {ChartOptionValueType} value  - Option value.
   */
  const handleSelectedDays = (value: ChartOptionValueType): void => {
    setAmountDays(value.amountDays);
    onSelectedOption(value);
    handleCloseMenu();
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        className={classes.dropDown}
        onClick={handleOpenMenu}>
        <Typography variant="subtitle2" color="primary">
          Last {amountDays} days
        </Typography>
        <Box fontSize="20px" height="19px">
          <ExpandMoreIcon fontSize="inherit" />
        </Box>
      </Box>
      <Menu
        onClose={handleCloseMenu}
        open={open}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        {menuOptions.map((option) => (
          <MenuItem
            key={option.label}
            onClick={() => handleSelectedDays(option.value)}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
