import { useState, useCallback } from 'react';
import { SortType } from '../types';

export type PaginationType<FilterState> = {
  page?: number;
  searchText?: string;
  sort?: SortType;
  filterState?: FilterState;
};

const INITIAL_PAGINATION_DATA = {
  page: 1,
  searchText: '',
  sort: {},
};

/**
 * @param filterState - Filters.
 *
 * @returns {Array<PaginationType, Function>} Pagination data.
 */
export const usePagination = <FilterState>(
  filterState?: FilterState,
): [
  PaginationType<FilterState>,
  (data: PaginationType<FilterState>) => void,
] => {
  const [paginationData, setPaginationState] = useState<
    PaginationType<FilterState>
  >({ ...INITIAL_PAGINATION_DATA, filterState });

  const setPaginationData = useCallback(
    (data: PaginationType<FilterState>): void => {
      setPaginationState((prevState) => ({
        ...prevState,
        ...data,
      }));
    },
    [],
  );

  return [paginationData, setPaginationData];
};
