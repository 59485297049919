import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { TableSearch } from './TableSearch';
import { FilterGreyIcon, FilterActiveIcon } from '../../../../icons/icons';

type ToolbarProps = {
  selection?: boolean;
  selectionCount?: number;
  onSelectionAll?: (state: boolean) => void;
  renderOptionalComponent?: () => JSX.Element | null;
  renderMenuFilter?: (
    anchorEl: null | HTMLElement,
    handleCloseFilter: () => void,
  ) => JSX.Element;
  onChangeSearch: (searchText: string) => void;
};

/**
 * Toolbar.
 *
 * @param props - Props received.
 * @param {Function} props.renderMenuFilter - Function to render menu filter.
 * @param {boolean} props.selection - Enable section checkbox.
 * @param {Function} props.onChangeSearch - Function to change search text.
 * @param {number} props.selectionCount - Amount of selection items.
 * @param {Function} props.onSelectionAll - Function to select all items.
 * @param {Function} props.renderOptionalComponent - FUnction to render optional component.
 * @returns {JSX.Element} Toolbar of table.
 */
export const Toolbar: React.FC<ToolbarProps> = ({
  renderMenuFilter,
  selection,
  onChangeSearch,
  onSelectionAll,
  selectionCount,
  renderOptionalComponent
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  let filterIcon = null;
  let selectorCheckbox: null | JSX.Element = null;

  /**
   * Function to show filters.
   *
   * @param {MouseEvent<HTMLElement>} e - Event.
   */
  const handleShowFilter = (e: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(e.currentTarget);
  };

  /**
   * Function to close filters.
   */
  const handleCloseFilter = (): void => {
    setAnchorEl(null);
  };

  /**
   * @param {React.ChangeEvent<HTMLInputElement>} e - Event.
   */
  const handleSelectionAll = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (onSelectionAll) {
      onSelectionAll(e.target.checked);
    }
  };

  if (renderMenuFilter) {
    const icon = anchorEl ? <FilterActiveIcon /> : <FilterGreyIcon />;

    filterIcon = (
      <Box>
        <IconButton onClick={handleShowFilter}>{icon}</IconButton>
      </Box>
    );
  }

  if (selection) {
    selectorCheckbox = (
      <Box display="flex" alignItems="center">
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              indeterminate={!!selectionCount}
              checked={!!selectionCount}
              onChange={handleSelectionAll}
            />
          }
          label={`${selectionCount?.toString()} experience selected`}
        />
      </Box>
    );
  }

  const justifyContent = selection ? 'space-between' : 'flex-end';

  return (
    <>
      <Box
        mt="25px"
        px="19px"
        mb="20px"
        display="flex"
        justifyContent={justifyContent}
        alignItems="center"
      >
        {selectorCheckbox}
        <Box display="flex" alignItems="center" position="relative">
          <Box>
            <TableSearch onChangeSearch={onChangeSearch} />
          </Box>
          {filterIcon}
          {renderMenuFilter && renderMenuFilter(anchorEl, handleCloseFilter)}
        </Box>
      </Box>
      {renderOptionalComponent && renderOptionalComponent()}
    </>
  );
};
