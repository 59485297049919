import gql from 'graphql-tag';

export const FETCH_NOTIFICATIONS = gql`
  query FetchNotifications {
    notificationsList(
      filter: { role: { name: { equals: "Administrator" } } }
      sort: { createdAt: DESC }
    ) {
      items {
        id
        message
        read
        createdAt
        path
        type
      }
    }
  }
`;

export const MARK_ALL_AS_READ = gql`
  mutation MarkAllAsRead {
    notificationUpdateByFilter(
      filter: { role: { name: { equals: "Administrator" } } }
      data: { read: { set: true } }
    ) {
      count
    }
  }
`;

export const SUB_NEW_NOTIFICATION = gql`
  subscription SubNewNotification {
    Notification(
      filter: {
        mutation_in: create
        node: { role: { name: { equals: "Administrator" } } }
      }
    ) {
      node {
        id
        recipient {
          id
        }
      }
    }
  }
`;

export const UPDATE_NOTIFICATION = gql`
  mutation UpdateNotification($data: NotificationUpdateInput!) {
    notificationUpdate(data: $data) {
      id
      message
      read
      createdAt
      path
    }
  }
`;
