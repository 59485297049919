import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import { useCallAction } from '@cobuildlab/react-simple-state';
import { useForm, FormProvider } from 'react-hook-form';
import { ExperienceListHeader } from './components/ExperienceListHeader';
import { ExperienceListTable } from './components/ExperienceListTable';
import {
  ExperienceFilterState,
  INITIAL_FILTER_STATE,
} from './components/ExperienceListMenuFilter';
import {
  fetchExperienceListAction,
  updateExperienceStatusAction,
  deactiveExperienceMultiplesAction,
} from './experience-actions';
import { ExperienceType, RelationType } from '../../shared/types';
import * as toast from '../../shared/components/ui/toasts/Toast';
import { usePagination } from '../../shared/hooks/usePagination';
import { useWebpageTitle } from '../../shared/hooks/useWebpageTitle';

/**
 *  Experience List.
 *
 * @returns {JSX.Element} Experiences table.
 */
export const ExperienceList: React.FC = () => {
  const [totalCount, setTotalCount] = useState<number>(0);
  const [experiences, setExperiences] = useState<ExperienceType[]>([]);
  const [paginationData, setPagination] = usePagination<ExperienceFilterState>({
    ...INITIAL_FILTER_STATE,
  });
  const [selectedExperiences, setSelectedExperiences] = useState<string[]>([]);
  const methods = useForm();

  const [fetchExperiences, loading] = useCallAction(fetchExperienceListAction, {
    /**
     * @param {RelationType<ExperienceType>} data  - Data experience.
     */
    onCompleted: (data: RelationType<ExperienceType>) => {
      const { count, items } = data;
      /**
       * Elements per page.
       */
      const first = 10;
      const totalPages = Math.ceil((count as number) / first);

      setTotalCount(totalPages);
      setExperiences(items as ExperienceType[]);
    },

    /**
     * @param e - Error Event.
     */
    onError: (e) => {
      toast.error(e.message);
    },
  });

  const [updateExperience, loadingUpdate] = useCallAction(
    updateExperienceStatusAction,
    {
      /**
       * Callback to success update.
       */
      onCompleted: () => {
        setPagination({ page: 1 });
        setSelectedExperiences([]);
        toast.success('Success', 'Experience updated successfully');
      },

      /**
       * @param {TypeError} e - Error Event.
       */
      onError: (e) => {
        toast.error(e.message);
      },
    },
  );

  const [deactiveExperience, loadingDeactiveExperience] = useCallAction(
    deactiveExperienceMultiplesAction,
    {
      /**
       * Callback to success update.
       */
      onCompleted: () => {
        setPagination({ page: 1 });
        setSelectedExperiences([]);
        toast.success('Success', 'Experiences updated successfully');
      },

      /**
       * @param {TypeError} e - Error Event.
       */
      onError: (e) => {
        toast.error(e.message);
      },
    },
  );

  useEffect(() => {
    const { searchText, filterState, page, sort } = paginationData;
    const queryParams = { searchText, ...filterState };

    fetchExperiences(queryParams, page, sort);
  }, [paginationData, fetchExperiences]);

  /**
   * @param {boolean} checked - State of selection.
   * @param {string} experienceId - Experience id.
   */
  const handleSelectedExperience = (
    checked: boolean,
    experienceId: string,
  ): void => {
    let experiencesIds = [];

    if (checked) {
      experiencesIds = selectedExperiences.slice();
      experiencesIds.push(experienceId);
    } else {
      experiencesIds = selectedExperiences.filter(
        (selectedExperienceId) => selectedExperienceId !== experienceId,
      );
    }

    setSelectedExperiences(experiencesIds);
  };

  /**
   * @param {ExperienceFilterState} defaultValues - Default state.
   */
  const handleResetFilter = (defaultValues: ExperienceFilterState): void => {
    setSelectedExperiences([]);
    setPagination({
      page: 1,
      filterState: {
        ...defaultValues,
      },
    });

    methods.reset();
  };

  /**
   * @param {boolean} state - State of selection all.
   */
  const handleSelectionAll = (state: boolean): void => {
    if (state) {
      const experiencesIds = experiences.map(({ id }) => id);
      setSelectedExperiences(experiencesIds);
    } else {
      setSelectedExperiences([]);
    }
  };

  /**
   * @param {ExperienceFilterState} filterState - Experience filter state.
   */
  const handleApplyFilters = (filterState: ExperienceFilterState): void => {
    setSelectedExperiences([]);
    setPagination({
      page: 1,
      filterState: {
        ...paginationData.filterState,
        ...filterState,
      },
    });
  };

  /**
   * @param {string} searchText - Search value.
   */
  const handleChangeSearch = (searchText: string): void => {
    setSelectedExperiences([]);
    setPagination({
      page: 1,
      searchText,
    });
  };

  /**
   * @param {number} newPage - New page pagination.
   */
  const handleChangePage = (newPage: number): void => {
    setSelectedExperiences([]);
    setPagination({
      page: newPage,
    });
  };

  /**
   * @param {string} field - Field name.
   * @param {string | null} direction - Order sort.
   */
  const handleChangeSort = (field: string, direction?: string): void => {
    setSelectedExperiences([]);

    if (direction) {
      setPagination({
        page: 1,
        sort: {
          [field]: direction.toUpperCase(),
        },
      });
    } else {
      setPagination({
        page: 1,
        sort: {},
      });
    }
  };

  /**
   * Function to deactive multiple experience.
   */
  const handleDeactiveExperiences = (): void => {
    deactiveExperience(selectedExperiences);
  };

  useWebpageTitle('Candid Travel Ventures - Experiences');

  const isLoading = loading || loadingUpdate || loadingDeactiveExperience;

  return (
    <>
      <ExperienceListHeader />
      <Box mt={4} flexGrow={1} display="flex">
        <FormProvider {...methods}>
          <ExperienceListTable
            page={paginationData.page as number}
            filterState={paginationData.filterState as ExperienceFilterState}
            onApplyFilters={handleApplyFilters}
            onChangeSearch={handleChangeSearch}
            onChangePage={handleChangePage}
            totalCount={totalCount}
            experiences={experiences}
            onChangeSort={handleChangeSort}
            loading={isLoading}
            selectedExperiences={selectedExperiences}
            onSelectedExperience={handleSelectedExperience}
            onSelectionAll={handleSelectionAll}
            onResetFilter={handleResetFilter}
            onUpdateExperience={updateExperience}
            onDeactiveExperiences={handleDeactiveExperiences}
          />
        </FormProvider>
      </Box>
    </>
  );
};
