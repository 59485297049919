import React from 'react';
import { createStyles, Drawer, List, makeStyles } from '@material-ui/core';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import PeopleIcon from '@material-ui/icons/People';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import MapIcon from '@material-ui/icons/Map';
import HomeIcon from '@material-ui/icons/Home';
import SettingsIcon from '@material-ui/icons/Settings';
import Box from '@material-ui/core/Box';
import { LogoDefault } from './ui/images/LogoDefault';
import { SidebarItem } from './Menu';

export const drawerWidth = 240;

const useStyles = makeStyles(() =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerContainer: {
      overflow: 'auto',
    },
    logoContainer: {
      padding: '0',
      margin: '34px auto',
    },
    sidebarListContainer: {
      paddingLeft: '7px',
      paddingRight: '7px',
    },
  }),
);

/**
 * @returns Component.
 */
export const Sidebar: React.FC = () => {
  const classes = useStyles();

  return (
    <Box boxShadow={24} zIndex={1250} height="100vh">
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <figure className={classes.logoContainer}>
          <LogoDefault />
        </figure>
        <div className={classes.drawerContainer}>
          <List className={classes.sidebarListContainer}>
            <SidebarItem
              pathname="/"
              icon={<HomeIcon fontSize="inherit" />}
              text="Dashboard"
            />
            <SidebarItem
              pathname="/inbox"
              icon={<InboxIcon fontSize="inherit" />}
              text="Inbox"
            />
            <SidebarItem
              pathname="/people"
              icon={<PeopleIcon fontSize="inherit" />}
              text="People"
            />
            <SidebarItem
              pathname="/bookings/"
              icon={<MonetizationOnIcon fontSize="inherit" />}
              text="Bookings"
            />
            <SidebarItem
              pathname="/experiences"
              icon={<MapIcon fontSize="inherit" />}
              text="Experiences"
            />
            <SidebarItem
              pathname="/my-account/information"
              icon={<SettingsIcon fontSize="inherit" />}
              text="Settings"
            />
          </List>
        </div>
      </Drawer>
    </Box>
  );
};
