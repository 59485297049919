import React, { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useParams, useHistory } from 'react-router-dom';
import { useFetchAction, useCallAction } from '@cobuildlab/react-simple-state';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { ExperienceGeneralFields } from './components/experience-form/ExperienceGeneralFields';
import { ExperienceOpeningTime } from './components/experience-form/ExperienceOpeningTime';
import { ExperiencePricing } from './components/experience-form/ExperiencePricing';
import { ExperienceRestrictions } from './components/experience-form/ExperienceRestrictions';
import { Media } from './components/experience-form/Media';
import { Waiver } from './components/experience-form/Waiver';
import { ExperienceFormButtons } from './components/experience-form/ExperienceFormButtons';
import { ExperienceAvatar } from './components/experience-form/ExperienceAvatar';
import { ExperienceFormProvider } from './components/experience-form/ExperienceFormProvider';
import { ExperienceDetailHeader } from './components/ExperienceDetailHeader';
import { PaperContainer } from '../../shared/components/PaperContainer';
import {
  fetchExperienceAction,
  updateExperienceAction,
} from './experience-actions';
import { experienceValidatorSchema } from './experience-validator';
import { ExperienceLoading } from './components/experience-form/ExperienceLoading';
import { ExperienceInputType } from './ExperienceAddView';
import {
  createExperienceCategoryFormat,
  createSubCategoryFormat,
  createOpeningTimeFormat,
  createPricingFormat,
} from './experience-utils';
import { omit } from '../../shared/utils';
import { ExperienceDataSubmitType } from './experience-model';
import * as toast from '../../shared/components/ui/toasts/Toast';
import { useWebpageTitle } from '../../shared/hooks/useWebpageTitle';

type ExperienceInputUpdateType = ExperienceInputType & {
  waiverToDelete?: string[];
  mediaToDelete?: string[];
  keywordsToDelete?: string[];
};
/**
 * @returns {JSX.Element} Experience detail.
 */
export const ExperienceDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const method = useForm<ExperienceInputUpdateType>({
    resolver: yupResolver(experienceValidatorSchema),
  });
  const history = useHistory();
  const [experience, loading] = useFetchAction(fetchExperienceAction, [id]);

  const [updateExperience, loadingExperience] = useCallAction(
    updateExperienceAction,
    {
      /**
       * Handle succes update experience.
       */
      onCompleted: () => {
        toast.success('Success', 'Experience updated successfully');
        method.setValue('waiverToDelete', []);
        method.setValue('mediaToDelete', []);
        history.push('/experiences');
      },

      /**
       * @param {TypeError} e - Event error.
       */
      onError: (e) => {
        toast.error(e.message);
      },
    },
  );

  /**
   * Update experience.
   *
   * @param inputs - Data of inputs.
   */
  const onSubmit: SubmitHandler<ExperienceInputUpdateType> = (inputs): void => {
    const media = inputs.media || [];
    const mediaToDelete = inputs.mediaToDelete || [];
    const waiver = inputs.waiver || [];
    const waiverToDelete = inputs.waiverToDelete || [];
    const keywords = inputs.keywords || [];
    const keywordsToDelete = inputs.keywordsToDelete || [];

    const city = {
      connect: {
        id: inputs.city,
      },
    };

    const state = {
      connect: {
        id: inputs.state,
      },
    };

    const experienceExperienceCategoryRelation = createExperienceCategoryFormat(
      experience,
      inputs.categories,
    );

    const experienceExperienceSubCategoryRelation = createSubCategoryFormat(
      experience,
      inputs.subCategories,
    );

    const experienceExperienceScheduleRelation = createOpeningTimeFormat(
      experience,
      inputs.openingTimes,
    );

    const experienceExperiencePricingRelation = createPricingFormat(
      experience,
      inputs.pricings,
    );

    const experienceExperienceMediaRelation = {
      disconnect: mediaToDelete.map((mediaId) => ({ id: mediaId })),
      create: media.map((mediaItem) => ({
        file: {
          create: {
            fileId: mediaItem.id,
            filename: mediaItem.filename,
          },
        },
      })),
    };

    const experienceExperienceWaiverRelation = {
      disconnect: waiverToDelete.map((waiverId) => ({ id: waiverId })),
      create: waiver.map((waiverItem) => ({
        file: {
          create: {
            fileId: waiverItem.id,
            filename: waiverItem.filename,
          },
        },
      })),
    };

    const experienceExperienceKeywordRelation = {
      disconnect: keywordsToDelete.map((keywordId) => ({ id: keywordId })),
      create: keywords.map(({ text }) => ({
        text,
      })),
    };

    const location = [];

    if (inputs.lng && inputs.lat) {
      location.push(inputs.lng);
      location.push(inputs.lat);
    }

    const data = {
      ...omit<ExperienceDataSubmitType>(
        [
          'mediaToDelete',
          'waiverToDelete',
          'openingTimes',
          'pricings',
          'media',
          'waiver',
          'categories',
          'subCategories',
          'keywords',
          'keywordsToDelete',
          'lat',
          'lng',
        ],
        inputs,
      ),
      id: experience.id as string,
      city,
      state,
      experienceExperienceCategoryRelation,
      experienceExperienceSubCategoryRelation,
      experienceExperienceMediaRelation,
      experienceExperienceWaiverRelation,
      experienceExperienceScheduleRelation,
      experienceExperiencePricingRelation,
      experienceExperienceKeywordRelation,
      requireReservation: !!inputs.requireReservation,
      location,
    };

    updateExperience(data, experience);
  };

  useEffect(() => {
    /**
     * Manually registers some fields whose value cannot
     * be taken by the inputRef attribute.
     */
    method.register('categories');
    method.register('subCategories');
    method.register('openingTimes');
    method.register('pricings');
    method.register('media');
    method.register('mediaToDelete');
    method.register('waiver');
    method.register('waiverToDelete');
    method.register('requireReservation');
    method.register('lat');
    method.register('lng');
  }, [method]);

  let content = (
    <ExperienceFormProvider
      experience={experience}
      loadingSubmit={loadingExperience}
    >
      <FormProvider {...method}>
        <PaperContainer>
          <Box pt={7} pr={5}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <ExperienceAvatar />
              </Grid>
              <Grid item xs={9}>
                <form onSubmit={method.handleSubmit(onSubmit)}>
                  <ExperienceGeneralFields />
                  <ExperienceOpeningTime />
                  <ExperiencePricing />
                  <ExperienceRestrictions />
                  <Media />
                  <Waiver />
                  <ExperienceFormButtons />
                </form>
              </Grid>
            </Grid>
          </Box>
        </PaperContainer>
      </FormProvider>
    </ExperienceFormProvider>
  );

  useWebpageTitle('Candid Travel Ventures - Experience Detail');

  if (loading) {
    content = <ExperienceLoading />;
  }

  return (
    <Box display="flex" flexDirection="column" flexGrow={1}>
      <ExperienceDetailHeader />
      <Box mt={4} flexGrow={1} display="flex">
        {content}
      </Box>
    </Box>
  );
};
