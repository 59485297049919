import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { PUBLIC_URL } from '../../../constants';

const LOGO = `${PUBLIC_URL}/logo.svg`;

const useStyles = makeStyles({
  image: {
    height: '42px',
    width: '168px',
  },
});

/**
 * The main logo of the App.
 *
 * @returns {JSX.Element} The logo component.
 */
export const LogoDefault: React.FC = () => {
  const classes = useStyles();

  return <img src={LOGO} alt="candid logo" className={classes.image} />;
};
