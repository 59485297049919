import React from 'react';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import { AccountMenuItem } from './AccountMenuItem';
import {
  AccountInfoIcon,
  AccountInfoGreenIcon,
} from '../../../shared/icons/icons';

/**
 * @returns {JSX.Element} Account menu.
 */
export const AccountMenu: React.FC = () => (
  <Box>
    <Box pt="25px" mb="25px">
      <AccountMenuItem
        text="Account Information"
        icon={<AccountInfoIcon />}
        selectedIcon={<AccountInfoGreenIcon />}
        path="/my-account/information"
      />
    </Box>
    <Divider />
  </Box>
);
