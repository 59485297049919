import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { toast } from 'react-toastify';
import { Auth0ProviderWithHistory } from './modules/auth/Auth0ProviderWithHistory';
import { Routes } from './routes/routes';
import { apolloClient as client } from './shared/apollo';
import { theme } from './shared/components/ui/theme';
import { ToastContainer } from './shared/components/ui/toasts/ToastContainer';
import './shared/css/App.css';
import 'react-toastify/dist/ReactToastify.css';

/**
 * @returns {JSX.Element} - Main app component.
 */
function App(): JSX.Element {
  return (
    <BrowserRouter>
      <Auth0ProviderWithHistory>
        <ApolloProvider client={client}>
          <CssBaseline />
          <ThemeProvider theme={theme}>
            <Routes />
            <ToastContainer
              position={toast.POSITION.TOP_CENTER}
              autoClose={false}
            />
          </ThemeProvider>
        </ApolloProvider>
      </Auth0ProviderWithHistory>
    </BrowserRouter>
  );
}

// eslint-disable-next-line import/no-default-export
export default App;
