import React, { useState, useMemo, useRef } from 'react';
import { useCallAction, useFetchAction } from '@cobuildlab/react-simple-state';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useSubscription as apolloSubcription } from '@apollo/client';
import { styled } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Popover from '@material-ui/core/Popover';
import CircularProgress from '@material-ui/core/CircularProgress';
import MailIcon from '@material-ui/icons/Mail';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import {
  fetchNotifications,
  markAllAsReadAction,
  updateNotificationAction,
} from './notification-actions';
import { NOTIFICATION_TYPES } from '../../shared/constants';
import { SUB_NEW_NOTIFICATION } from './notification-queries';
import { NotificationType } from '../../shared/types';

const NotificationsHeader = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
}));

const ListItemWrapper = styled('div')({
  '& .MuiListItemText-primary, & .MuiListItemText-primary:hover': {
    color: '#000000de',
  },
});

/**
 * @returns {JSX.Element} - NotificationList component.
 */
export function NotificationList(): JSX.Element {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const history = useHistory();

  const [notifications, loadingNotifications, { refetch }] = useFetchAction(
    fetchNotifications,
    [],
  );

  const [updateNotification, loadingUpdateNotification] = useCallAction(
    updateNotificationAction,
    {
      /**
       * Completion callback.
       */
      onCompleted: () => {
        refetch();
      },
    },
  );

  const [markAllAsRead, loadingMarkAllAsRead] = useCallAction(
    markAllAsReadAction,
    {
      /**
       * Completion callback.
       */
      onCompleted: () => {
        refetch();
      },
    },
  );

  const loading =
    loadingNotifications || loadingUpdateNotification || loadingMarkAllAsRead;

  const totalUnread = useMemo(() => {
    if (loading) {
      return 0;
    }

    return notifications.filter((notification) => !notification.read).length;
  }, [loading, notifications]);

  apolloSubcription(SUB_NEW_NOTIFICATION, {
    /**
     * Handle inconming notifications.
     */
    onSubscriptionData: () => {
      refetch();
    },
    fetchPolicy: 'network-only',
  });

  /**
   * Open popover.
   */
  const handleOpen = (): void => {
    setOpen(true);
  };

  /**
   * Close popover.
   */
  const handleClose = (): void => {
    setOpen(false);
  };

  /**
   * Mark all notifications as read.
   */
  const handleMarkAllAsRead = (): void => {
    handleClose();
    markAllAsRead();
  };

  /**
   * @param notification - Target notification.
   */
  const handleClick = (notification: NotificationType): void => {
    handleClose();
    history.push(notification.path || '#');
    if (!notification.read) {
      updateNotification({ id: notification.id, read: true });
    }
  };

  /**
   * @param {string} notificationType - Type of notification.
   * @returns {JSX.Element} - Icon component.
   */
  const getIcon = (notificationType: string | undefined): JSX.Element => {
    switch (notificationType) {
    case NOTIFICATION_TYPES.USER_NEW_MESSAGE:
      return <MailIcon />;
    case NOTIFICATION_TYPES.USER_BOOKING_CONFIRMED:
      return <CheckCircleIcon />;
    case NOTIFICATION_TYPES.USER_BOOKIING_REJECTED:
      return <CancelIcon />;
    case NOTIFICATION_TYPES.ADMIN_NEW_MESSAGE:
      return <MailIcon />;
    case NOTIFICATION_TYPES.ADMIN_BOOKING_CREATED:
      return <NewReleasesIcon />;
    case NOTIFICATION_TYPES.ADMIN_BOOKING_PAID:
      return <CreditCardIcon />;
    default:
      return <MailIcon />;
    }
  };

  const bellIcon = loading ? (
    <CircularProgress />
  ) : (
    <IconButton
      ref={anchorRef}
      color={open ? 'primary' : 'default'}
      onClick={handleOpen}
    >
      <Badge
        badgeContent={totalUnread > 99 ? '99+' : totalUnread}
        color="error"
      >
        <img src="/icons/alert.svg" alt="alert" />
      </Badge>
    </IconButton>
  );

  const withoutNotifications = (
    <Box padding="10px">
      <Typography variant="subtitle1">
        You don&apos;t have any notifications yet
      </Typography>
    </Box>
  );

  const withNotifications = (
    <div>
      <NotificationsHeader display="flex" alignItems="center" py={2} px={2.5}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h6">Notifications</Typography>
          <Typography variant="body2">
            {totalUnread > 0 &&
              `${totalUnread} new notification${totalUnread === 1 ? '' : 's'}`}
          </Typography>
        </Box>

        {totalUnread > 0 && (
          <Tooltip title="Mark all as read">
            <IconButton onClick={handleMarkAllAsRead}>
              <Box color="primary.contrastText">
                <DoneAllIcon />
              </Box>
            </IconButton>
          </Tooltip>
        )}
      </NotificationsHeader>
      <Divider />
      <List disablePadding>
        {!loading &&
          notifications.map((notification) => (
            <ListItemWrapper key={notification.id}>
              <ListItem
                button
                selected={!notification.read}
                onClick={() => {
                  handleClick(notification);
                }}
              >
                <ListItemAvatar>
                  <Avatar>{getIcon(notification.type)}</Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={notification.message}
                  secondary={moment(notification.createdAt).fromNow()}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => {
                      handleClick(notification);
                    }}
                  >
                    <ArrowForwardIosIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            </ListItemWrapper>
          ))}
      </List>
    </div>
  );

  return (
    <>
      {bellIcon}

      <Popover
        open={open && !loading}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{ square: false }}
      >
        {notifications.length > 0 ? withNotifications : withoutNotifications}
      </Popover>
    </>
  );
}
