import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { ButtonDefault, ButtonDanger } from '../buttons/Buttons';

type Props = {
  isOpen: boolean;
  title: string;
  children: React.ReactNode;
  loading: boolean;
  onConfirm: () => void;
  onClose: () => void;
  onCancel: () => void;
  cancelText: string;
  confirmText: string;
};

/**
 * @param props - Props received.
 * @param {boolean} props.isOpen - Check if is open.
 * @param {string} props.title  - Title.
 * @param {React.ReactNode} props.children - Element children.
 * @param {boolean} props.loading - Loading state.
 * @param {Function} props.onConfirm - Function to confirm.
 * @param {Function} props.onClose - Function to close.
 * @param {Function} props.onCancel - Function to cancel.
 * @param {string} props.cancelText - Cancel text.
 * @param {string} props.confirmText - Confirm text.
 *
 * @returns {JSX.Element} Decision dialog.
 */
export const DecisionDialog: React.FC<Props> = ({
  isOpen,
  title,
  children,
  loading,
  onConfirm,
  onClose,
  onCancel,
  cancelText,
  confirmText,
}) => (
  <Dialog open={isOpen} onClose={onClose}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>{children}</DialogContent>
    <Divider />
    <Box p={2}>
      <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
        <Grid item>
          <ButtonDefault disabled={loading} onClick={onCancel}>
            {cancelText.toUpperCase()}
          </ButtonDefault>
        </Grid>
        <Grid item>
          <ButtonDanger isLoading={loading} onClick={onConfirm}>
            {confirmText.toUpperCase()}
          </ButtonDanger>
        </Grid>
      </Grid>
    </Box>
  </Dialog>
);
