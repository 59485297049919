import React from 'react';
import {
  Avatar,
  Box,
  Grid,
  Typography,
  Button,
  makeStyles,
} from '@material-ui/core';
import { PictureAsPdf } from '@material-ui/icons';
import { CurrentUser, MessageType } from '../../../shared/types';

const useStyles = makeStyles({
  buttonMediaFile: {
    backgroundColor: 'rgba(0,0,0,0.1)',
    boxShadow: 'none',
    padding: '8px 3px',
    margin: '4px',
  },
  buttonMediaImg: {
    padding: '2px',
  },
  iconColor: {
    color: 'rgba(129,0,0,0.5)',
  },
});

export interface DisplayMessagesProps {
  data: MessageType;
  sessionUser: CurrentUser;
  dataHeader?: string;
  scroll: React.MutableRefObject<HTMLDivElement | null>;
  scrollDemo: () => void;
}

/**
 * @param {*} data - Data recived.
 * @returns {JSX.Element} - View.
 */
export const DisplayMessages: React.FC<DisplayMessagesProps> = ({
  data,
  sessionUser,
  dataHeader,
  scroll,
  scrollDemo,
}) => {
  const sameUser = sessionUser?.user?.email === data?.createdBy?.email;
  const classes = useStyles();
  return (
    <Box key={`msg-${data?.id}`}>
      <Grid container direction="row">
        <Grid item md={1}>
          {!sameUser ? (
            <>
              <Avatar alt="Avatar Customer" src={dataHeader} />
            </>
          ) : null}
        </Grid>
        <Grid item xs={10} md={11}>
          <Grid
            container
            direction="row"
            justifyContent={sameUser ? 'flex-end' : 'flex-start'}
            alignItems="center"
          >
            <Grid item>
              <Box
                borderRadius={
                  sameUser ? '10px 10px 0px 10px' : '10px 10px 10px 0px'
                }
                padding="10px"
                margin="10px"
                boxShadow="0px 0px 15px #e0e0e0"
                style={{
                  backgroundColor: sameUser ? '#00CC99' : 'white',
                }}
              >
                {data?.messageMediaRelation?.items ? (
                  <Grid container direction="row" justifyContent="center">
                    {data?.messageMediaRelation?.items?.map((fileItem) => (
                      <div key={`file-${data?.id}`}>
                        <Grid item md={12}>
                          {fileItem.file.filename.includes('pdf') ? (
                            <>
                              <Button
                                href={fileItem.file.downloadUrl}
                                target="_blank"
                                variant="contained"
                                className={classes.buttonMediaFile}
                              >
                                <Grid
                                  container
                                  direction="row"
                                  justifyContent="center"
                                >
                                  <Grid item md={6}>
                                    <PictureAsPdf
                                      fontSize="large"
                                      className={classes.iconColor}
                                    />
                                  </Grid>
                                  <Grid item md={8}>
                                    <Typography variant="caption" noWrap>
                                      {fileItem.file.filename.substring(0, 8)}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Button>
                            </>
                          ) : (
                            <Button
                              href={fileItem.file.downloadUrl}
                              target="_blank"
                              className={classes.buttonMediaImg}
                            >
                              <img
                                key={fileItem.id}
                                src={fileItem.file.downloadUrl}
                                alt={fileItem.file.filename}
                                width="100px"
                                height="100px"
                              />
                            </Button>
                          )}
                        </Grid>
                      </div>
                    ))}
                  </Grid>
                ) : null}
                <Typography
                  ref={scroll}
                  style={{
                    fontSize: '13px',
                    color: sameUser ? 'white' : 'black',
                    fontWeight: 500,
                  }}
                >
                  {data?.message}
                  {scrollDemo()}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
