import React from 'react';
import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { Sidebar } from './Sidebar';
import { Topbar } from './Topbar';

const useStyles = makeStyles({
  root: {
    display: 'flex',
  },
  mainContent: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  content: {
    padding: '35px 55px 55px 55px',
  },
});

/**
 * @param {object}props - Props.
 * @param {JSX.Element} props.children - Children to render.
  @returns {JSX.Element} - Layout component of the app.
 */
export function Layout({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Sidebar />
      <main className={classes.mainContent}>
        <Topbar />
        <Box
          className={classes.content}
          width="100%"
          height="100%"
          maxWidth="calc(100vw - 75px)"
          minHeight="calc(100vh - 73px)"
          display="flex"
          flexDirection="column">
          {children}
        </Box>
      </main>
    </div>
  );
}
