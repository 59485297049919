import { createAction } from '@cobuildlab/react-simple-state';
import {
  OnExperienceCategories,
  OnExperienceCategoriesError,
} from './experience-category-events';
import { FETCH_EXPERIENCE_CATEGORIES } from './experience-category-queries';
import { apolloClient } from '../../shared/apollo';

export const fetchExperienceCategoriesAction = createAction(
  OnExperienceCategories,
  OnExperienceCategoriesError,
  async (isActive = true) => {
    const response = await apolloClient.query({
      query: FETCH_EXPERIENCE_CATEGORIES,
      fetchPolicy: 'cache-first',
      variables: {
        isActive,
      },
    });

    return response?.data?.experienceCategoriesList?.items;
  },
);
