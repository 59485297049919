import React, { useRef, useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import uniqueString from 'unique-string';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';
import { useFormContext } from 'react-hook-form';
import * as toast from '../../../../shared/components/ui/toasts/Toast';
import { ExperienceKeywordType } from '../../../../shared/types';
import { ExperienceFormContext } from './ExperienceFormProvider';

type PropsStyleTypes = {
  isActive: boolean;
  hasKeywords: boolean;
}

const useStyles = makeStyles({
  inputContainer: {
    position: 'relative',
    minHeight: '56px',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '11.5px',
    paddingRight: '11.5px',
    borderRadius: '4px',
    border: '1px solid #D2D2D2',
    color: '#ADADAD',
    fontSize: '16px',
    lineHeight: '26px',
    cursor: 'pointer',
    '&:hover': {
      border: '1px solid rgba(0, 0, 0, 0.87)',
    },
  },
  inputLabel: {
    position: 'absolute',
    top: '0',
    left: '-11px',
    paddingLeft: '4.5px',
    paddingRight: '5px',
    background: 'white',
    color: 'color: rgba(0,0,0,0.54)',
    transformOrigin: '100px 20px',
    transform: 'scale(0.8) translateY(-19px)',
  },
  chipContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    /**
     * @param props - Props received.
     * @param {boolean} props.hasKeywords - If has keywords.
     *
     * @returns {string} Padding top styles.
     */
    paddingTop: ({ hasKeywords }: PropsStyleTypes) =>  hasKeywords ? '10px' : '0px',
  },
  input: {
    /**
     * @param props - Props received.
     * @param {boolean} props.isActive - Is active input.
     * @returns {string} Visible styles.
     */
    visibility: ({ isActive }: PropsStyleTypes) => isActive ? 'visible' : 'hidden',
    /**
     * @param props - Props received.
     * @param {boolean} props.hasKeywords  - If has keywords.
     * @returns {string}  Margin styles.
     */
    marginLeft: ({ hasKeywords }: PropsStyleTypes) =>  hasKeywords ? '6px' : '0px',
    marginBottom: '5px',
    border: 'none',
    fontFamily: 'Barlow',
    fontWeight: 400,
    fontSize: '16px',
    outline: 'none',

    '&::placeholder': {
      color: 'rgba(0, 0 ,0 , 0.54)',
      letterSpacing: '0.09px'
    }
  },
});

/**
 * @returns {JSX.Element} Input keywords.
 */
export const ExperienceKeyWords: React.FC = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [activeInput, setActiveInput] = useState<boolean>(false);
  const [keywords, setKeywords] = useState<ExperienceKeywordType[]>([]);
  const [text, setText] = useState<string>('');
  const { setValue, getValues } = useFormContext();
  const { loadingSubmit, experience } = useContext(ExperienceFormContext);

  const classes = useStyles({
    isActive: activeInput,
    hasKeywords: !!keywords.length
  });


  /**
   * @param {React.KeyboardEvent<HTMLInputElement>} e - Event form.
   */
  const handleAddKeywords = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key !== 'Enter') {
      return;
    }

    if (!text) {
      return;
    }

    const isRepeat = keywords.some(({ text: label }) => ( label.trim() === text.trim() ));

    if (isRepeat) {
      toast.error('Words cannot be repeated');
      return;
    }

    const newKeyword = { text: text.trim(), id: uniqueString() };
    const keywordsToAdd: ExperienceKeywordType[] = getValues('keywords') || [];

    setText('');
    setKeywords([...keywords, newKeyword]);
    setValue('keywords', [...keywordsToAdd, newKeyword]);
  };

  /**
   * @param {string} keywordId - Keyword id.
   */
  const handleRemoveFromForm = (keywordId: string): void => {
    const keywordsToAdd: ExperienceKeywordType[] = getValues('keywords') || [];

    const filterKeywordsToAdd = keywordsToAdd.filter(({ id }) => (
      id !== keywordId
    ));

    setValue('keywords', filterKeywordsToAdd);
  };

  /**
   * @param {string} keywordId - Keyword id.
   */
  const handleToDelete = (keywordId: string): void => {
    if (!experience) return;

    const { experienceExperienceKeywordRelation: { items } } = experience;
    const isInForm = items?.some(({ id }) => id === keywordId);

    if (isInForm) {
      const keywordsToDelete = getValues('keywordsToDelete') || [];
      setValue('keywordsToDelete', [...keywordsToDelete, keywordId]);
    }
  };

  /**
   * @param {string} keywordId - Keyword id.
   */
  const handleRemove = (keywordId: string): void => {
    const keywordsToShow = keywords.filter(({ id }) => (
      id !== keywordId
    ));

    setKeywords(keywordsToShow);
    setActiveInput(!!keywordsToShow.length);
    handleRemoveFromForm(keywordId);
    handleToDelete(keywordId);

    if (!keywordsToShow.length) {
      setText('');
    }
  };

  let label = (
    <span>Optional Keywords </span>
  );

  let content: JSX.Element[] | null = null;

  if (activeInput || keywords.length) {
    label = (
      <div className={classes.inputLabel}>
        Optional Keywords
      </div>
    );
  }

  if (keywords.length) {
    content = keywords.map((keyword) => (
      <Box mb="5px" ml="2px" key={keyword.id}>
        <Chip
          label={keyword.text}
          color="primary"
          onDelete={() =>  {
            if (!loadingSubmit) {
              handleRemove(keyword.id as string);
            }
          }}
        />
      </Box>
    ));
  }

  useEffect(() => {
    if (activeInput) {
      inputRef.current?.focus();
    }
  }, [activeInput]);

  useEffect(() => {
    if (experience) {
      const { experienceExperienceKeywordRelation: { items } } = experience;

      if (items?.length) {
        setKeywords(items.map((keyword) => ({...keyword})));
      }
    }
  }, [experience]);

  return (
    <div
      className={classes.inputContainer}
      role="button"
      tabIndex={0}
      onClick={() => {
        setActiveInput(true);
      }}
    >
      {label}
      <Box className={classes.chipContainer}>
        {content}
        <input
          value={text}
          onKeyPress={(e) =>  e.key === 'Enter' && e.preventDefault() }
          onKeyUp={handleAddKeywords}
          className={classes.input}
          placeholder="Add Keyword"
          disabled={loadingSubmit}
          ref={inputRef}
          onBlur={() => {
            if (!keywords.length) {
              setText('');
              setActiveInput(false);
            }
          }}
          onChange={(e) => setText(e.currentTarget.value)}
        />
      </Box>
    </div>
  );
};
