import { createAction } from '@cobuildlab/react-simple-state';
import { apolloClient } from '../../shared/apollo';
import { NotificationType } from '../../shared/types';
import {
  OnErrorFetchNotifications,
  OnFetchNotifications,
  OnMarkAllAsRead,
  OnErrorMarkAllAsRead,
  OnUpdateNotification,
  OnErrorUpdateNotification,
} from './notification-events';
import {
  FETCH_NOTIFICATIONS,
  MARK_ALL_AS_READ,
  UPDATE_NOTIFICATION,
} from './notification-queries';

/**
 * @description - Fetch notifications.
 */
export const fetchNotifications = createAction(
  OnFetchNotifications,
  OnErrorFetchNotifications,
  async (): Promise<NotificationType[]> => {
    const response = await apolloClient.query({
      query: FETCH_NOTIFICATIONS,
      fetchPolicy: 'network-only',
    });

    return response.data.notificationsList.items;
  },
);

/**
 * @description - Mark all of admin's notifications as read.
 */
export const markAllAsReadAction = createAction(
  OnMarkAllAsRead,
  OnErrorMarkAllAsRead,
  async (): Promise<{ count: number }> => {
    const response = await apolloClient.mutate({
      mutation: MARK_ALL_AS_READ,
      fetchPolicy: 'network-only',
    });

    return response.data.notificationUpdateByFilter;
  },
);

/**
 * @description - Update notification.
 */
export const updateNotificationAction = createAction(
  OnUpdateNotification,
  OnErrorUpdateNotification,
  async (data: NotificationType): Promise<NotificationType> => {
    const response = await apolloClient.mutate({
      mutation: UPDATE_NOTIFICATION,
      fetchPolicy: 'network-only',
      variables: {
        data,
      },
    });

    return response.data.notificationUpdate;
  },
);
