import { createAction } from '@cobuildlab/react-simple-state';
import { apolloClient } from '../../shared/apollo';
import { MsgSubscriptionNode } from '../../shared/types';
import {
  OnFetchChatHeader,
  OnFetchChatHeaderError,
  OnFetchItineraries,
  OnFetchItinerariesError,
  OnFetchMessages,
  OnFetchMessagesError,
  OnFetchNewMessages,
  OnFetchNewMessagesError,
  OnMessageCreate,
  OnMessageCreateError,
  OnSearchMessages,
  OnSearchMessagesError,
  OnSelectedItemId,
  OnSelectedItemIdError,
} from './inbox-events';
import {
  MSG_CREATE,
  MESSAGES_LIST,
  CHAT_HEADER,
  ITINERARIES_LIST,
} from './inbox-queries';

export type FileType = {
  fileId: string;
  downloadUrl: string;
  filename: string;
};

export const fetchMessagesListAction = createAction(
  OnFetchMessages,
  OnFetchMessagesError,
  async (filterId?: string) => {
    const filter = {
      filterMsg: {
        inbox: {
          id: {
            equals: filterId,
          },
        },
      },
    };
    const response = await apolloClient.query({
      query: MESSAGES_LIST,
      variables: filter,
      fetchPolicy: 'network-only',
    });
    return response?.data?.messagesList;
  },
);

export const fetchSelectedItemIdAction = createAction(
  OnSelectedItemId,
  OnSelectedItemIdError,
  async (itemSelectedId: string) => {
    const itemId = itemSelectedId;
    return itemId;
  },
);

export const createMessageAction = createAction(
  OnMessageCreate,
  OnMessageCreateError,
  async (inboxId: string, imageFile?: FileType[], messageText?: string) => {
    const messageMediaRelation = {
      create: imageFile?.map((midia) => ({
        file: {
          create: {
            fileId: midia.fileId,
            filename: midia.filename,
          },
        },
      })),
    };

    const data = {
      message: messageText,
      inbox: {
        connect: {
          id: inboxId,
        },
      },
      messageMediaRelation,
    };

    const response = await apolloClient.mutate({
      mutation: MSG_CREATE,
      fetchPolicy: 'network-only',
      variables: {
        data,
      },
    });
    return response?.data;
  },
);

export const searchInMessagesAction = createAction(
  OnSearchMessages,
  OnSearchMessagesError,
  async (searchValue: string) => searchValue,
);

export const fetchChatHeader = createAction(
  OnFetchChatHeader,
  OnFetchChatHeaderError,
  async (tripInboxId?: string) => {
    const filter = {
      filter: {
        inboxRelation: {
          some: {
            id: {
              equals: tripInboxId,
            },
          },
        },
      },
    };
    const response = await apolloClient.query({
      query: CHAT_HEADER,
      fetchPolicy: 'network-only',
      variables: filter,
    });
    return response?.data?.itinerariesList;
  },
);

export const fetchNewMessages = createAction(
  OnFetchNewMessages,
  OnFetchNewMessagesError,
  async (inboxIdent: string, subscriptionMsg: MsgSubscriptionNode[]) => {
    const dfc = {
      inbox: inboxIdent,
      newMsg: [subscriptionMsg[0]?.node[0]?.message],
    };
    return dfc;
  },
);

export const fetchItinerariesAction = createAction(
  OnFetchItineraries,
  OnFetchItinerariesError,
  async (tripFilter?: string) => {
    const filter = {
      filter: {
        inboxRelation: {
          some: {
            messageRelation: {
              some: {
                id: {
                  is_not_empty: true,
                },
              },
            },
          },
        },
        OR: [
          { OR: { user: { firstName: { contains: tripFilter } } } },
          { OR: { user: { lastName: { contains: tripFilter } } } },
          { OR: { user: { fullName: { contains: tripFilter } } } },
          {
            OR: {
              inboxRelation: {
                some: {
                  messageRelation: {
                    some: { message: { contains: tripFilter } },
                  },
                },
              },
            },
          },
        ],
      },
    };
    const response = await apolloClient.query({
      query: ITINERARIES_LIST,
      fetchPolicy: 'network-only',
      variables: filter,
    });
    return response?.data?.itinerariesList;
  },
);
