import React from 'react';
import Menu from '@material-ui/core/Menu';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useFormContext, Controller, SubmitHandler } from 'react-hook-form';
import { CardTitlePage } from '../../../shared/components/ui/texts/Texts';
import {
  ButtonLink,
  ButtonLinkCancel,
  ButtonLinkDanger,
} from '../../../shared/components/ui/buttons/Buttons';

export type PeopleFilterState = {
  status: string;
  showArchivedUsers: boolean;
};

type ExperienceListMenuFilterProps = {
  onClose: () => void;
  anchorEl: null | HTMLElement;
  onApplyFilters: (filterState: PeopleFilterState) => void;
  filterState: PeopleFilterState;
  onResetFilter: (filterState: PeopleFilterState) => void;
};

const useStyles = makeStyles({
  menu: {
    width: '520px',
    borderRadius: '8px',
    outline: '0',
  },
});

export const INITIAL_FILTER_STATE = {
  status: '',
  showArchivedUsers: false,
};

/**
 * @param props - Props received.
 * @param {null | HTMLElement} props.anchorEl - Anchor element.
 * @param {Function} props.onClose - Function to close filter.
 * @param {Function} props.onApplyFilters - Function to apply filters.
 * @param {PeopleFilterState} props.filterState - Experience filter state.
 * @param {Function} props.onResetFilter - Function to reset filter.
 * @returns {JSX.Element} Menu filter.
 */
export const PeopleListMenuFilter: React.FC<ExperienceListMenuFilterProps> = ({
  anchorEl,
  onClose,
  onResetFilter,
  onApplyFilters,
  filterState,
}) => {
  const classes = useStyles();
  const open = !!anchorEl;
  const { control, handleSubmit } = useFormContext();

  /**
   * Function to apply filter.
   *
   * @param {PeopleFilterState} data - Filter data.
   */
  const onSubmit: SubmitHandler<PeopleFilterState> = (data): void => {
    onApplyFilters(data);
    onClose();
  };

  /**
   * Function to reset filter.
   */
  const handleResetFilter = (): void => {
    onResetFilter(INITIAL_FILTER_STATE);
    onClose();
  };

  return (
    <Menu
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      elevation={24}
      classes={{
        paper: classes.menu,
      }}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Container>
        <Box
          pt={2}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <CardTitlePage>Filter by</CardTitlePage>
          <ButtonLinkDanger type="button" onClick={handleResetFilter}>
            RESET FILTERS
          </ButtonLinkDanger>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mt={2} pb={4}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel>Status</InputLabel>
                  <Controller
                    name="status"
                    control={control}
                    defaultValue={filterState.status}
                    render={({ field }) => (
                      <Select {...field} label="Status">
                        <MenuItem value="RETURNING">Returning</MenuItem>
                        <MenuItem value="ONE_TIME">One Time</MenuItem>
                        <MenuItem value="NEW">New</MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="showArchivedUsers"
                  control={control}
                  defaultValue={filterState.showArchivedUsers}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={field.value}
                          onChange={field.onChange}
                          color="primary"
                        />
                      }
                      label="Show archived users"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="flex-end">
                  <Box mr={1}>
                    <ButtonLinkCancel type="button" onClick={onClose}>
                      CANCEL
                    </ButtonLinkCancel>
                  </Box>
                  <Box>
                    <ButtonLink type="submit">APPLY FILTERS</ButtonLink>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Container>
    </Menu>
  );
};
