import { styled } from '@material-ui/core/styles';
import TableContainer from '@material-ui/core/TableContainer';

export const Container = styled(TableContainer)({
  background: '#FFFFFF',
  borderRadius: 8,
  boxShadow: '0 7px 21px 0 rgba(171,171,171,0.17)',
  // height: '100%',
  marginTop: 25,
  padding: 40,
});
