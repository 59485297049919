import { useEffect } from 'react';

/**
 * @param {string} title - Web title.
 */
export const useWebpageTitle = (title: string): void => {
  useEffect(() => {
    document.title = title;
  }, [title]);
};
