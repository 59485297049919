import { createAction } from '@cobuildlab/react-simple-state';
import { apolloClient } from '../../shared/apollo';
import {
  ExperienceDataSubmitType,
  ExperienceListDataType,
  ExperienceMediaCreateType,
} from './experience-model';
import {
  FETCH_EXPERIENCES_POPULAR,
  CREATE_EXPERIENCE,
  FETCH_EXPERIENCE,
  UPDATE_EXPERIENCE,
  FETCH_EXPERIENCES_LIST,
  DELETE_EXPERIENCE,
  DELETE_MULTIPLES_EXPERIENCES,
  DEACTIVE_MULTIPLES_EXPERIENCES,
  CREATE_EXPERIENCE_PRICINGS,
} from './experience-queries';
import {
  OnExperiencePopular,
  OnExperiencePopularError,
  OnExperienceCreate,
  OnExperienceCreateError,
  OnExperienceUpdate,
  OnExperienceUpdateError,
  OnExperienceDetail,
  OnExperienceDetailError,
  OnExperienceList,
  OnExperienceListError,
  OnExperienceDelete,
  OnExperienceDeleteError,
  OnExperienceMultipleDelete,
  OnExperienceMultipleDeleteError,
  OnExperienceMultipleDeactiveError,
  OnExperienceMultipleDeactive,
} from './experience-events';
import {
  createExperienceValidator,
  openingTimesValidator,
  pricingValidator,
  mediaRequiredValidator,
  mediaUpdateRequireValidator,
  maxWaiverValidator,
  maxWaiverUpdateValidator,
  createPriceValidationTraveler,
} from './experience-validator';
import { createFilterExperienceList } from './experience-utils';
import {
  SortType,
  ExperienceType,
  KeyFilterType,
  ExperienceMediaGalleryType,
  ExperienceWaveType,
} from '../../shared/types';

export const fetchExperiencesPopularAction = createAction(
  OnExperiencePopular,
  OnExperiencePopularError,
  async (startDateTime: string, endDateTime: string) => {
    const response = await apolloClient.query({
      query: FETCH_EXPERIENCES_POPULAR,
      variables: {
        startDateTime,
        endDateTime,
      },
    });

    return response.data?.experiencesList?.groups;
  },
);

export const createExperienceAction = createAction(
  OnExperienceCreate,
  OnExperienceCreateError,
  async (data) => {
    createExperienceValidator({
      openingTimes: data.experienceExperienceScheduleRelation.create,
      pricings: data.experienceExperiencePricingRelation.create,
      location: data.location,
    });

    createPriceValidationTraveler(
      data.experienceExperiencePricingRelation.create,
    );
    mediaRequiredValidator(data.experienceExperienceMediaRelation.create);
    maxWaiverValidator(data.experienceExperienceWaiverRelation.create);

    const { experienceExperiencePricingRelation, ...restData } = data;

    const response = await apolloClient.mutate({
      mutation: CREATE_EXPERIENCE,
      fetchPolicy: 'network-only',
      variables: {
        data: { ...restData, stripeProductId: '' },
      },
    });

    await apolloClient.mutate({
      mutation: CREATE_EXPERIENCE_PRICINGS,
      fetchPolicy: 'network-only',
      variables: {
        data: experienceExperiencePricingRelation.create.map(
          (item: Record<string, unknown>) => ({
            experience: {
              connect: {
                id: response?.data?.experienceCreate?.id,
              },
            },
            stripePriceId: '',
            ...item,
          }),
        ),
      },
    });

    return response?.data?.experienceCreate?.id;
  },
);

export const updateExperienceAction = createAction(
  OnExperienceUpdate,
  OnExperienceUpdateError,
  async (data: ExperienceDataSubmitType, experience: ExperienceType) => {
    const {
      experienceExperienceScheduleRelation: scheduleRelation,
      experienceExperiencePricingRelation: pricingRelation,
      experienceExperienceMediaRelation: mediaRelation,
      experienceExperienceWaiverRelation: waiverRelation,
    } = data;

    const updatedSchedules = scheduleRelation.update || [];
    const updatedPricings = pricingRelation.update || [];

    createExperienceValidator({
      openingTimes: updatedSchedules.map((schedule) => ({ ...schedule.data })),
      pricings: updatedPricings.map((pricing) => ({ ...pricing.data })),
      location: data.location,
    });

    mediaUpdateRequireValidator(
      mediaRelation.create as ExperienceMediaCreateType[],
      mediaRelation.disconnect as KeyFilterType[],
      experience.experienceExperienceMediaRelation
        .items as ExperienceMediaGalleryType[],
    );

    maxWaiverUpdateValidator(
      waiverRelation.create as ExperienceMediaCreateType[],
      waiverRelation.disconnect as KeyFilterType[],
      experience.experienceExperienceWaiverRelation
        .items as ExperienceWaveType[],
    );

    if (scheduleRelation.create?.length) {
      openingTimesValidator(scheduleRelation.create);
    }

    if (pricingRelation.create?.length) {
      pricingValidator(pricingRelation.create);
    }

    if (pricingRelation.create) {
      const selectedPricings = updatedPricings.map((pricing) => ({
        ...pricing.data,
      }));

      createPriceValidationTraveler(
        pricingRelation.create,
        selectedPricings.filter(({ isActive }) => isActive),
      );
    }

    const response = await apolloClient.mutate({
      mutation: UPDATE_EXPERIENCE,
      fetchPolicy: 'network-only',
      variables: {
        data: {
          ...data,
          experienceExperiencePricingRelation: {
            ...pricingRelation,
            create: [],
          },
        },
      },
    });

    if (pricingRelation?.create?.length) {
      await apolloClient.mutate({
        mutation: CREATE_EXPERIENCE_PRICINGS,
        fetchPolicy: 'network-only',
        variables: {
          data: pricingRelation.create.map((item: Record<string, unknown>) => ({
            experience: {
              connect: {
                id: response?.data?.experienceUpdate?.id,
              },
            },
            stripePriceId: '',
            ...item,
          })),
        },
      });
    }

    return response?.data?.experienceUpdate?.id;
  },
);

export const fetchExperienceAction = createAction(
  OnExperienceDetail,
  OnExperienceDetailError,
  async (id: string) => {
    const response = await apolloClient.query({
      query: FETCH_EXPERIENCE,
      fetchPolicy: 'network-only',
      variables: {
        id,
      },
    });

    return response?.data?.experience;
  },
);

export const fetchExperienceListAction = createAction(
  OnExperienceList,
  OnExperienceListError,
  async (
    data: ExperienceListDataType,
    page: number,
    sortData: SortType,
    first = 10,
  ) => {
    const filter = createFilterExperienceList(data);
    const skip = (page - 1) * first;
    let sort;

    if (sortData && Object.keys(sortData).length) {
      sort = sortData;
    }

    const response = await apolloClient.query({
      query: FETCH_EXPERIENCES_LIST,
      fetchPolicy: 'network-only',
      variables: {
        filter,
        skip,
        first,
        sort,
      },
    });

    return response?.data?.experiencesList;
  },
);

export const deleteExperienceAction = createAction(
  OnExperienceDelete,
  OnExperienceDeleteError,
  async (id: string) => {
    const response = await apolloClient.mutate({
      mutation: DELETE_EXPERIENCE,
      fetchPolicy: 'network-only',
      variables: {
        id,
      },
    });

    return response?.data?.experienceDelete?.success;
  },
);

export const deleteMultipleExperienceAction = createAction(
  OnExperienceMultipleDelete,
  OnExperienceMultipleDeleteError,
  async (ids: string[]) => {
    const response = await apolloClient.mutate({
      mutation: DELETE_MULTIPLES_EXPERIENCES,
      fetchPolicy: 'network-only',
      variables: {
        ids,
      },
    });

    return response?.data?.experienceDeleteByFilter?.success;
  },
);

export const updateExperienceStatusAction = createAction(
  OnExperienceUpdate,
  OnExperienceUpdateError,
  async (data) => {
    const response = await apolloClient.mutate({
      mutation: UPDATE_EXPERIENCE,
      fetchPolicy: 'network-only',
      variables: {
        data,
      },
    });

    return response?.data?.experienceUpdate?.id;
  },
);

export const deactiveExperienceMultiplesAction = createAction(
  OnExperienceMultipleDeactive,
  OnExperienceMultipleDeactiveError,
  async (experienceIds) => {
    const response = await apolloClient.mutate({
      mutation: DEACTIVE_MULTIPLES_EXPERIENCES,
      fetchPolicy: 'network-only',
      variables: {
        experienceIds,
      },
    });

    return response?.data?.experienceUpdateByFilter?.items;
  },
);
