import React from 'react';
import { Controller, useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import { ButtonPrimary } from '../../../shared/components/ui/buttons/Buttons';
import { BookingType } from '../../../shared/types';
import { bookingSchemaValidation } from '../booking-validators';

type RejectDialogProps = {
  booking: BookingType | null;
  isOpen: boolean;
  onClose: () => void;
  rejectBooking: (id: string, email: string, reason: string, refundAmount: number) => void;
};

type RejectDialogInputs = {
  rejectionReason: string;
};

/**
 * @param props - Props received.
 * @param {BookingType | null} props.booking - Booking data.
 * @param {boolean} props.isOpen - Check if is open.
 * @param {Function} props.onClose - Function to close.
 * @param {Function} props.rejectBooking - Reject booking action.
 *
 * @returns {JSX.Element} Booking details.
 */
export const RejectDialog: React.FC<RejectDialogProps> = ({
  booking,
  isOpen,
  onClose,
  rejectBooking,
}) => {
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(bookingSchemaValidation),
  });

  if (!booking) {
    return null;
  }

  /**
   * Close dialog.
   */
  const handleClose = (): void => {
    onClose();
    reset();
  };

  /**
   * @param {RejectDialogInputs} inputs - RejectDialog input data.
   */
  const onSubmit: SubmitHandler<RejectDialogInputs> = (
    inputs: RejectDialogInputs,
  ): void => {
    const refundAmount =
    booking.bookingBookingPricingRelation.items.reduce(
      (total, item) => total + item.price * item.peopleAmount,
      0
    ) * 100;
    rejectBooking(booking.id, booking.user.email, inputs.rejectionReason, refundAmount);
    handleClose();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>Reject Booking</DialogTitle>
      <DialogContent>
        <Box mt="20px" mb="20px">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="rejectionReason"
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      label="REASONS FOR THE REJECT"
                      multiline
                      rows={4}
                      variant="outlined"
                      fullWidth
                      error={!!error}
                      helperText={error && error.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2} justifyContent="flex-end">
                  <Grid item>
                    <ButtonPrimary
                      variant="outlined"
                      color="primary"
                      onClick={handleClose}
                    >
                      CANCEL
                    </ButtonPrimary>
                  </Grid>
                  <Grid item>
                    <ButtonPrimary
                      variant="outlined"
                      color="secondary"
                      type="submit"
                    >
                      REJECT BOOKING
                    </ButtonPrimary>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
