import gql from 'graphql-tag';

export const FETCH_EXPERIENCE_CATEGORIES = gql`
  query FetchExperienceCategory($isActive: Boolean) {
    experienceCategoriesList(filter: { isActive: { equals: $isActive } }) {
      items {
        id
        experienceCategoryName
      }
    }
  }
`;
