import React, { useEffect, useState } from 'react';
import { useCallAction, useSubscription } from '@cobuildlab/react-simple-state';
import { useSubscription as apolloSubcription } from '@apollo/client';
import moment from 'moment';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import {
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import orderBy from 'lodash/orderBy';
import { ItineraryType, RelationType } from '../../../shared/types';
import { OnSearchMessages, OnFetchNewMessages } from '../inbox-events';
import {
  fetchItinerariesAction,
  fetchSelectedItemIdAction,
} from '../inbox-actions';
import { MainLoader } from '../../../shared/components/MainLoader';
import { SUBS_NEW_MSG } from '../inbox-queries';

const useStyles = makeStyles({
  MuiListItem: {
    '&:hover': {
      backgroundColor: '#00CC99',
      color: '#fff',
    },
  },
  containerStl: {
    padding: '0px 1.3rem',
  },
  displayList: {
    height: '690px',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '5px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#00CC99',
      borderRadius: '10px',
    },
  },
  listItemTextP: {
    fontWeight: 500,
    color: 'black',
  },
  listItemTextS: {
    fontSize: '13px',
    fontWeight: 500,
    color: 'grey',
  },
  listItemTextD: {
    fontSize: '14px',
    color: 'black',
  },
});

/**
 * @returns {JSX.Element} - List of messages.
 */
export const InboxList: React.FC = () => {
  const classes = useStyles();
  const [itineraries, setItineraries] = useState<ItineraryType[]>([]);
  const [search, setSearch] = useState<string>();
  const [selectedItemId, setSelectedItemId] = useState<string>('');
  const [firstFetch, setFirstFetch] = useState<boolean>(false);
  const [isNewData, setIsNewData] = useState<boolean>(false);
  const [newMessages, setNewMessages] = useState<
    {
      inbox: string;
      newMsg: string[];
    }[]
  >([]);

  useSubscription(OnSearchMessages, (state) => {
    if (state !== null) setSearch(state);
  });

  useSubscription(OnFetchNewMessages, (state) => {
    if (state) setNewMessages([state]);
  });

  apolloSubcription(SUBS_NEW_MSG, {
    /**
     *
     * @param onSubs - Param.
     */
    onSubscriptionData: (onSubs) => {
      if (onSubs?.subscriptionData?.data) {
        setIsNewData(true);
      }
    },
    fetchPolicy: 'network-only',
  });

  const [itemIdSelection] = useCallAction(fetchSelectedItemIdAction, {});

  const [fetchItineraries, loadingTrips] = useCallAction(
    fetchItinerariesAction,
    {
      /**
       * @param {RelationType<ItineraryType>} data  - Trips data.
       */
      onCompleted: (data: RelationType<ItineraryType>) => {
        const { items } = data;
        setItineraries(items as ItineraryType[]);
        setFirstFetch(true);
      },
    },
  );

  /**
   * @param e - Event trigger.
   * @param item - Item object selected.
   */
  const inboxSelectedId = (
    e: React.MouseEvent<HTMLElement>,
    item: ItineraryType,
  ): void => {
    const tripInboxId = item?.inboxRelation?.items?.find(
      (inboxI) => inboxI?.id,
    );
    if (e && tripInboxId) {
      itemIdSelection(tripInboxId.id);
      setSelectedItemId(tripInboxId?.id);
    }
  };

  const trips = [...itineraries];
  const sortingTrips = orderBy(
    trips,
    (trip) => {
      const {
        inboxRelation: { items: inboxs },
      } = trip;
      const {
        messageRelation: { items: messages },
      } = inboxs[0];

      const sortingMessages = orderBy(messages, ['createdAt'], ['desc']);

      return sortingMessages[0].createdAt;
    },
    ['desc'],
  );

  const { id } = useParams<{ id: string }>();

  const defaultTrip = sortingTrips[0]?.inboxRelation?.items?.find(
    (defaultItem) => defaultItem.id,
  );

  const userChats = sortingTrips?.filter((latest) =>
    latest?.inboxRelation?.items?.find(
      (defaultItem) => defaultItem?.createdBy?.id === id,
    ),
  );

  const latestUserChat = userChats[0]?.inboxRelation?.items[0]?.id;

  useEffect(() => {
    if ((itineraries.length === 0 && search === '') || search === null) {
      fetchItineraries();
    }

    if (search !== null) {
      fetchItinerariesAction(search).then((e) => {
        if (e) {
          setItineraries(e.items);
        }
      });
    }

    if (isNewData === true) {
      fetchItineraries();
      setIsNewData(false);
    }
  }, [itineraries.length, fetchItineraries, search, newMessages, isNewData]);

  useEffect(() => {
    if (selectedItemId === '' && latestUserChat) {
      itemIdSelection(latestUserChat);
      return;
    }

    if (selectedItemId === '' && defaultTrip) itemIdSelection(defaultTrip?.id);
  }, [latestUserChat, defaultTrip, selectedItemId, itemIdSelection]);

  /**
   *
   * @returns {JSX.Element} - ListMessages.
   */
  const ListMessages = (): JSX.Element => {
    /**
     * @param tripItem - Item from messageRelation.
     * @returns {string} - Date formated.
     */
    const formatDate = (tripItem: ItineraryType): string => {
      if (tripItem) {
        const getDate = tripItem?.inboxRelation.items
          ?.map(
            (maped) => maped?.messageRelation?.items?.slice(-1)[0].createdAt,
          )
          .pop();

        const now = moment().format();
        const daysDiff = moment(now).diff(getDate);
        const duration = moment.duration(daysDiff, 'milliseconds');
        const totalDays = Math.floor(duration.asDays());

        const mSecDuration = moment.duration(daysDiff);
        const totalHours = Math.floor(mSecDuration.asHours());
        const totalMinutes = Math.floor(mSecDuration.asMinutes());

        const lessThanMonth = moment(getDate).format('MMM DD, h:mm a');
        const moreThanMonth = moment(getDate).format('MMM DD');

        const lessThanHour = totalHours < 1;
        const lessThanDay = totalHours < 24;
        const moreThanDay = totalHours >= 24 && !lessThanDay;

        if (lessThanHour && totalMinutes < 1) {
          return 'A MOMENT AGO';
        }

        if (lessThanHour && totalMinutes < 60) {
          return `${totalMinutes} MINUTES AGO`;
        }

        if (lessThanDay) {
          return `${totalHours} HOURS AGO`;
        }

        if (moreThanDay) {
          return lessThanMonth;
        }

        if (totalDays > 31 || moreThanDay) {
          return moreThanMonth;
        }
      }
      return 'result not handled';
    };

    return (
      <List className={classes.displayList}>
        {sortingTrips?.map((item) => (
          <Box key={`inbox-${item?.id}`}>
            <ListItem
              key={item.id}
              disableGutters
              divider
              button
              className={classes.MuiListItem}
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                inboxSelectedId(e, item);
              }}
              selected={
                selectedItemId ===
                item?.inboxRelation?.items?.find((inId) => inId.id)?.id
              }
            >
              <Grid container direction="row" className={classes.containerStl}>
                <Grid item md={8} className={classes.containerStl}>
                  <ListItemText
                    classes={{
                      primary: classes.listItemTextP,
                      secondary: classes.listItemTextS,
                    }}
                    primary={item.user?.fullName || 'No name'}
                    secondary={item?.itineraryName || 'No trip name'}
                  />
                </Grid>
                <Grid item md={4} className={classes.containerStl}>
                  <Typography
                    align="right"
                    variant="overline"
                    style={{
                      marginTop: '13px',
                      fontSize: '12px',
                      color: '#757575',
                      fontWeight: 500,
                    }}
                  >
                    {formatDate(item)}
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={10}
                  style={{ padding: '0px 1.3rem', marginBottom: '10px' }}
                >
                  <Typography noWrap className={classes.listItemTextD}>
                    {item?.inboxRelation?.items
                      ?.map(
                        (maped) =>
                          maped?.messageRelation?.items?.slice(-1)[0].message,
                      )
                      .pop()
                      ?.substring(0, 70)}
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
          </Box>
        ))}
      </List>
    );
  };

  return (
    <Grid container direction="row">
      <Grid item xs={12} md={12}>
        {(loadingTrips && firstFetch === false) ||
        (firstFetch && trips.length === 0) ? (
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-end"
            >
              <Box height="350px" marginTop="40%">
                {trips.length === 0 && !firstFetch ? (
                  <MainLoader />
                ) : (
                  'No messages'
                )}
              </Box>
            </Grid>
          ) : (
            <ListMessages />
          )}
      </Grid>
    </Grid>
  );
};
