import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { BasicText } from '../../../shared/components/ui/texts/Texts';

type DashboardCardProps = {
  title: string;
  message: string;
};

const useStyles = makeStyles({
  card: {
    borderRadius: '8px',
    padding: '25px',
    width: '100%',
  },
});

/**
 *
 * @param props - Props received.
 * @param {string} props.title - Title of card.
 * @param {string} props.message - Message of card.
 *
 * @returns {JSX.Element} Dashboard card.
 */
export const DashboardCard: React.FC<DashboardCardProps> = ({
  title,
  message,
}) => {
  const classes = useStyles();

  return (
    <Paper elevation={24} className={classes.card}>
      <Box>
        <Typography variant="h3" align="center" color="primary">
          {title}
        </Typography>
      </Box>
      <Box mt="2px">
        <BasicText align="center">{message}</BasicText>
      </Box>
    </Paper>
  );
};
