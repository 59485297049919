import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import { BLACK_TITLE, COLOR_BREAD_CRUMB } from './ui/theme/contants';
import { useMatchPath } from '../hooks/useMatchPath';

type BreadcrumbItemProps = {
  pathToMatch: string;
  breadcrumb: Array<{ label: string; path?: string; paramValue?: string }>;
};

const useStyles = makeStyles({
  link: {
    color: COLOR_BREAD_CRUMB,
    fontSize: '16px',
    textDecoration: 'none',
  },
  text: {
    color: BLACK_TITLE,
    letterSpacing: 0,
  },
});

/**
 * @param props - Received props.
 * @param {string} props.pathToMatch - Breadcrumb path.
 * @param {Array<{label: string, path: string}>} props.breadcrumb - Data from breadcrumb.
 *
 * @returns {JSX.Element} The breadcrumb.
 */
export const BreadcrumbItem: React.FC<BreadcrumbItemProps> = ({
  pathToMatch,
  breadcrumb,
}) => {
  const match = useMatchPath(pathToMatch);
  const classes = useStyles();
  const location = useLocation();

  let content: JSX.Element | null = null;

  if (match) {
    const breadcrumbList = breadcrumb.map((node) => {
      let { label } = node;

      if (node.path) {
        return (
          <Link key={node.path} className={classes.link} to={node.path}>
            {node.label}
          </Link>
        );
      }

      if (node.paramValue) {
        const params = new URLSearchParams(location.search);
        label = params.get(node.paramValue) as string;
      }

      return (
        <Typography className={classes.text} key={node.label}>
          {label}
        </Typography>
      );
    });

    content = <Breadcrumbs>{breadcrumbList}</Breadcrumbs>;
  }

  return <>{content}</>;
};
