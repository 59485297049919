import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { useFetchAction } from '@cobuildlab/react-simple-state';
import Box from '@material-ui/core/Box';
import { DashboardCard } from './components/DashboardCard';
import { DashboardHeader } from './components/DashboardHeader';
import { DashboardExperienceList } from './components/DashboardExperienceList';
import { DashboardCardSelected } from './components/DashboardCardSelected';
import { fetchDasbhoardAverageAction as fetchDasbhoardAverage } from './dashboard-actions';
import { DashboardUserChart } from './components/DashboardUserChart';
import {
  extractKpis,
  DashboarKpiType,
  DashboardDataType,
} from './dashboard-utils';
import { DashboardRevenueChart } from './components/DashboardRevenueChart';
import { DashboardCardLoading } from './components/DashboardCardLoding';
import { useWebpageTitle } from '../../shared/hooks/useWebpageTitle';

/**
 *
 * @returns {JSX.Element} - Dashboard view.
 */
export const Dashboard: React.FC = () => {
  const [kpis, setKpis] = useState<DashboarKpiType[]>([]);

  const [dashboardData, loading] = useFetchAction(fetchDasbhoardAverage, []);

  useEffect(() => {
    if (dashboardData) {
      setKpis(extractKpis(dashboardData as DashboardDataType));
    }
  }, [dashboardData]);

  let content: JSX.Element | JSX.Element[] = kpis.map(
    ({ title, message, hasMenu }) => {
      if (hasMenu) {
        return (
          <Grid item xs={6} key={message}>
            <DashboardCardSelected defaultTitle={title} message={message} />
          </Grid>
        );
      }

      return (
        <Grid item xs={6} key={message}>
          <DashboardCard title={title} message={message} />
        </Grid>
      );
    },
  );

  if (loading || !kpis.length) {
    content = <DashboardCardLoading />;
  }

  useWebpageTitle('Candid Travel Ventures - Dashboard');

  return (
    <div>
      <DashboardHeader />
      <Box mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <Grid container spacing={3}>
              {content}
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <DashboardExperienceList loadingDashboard={loading} />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <DashboardUserChart loadingDashboard={loading} />
              </Grid>
              <Grid item xs={6}>
                <DashboardRevenueChart loadingDashboard={loading} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};
