import { useState, useEffect } from 'react';
import { useLocation, matchPath } from 'react-router-dom';

/**
 * Hook used to check path match.
 *
 * @param {string} path - Path to compare.
 * @param {boolean} exact - If the path must be exact.
 *
 * @returns {boolean} Status if current path matches.
 */
export const useMatchPath = (path: string, exact = true): boolean => {
  const [isActive, setIsactive] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const match = matchPath(location.pathname, {
      path,
      exact,
      strict: true,
    });

    setIsactive(!!match);
  }, [location.pathname, exact, path]);

  return isActive;
};

/**
 * Hook used to check path match in sidebar.
 *
 * @param {string} path - Path to compare.
 *
 * @returns {boolean} Status if current path matches.
 */
export const useActiveLink = (path: string): boolean => {
  // this to match with nested routes
  const exact = path === '/';
  const isActive = useMatchPath(path, exact);

  return isActive;
};
