import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import { useHistory, Link } from 'react-router-dom';
import {
  useFetchAction,
  useSubscription,
} from '@cobuildlab/react-simple-state';
import Skeleton from '@material-ui/lab/Skeleton';
import {
  BLACK_TITLE,
  GRAY_178,
  COLOR_PRIMARY,
  COLOR_BORDER,
} from './ui/theme/contants';
import { fetchSession } from '../../modules/session/session-actions';
import { UserType } from '../types';
import {
  OnUpdateUserAvatar,
  OnUpdateUser,
} from '../../modules/user/user-events';
import { BasicGrayText } from './ui/texts/Texts';
import { AccountInfoIcon } from '../icons/icons';

const useStyles = makeStyles({
  mainContainer: {
    cursor: 'pointer',
  },
  avatarContainer: {
    margin: '0 0 0 10px',
    padding: 0,
    flexShrink: 0,
  },
  avatarItem: {
    height: '60px',
    width: '60px',
    backgroundColor: COLOR_PRIMARY,
  },
  title: {
    maxWidth: '200px',
    color: BLACK_TITLE,
    fontWeight: 600,
    fontSize: '16px',
    textOverflow: 'ellipsis',
    lineHeight: '19px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  iconContainer: {
    color: GRAY_178,
  },
  menu: {
    width: '320px',
    paddingBottom: 0,
  },
  menuItem: {
    padding: 0,
    margin: 0,
    borderBottom: `1px solid ${COLOR_BORDER}`,
  },
  itemLogout: {
    padding: 0,
    margin: 0,
    color: COLOR_PRIMARY,
  },
  link: {
    width: '100%',
    color: 'inherit',
    textDecoration: 'none',
  },
  avatar: {
    backgroundColor: COLOR_PRIMARY,
  },
});

/**
 *
 * @returns {JSX.Element} Avatar menu.
 */
export const AvatarMenu: React.FC = () => {
  const classes = useStyles();
  const [user, setUser] = useState<null | UserType>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [session, loading, { refetch }] = useFetchAction(fetchSession, []);
  const history = useHistory();
  const open = !!anchorEl;

  useEffect(() => {
    if (session) {
      setUser(session);
    }
  }, [session, user]);

  useSubscription(OnUpdateUser, (userData) => {
    if (userData) {
      refetch();
    }
  });

  useSubscription(OnUpdateUserAvatar, (userData) => {
    if (userData) {
      refetch();
    }
  });

  /**
   *  Close menu.
   */
  const handleClose = (): void => {
    setAnchorEl(null);
  };

  /**
   * @param {React.MouseEvent<HTMLElement>} e - Event.
   */
  const handleOpenMenu = (e: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(e.currentTarget);
  };

  const [firstName = '', lastName = ''] = user?.fullName?.split(' ') || [];
  const firstNameInital = firstName?.charAt(0);
  const lastNameInitial = lastName?.charAt(0);

  let content = (
    <Box display="flex" alignItems="center" paddingBottom="2px">
      <figure className={classes.avatarContainer}>
        <Avatar
          src={user?.avatar?.downloadUrl}
          alt="avatar"
          className={classes.avatar}
        >
          {firstNameInital || ''}
          {lastNameInitial || ''}
        </Avatar>
      </figure>
      <Box marginLeft="10px">
        <Typography className={classes.title}>{user?.fullName}</Typography>
        <BasicGrayText>ADMINISTRATOR</BasicGrayText>
      </Box>
      <IconButton className={classes.iconContainer}>
        <KeyboardArrowDownIcon />
      </IconButton>
    </Box>
  );

  if (loading && !user) {
    content = (
      <Box
        display="flex"
        alignItems="center"
        paddingBottom="2px"
        paddingLeft="10px"
      >
        <Skeleton variant="circle" width={40} height={40} />
        <Box marginLeft="10px" width="140px">
          <Skeleton variant="text" />
          <Skeleton variant="text" />
        </Box>
        <IconButton className={classes.iconContainer}>
          <KeyboardArrowDownIcon />
        </IconButton>
      </Box>
    );
  }

  return (
    <>
      <Box
        flexShrink={0}
        position="relative"
        onClick={handleOpenMenu}
        className={classes.mainContainer}
      >
        {content}
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        classes={{ paper: classes.menu }}
      >
        <MenuItem className={classes.menuItem}>
          <Box display="flex" alignItems="center" py="15px" ml="24px">
            <Box marginRight="13px">
              <Avatar
                className={classes.avatarItem}
                src={user?.avatar?.downloadUrl}
                alt="avatar"
              >
                {firstNameInital || ''}
                {lastNameInitial || ''}
              </Avatar>
            </Box>
            <Box>
              <Typography className={classes.title}>
                {user?.fullName}
              </Typography>
              <BasicGrayText>ADMINISTRATOR</BasicGrayText>
            </Box>
          </Box>
        </MenuItem>
        <MenuItem className={classes.menuItem}>
          <Link
            to="/my-account/information"
            className={classes.link}
            onClick={handleClose}
          >
            <Box ml="24px" height="56px" display="flex" alignItems="center">
              <Box pt="8px" mr="14px">
                <AccountInfoIcon />
              </Box>
              <Box>
                <Typography>My account</Typography>
              </Box>
            </Box>
          </Link>
        </MenuItem>
        <MenuItem
          className={` ${classes.itemLogout}`}
          onClick={() => history.push('/logout')}
        >
          <Box ml="24px" height="56px" display="flex" alignItems="center">
            <Box pt="7px" mr="14px">
              <PowerSettingsNewIcon />
            </Box>
            <Box>
              <Typography>Sign Out</Typography>
            </Box>
          </Box>
        </MenuItem>
      </Menu>
    </>
  );
};
