import { FETCH_INVOICE_TOTAL } from './invoice-queries';
import { apolloClient } from '../../shared/apollo';

/**
 * @param {string} date - Date query param.
 *
 * @returns {Promise<number>} Amount total credit.
 */
export const fetchInvoiceTotalService = async (
  date: string,
): Promise<number> => {
  const response = await apolloClient.query<{
    invoicesList: {
      groups: Array<{ amount: number }>;
    };
  }>({
    query: FETCH_INVOICE_TOTAL,
    fetchPolicy: 'network-only',
    variables: {
      date,
    },
  });

  return response.data?.invoicesList.groups[0]?.amount;
};

/**
 * @param {string[]} dates - Selected dates.
 *
 * @returns {Array<number| null>} Amount per days.
 */
export const fetchMultipleInvoiceTotal = async (
  dates: string[],
): Promise<Array<number | null>> => {
  const results = await Promise.all(
    dates.map((date) => fetchInvoiceTotalService(date)),
  );

  return results;
};
