import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles({
  card: {
    borderRadius: '8px',
    padding: '25px',
  },
});

/**
 *
 * @returns {JSX.Element} Card loading.
 */
export const DashboardCardLoading: React.FC = () => {
  const classes = useStyles();

  return (
    <>
      <Grid item xs={6}>
        <Paper elevation={24} className={classes.card}>
          <Box mt="2px">
            <Skeleton variant="text" height={20} width="100%" />
            <Box mt="5px">
              <Skeleton variant="rect" height={50} width="100%" />
            </Box>
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <Paper elevation={24} className={classes.card}>
          <Box mt="2px">
            <Skeleton variant="text" height={20} width="100%" />
            <Box mt="5px">
              <Skeleton variant="rect" height={50} width="100%" />
            </Box>
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <Paper elevation={24} className={classes.card}>
          <Box mt="2px">
            <Skeleton variant="text" height={20} width="100%" />
            <Box mt="5px">
              <Skeleton variant="rect" height={50} width="100%" />
            </Box>
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <Paper elevation={24} className={classes.card}>
          <Box mt="2px">
            <Skeleton variant="text" height={20} width="100%" />
            <Box mt="5px">
              <Skeleton variant="rect" height={50} width="100%" />
            </Box>
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <Paper elevation={24} className={classes.card}>
          <Box mt="2px">
            <Skeleton variant="text" height={20} width="100%" />
            <Box mt="5px">
              <Skeleton variant="rect" height={50} width="100%" />
            </Box>
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <Paper elevation={24} className={classes.card}>
          <Box mt="2px">
            <Skeleton variant="text" height={20} width="100%" />
            <Box mt="5px">
              <Skeleton variant="rect" height={50} width="100%" />
            </Box>
          </Box>
        </Paper>
      </Grid>
    </>
  );
};
