import { createAction } from '@cobuildlab/react-simple-state';
import { OnTravelerType, OnTravelrTypeError } from './traveler-type-events';
import { FETCH_TRAVELER_TYPES } from './traveler-type-queries';
import { apolloClient } from '../../shared/apollo';

export const fetchTravelerTypesAction = createAction(
  OnTravelerType,
  OnTravelrTypeError,
  async () => {
    const response = await apolloClient.query({
      query: FETCH_TRAVELER_TYPES,
      fetchPolicy: 'cache-first',
    });

    return response?.data?.travelerTypesList?.items;
  },
);
