import { createAction } from '@cobuildlab/react-simple-state';
import { apolloClient } from '../../shared/apollo';
import {
  OnExperienceSubCategories,
  OnExperienceSubCategoriesError,
} from './experience-subcategory-events';
import { FETCH_EXPERIENCE_SUBCATEGORIES } from './experience-subcategory-queries';

export const fetchExperienceSubCategoriesAction = createAction(
  OnExperienceSubCategories,
  OnExperienceSubCategoriesError,
  async (categoryIds, isActive = true) => {
    const response = await apolloClient.query({
      query: FETCH_EXPERIENCE_SUBCATEGORIES,
      variables: {
        categoryIds,
        isActive,
      },
    });

    return response?.data?.experienceSubCategoriesList?.items;
  },
);
