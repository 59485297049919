import { createEvent } from '@cobuildlab/react-simple-state';
import { UserType, GenderType } from '../../shared/types';

export const OnFetchUsers = createEvent<UserType[]>({
  initialValue: [],
});
export const OnErrorFetchUsers = createEvent<TypeError>();

export const OnFetchUser = createEvent<UserType>();
export const OnErrorFetchUser = createEvent<TypeError>();

export const OnUpdateUser = createEvent<UserType>();
export const OnUpdateUserError = createEvent<TypeError>();

export const OnFetchGenders = createEvent<GenderType[]>({
  initialValue: [],
});
export const OnErrorFetchGenders = createEvent<TypeError>();

export const OnArchiveUser = createEvent<UserType>();
export const OnArchiveUserError = createEvent<TypeError>();

export const OnUnarchiveUser = createEvent<UserType>();
export const OnUnarchiveUserError = createEvent<TypeError>();
