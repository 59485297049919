import { createEvent } from '@cobuildlab/react-simple-state';
import { ExperienceType, RelationType } from '../../shared/types';

export type ExperiencePopularType = {
  bookingCount: number;
  experiencesPopular: {
    items: ExperienceType[];
  };
};

export const OnExperiencePopular = createEvent<ExperiencePopularType[]>();
export const OnExperiencePopularError = createEvent();
export const OnExperienceCreate = createEvent();
export const OnExperienceCreateError = createEvent<TypeError>();
export const OnExperienceUpdate = createEvent();
export const OnExperienceUpdateError = createEvent<TypeError>();
export const OnExperienceDetail = createEvent<ExperienceType>();
export const OnExperienceDetailError = createEvent<TypeError>();
export const OnExperienceList = createEvent<RelationType<ExperienceType>>();
export const OnExperienceListError = createEvent<TypeError>();
export const OnExperienceDelete = createEvent<boolean>();
export const OnExperienceDeleteError = createEvent<TypeError>();
export const OnExperienceMultipleDelete = createEvent<boolean>();
export const OnExperienceMultipleDeleteError = createEvent<TypeError>();
export const OnExperienceMultipleDeactiveError = createEvent<TypeError>();
export const OnExperienceMultipleDeactive = createEvent<{id: string}[]>();
