import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';
import { useCallAction } from '@cobuildlab/react-simple-state';
import CircularProgress from '@material-ui/core/CircularProgress';
import { fetchItineraryReservedCountAction } from '../../itinerary/itinerary-actions';
import { BasicText } from '../../../shared/components/ui/texts/Texts';

type DashboardCardSelectedProps = {
  defaultTitle: string;
  message: string;
};

const useStyles = makeStyles({
  card: {
    borderRadius: '8px',
    padding: '25px',
  },
  containerSelector: {
    cursor: 'pointer',
  },
});

/**
 *
 * @param props - Props received.
 * @param {string} props.defaultTitle - Title of card.
 * @param {string} props.message - Message of card.
 *
 * @returns {JSX.Element} Dashboard card.
 */
export const DashboardCardSelected: React.FC<DashboardCardSelectedProps> = ({
  defaultTitle,
  message,
}) => {
  const [amountDays, setAmountDays] = useState(90);
  const [prevAmountDays, setPrevAmountDays] = useState(90);
  const [title, setTitle] = useState(defaultTitle);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const classes = useStyles();
  const open = !!anchorEl;

  const [fetchItineraryReservedCount, loading] = useCallAction(
    fetchItineraryReservedCountAction,
    {
      /**
       * Success callback action.
       *
       * @param {number} itinearyCount - Itineraries reserverd count.
       */
      onCompleted: (itinearyCount) => {
        setTitle(itinearyCount.toString());
        // this to move select to previous state on error
        setPrevAmountDays(amountDays);
      },

      /**
       *  Error Callback action.
       */
      onError: () => {
        setAmountDays(prevAmountDays);
      },
    },
  );

  /**
   * @param {HTMLElement} e - Event.
   */
  const handleShowMenu = (e: React.MouseEvent<HTMLElement>): void => {
    if (loading) return;

    setAnchorEl(e.currentTarget);
  };

  /**
   * Close menu.
   */
  const handleCloseMenu = (): void => {
    setAnchorEl(null);
  };

  /**
   * @param {number} selectedDays - Number of days to subtract and get date.
   */
  const handleSelectedItem = (selectedDays: number): void => {
    const startDate = moment()
      .subtract(selectedDays, 'd')
      .startOf('day')
      .format();
    const endDate = moment().endOf('day').format();

    fetchItineraryReservedCount(startDate, endDate);
    handleCloseMenu();
    setAmountDays(selectedDays);
  };

  let headerContent = (
    <Typography variant="h3" noWrap align="center" color="primary">
      {title}
    </Typography>
  );

  if (loading) {
    headerContent = (
      <Box display="flex" justifyContent="center" mb={1}>
        <CircularProgress size={50} />
      </Box>
    );
  }

  return (
    <>
      <Paper elevation={24} className={classes.card}>
        <Box>{headerContent}</Box>
        <Box
          mt="2px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <BasicText align="center">{message}</BasicText>
          <Box
            display="flex"
            ml={1}
            alignItems="center"
            className={classes.containerSelector}
            onClick={handleShowMenu}
          >
            <Typography color="primary" variant="subtitle2">
              {amountDays} days
            </Typography>
            <Box fontSize="18px" height="17px">
              <ExpandMoreIcon color="primary" fontSize="inherit" />
            </Box>
          </Box>
        </Box>
      </Paper>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={() => handleSelectedItem(30)}>30 days</MenuItem>
        <MenuItem onClick={() => handleSelectedItem(45)}>45 days</MenuItem>
        <MenuItem onClick={() => handleSelectedItem(60)}>60 days</MenuItem>
        <MenuItem onClick={() => handleSelectedItem(90)}>90 days</MenuItem>
        <MenuItem onClick={() => handleSelectedItem(120)}>120 days</MenuItem>
        <MenuItem onClick={() => handleSelectedItem(240)}>240 days</MenuItem>
        <MenuItem onClick={() => handleSelectedItem(365)}>365 days</MenuItem>
      </Menu>
    </>
  );
};
