import React from 'react';
import Menu from '@material-ui/core/Menu';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import { useFormContext, Controller, SubmitHandler } from 'react-hook-form';
import moment from 'moment';
import { CardTitlePage } from '../../../shared/components/ui/texts/Texts';
import {
  ButtonLink,
  ButtonLinkCancel,
  ButtonLinkDanger,
} from '../../../shared/components/ui/buttons/Buttons';
import {
  DatePicker,
  DatePickerType,
} from '../../../shared/components/ui/form/DatePicker';

export type ExperienceFilterState = {
  startDate: string;
  endDate: string;
  status: string;
};

type ExperienceListMenuFilterProps = {
  onClose: () => void;
  anchorEl: null | HTMLElement;
  onApplyFilters: (filterState: ExperienceFilterState) => void;
  filterState: ExperienceFilterState;
  onResetFilter: (filterState: ExperienceFilterState) => void;
};

const useStyles = makeStyles({
  menu: {
    width: '520px',
    borderRadius: '8px',
    outline: '0',
  },
});

export const INITIAL_FILTER_STATE = {
  startDate: '',
  endDate: '',
  status: '',
};

/**
 * @param props - Props received.
 * @param {null | HTMLElement} props.anchorEl - Anchor element.
 * @param {Function} props.onClose - Function to close filter.
 * @param {Function} props.onApplyFilters - Function to apply filters.
 * @param {ExperienceFilterState} props.filterState - Experience filter state.
 * @param {Function} props.onResetFilter - Function to reset filter.
 * @returns {JSX.Element} Menu filter.
 */
export const ExperienceListMenuFilter: React.FC<ExperienceListMenuFilterProps> =
  ({ anchorEl, onClose, onApplyFilters, onResetFilter, filterState }) => {
    const classes = useStyles();
    const open = !!anchorEl;
    const { control, setValue, handleSubmit } = useFormContext();

    /**
     * Function to apply filter.
     *
     * @param {ExperienceFilterState} data - Filter data.
     */
    const onSubmit: SubmitHandler<ExperienceFilterState> = (data): void => {
      onApplyFilters(data);
      onClose();
    };

    /**
     * @param {string} startDate - Start date filter.
     */
    const handleChangeStartDate = (
      startDate: string | DatePickerType,
    ): void => {
      const date = moment(startDate).startOf('day').format();
      setValue('startDate', moment(date));
    };

    /**
     * @param {string} endDate - End date filter.
     */
    const handleChangeEndDate = (endDate: string | DatePickerType): void => {
      const date = moment(endDate).endOf('day').format();
      setValue('endDate', date);
    };

    /**
     * Function to reset filter.
     */
    const handleResetFilter = (): void => {
      onResetFilter(INITIAL_FILTER_STATE);
      onClose();
    };

    return (
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        elevation={24}
        classes={{
          paper: classes.menu,
        }}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Container>
          <Box
            pt={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <CardTitlePage>Filter by</CardTitlePage>
            <ButtonLinkDanger type="button" onClick={handleResetFilter}>
              RESET FILTERS
            </ButtonLinkDanger>
          </Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box mt={2} pb={4}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <DatePicker
                        defaultValue={filterState.startDate || ''}
                        label="Starting Date"
                        onChange={handleChangeStartDate}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <DatePicker
                        defaultValue={filterState.endDate || ''}
                        label="End Date"
                        onChange={handleChangeEndDate}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>Status</InputLabel>
                    <Controller
                      name="status"
                      control={control}
                      defaultValue={filterState.status}
                      render={({ field }) => (
                        <Select {...field} label="Status">
                          <MenuItem value="active">Active</MenuItem>
                          <MenuItem value="inactive">Inactive</MenuItem>
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent="flex-end">
                    <Box mr={1}>
                      <ButtonLinkCancel type="button" onClick={onClose}>
                        CANCEL
                      </ButtonLinkCancel>
                    </Box>
                    <Box>
                      <ButtonLink type="submit">APPLY FILTERS</ButtonLink>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Container>
      </Menu>
    );
  };
