import React from 'react';
import Box from '@material-ui/core/Box';
import { useFetchAction } from '@cobuildlab/react-simple-state';
import { Skeleton } from '@material-ui/lab';
import { ButtonPrimaryLink } from '../../../shared/components/ui/buttons/Buttons';
import { PageTitle } from '../../../shared/components/ui/texts/Texts';
import { fetchSession } from '../../session/session-actions';

/**
 * Dashboard top info.
 *
 * @returns {JSX.Element} Dashoard header.
 */
export const DashboardHeader: React.FC = () => {
  const [session, loading] = useFetchAction(fetchSession, []);
  let title = 'Welcome back';

  if (session?.firstName) {
    title = `Welcome back, ${session?.firstName}`;
  }

  let content = <PageTitle>{title}</PageTitle>;

  if (loading) {
    content = <Skeleton width="400px" />;
  }

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      mb={3}
    >
      {content}
      <ButtonPrimaryLink to="/experiences/add-experience">
        ADD EXPERIENCE
      </ButtonPrimaryLink>
    </Box>
  );
};
