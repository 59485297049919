import { createAction } from '@cobuildlab/react-simple-state';
import {
  OnDashboard,
  OnDashboardError,
  OnDashboardRevenue,
  OnDashboardRevenueError,
} from './dashboard-events';
import { getDefaultDays } from './dashboard-utils';
import { fetchBookingCancelledCountService as fetchBookingCancelledCount } from '../booking/booking-services';
import { fetchItineraryCountByPaymentDateService as fetchItineraryPaymentByDate } from '../itinerary/itinerary-services';
import { fetchInvoiceTotalService } from '../invoice/invoice-services';
import { fetchItineraryKpiService } from '../itinerary-kpi/itinerary-kpi-services';

export const fetchDasbhoardAverageAction = createAction(
  OnDashboard,
  OnDashboardError,
  async () => {
    const { startDateTime, endDateTime, startYear, endYear } = getDefaultDays();

    const results = await Promise.all<unknown>([
      fetchItineraryKpiService(startYear, endYear),
      fetchBookingCancelledCount(startDateTime, endDateTime),
      fetchItineraryPaymentByDate(startDateTime, endDateTime),
    ]);

    return results;
  },
);

export const fetchRevenueAction = createAction(
  OnDashboardRevenue,
  OnDashboardRevenueError,
  async (dates: string[]) => {
    const results = await Promise.all(
      dates.map((date) => fetchInvoiceTotalService(date)),
    );

    return results;
  },
);
