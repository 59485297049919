import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import {
  CheckCircleOutline as CheckCircleOutlineIcon,
  Warning as WarningIcon,
  InfoOutlined as InfoOutlinedIcon,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

/**
 * @param typeAlert - Type alert.
 * @returns {string} - Main color.
 */
export const getMainColor = (typeAlert: string): string => {
  if (typeAlert === 'success') return '#00C6BD';
  if (typeAlert === 'warning') return '#FF782A';
  if (typeAlert === 'info') return '#09A7FA';
  return '#FA336A';
};

/**
 * @param typeAlert - Type alert.
 * @returns {string} - Secondary color.
 */
export const getSecondaryColor = (typeAlert: string): string => {
  if (typeAlert === 'success') return '#ECF9ED';
  if (typeAlert === 'warning') return '#FFF5EE';
  if (typeAlert === 'info') return '#F5FCFF';
  return '#FDECEA';
};

type UseStylesProps = {
  type: string;
};

const useStyles = makeStyles((theme) => ({
  root: {},
  alert: {
    maxWidth: 600,
    /**
     * @param props - Props recieved.
     *
     * @returns {string} Styles.
     */
    border: (props: UseStylesProps) => `1px solid ${getMainColor(props.type)}`,
    borderRadius: '8px',
    /**
     * @param props - Props recieved.
     *
     * @returns {string} Styles.
     */
    backgroundColor: (props: UseStylesProps) => getSecondaryColor(props.type),
    boxShadow: '0 9px 21px 0 rgba(171,171,171,0.17)',
  },
  alertBase: {
    backgroundColor: 'transparent',
  },
  icon: {
    /**
     * @param props - Props recieved.
     *
     * @returns {string} Styles.
     */
    color: (props: UseStylesProps) => getMainColor(props.type),
  },
  action: {
    alignItems: 'flex-start',
  },
  alertTitle: {
    /**
     * @param props - Props recieved.
     *
     * @returns {string} Styles.
     */
    color: (props: UseStylesProps) => getMainColor(props.type),
    fontSize: '16px',
    fontWeight: 500,
    letterSpacing: '1px',
    lineHeight: '24px',
  },
  alertDescription: {
    color: '#222222',
    fontSize: '16px',
    letterSpacing: '0.11px',
    lineHeight: '20px',
  },
  list: {
    margin: '0px',
    padding: '0px',
    '& > li:not(:last-child)': {
      marginBottom: theme.spacing(1),
    },
  },
}));

type AlertDefaulProps = {
  type: 'success' | 'info' | 'warning' | 'error';
  description: string | Array<string>;
  title?: string;
  onClose?: (event: React.SyntheticEvent<Element, Event>) => void;
};

/**
 * Default alert, with title, subtitle, and closing icon, configured to define
 * your style according to the 'type' property.
 *
 * @param props - Props of Toast.
 * @param props.type - Type of toast (info, success, warning, error).
 * @param props.title - Title of alert.
 * @param props.description - Description of alert.
 * @param props.onClose - Close funciton.
 * @returns The Toast Component.
 */
export const AlertDefault: React.FC<AlertDefaulProps> = ({
  type,
  description,
  title,
  onClose,
}) => {
  const classes = useStyles({ type });
  return (
    <Alert
      elevation={6}
      variant="outlined"
      iconMapping={{
        success: (
          <CheckCircleOutlineIcon
            classes={{ root: classes.icon }}
            fontSize="inherit"
          />
        ),
        info: (
          <InfoOutlinedIcon
            classes={{ root: classes.icon }}
            fontSize="inherit"
          />
        ),
        warning: (
          <WarningIcon classes={{ root: classes.icon }} fontSize="inherit" />
        ),
        error: (
          <InfoOutlinedIcon
            classes={{ root: classes.icon }}
            fontSize="inherit"
          />
        ),
      }}
      classes={{ root: classes.alert, action: classes.action }}
      onClose={onClose}
      severity={type}>
      <AlertTitle classes={{ root: classes.alertTitle }}>{title}</AlertTitle>
      <div className={classes.alertDescription}>{description}</div>
    </Alert>
  );
};

/**
 * Base Alert, with title, subtitle and backgroundcolor transparent.
 *
 * @param props - Props of AlertBase.
 * @returns The Alert Component.
 */
export const AlertBase: React.FC<AlertDefaulProps> = (props) => {
  const { type, description, title, onClose } = props;
  const classes = useStyles({ type });

  let message = null;

  if (Array.isArray(description)) {
    message = (
      <ul className={classes.list}>
        {description.map((msg) => (
          <li>{capitalize(msg)}</li>
        ))}
      </ul>
    );
  } else {
    message = description;
  }

  return (
    <Alert
      classes={{ root: classes.alertBase }}
      severity={type}
      onClose={onClose}>
      <AlertTitle classes={{ root: classes.alertTitle }}>{title}</AlertTitle>
      <div className={classes.alertDescription}>{message}</div>
    </Alert>
  );
};

/**
 * Capitalizes the first letter on a text string.
 *
 * @param {string} text - The text to capitalize.
 *
 * @returns {string} The capitalized text.
 */
function capitalize(text: string): string {
  return text.charAt(0).toUpperCase() + text.slice(1);
}
