import {
  useCallAction,
  useSubscription,
  useFetchAction,
} from '@cobuildlab/react-simple-state';
import { useQuery, useSubscription as apolloSubcription } from '@apollo/client';
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  styled,
  TextField,
  Typography,
} from '@material-ui/core';
import * as filestack from 'filestack-js';
import moment from 'moment';
import React, { KeyboardEvent, useEffect, useRef, useState } from 'react';
import { Send, AttachFile, PictureAsPdf } from '@material-ui/icons';
import {
  ItineraryType,
  MessageType,
  MsgSubscriptionNode,
} from '../../../shared/types';
import { OnSelectedItemId } from '../inbox-events';
import {
  createMessageAction,
  fetchChatHeader,
  fetchMessagesListAction,
  fetchNewMessages,
} from '../inbox-actions';
import { CURRENT_USER, SUBS_NEW_MSG } from '../inbox-queries';
import { MainLoader } from '../../../shared/components/MainLoader';
import { fetchFileUploadInfoAction } from '../../file-upload/file-upload-actions';
import { DisplayMessages } from './DisplayMessages';
import { Timestamp } from './Timestamp';
import { InboxChatHeader } from './InboxChatHeader';

export type FileType = {
  fileId: string;
  downloadUrl: string;
  filename: string;
};

export type DaysArray = {
  today: MessageType[];
  yesterday: MessageType[];
  older: MessageType[];
};

const useStyles = makeStyles({
  messagesContainer: {
    marginTop: '5px',
    height: '550px',
    maxHeight: '550px',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '5px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#00CC99',
      borderRadius: '10px',
    },
  },
  filesContainer: {
    marginTop: '5px',
    overflow: 'auto',
    maxHeight: '100px',
    display: 'flex',
    flexWrap: 'wrap',
    '&::-webkit-scrollbar': {
      width: '5px',
      height: '5px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#00CC99',
      borderRadius: '10px',
    },
  },
  msgInput: {
    padding: '5px 13px',
  },
  iconColor: {
    color: 'rgba(129,0,0,0.5)',
  },
  iconBorder: {
    borderColor: 'rgba(129,0,0,0.5)',
  },
  loadMsg: {
    backgroundColor: 'rgba(255,255,255,0.8)',
    margin: '0px auto',
  },
});

const ChatLoading = styled(Box)(({ theme }) => ({
  backgroundColor: 'rgba(255,255,255,0.8)',
  margin: '0px auto',
  top: '375px',
  position: 'absolute',
  width: '42%',
  height: '100%',
  maxHeight: '552px',
  zIndex: 60,
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    width: '33%',
    top: '450px',
  },
  [theme.breakpoints.down('sm')]: {
    top: '508px',
    width: '29%',
  },
}));

/**
 * @returns {JSX.Element} - Info traveler.
 */
export const InboxChat: React.FC = () => {
  const classes = useStyles();

  const { data } = useQuery(CURRENT_USER);
  const sessionUser = data;

  const [clientFileStack, setClientFileStack] = useState<filestack.Client>();
  const [path, setPath] = useState<string>('');
  const [imagesUpload, setImagesUpload] = useState<FileType[]>([]);
  const [sources, setSources] = useState<filestack.PickerFileMetadata[]>([]);
  const [chatHeader, setChatHeader] = useState<ItineraryType[]>([]);
  const [selectedItemId, setSelectedItemId] = useState<string>('');
  const [messages, setMessages] = useState<MessageType[]>([]);
  const [textValue, setTextValue] = useState<string>();
  const [msgContent, setMsgContent] = useState<MsgSubscriptionNode[]>([]);
  const [userAvatar, setUserAvatar] = useState<string>();

  useSubscription(OnSelectedItemId, (state) => {
    if (state && state !== selectedItemId) {
      setSelectedItemId(state);
      setChatHeader([]);
      setMessages([]);
    }
    setMsgContent([]);
  });

  const [fileUploadInfo, loadingUploadInfo] = useFetchAction(
    fetchFileUploadInfoAction,
    [],
  );

  const [messageCreate, sendingMsg] = useCallAction(createMessageAction, {
    /**
     *
     * @param {TypeError} e - Error e.
     */
    onError: (e) => {
      console.log('Error in message');
      console.log(e.message);
    },
  });

  const [, loadingFA, { refetch }] = useFetchAction(
    fetchMessagesListAction,
    [selectedItemId],
    {
      /**
       * @param {Event} e - Event result.
       */
      onCompleted: (e) => {
        setMessages(e?.items);
      },
    },
  );
  const [, loadingCH] = useFetchAction(fetchChatHeader, [selectedItemId], {
    /**
     * @param {Event} e - Event result.
     */
    onCompleted: (e) => {
      setChatHeader(e?.items);
    },
  });

  useFetchAction(fetchNewMessages, [selectedItemId, msgContent]);

  apolloSubcription(SUBS_NEW_MSG, {
    variables: {
      filter: {
        mutation_in: 'create',
        node: {
          inbox: {
            id: {
              equals: selectedItemId,
            },
          },
        },
      },
    },
    /**
     *
     * @param onSubs - Param.
     */
    onSubscriptionData: (onSubs) => {
      if (onSubs?.subscriptionData?.data) {
        setMsgContent((prev) => [
          ...prev,
          onSubs?.subscriptionData?.data?.Message.node.message,
        ]);
      }
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    refetch();
  }, [msgContent, messageCreate, refetch]);

  useEffect(() => {
    if (fileUploadInfo) {
      setClientFileStack(
        filestack.init(fileUploadInfo.apiKey, {
          security: {
            signature: fileUploadInfo.signature,
            policy: fileUploadInfo.policy,
          },
        }),
      );

      setPath(fileUploadInfo.path);
    }
  }, [fileUploadInfo]);

  /**
   *
   * @param files - Filestack files client.
   */
  const onUploadDone = (files: filestack.PickerResponse): void => {
    if (files.filesUploaded.length > 0) {
      if (sources.length > 0) {
        const newSourcesArr = files.filesUploaded.map((newSrc) => newSrc);
        const oldSourcesArr = [...sources];
        const conc = oldSourcesArr.concat(newSourcesArr);
        setSources(conc);
      } else {
        setSources(files.filesUploaded);
      }
    }
  };

  /**
   * - Handle uplaod files.
   */
  const handleAttachFile = (): void => {
    if (loadingUploadInfo || !clientFileStack) return;
    const options = {
      fromSources: ['local_file_system'],
      accept: ['image/*', '.pdf'],
      maxFiles: 15,
      onUploadDone,
      storeTo: { path },
    };
    clientFileStack.picker(options).open();
  };

  const { today, yesterday, older } = messages.reduce<DaysArray>(
    (prev, current) => {
      const todayUnix = moment().format('YYYY-MM-DD');
      const yesterdateUnix = moment().subtract(1, 'day').format('YYYY-MM-DD');

      if (moment(current.createdAt).format('YYYY-MM-DD') === todayUnix) {
        const newArr = [...prev.today];
        newArr.push(current);
        return { ...prev, today: newArr };
      }

      if (moment(current.createdAt).format('YYYY-MM-DD') === yesterdateUnix) {
        const newArrYest = [...prev.yesterday];
        newArrYest.push(current);
        return { ...prev, yesterday: newArrYest };
      }

      const newArr = [...prev.older];
      newArr.push(current);
      return { ...prev, older: newArr };
    },
    {
      today: [],
      yesterday: [],
      older: [],
    },
  );

  /**
   * @param {string} dateReq - Date required to format.
   * @returns {string} - Date formated.
   */
  const formatDate = (dateReq: string): string => {
    if (messages && dateReq === 'createdAt') {
      const getDate = messages?.slice(-1)[0]?.createdAt;
      const now = moment().format();
      const daysDiff = moment(now).diff(getDate);
      const duration = moment.duration(daysDiff, 'milliseconds');
      const totalDays = Math.floor(duration.asDays());

      const mSecDuration = moment.duration(daysDiff);
      const totalHours = Math.floor(mSecDuration.asHours());
      const totalMinutes = Math.floor(mSecDuration.asMinutes());

      const lessThanMonth = moment(messages?.slice(-1)[0]?.createdAt).format(
        'MMM DD, h:mm a',
      );
      const moreThanMonth = moment(messages?.slice(-1)[0]?.createdAt).format(
        'MMM DD',
      );

      const lessThanHour = totalHours < 1;
      const lessThanDay = totalHours < 24;
      const moreThanDay = totalHours >= 24 && !lessThanDay;

      if (lessThanHour && totalMinutes < 1) {
        return 'A MOMENT AGO';
      }

      if (lessThanHour && totalMinutes < 60) {
        return `${totalMinutes} MINUTES AGO`;
      }

      if (lessThanDay) {
        return `${totalHours} HOURS AGO`;
      }

      if (moreThanDay) {
        return lessThanMonth;
      }

      if (totalDays > 31 || moreThanDay) {
        return moreThanMonth;
      }
    }

    if (chatHeader && dateReq === 'startDateTime') {
      const formatStartDateTime = moment
        .tz(chatHeader[0]?.startDateTime, 'UTC')
        .format('MMM DD, YYYY');

      const formatEndDateTime = moment
        .tz(chatHeader[0]?.endDateTime, 'UTC')
        .format('MMM DD, YYYY');

      return `${formatStartDateTime} - ${formatEndDateTime}` || 'No date';
    }
    return 'a';
  };

  const mapDay = older.map((filt: MessageType) =>
    moment(filt.createdAt).format('MMM DD, YYYY'),
  );
  const previousDays = mapDay.reduce<string[]>(
    (prev, current) => (prev.includes(current) ? prev : [...prev, current]),
    [],
  );

  const scrollRef = useRef<null | HTMLDivElement>(null);
  /**
   *
   * @returns {void} - Executes scroll bottom.
   */
  const scrollDemo = (): void => scrollRef.current?.scrollIntoView();

  let filesReady = <Box>{null}</Box>;

  if (sources) {
    filesReady = (
      <>
        <Box>
          <Grid container direction="row">
            <Grid item md={12}>
              <Grid
                container
                direction="row"
                className={classes.filesContainer}
              >
                {sources.map((urls) => {
                  const { policy, signature } = fileUploadInfo;
                  const { filename, handle, url } = urls;
                  const readUrl = `${url}?policy=${policy}&signature=${signature}`;
                  const dataFile = {
                    fileId: handle,
                    filename,
                    downloadUrl: readUrl,
                  };
                  const compare = imagesUpload.some(
                    (img) => img?.fileId === dataFile?.fileId,
                  );

                  if (imagesUpload.length === 0 && !compare) {
                    setImagesUpload([dataFile]);
                  }
                  if (imagesUpload.length > 0 && compare === false) {
                    const copy = [...imagesUpload];
                    copy.push(dataFile);
                    setImagesUpload(copy);
                  }

                  return (
                    <Grid item style={{ marginLeft: '5px' }}>
                      {urls?.filename?.includes('pdf') ? (
                        <Box
                          border={2}
                          paddingY={2}
                          paddingX={1}
                          className={classes.iconBorder}
                        >
                          <Grid
                            container
                            direction="row"
                            justifyContent="center"
                          >
                            <Grid item md={6}>
                              <PictureAsPdf
                                fontSize="large"
                                className={classes.iconColor}
                                style={{ color: 'rgba(129,0,0,0.5)' }}
                              />
                            </Grid>
                            <Grid item md={8}>
                              <Typography variant="caption" noWrap>
                                {urls?.filename.substring(0, 8)}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      ) : (
                        <img
                          src={readUrl}
                          alt="filesImg"
                          width="100px"
                          height="100px"
                        />
                      )}
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </>
    );
  }

  /**
   *
   * @param {Event} e - Enter Key Event.
   */
  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (
        (selectedItemId && textValue !== undefined && textValue !== '') ||
        (selectedItemId && imagesUpload.length > 0)
      ) {
        messageCreate(selectedItemId, imagesUpload, textValue);
        refetch();
        setTextValue('');
        setSources([]);
        setImagesUpload([]);
      }
    }
  };

  /**
   * - Send message.
   */
  const handleSendMessage = (): void => {
    if (
      (selectedItemId && textValue !== undefined && textValue !== '') ||
      (selectedItemId && imagesUpload.length > 0)
    ) {
      messageCreate(selectedItemId, imagesUpload, textValue);
      setTextValue('');
      setSources([]);
      setImagesUpload([]);
    }
  };

  if (chatHeader && userAvatar === null) {
    setUserAvatar(chatHeader[0]?.user?.avatar?.downloadUrl);
  }

  return (loadingFA && loadingCH) || messages.length === 0 ? (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="flex-end"
    >
      <Box height="300px" marginTop="40%">
        <MainLoader />
      </Box>
    </Grid>
  ) : (
    <>
      <Grid container direction="column" alignItems="stretch">
        <Grid item md={12}>
          <InboxChatHeader
            chatHeader={chatHeader}
            messages={messages}
            formatDate={formatDate}
          />
        </Grid>

        <Box className={classes.messagesContainer}>
          {sendingMsg || (sendingMsg && loadingFA) ? (
            <Grid container direction="row" justifyContent="center">
              <ChatLoading>
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box marginTop="40%">
                    <MainLoader />
                  </Box>
                </Grid>
              </ChatLoading>
            </Grid>
          ) : null}
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <Grid item xs={12} md={12}>
              {previousDays.map((d: string) => {
                const mapOlderDays = older.filter(
                  (old: MessageType) =>
                    moment(old.createdAt).format('MMM DD, YYYY') ===
                    moment(d).format('MMM DD, YYYY'),
                );

                return (
                  <div key={`ts-${d}`}>
                    {mapOlderDays.length > 0 ? (
                      <Timestamp text={moment(d).format('MMM DD, YYYY')} />
                    ) : null}
                    {mapOlderDays.map((mss: MessageType) => (
                      <div key={`older-${mss?.id}`}>
                        <DisplayMessages
                          data={mss}
                          sessionUser={sessionUser}
                          dataHeader={userAvatar}
                          scroll={scrollRef}
                          scrollDemo={scrollDemo}
                        />
                      </div>
                    ))}
                  </div>
                );
              })}

              {yesterday.length > 0 ? <Timestamp text="Yesterday" /> : null}
              {yesterday?.map((messageItemYest: MessageType) => (
                <div key={`yesterday-${messageItemYest?.id}`}>
                  <DisplayMessages
                    data={messageItemYest}
                    sessionUser={sessionUser}
                    dataHeader={userAvatar}
                    scroll={scrollRef}
                    scrollDemo={scrollDemo}
                  />
                </div>
              ))}

              {today.length > 0 ? <Timestamp text="Today" /> : null}
              {today?.map((messageItemToday: MessageType) => (
                <div key={`today-${messageItemToday?.id}`}>
                  <DisplayMessages
                    data={messageItemToday}
                    sessionUser={sessionUser}
                    dataHeader={userAvatar}
                    scroll={scrollRef}
                    scrollDemo={scrollDemo}
                  />
                </div>
              ))}
            </Grid>
          </Grid>
        </Box>
      </Grid>

      <Grid container direction="column" alignItems="stretch">
        {sources.length > 0 ? (
          <Box
            width="100%"
            position="relative"
            marginTop="5px"
            boxShadow={3}
            zIndex={30}
          >
            {filesReady}
          </Box>
        ) : null}

        <FormControl
          variant="outlined"
          fullWidth
          style={{
            boxShadow: '0px 0px 15px #e0e0e0',
            margin: '5px 0px 30px 0px',
          }}
        >
          <TextField
            value={textValue}
            placeholder="Type here"
            multiline
            className={classes.msgInput}
            onChange={(e) => setTextValue(e.target.value)}
            onKeyDown={handleKeyDown}
            minRows={1}
            maxRows={5}
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => handleAttachFile()}>
                    <AttachFile />
                  </IconButton>
                  <IconButton onClick={() => handleSendMessage()} edge="end">
                    <Send />
                  </IconButton>
                </InputAdornment>
              ),
              classes: { input: classes.msgInput },
            }}
          />
        </FormControl>
      </Grid>
    </>
  );
};
