import React, { useCallback, useEffect, useState } from 'react';
import moment, { Moment } from 'moment-timezone';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

export declare type DatePickerType = Moment | null | Date;

export declare type ParsableDate = string | number | Date | null | undefined;

export type DatePickerProps = {
  onChange?: (value: DatePickerType | string) => void;
  format?: string;
  value?: ParsableDate;
  error?: boolean;
  label?: string;
  placeholder?: string;
  defaultValue?: string;
  isDisabled?: boolean;
  disableFuture?: boolean;
};

export type DatePickerTimezoneProps = {
  onChange?: (value: DatePickerType | string) => void;
  format?: string;
  value?: ParsableDate;
  error?: boolean;
  label?: string;
  placeholder?: string;
  defaultValue?: string;
  isDisabled?: boolean;
  disableFuture?: boolean;
  timezone?: string;
};

// WARNING: not change the defult style input
/**
 * @param props - Props received.
 * @param {Function} props.onChange - Function to change date.
 * @param {string} props.format - Date format.
 * @param {boolean} props.error - Status error.
 * @param {string} props.label - Label.
 * @param {string} props.placeholder - Placeholder.
 * @param {boolean} props.isDisabled - Input is disabled.
 * @param {string} props.defaultValue - Defalult value.
 * @param {boolean} props.disableFuture - Disable future date.
 *
 * @returns {JSX.Element} Date picker.
 */
export const DatePicker: React.FC<DatePickerProps> = ({
  onChange,
  format = 'MM/DD/YYYY',
  error,
  label,
  placeholder,
  defaultValue = null,
  isDisabled = false,
  disableFuture = false,
}) => {
  const [state, setState] = useState<DatePickerType>(null);

  useEffect(() => {
    if (defaultValue) {
      setState((prevState) =>
        prevState === null ? moment(defaultValue) : prevState,
      );
    }
  }, [defaultValue]);

  const onChangeValue = useCallback(
    (date: DatePickerType) => {
      setState(date);
      if (date) {
        onChange?.(moment(date).format());
      }
    },
    [onChange],
  );

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDatePicker
        fullWidth
        label={label}
        placeholder={placeholder}
        helperText={error && 'date is a required field'}
        error={error}
        autoOk
        variant="inline"
        inputVariant="outlined"
        margin="normal"
        format={format}
        value={state}
        InputAdornmentProps={{ position: 'end' }}
        onChange={onChangeValue}
        disabled={isDisabled}
        disableFuture={disableFuture}
      />
    </MuiPickersUtilsProvider>
  );
};

/**
 * @param props - Props received.
 * @param {Function} props.onChange - Function to change date.
 * @param {string} props.format - Date format.
 * @param {boolean} props.error - Status error.
 * @param {string} props.label - Label.
 * @param {string} props.placeholder - Placeholder.
 * @param {boolean} props.isDisabled - Input is disabled.
 * @param {string} props.defaultValue - Defalult value.
 * @param {boolean} props.disableFuture - Disable future date.
 * @param {string} props.timezone - Timezone of date.
 *
 * @returns {JSX.Element} Date picker.
 */
export const DatePickerTimezone: React.FC<DatePickerTimezoneProps> = ({
  onChange,
  format = 'MM/DD/YYYY',
  error,
  label,
  placeholder,
  defaultValue = null,
  isDisabled = false,
  disableFuture = false,
  timezone = 'UTC',
}) => {
  const [state, setState] = useState<DatePickerType>(null);

  useEffect(() => {
    if (defaultValue) {
      setState((prevState) =>
        prevState === null ? moment.tz(defaultValue, timezone) : prevState,
      );
    }
  }, [defaultValue, timezone]);

  const onChangeValue = useCallback(
    (date: DatePickerType) => {
      setState(date);
      if (date) {
        onChange?.(moment(date).format());
      }
    },
    [onChange],
  );

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDatePicker
        fullWidth
        label={label}
        placeholder={placeholder}
        helperText={error && 'date is a required field'}
        error={error}
        autoOk
        variant="inline"
        inputVariant="outlined"
        margin="normal"
        format={format}
        value={state}
        InputAdornmentProps={{ position: 'end' }}
        onChange={onChangeValue}
        disabled={isDisabled}
        disableFuture={disableFuture}
      />
    </MuiPickersUtilsProvider>
  );
};
