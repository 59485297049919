/** COLORS. */
export const COLOR_PRIMARY = '#00CC99';
export const COLOR_SECONDARY = '#FA336A';
export const COLOR_VIOLET = '#E10C62';
export const COLOR_BORDER = '#E7E7E7';
export const BG_MENU = '#F8F8F8';
export const GRAY_178 = 'rgb(178, 178, 178)';
export const GRAY_176 = '#9B9B9B';
export const GRAY_69 = 'rgb(69, 69, 69)';
export const GRAY_134 = 'rgb(134, 134, 134)';
export const GRAY_250 = 'rgb(250, 250, 250)';
export const GRAY_214 = 'rgb(214, 214, 214)';
export const COLOR_THEAD = 'rgba(0, 0, 0, 0.87)';
export const COLOR_INACTIVE = 'rgb(235, 67, 54)';
export const COLOR_DANGER = '#f44336';
export const GRAY_DARK = '#313131';
export const BLACK_TITLE = 'rgba(0, 0, 0, 0.87)';
export const COLOR_BREAD_CRUMB = '#969696';
export const COLOR_INFO_TYPES = '#787878';
