import gql from 'graphql-tag';

export const FETCH_EXPERIENCES_POPULAR = gql`
  query FetchPopularExperience(
    $startDateTime: DateTime!
    $endDateTime: DateTime!
  ) {
    experiencesList(
      filter: {
        AND: [
          {
            experienceBookingRelation: {
              some: {
                approvedDate: { gte: $startDateTime }
                bookingStatus: {
                  OR: [
                    { statusName: { equals: "PAID" } }
                    { statusName: { equals: "COMPLETED" } }
                    { statusName: { equals: "CONFIRMED" } }
                  ]
                }
              }
            }
          }
          {
            experienceBookingRelation: {
              some: {
                approvedDate: { lte: $endDateTime }
                bookingStatus: {
                  OR: [
                    { statusName: { equals: "PAID" } }
                    { statusName: { equals: "COMPLETED" } }
                    { statusName: { equals: "CONFIRMED" } }
                  ]
                }
              }
            }
          }
        ]
      }
      groupBy: {
        query: {
          experienceName: { as: "name" }
          experienceBookingRelation: {
            id: { as: "bookingCount", fn: { aggregate: COUNT } }
          }
          _group: { as: "experiencesPopular" }
        }
        sort: { alias: "bookingCount", direction: DESC }
        first: 5
      }
    ) {
      groups {
        bookingCount: Int
        experiencesPopular: ExperienceGroup {
          items {
            id
            experienceName
            city {
              cityName
            }
            experienceBookingRelation(
              filter: {
                AND: [
                  { approvedDate: { gte: $startDateTime } }
                  { approvedDate: { lte: $endDateTime } }
                  {
                    bookingStatus: {
                      OR: [
                        { statusName: { equals: "PAID" } }
                        { statusName: { equals: "COMPLETED" } }
                        { statusName: { equals: "CONFIRMED" } }
                      ]
                    }
                  }
                ]
              }
            ) {
              count
            }
          }
        }
      }
    }
  }
`;

export const CREATE_EXPERIENCE = gql`
  mutation CreateExperience($data: ExperienceCreateInput!) {
    experienceCreate(data: $data) {
      id
    }
  }
`;

export const CREATE_EXPERIENCE_PRICINGS = gql`
  mutation CreateExperiencePricing($data: [ExperiencePricingCreateManyInput]!) {
    experiencePricingCreateMany(data: $data) {
      items {
        id
      }
    }
  }
`;

export const UPDATE_EXPERIENCE = gql`
  mutation UpdateExperience($data: ExperienceUpdateInput!) {
    experienceUpdate(data: $data, force: true) {
      id
    }
  }
`;

export const FETCH_EXPERIENCE = gql`
  query FetchExperience($id: ID!) {
    experience(id: $id) {
      id
      experienceName
      timezone
      experienceExperienceCategoryRelation {
        items {
          id
          experienceCategory {
            id
            experienceCategoryName
          }
        }
      }
      experienceExperienceSubCategoryRelation {
        items {
          id
          experienceSubCategory {
            id
            experienceSubCategoryName
          }
        }
      }
      operatorsName
      address
      city {
        id
        cityName
      }
      state {
        id
        stateName
      }
      location {
        coordinates
      }
      zipcode
      websiteLink
      contactEmailAddress
      phoneNumber
      experienceDescription
      requireReservation
      experienceExperienceScheduleRelation(
        filter: { isActive: { equals: true } }
      ) {
        items {
          id
          startHour
          endHour
          day
          isActive
        }
      }
      experienceExperiencePricingRelation(
        filter: { isActive: { equals: true } }
      ) {
        items {
          id
          startTime
          endTime
          pricingName
          feeAmount
          isActive
          experiencePricingBookingPricingRelation {
            count
          }
          travelerType {
            id
            travelerTypeName
          }
        }
      }
      experienceAdditionalInformation
      experienceExperienceMediaRelation {
        items {
          id
          file {
            id
            filename
            downloadUrl
            meta
          }
          isActive
        }
      }
      experienceExperienceWaiverRelation {
        items {
          id
          file {
            id
            filename
            downloadUrl
            meta
          }
          isActive
        }
      }
      experienceExperienceKeywordRelation {
        items {
          id
          text
        }
      }
      file {
        id
        downloadUrl
      }
      isActive
    }
  }
`;

export const FETCH_EXPERIENCES_LIST = gql`
  query FetchExperiencesList(
    $skip: Int
    $first: Int
    $filter: ExperienceFilter!
    $sort: [ExperienceSort!]
  ) {
    experiencesList(
      filter: $filter
      skip: $skip
      first: $first
      sort: $sort
      orderBy: createdAt_DESC
    ) {
      count
      items {
        id
        experienceName
        isActive
        address
        operatorsName
        experienceExperiencePricingRelation {
          items {
            travelerType {
                travelerTypeName
            }
            id
            pricingName
            feeAmount
          }
        }
      }
    }
  }
`;

export const DELETE_EXPERIENCE = gql`
  mutation DeleteExperience($id: ID!) {
    experienceDelete(data: { id: $id }, force: true) {
      success
    }
  }
`;

export const DELETE_MULTIPLES_EXPERIENCES = gql`
  mutation DeleteMultiplesExperiences($ids: [ID!]) {
    experienceDeleteByFilter(filter: { id: { in: $ids } }, force: true) {
      success
    }
  }
`;

export const DEACTIVE_MULTIPLES_EXPERIENCES = gql`
  mutation DeactiveMultipleExperiences($experienceIds: [ID!]) {
    experienceUpdateByFilter(
      filter: { id: { in: $experienceIds } }
      data: { isActive: { set: false } }
    ) {
      items {
        id
        isActive
      }
    }
  }
`;
