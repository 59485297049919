import React from 'react';
import Box from '@material-ui/core/Box';
import { PageTitle } from '../../../shared/components/ui/texts/Texts';

/**
 *
 * @returns {JSX.Element} Inbox header.
 */
export const InboxHeader: React.FC = () => (
  <Box>
    <PageTitle>Inbox</PageTitle>
  </Box>
);
