import { ReactComponent as FilterGrey } from './filter-grey.svg';
import { ReactComponent as Filter } from './filter.svg';
import { ReactComponent as Upload } from './upload.svg';
import { ReactComponent as DeleteRed } from './delete-red.svg';
import { ReactComponent as AddImage } from './add-image.svg';
import { ReactComponent as AccountInfoGreen } from './account-info-green.svg';
import { ReactComponent as AccountInfo } from './account-info-grey.svg';
import { ReactComponent as Bell } from './notifications-grey.svg';
import { ReactComponent as BellGreen } from './notifications-green.svg';
import { ReactComponent as PadLock } from './sign-in-grey.svg';
import { ReactComponent as PadLockGreen } from './sign-in-green.svg';
import { ReactComponent as MenuPusher } from './menu-pusher.svg';
import { ReactComponent as Reject } from './reject-grey.svg';
import { ReactComponent as DetailGrey } from './details-grey.svg';
import { ReactComponent as Search } from './search.svg';
import { ReactComponent as ApproveWhite } from './approve-white.svg';
import { ReactComponent as ApproveGrey } from './approve-grey.svg';

export const FilterGreyIcon = FilterGrey;
export const FilterActiveIcon = Filter;
export const UploadIcon = Upload;
export const DeleteRedIcon = DeleteRed;
export const AddImageIcon = AddImage;
export const AccountInfoGreenIcon = AccountInfoGreen;
export const AccountInfoIcon = AccountInfo;
export const BellIcon = Bell;
export const BellGreenIcon = BellGreen;
export const PadLockIcon = PadLock;
export const PadLockGreenIcon = PadLockGreen;
export const MenuPusherIcon = MenuPusher;
export const RejectIcon = Reject;
export const DetailGrayIcon = DetailGrey;
export const SearchIcon = Search;
export const ApproveWhiteIcon = ApproveWhite;
export const ApproveGreyIcon = ApproveGrey;
