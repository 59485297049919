import { createEvent } from '@cobuildlab/react-simple-state';
import { ValidationError } from '@cobuildlab/validation-utils';
import { NotificationType } from '../../shared/types';

export const OnFetchNotifications = createEvent<NotificationType[]>({
  initialValue: [],
});
export const OnErrorFetchNotifications = createEvent<ValidationError>({});
export const OnMarkAllAsRead = createEvent<{ count: number }>();
export const OnErrorMarkAllAsRead = createEvent<ValidationError>({});
export const OnUpdateNotification = createEvent<NotificationType>();
export const OnErrorUpdateNotification = createEvent<ValidationError>({});
