import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import React, { useContext, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ExperienceFormContext } from './ExperienceFormProvider';
import { TIME_ZONES } from '../../../../shared/constants';

/**
 * @returns {JSX.Element} Select time zone.
 */
export const ExperienceTimeZoneSelect: React.FC = () => {
  const { control } = useFormContext();
  const { experience } = useContext(ExperienceFormContext);

  const timeZones = useMemo(
    () =>
      TIME_ZONES.map((timezone) => (
        <MenuItem key={timezone.id} value={timezone.timezone}>
          {timezone.gmtFormat}
        </MenuItem>
      )),
    []
  );

  return (
    <Controller
      name='timezone'
      control={control}
      defaultValue={experience?.timezone || ''}
      render={({ field }) => (
        <Select {...field} label='Time Zone'>
          {timeZones}
        </Select>
      )}
    />
  );
};
