import { createEvent } from '@cobuildlab/react-simple-state';
import { BookingType } from '../../shared/types';

export const OnFetchBookings = createEvent<BookingType[]>({
  initialValue: [],
});
export const OnErrorFetchBookings = createEvent<TypeError>();
export const OnBookingConfirm = createEvent();
export const OnBookingConfirmError = createEvent<TypeError>();
export const OnBookingReject = createEvent();
export const OnBookingRejectError = createEvent<TypeError>();
export const OnFetchBooking = createEvent<BookingType[]>();
export const OnErrorFetchBooking = createEvent<TypeError>();
