import React, { useState } from 'react';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import { GRAY_69, GRAY_178 } from '../../theme/contants';

type TableActionProps = {
  renderAction: (
    anchorEL: null | HTMLElement,
    handleCloseMenu: () => void,
  ) => void;
};

type StylesProps = {
  isActive: boolean;
};

const useStyles = makeStyles({
  iconContainer: {
    /**
     * @param props - Props received.
     * @param {boolean} props.isActive  - Check if active.
     * @returns {string} - Color status.
     */
    color: (props: StylesProps) => (props.isActive ? GRAY_69 : GRAY_178),
    fontSize: '25px',
  },
});

/**
 * @param props - Props received.
 * @param {Function} props.renderAction - Function to render menu.
 *
 * @returns {JSX.Element} Table row menu.
 */
export const TableAction: React.FC<TableActionProps> = ({ renderAction }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isActive, setIsActive] = useState(false);
  const classes = useStyles({ isActive });

  /**
   * Function to show menu.
   *
   * @param {HTMLElement} e - Event.
   */
  const handleShowMenu = (e: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(e.currentTarget);
    setIsActive(true);
  };

  /**
   * Close menu.
   */
  const handleCloseMenu = (): void => {
    setAnchorEl(null);
    setIsActive(false);
  };

  return (
    <>
      <IconButton onClick={handleShowMenu} className={classes.iconContainer}>
        <MoreVertIcon fontSize="inherit" />
      </IconButton>
      {renderAction(anchorEl, handleCloseMenu)}
    </>
  );
};
