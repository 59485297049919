import React from 'react';
import Box from '@material-ui/core/Box';
import { PageTitle } from '../../../shared/components/ui/texts/Texts';

/**
 * @returns {JSX.Element} Page title.
 */
export const AccountHeader: React.FC = () => (
  <Box mb={3}>
    <PageTitle>My Account</PageTitle>
  </Box>
);
