import gql from 'graphql-tag';

export const FETCH_FILE_UPLOAD_INFO = gql`
  query FetchFileUploadInfo {
    fileUploadInfo {
      policy
      apiKey
      path
      signature
    }
  }
`;
