import { apolloClient } from '../../shared/apollo';
import {
  FETCH_ITINERARY_COUNT,
  FETCH_ITINERARY_ADULTS_SIZE,
  FETCH_ITINERARY_INFANTS_SIZE,
  FETCH_ITINERARY_CHILDREN_SIZE,
  FETCH_ITINERARY_CREDIT_AMOUNT,
  FETCH_ITINERARY_DEBIT_AMOUNT,
  FETCH_PAID_ITINERARIES_COUNT,
} from './itinerary-queries';

/**
 * @param {string} startDateTime  - Start date itinerary.
 * @param {string} endDateTime - End date itinerary.
 * @param {string} paymentStatus - Payment status.
 * @param {string|undefined} bookingStatus - Booking status.
 * @returns {Record} - Filter of itinerary paid count.
 */
const createFilterItineraryPaid = (
  startDateTime: string,
  endDateTime: string,
  paymentStatus: string,
  bookingStatus?: string,
): Record<string, unknown> => {
  let filterData: Record<string, unknown> = {
    startDateTime: {
      gte: startDateTime,
    },
    endDateTime: {
      lte: endDateTime,
    },
  };

  if (bookingStatus) {
    filterData = {
      ...filterData,
      itineraryBookingRelation: {
        some: {
          bookingStatus: {
            statusName: {
              equals: bookingStatus,
            },
          },
        },
      },
    };
  }

  const filter = {
    OR: [
      {
        ...filterData,
        itineraryDebitRelation: {
          some: {
            status: {
              equals: paymentStatus,
            },
          },
        },
      },
      {
        ...filterData,
        itineraryCreditRelation: {
          some: {
            status: {
              equals: paymentStatus,
            },
          },
        },
      },
    ],
  };

  return filter;
};

/**
 * @param {string} startDateTime  - Start date itinerary.
 * @param {string} endDateTime - End date itinerary.
 * @param {string} status - Booking status.
 *
 * @returns {Record} - Filter of itinerary paid count.
 */
export const createFilterItineraryFree = (
  startDateTime: string,
  endDateTime: string,
  status: string,
): Record<string, unknown> => {
  const filter = {
    startDateTime: {
      gte: startDateTime,
    },
    endDateTime: {
      lte: endDateTime,
    },
    itineraryDebitRelation: {
      every: {
        id: {
          is_empty: true,
        },
      },
    },
    itineraryCreditRelation: {
      every: {
        id: {
          is_empty: true,
        },
      },
    },
    itineraryBookingRelation: {
      some: {
        bookingStatus: {
          statusName: {
            equals: status,
          },
        },
      },
    },
  };

  return filter;
};

/**
 * Paid itineraries with at least one confirmed experience.
 *
 * @param {string} startDateTime  - Start date itinerary.
 * @param {string} endDateTime - End date itinerary.
 * @param {string} paymentStatus - Payment status.
 * @param {string} bookingStatus - Booking status.
 *
 * @returns {Promise<number>} - Number of paid itineraries.
 */
export const fetchItineraryPaidWithDaysCountService = async (
  startDateTime: string,
  endDateTime: string,
  paymentStatus: string,
  bookingStatus: string,
): Promise<number> => {
  const filter = createFilterItineraryPaid(
    startDateTime,
    endDateTime,
    paymentStatus,
    bookingStatus,
  );

  const response = await apolloClient.query<{
    itinerariesList: { count: number };
  }>({
    query: FETCH_ITINERARY_COUNT,
    fetchPolicy: 'network-only',
    variables: {
      filter,
    },
  });

  return response.data?.itinerariesList?.count;
};

/**
 * Itinerary payments completed
 * you can have all your experiences canceled.
 *
 * @param {string} startDateTime  - Start date itinerary.
 * @param {string} endDateTime - End date itinerary.
 * @param {string} paymentStatus - Payment status.
 *
 * @returns {Promise<number>} - Number of paid itineraries.
 */
export const fetchItineraryPaidCompletedCountService = async (
  startDateTime: string,
  endDateTime: string,
  paymentStatus: string,
): Promise<number> => {
  const filter = createFilterItineraryPaid(
    startDateTime,
    endDateTime,
    paymentStatus,
  );

  const response = await apolloClient.query<{
    itinerariesList: { count: number };
  }>({
    query: FETCH_ITINERARY_COUNT,
    fetchPolicy: 'network-only',
    variables: {
      filter,
    },
  });

  return response.data?.itinerariesList?.count;
};

/**
 *
 * Itineraries by your payment date.
 *
 * @param {string} startDateTime  - Payment Start date of itinerary.
 * @param {string} endDateTime - Payment End date of itinerary.
 *
 * @returns {Promise<number>} - Number of paid itineraries.
 */
export const fetchItineraryCountByPaymentDateService = async (
  startDateTime: string,
  endDateTime: string,
): Promise<number> => {
  const response = await apolloClient.query<{
    itinerariesList: { count: number };
  }>({
    query: FETCH_PAID_ITINERARIES_COUNT,
    fetchPolicy: 'network-only',
    variables: {
      startDateTime,
      endDateTime,
    },
  });

  return response.data?.itinerariesList?.count;
};

/**
 * @param {string} startDateTime  - Start date itinerary.
 * @param {string} endDateTime - End date itinerary.
 * @param {string} status - Payment status.
 *
 * @returns {Promise<number>} - Number of free itineraries.
 */
export const fetchItineraryFreeCountService = async (
  startDateTime: string,
  endDateTime: string,
  status: string,
): Promise<number> => {
  const filter = createFilterItineraryFree(startDateTime, endDateTime, status);

  const response = await apolloClient.query<{
    itinerariesList: { count: number };
  }>({
    query: FETCH_ITINERARY_COUNT,
    fetchPolicy: 'network-only',
    variables: {
      filter,
    },
  });

  return response.data?.itinerariesList?.count;
};

/**
 * @param {string} startDateTime  - Start date itinerary.
 * @param {string} endDateTime - End date itinerary.
 * @param {string} status - Payment status.
 *
 * @returns {Promise<number>} - Credit amount of payments.
 */
export const fetchCreditAmountService = async (
  startDateTime: string,
  endDateTime: string,
  status: string,
): Promise<number> => {
  const filter = {
    startDateTime: {
      gte: startDateTime,
    },
    endDateTime: {
      lte: endDateTime,
    },
    itineraryCreditRelation: {
      some: {
        status: {
          equals: status,
        },
      },
    },
  };

  const response = await apolloClient.query<{
    itinerariesList: { groups: Array<{ creditAmount: number }> };
  }>({
    query: FETCH_ITINERARY_CREDIT_AMOUNT,
    fetchPolicy: 'network-only',
    variables: {
      filter,
    },
  });

  return response.data?.itinerariesList?.groups[0].creditAmount;
};

/**
 * @param {string} startDateTime  - Start date itinerary.
 * @param {string} endDateTime - End date itinerary.
 * @param {string} status - Payment status.
 *
 * @returns {Promise<number>} - Debit amount of payments.
 */
export const fetchDebitAmountService = async (
  startDateTime: string,
  endDateTime: string,
  status: string,
): Promise<number> => {
  const filter = {
    startDateTime: {
      gte: startDateTime,
    },
    endDateTime: {
      lte: endDateTime,
    },
    itineraryDebitRelation: {
      some: {
        status: {
          equals: status,
        },
      },
    },
  };

  const response = await apolloClient.query<{
    itinerariesList: { groups: Array<{ debitAmount: number }> };
  }>({
    query: FETCH_ITINERARY_DEBIT_AMOUNT,
    fetchPolicy: 'network-only',
    variables: {
      filter,
    },
  });

  return response.data?.itinerariesList?.groups[0].debitAmount;
};

/**
 * @param {string} startDateTime  - Start date itinerary.
 * @param {string} endDateTime - End date itinerary.
 *
 * @returns {Promise<number>} - Adults count.
 */
export const fetchAdultsCountService = async (
  startDateTime: string,
  endDateTime: string,
): Promise<number> => {
  const response = await apolloClient.query<{
    itinerariesList: {
      groups: Array<{ adultsCount: number }>;
    };
  }>({
    query: FETCH_ITINERARY_ADULTS_SIZE,
    fetchPolicy: 'network-only',
    variables: {
      startDateTime,
      endDateTime,
    },
  });

  return response.data?.itinerariesList?.groups[0]?.adultsCount;
};

/**
 * @param {string} startDateTime  - Start date itinerary.
 * @param {string} endDateTime - End date itinerary.
 *
 * @returns {Promise<number>} - Infants count.
 */
export const fetchInfantsCountService = async (
  startDateTime: string,
  endDateTime: string,
): Promise<number> => {
  const response = await apolloClient.query<{
    itinerariesList: {
      groups: Array<{ infantsCount: number }>;
    };
  }>({
    query: FETCH_ITINERARY_INFANTS_SIZE,
    fetchPolicy: 'network-only',
    variables: {
      startDateTime,
      endDateTime,
    },
  });

  return response.data?.itinerariesList?.groups[0]?.infantsCount;
};

/**
 * @param {string} startDateTime  - Start date itinerary.
 * @param {string} endDateTime - End date itinerary.
 *
 * @returns {Promise<number>} - Children count.
 */
export const fetchChildrenCountService = async (
  startDateTime: string,
  endDateTime: string,
): Promise<number> => {
  const response = await apolloClient.query<{
    itinerariesList: {
      groups: Array<{ childrensCount: number }>;
    };
  }>({
    query: FETCH_ITINERARY_CHILDREN_SIZE,
    fetchPolicy: 'network-only',
    variables: {
      startDateTime,
      endDateTime,
    },
  });

  return response.data?.itinerariesList?.groups[0]?.childrensCount;
};
