export const WORKSPACE_ID = process.env.REACT_APP_WORKSPACE_ID;
export const ENVIRONMENT_NAME = process.env.REACT_APP_ENVIRONMENT_NAME || '';
export const WORKSPACE_ENDPOINT = `https://api.8base.com/${WORKSPACE_ID}${
  ENVIRONMENT_NAME.toLowerCase() === 'master' || ENVIRONMENT_NAME === ''
    ? ''
    : `_${ENVIRONMENT_NAME}`
}`;
export const AUTH_PROFILE_ID = process.env.REACT_APP_AUTH0_PROFILE_ID;
export const AUTH_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID || '';
export const AUTH_CLIENT_DOMAIN = process.env.REACT_APP_AUTH0_CLIENT_DOMAIN || '';
export const EIGHTBASE_WS_ENDPOINT = 'wss://ws.8base.com';
export const AUTH_CLIENT_REDIRECT_URI = `${window.location.origin}/auth/callback`;
export const AUTH_CLIENT_REDIRECT_LOGOUT = `${window.location.origin}/logout`;
export const { PUBLIC_URL } = process.env;
export const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
export const COUNTRY_CODE = process.env.REACT_APP_COUNTRY_CODE;

export const NOTIFICATION_TYPES = {
  USER_NEW_MESSAGE: 'USER_NEW_MESSAGE',
  USER_BOOKING_CONFIRMED: 'USER_BOOKING_CONFIRMED',
  USER_BOOKIING_REJECTED: 'USER_BOOKIING_REJECTED',
  ADMIN_NEW_MESSAGE: 'ADMIN_NEW_MESSAGE',
  ADMIN_BOOKING_CREATED: 'ADMIN_BOOKING_CREATED',
  ADMIN_BOOKING_PAID: 'ADMIN_BOOKING_PAID',
};

export const TIME_ZONES = [
  {
    id: 1,
    timezone: 'America/New_York',
    gmtFormat: '(GMT-4:00) Eastern Time (US & Canada)',
  },
  {
    id: 2,
    timezone: 'America/Chicago',
    gmtFormat: '(GMT-5:00) Central Time (US & Canada)',
  },
  {
    id: 3,
    timezone: 'America/Los_Angeles',
    gmtFormat: '(GMT-7:00) Pacific Time (US & Canada)',
  },
  {
    id: 4,
    timezone: 'America/Phoenix',
    gmtFormat: '(GMT-7:00) Mountain Time (US & Canada)',
  },
  {
    id: 5,
    timezone: 'Pacific/Honolulu',
    gmtFormat: '(GMT-10:00) Hawaii Time, (US)',
  },
];
