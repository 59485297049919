import gql from 'graphql-tag';

export const FETCH_BOOKING_STATUS = gql`
  query FetchBookingStatus($first: Int, $filter: BookingStatusFilter) {
    bookingStatusesList(first: $first, filter: $filter) {
      items {
        id
      }
    }
  }
`;
