import React, { useState, useContext, useEffect } from 'react';
import { useCallAction } from '@cobuildlab/react-simple-state';
import { useFormContext } from 'react-hook-form';
import { SelectCheckbox, SelectCheckboxItemType } from './SelectCheckbox';
import { fetchExperienceCategoriesAction as fetchCategoriesAction } from '../../../experience-category/experience-category-actions';
import { ExperienceCategoryType as CategoryType } from '../../../../shared/types';
import { ExperienceFormContext } from './ExperienceFormProvider';

/**
 * @returns {JSX.Element} Categories list.
 */
export const ExperienceCategorySelect: React.FC = () => {
  const {
    getValues,
    setValue,
    formState: { errors },
    clearErrors,
  } = useFormContext();
  const { experience } = useContext(ExperienceFormContext);
  const [categoryItems, setCategoryItems] = useState<SelectCheckboxItemType[]>(
    [],
  );

  const [fetchCategories, loading] = useCallAction(fetchCategoriesAction, {
    /**
     * @param {CategoryType[]} categories - List of categories.
     */
    onCompleted: (categories: CategoryType[]) => {
      /**
       * Ids of categories selecteds.
       */
      const selectedCategoryIds: string[] = getValues('categories') || [];

      const items = categories.map((category) => {
        const isChecked = selectedCategoryIds.find((id) => id === category.id);

        return {
          id: category.id,
          name: category.experienceCategoryName,
          checked: !!isChecked,
        };
      });

      setCategoryItems(items);
    },
  });

  useEffect(() => {
    if (experience) {
      const {
        experienceExperienceCategoryRelation: { items },
      } = experience;

      const categories = items?.map(
        ({ experienceCategory }) => experienceCategory.id,
      );

      setValue('categories', categories);
      fetchCategories();
    }
  }, [experience, setValue, fetchCategories]);

  /**
   * @param {string} id  - Id item.
   */
  const handleChangeState = (id: string): void => {
    const selectedCategories = categoryItems.map((category) => {
      if (category.id === id) {
        return {
          ...category,
          checked: !category.checked,
        };
      }

      return category;
    });

    setCategoryItems(selectedCategories);
  };

  /**
   * Function to apply change.
   */
  const handleApply = (): void => {
    const selectedCategories = categoryItems.filter(
      (category) => category.checked,
    );
    const categoryIds = selectedCategories.map((category) => category.id);

    if (categoryIds.length) {
      clearErrors('categories');
      setValue('categories', categoryIds);
    } else {
      setValue('categories', undefined);
    }
  };

  /**
   * Function to remove not apply pills.
   */
  const handleRemoveNoApplied = (): void => {
    /**
     *
     * Ids of categories selecteds.
     */
    const selectedCategoryIds: string[] = getValues('categories') || [];
    const selectedCategories = categoryItems.map((category) => {
      const isChecked = selectedCategoryIds.find((id) => id === category.id);
      return {
        ...category,
        checked: !!isChecked,
      };
    });

    setCategoryItems(selectedCategories);
  };

  /**
   * Function to remove item from pill.
   *
   * @param {string} id - Id of item.
   */
  const handleRemove = (id: string): void => {
    /**
     *
     * Ids of categories in form.
     */
    const selectedCategoryIds: string[] = getValues('categories') || [];
    const selectedCategories = categoryItems.filter(
      (category) => category.id !== id,
    );
    const categoryIdsToSave = selectedCategoryIds.filter(
      (categoryId) => categoryId !== id,
    );

    setCategoryItems(selectedCategories);

    if (categoryIdsToSave.length) {
      setValue('categories', categoryIdsToSave);
    } else {
      setValue('categories', undefined);
    }
  };

  return (
    <SelectCheckbox
      label="Categories"
      items={categoryItems}
      loading={loading}
      hasError={!!errors?.categories}
      errorMessage={errors?.categories?.message}
      onFetchData={fetchCategories}
      onChangeState={handleChangeState}
      onRemoveNoApplied={handleRemoveNoApplied}
      onRemove={handleRemove}
      onApply={handleApply}
    />
  );
};
