import { createAction } from '@cobuildlab/react-simple-state';
import moment from 'moment';
import { apolloClient } from '../../shared/apollo';
import { BookingType, SortType } from '../../shared/types';
import {
  OnFetchBookings,
  OnErrorFetchBookings,
  OnBookingConfirm,
  OnBookingConfirmError,
  OnBookingReject,
  OnBookingRejectError,
  OnFetchBooking,
  OnErrorFetchBooking
} from './booking-events';
import { BookingListDataType } from './booking-model';
import {
  FETCH_BOOKING,
  FETCH_BOOKINGS,
  REFUND_BOOKING,
  UPDATE_BOOKING
} from './booking-queries';
import { createFilterBookingsList } from './booking-utils';
import { FETCH_BOOKING_STATUS } from '../booking-status/booking-status-queries';

/**
 * @description - Fetch bookings.
 */
export const fetchBookingsAction = createAction(
  OnFetchBookings,
  OnErrorFetchBookings,
  async (
    data: BookingListDataType,
    page: number,
    sortData: SortType,
    first = 10
  ) => {
    const filter = createFilterBookingsList(data);
    const skip = (page - 1) * first;
    let sort;

    if (sortData && Object.keys(sortData).length) {
      sort = sortData;
    }

    const response = await apolloClient.query({
      query: FETCH_BOOKINGS,
      fetchPolicy: 'network-only',
      variables: { filter, skip, first, sort }
    });

    return response.data.bookingsList;
  }
);

/**
 * @description - Confirm booking.
 */
export const confirmBookingAction = createAction(
  OnBookingConfirm,
  OnBookingConfirmError,
  async (booking: BookingType) => {
    const confirmedStatusResponse = await apolloClient.query({
      query: FETCH_BOOKING_STATUS,
      fetchPolicy: 'network-only',
      variables: {
        first: 1,
        filter: { statusName: { equals: 'CONFIRMED' } }
      }
    });

    const approvedDate = moment();
    const timeElapsed = Math.round(
      moment.duration(approvedDate.diff(booking.createdAt)).asSeconds()
    );

    const response = await apolloClient.mutate({
      mutation: UPDATE_BOOKING,
      fetchPolicy: 'network-only',
      variables: {
        data: {
          id: booking.id,
          bookingStatus: {
            reconnect: {
              id: confirmedStatusResponse.data.bookingStatusesList.items[0].id
            }
          },
          approvedDate,
          timeElapsed
        }
      }
    });

    return response?.data?.bookingUpdate?.id;
  }
);

/**
 * @description - Reject booking.
 */
export const rejectBookingAction = createAction(
  OnBookingReject,
  OnBookingRejectError,
  async (id: string, userEmail: string, reason: string, refundAmount: number) => {
    const refundResponse = await apolloClient.mutate({
      mutation: REFUND_BOOKING,
      fetchPolicy: 'network-only',
      variables: {
        data: {
          bookingId: id,
          email: userEmail,
          reason,
          refundAmount
        }
      }
    });
    console.log(refundResponse);

    return refundResponse;
  }
);

/**
 * @description - Fetch booking.
 */
export const fetchBookingAction = createAction(
  OnFetchBooking,
  OnErrorFetchBooking,
  async (id: string) => {
    const response = await apolloClient.query({
      query: FETCH_BOOKING,
      fetchPolicy: 'network-only',
      variables: { id }
    });

    return response.data.booking;
  }
);
